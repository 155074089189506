import { put, call, fork, takeLatest, takeEvery, select } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { defaultValue } from 'admin/pages/home/common/tfai_account_titles/tfaiAccountTitleDefaultValue'
import * as entireConfigDuck from './entireConfig.duck'
import * as loadingDuck from './loading.duck'
//utils
import { resortPosition } from 'shared/utils/arrayMethod'

// Actions
export const actionTypes = {
  CREATE_TFAI_ACCOUNT_TITLE: 'tfaiAccountTitle/CREATE_TFAI_ACCOUNT_TITLE',
  FETCH_TFAI_ACCOUNT_TITLES: 'tfaiAccountTitle/FETCH_TFAI_ACCOUNT_TITLES',
  FETCH_TFAI_ACCOUNT_TITLE: 'tfaiAccountTitle/FETCH_TFAI_ACCOUNT_TITLE',
  UPADE_TFAI_ACCOUNT_TITLE: 'tfaiAccountTitle/UPADE_TFAI_ACCOUNT_TITLE',
  DELETE_TFAI_ACCOUNT_TITLE: 'tfaiAccountTitle/DELETE_TFAI_ACCOUNT_TITLE',
  DELETE_TFAI_ACCOUNT_TITLE_BY_POSITION: 'tfaiAccountTitle/DELETE_TFAI_ACCOUNT_TITLE_BY_POSITION',

  TFAI_ACCOUNT_TITLE_CREATED: 'tfaiAccountTitle/TFAI_ACCOUNT_TITLE_CREATED',
  TFAI_ACCOUNT_TITLES_LOADED: 'tfaiAccountTitle/TFAI_ACCOUNT_TITLES_LOADED',
  TFAI_ACCOUNT_TITLE_LOADED: 'tfaiAccountTitle/TFAI_ACCOUNT_TITLE_LOADED',
  TFAI_ACCOUNT_TITLE_UPDATED: 'tfaiAccountTitle/TFAI_ACCOUNT_TITLE_UPDATED',
  TFAI_ACCOUNT_TITLE_DELETED: 'tfaiAccountTitle/TFAI_ACCOUNT_TITLE_DELETED',

  INSERT_NEW_ROW: 'tfaiAccountTitle/INSERT_NEW_ROW',

  UPDATE_GRID_INDEX: 'tfaiAccountTitle/UPDATE_GRID_INDEX',
}

const initialState = {
  tfaiAccountTitle: null,
  tfaiAccountTitles: null,
  agGridIndex: 0,
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_TFAI_ACCOUNT_TITLE:
      return { ...state, tfaiAccountTitle: action.payload }
    case actionTypes.FETCH_TFAI_ACCOUNT_TITLES:
      return { ...state, tfaiAccountTitles: null }
    case actionTypes.FETCH_TFAI_ACCOUNT_TITLE:
      return { ...state, tfaiAccountTitle: null }
    case actionTypes.UPADE_TFAI_ACCOUNT_TITLE:
      return { ...state, tfaiAccountTitle: action.payload }
    case actionTypes.DELETE_TFAI_ACCOUNT_TITLE:
      return state

    case actionTypes.TFAI_ACCOUNT_TITLE_CREATED:
      return !state.tfaiAccountTitles ? { ...state, tfaiAccountTitles: [action.payload] } : { ...state, tfaiAccountTitles: [...state.tfaiAccountTitles, action.payload] }
    case actionTypes.TFAI_ACCOUNT_TITLES_LOADED:
      return { ...state, tfaiAccountTitles: resortPosition(action.payload) }
    case actionTypes.TFAI_ACCOUNT_TITLE_LOADED:
      return { ...state, tfaiAccountTitle: action.payload }
    case actionTypes.TFAI_ACCOUNT_TITLE_UPDATED:
      return {
        ...state,
        tfaiAccountTitle: action.payload,
        tfaiAccountTitles: state.tfaiAccountTitles.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.TFAI_ACCOUNT_TITLE_DELETED:
      const { position: deleteIdPosition, id: deleteId } = action.payload
      return {
        ...state,
        tfaiAccountTitles: resortPosition(state.tfaiAccountTitles.filter(item => item.id !== deleteId)),
        agGridIndex: deleteIdPosition
      }

    case actionTypes.DELETE_TFAI_ACCOUNT_TITLE_BY_POSITION:
      const { position: deletePosition} = action.payload
      return {
        ...state,
        tfaiAccountTitles: resortPosition(state.tfaiAccountTitles.filter(item => item.position !== deletePosition)),
        agGridIndex: deletePosition
      }

    case actionTypes.INSERT_NEW_ROW:
      return {
        ...state,
        tfaiAccountTitles: state?.tfaiAccountTitles ? resortPosition([...state.tfaiAccountTitles, { ...defaultValue }]) : [{ ...defaultValue }],
        agGridIndex: state?.tfaiAccountTitles?.length ? state?.tfaiAccountTitles?.length : 0
      }

    case actionTypes.UPDATE_GRID_INDEX:
      const { index: gridIndex } = action.payload
      return { ...state, agGridIndex: gridIndex}

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createTfaiAccountTitle: (tfaiAccountTitle, { callback, isLast }) => ({ type: actionTypes.CREATE_TFAI_ACCOUNT_TITLE, payload: { tfaiAccountTitle, callback, isLast } }),
  fetchTfaiAccountTitles: () => ({ type: actionTypes.FETCH_TFAI_ACCOUNT_TITLES }),
  fetchTfaiAccountTitle: (id) => ({ type: actionTypes.FETCH_TFAI_ACCOUNT_TITLE, payload: id }),
  updateTfaiAccountTitle: (tfaiAccountTitle, { callback, isLast }) => ({ type: actionTypes.UPADE_TFAI_ACCOUNT_TITLE, payload: { tfaiAccountTitle, callback, isLast } }),
  deleteTfaiAccountTitle: (id, position) => ({ type: actionTypes.DELETE_TFAI_ACCOUNT_TITLE, payload: { id, position } }),
  deleteTfaiAccountTitleByPosition: (position) => ({ type: actionTypes.DELETE_TFAI_ACCOUNT_TITLE_BY_POSITION, payload: {position} }),
  tfaiAccountTitleCreated: (tfaiAccountTitle) => ({ type: actionTypes.TFAI_ACCOUNT_TITLE_CREATED, payload: tfaiAccountTitle }),
  tfaiAccountTitlesLoaded: (tfaiAccountTitles) => ({ type: actionTypes.TFAI_ACCOUNT_TITLES_LOADED, payload: tfaiAccountTitles }),
  tfaiAccountTitleLoaded: (tfaiAccountTitle) => ({ type: actionTypes.TFAI_ACCOUNT_TITLE_LOADED, payload: tfaiAccountTitle }),
  tfaiAccountTitleUpdated: (tfaiAccountTitle) => ({ type: actionTypes.TFAI_ACCOUNT_TITLE_UPDATED, payload: tfaiAccountTitle }),
  tfaiAccountTitleDeleted: (id, position) => ({ type: actionTypes.TFAI_ACCOUNT_TITLE_DELETED, payload: { id, position} }),

  insertNewRow: () => ({ type: actionTypes.INSERT_NEW_ROW }),

  updateGridIndex: (index) => ({ type: actionTypes.UPDATE_GRID_INDEX, payload: { index }}),
}

// Saga
export function * saga () {
  yield takeEvery(actionTypes.CREATE_TFAI_ACCOUNT_TITLE, function * createTfaiAccountTitleSaga (action) {
    const { tfaiAccountTitle, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.post, 'tfai_account_titles', tfaiAccountTitle)
      if (isLast) {
        yield put(actions.fetchTfaiAccountTitles())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_ACCOUNT_TITLES, function * fetchTfaiAccountTitlesSaga () {
    try {
      yield put(loadingDuck.actions.setLoading(true))
      const result = yield call(apiClient.get, 'tfai_account_titles')
      yield put(actions.tfaiAccountTitlesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      yield put(loadingDuck.actions.setLoading(false))
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_ACCOUNT_TITLE, function * fetchTfaiAccountTitleSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `tfai_account_titles/${id}`)
      yield put(actions.tfaiAccountTitleLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeEvery(actionTypes.UPADE_TFAI_ACCOUNT_TITLE, function * updateTfaiAccountTitleSaga (action) {
    const { tfaiAccountTitle, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.put, `tfai_account_titles/${tfaiAccountTitle.id}`, tfaiAccountTitle)
      if (isLast) {
        yield put(actions.fetchTfaiAccountTitles())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.DELETE_TFAI_ACCOUNT_TITLE, function * deleteTfaiAccountTitleSaga (action) {
    try {
      const { id, position } = action.payload
      yield call(apiClient.delete, `tfai_account_titles/${id}`)
      yield put(actions.tfaiAccountTitleDeleted(id, position))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
