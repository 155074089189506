import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_TAG: 'productTag/CREATE_PRODUCT_TAG',
  FETCH_PRODUCT_TAGS: 'productTag/FETCH_PRODUCT_TAGS',
  FETCH_PRODUCT_TAG: 'productTag/FETCH_PRODUCT_TAG',
  UPADE_PRODUCT_TAG: 'productTag/UPADE_PRODUCT_TAG',
  DELETE_PRODUCT_TAG: 'productTag/DELETE_PRODUCT_TAG',

  PRODUCT_TAG_CREATED: 'productTag/PRODUCT_TAG_CREATED',
  PRODUCT_TAGS_LOADED: 'productTag/PRODUCT_TAGS_LOADED',
  PRODUCT_TAG_LOADED: 'productTag/PRODUCT_TAG_LOADED',
  PRODUCT_TAG_UPDATED: 'productTag/PRODUCT_TAG_UPDATED',
  PRODUCT_TAG_DELETED: 'productTag/PRODUCT_TAG_DELETED'
}

const initialState = {
  productTag: null,
  productTags: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_TAG:
      return { ...state, productTag: action.payload }
    case actionTypes.FETCH_PRODUCT_TAGS:
      return { ...state, productTags: null }
    case actionTypes.FETCH_PRODUCT_TAG:
      return { ...state, productTag: null }
    case actionTypes.UPADE_PRODUCT_TAG:
      return { ...state, productTag: action.payload }
    case actionTypes.DELETE_PRODUCT_TAG:
      return state

    case actionTypes.PRODUCT_TAG_CREATED:
      return { ...state, productTags: [...state.productTags, action.payload] }
    case actionTypes.PRODUCT_TAGS_LOADED:
      return { ...state, productTags: action.payload }
    case actionTypes.PRODUCT_TAG_LOADED:
      return { ...state, productTag: action.payload }
    case actionTypes.PRODUCT_TAG_UPDATED:
      return {
        ...state,
        productTag: action.payload,
        productTags: state.productTags.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_TAG_DELETED:
      return { ...state, productTags: state.productTags.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductTag: (productTag, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_TAG, payload: { productTag, setSubmitting } }),
  fetchProductTags: () => ({ type: actionTypes.FETCH_PRODUCT_TAGS }),
  fetchProductTag: (id) => ({ type: actionTypes.FETCH_PRODUCT_TAG, payload: id }),
  updateProductTag: (productTag, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_TAG, payload: { productTag, setSubmitting } }),
  deleteProductTag: id => ({ type: actionTypes.DELETE_PRODUCT_TAG, payload: id }),
  productTagCreated: (productTag) => ({ type: actionTypes.PRODUCT_TAG_CREATED, payload: productTag }),
  productTagsLoaded: (productTags) => ({ type: actionTypes.PRODUCT_TAGS_LOADED, payload: productTags }),
  productTagLoaded: (productTag) => ({ type: actionTypes.PRODUCT_TAG_LOADED, payload: productTag }),
  productTagUpdated: (productTag) => ({ type: actionTypes.PRODUCT_TAG_UPDATED, payload: productTag }),
  productTagDeleted: id => ({ type: actionTypes.PRODUCT_TAG_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_TAG, function * createProductTagSaga (action) {
    const { productTag, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_tags', productTag)
      yield put(actions.productTagCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_TAGS, function * fetchProductTagsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_tags')
      yield put(actions.productTagsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_TAG, function * fetchProductTagSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_tags/${id}`)
      yield put(actions.productTagLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_TAG, function * updateProductTagSaga (action) {
    const { productTag, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_tags/${productTag.id}`, productTag)
      yield put(actions.productTagUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_TAG, function * deleteProductTagSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_tags/${id}`)
      yield put(actions.productTagDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
