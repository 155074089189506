import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_MINOR_CATEGORY: 'productMinorCategory/CREATE_PRODUCT_MINOR_CATEGORY',
  FETCH_PRODUCT_MINOR_CATEGORIES: 'productMinorCategory/FETCH_PRODUCT_MINOR_CATEGORIES',
  FETCH_PRODUCT_MINOR_CATEGORY: 'productMinorCategory/FETCH_PRODUCT_MINOR_CATEGORY',
  UPADE_PRODUCT_MINOR_CATEGORY: 'productMinorCategory/UPADE_PRODUCT_MINOR_CATEGORY',
  DELETE_PRODUCT_MINOR_CATEGORY: 'productMinorCategory/DELETE_PRODUCT_MINOR_CATEGORY',

  PRODUCT_MINOR_CATEGORY_CREATED: 'productMinorCategory/PRODUCT_MINOR_CATEGORY_CREATED',
  PRODUCT_MINOR_CATEGORIES_LOADED: 'productMinorCategory/PRODUCT_MINOR_CATEGORIES_LOADED',
  PRODUCT_MINOR_CATEGORY_LOADED: 'productMinorCategory/PRODUCT_MINOR_CATEGORY_LOADED',
  PRODUCT_MINOR_CATEGORY_UPDATED: 'productMinorCategory/PRODUCT_MINOR_CATEGORY_UPDATED',
  PRODUCT_MINOR_CATEGORY_DELETED: 'productMinorCategory/PRODUCT_MINOR_CATEGORY_DELETED'
}

const initialState = {
  productMinorCategory: null,
  productMinorCategories: []
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_MINOR_CATEGORY:
      return { ...state, productMinorCategory: action.payload }
    case actionTypes.FETCH_PRODUCT_MINOR_CATEGORIES:
      return { ...state, productMinorCategories: [] }
    case actionTypes.FETCH_PRODUCT_MINOR_CATEGORY:
      return { ...state, productMinorCategory: null }
    case actionTypes.UPADE_PRODUCT_MINOR_CATEGORY:
      return { ...state, productMinorCategory: action.payload }
    case actionTypes.DELETE_PRODUCT_MINOR_CATEGORY:
      return state

    case actionTypes.PRODUCT_MINOR_CATEGORY_CREATED:
      return {...state, productMinorCategories: [...state.productMinorCategories, action.payload] }
    case actionTypes.PRODUCT_MINOR_CATEGORIES_LOADED:
      return { ...state, productMinorCategories: action.payload }
    case actionTypes.PRODUCT_MINOR_CATEGORY_LOADED:
      return { ...state, productMinorCategory: action.payload }
    case actionTypes.PRODUCT_MINOR_CATEGORY_UPDATED:
      return {
        ...state,
        productMinorCategory: action.payload,
        productMinorCategories: state.productMinorCategories.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_MINOR_CATEGORY_DELETED:
      return { ...state, productMinorCategories: state.productMinorCategories.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductMinorCategory: (productMinorCategory, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_MINOR_CATEGORY, payload: { productMinorCategory, setSubmitting } }),
  fetchProductMinorCategories: () => ({ type: actionTypes.FETCH_PRODUCT_MINOR_CATEGORIES }),
  fetchProductMinorCategory: (id) => ({ type: actionTypes.FETCH_PRODUCT_MINOR_CATEGORY, payload: id }),
  updateProductMinorCategory: (productMinorCategory, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_MINOR_CATEGORY, payload: { productMinorCategory, setSubmitting } }),
  deleteProductMinorCategory: id => ({ type: actionTypes.DELETE_PRODUCT_MINOR_CATEGORY, payload: id }),
  productMinorCategoryCreated: (productMinorCategory) => ({ type: actionTypes.PRODUCT_MINOR_CATEGORY_CREATED, payload: productMinorCategory}),
  productMinorCategoriesLoaded: (productMinorCategories) => ({ type: actionTypes.PRODUCT_MINOR_CATEGORIES_LOADED, payload: productMinorCategories }),
  productMinorCategoryLoaded: (productMinorCategory) => ({ type: actionTypes.PRODUCT_MINOR_CATEGORY_LOADED, payload: productMinorCategory }),
  productMinorCategoryUpdated: (productMinorCategory) => ({ type: actionTypes.PRODUCT_MINOR_CATEGORY_UPDATED, payload: productMinorCategory }),
  productMinorCategoryDeleted: id => ({ type: actionTypes.PRODUCT_MINOR_CATEGORY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_MINOR_CATEGORY, function * createProductMinorCategorySaga (action) {
    const { productMinorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_minor_categories', productMinorCategory)
      yield put(actions.productMinorCategoryCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_MINOR_CATEGORIES, function * fetchProductMinorCategoriesSaga () {
    try {
      const result = yield call(apiClient.get, 'product_minor_categories')
      yield put(actions.productMinorCategoriesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_MINOR_CATEGORY, function * fetchProductMinorCategorySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_minor_categories/${id}`)
      yield put(actions.productMinorCategoryLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_MINOR_CATEGORY, function * updateProductMinorCategorySaga (action) {
    const { productMinorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_minor_categories/${productMinorCategory.id}`, productMinorCategory)
      yield put(actions.productMinorCategoryUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_MINOR_CATEGORY, function * deleteProductMinorCategorySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_minor_categories/${id}`)
      yield put(actions.productMinorCategoryDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
