import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PURCHASE: 'purchase/CREATE_PURCHASE',
  FETCH_PURCHASES: 'purchase/FETCH_PURCHASES',
  FETCH_PURCHASE: 'purchase/FETCH_PURCHASE',
  UPADE_PURCHASE: 'purchase/UPADE_PURCHASE',
  DELETE_PURCHASE: 'purchase/DELETE_PURCHASE',

  PURCHASE_CREATED: 'purchase/PURCHASE_CREATED',
  PURCHASES_LOADED: 'purchase/PURCHASES_LOADED',
  PURCHASE_LOADED: 'purchase/PURCHASE_LOADED',
  PURCHASE_UPDATED: 'purchase/PURCHASE_UPDATED',
  PURCHASE_DELETED: 'purchase/PURCHASE_DELETED'
}

const initialState = {
  purchase: null,
  purchases: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PURCHASE:
      return { ...state, purchase: action.payload }
    case actionTypes.FETCH_PURCHASES:
      return { ...state, purchases: null }
    case actionTypes.FETCH_PURCHASE:
      return { ...state, purchase: null }
    case actionTypes.UPADE_PURCHASE:
      return { ...state, purchase: action.payload }
    case actionTypes.DELETE_PURCHASE:
      return state

    case actionTypes.PURCHASES_LOADED:
      return { ...state, purchases: action.payload }
    case actionTypes.PURCHASE_LOADED:
      return { ...state, purchase: action.payload }
    case actionTypes.PURCHASE_UPDATED:
      return {
        ...state,
        purchase: action.payload,
        purchases: state.purchases.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PURCHASE_DELETED:
      return { ...state, purchases: state.purchases.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPurchase: (purchase, setSubmitting) => ({ type: actionTypes.CREATE_PURCHASE, payload: { purchase, setSubmitting } }),
  fetchPurchases: () => ({ type: actionTypes.FETCH_PURCHASES }),
  fetchPurchase: (id) => ({ type: actionTypes.FETCH_PURCHASE, payload: id }),
  updatePurchase: (purchase, setSubmitting) => ({ type: actionTypes.UPADE_PURCHASE, payload: { purchase, setSubmitting } }),
  deletePurchase: id => ({ type: actionTypes.DELETE_PURCHASE, payload: id }),
  purchasesLoaded: (purchases) => ({ type: actionTypes.PURCHASES_LOADED, payload: purchases }),
  purchaseLoaded: (purchase) => ({ type: actionTypes.PURCHASE_LOADED, payload: purchase }),
  purchaseUpdated: (purchase) => ({ type: actionTypes.PURCHASE_UPDATED, payload: purchase }),
  purchaseDeleted: id => ({ type: actionTypes.PURCHASE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PURCHASE, function * createPurchaseSaga (action) {
    const { purchase, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'purchases', purchase)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PURCHASES, function * fetchPurchasesSaga () {
    try {
      const result = yield call(apiClient.get, 'purchases')
      yield put(actions.purchasesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PURCHASE, function * fetchPurchaseSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `purchases/${id}`)
      yield put(actions.purchaseLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PURCHASE, function * updatePurchaseSaga (action) {
    const { purchase, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `purchases/${purchase.id}`, purchase)
      yield put(actions.purchaseUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PURCHASE, function * deletePurchaseSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `purchases/${id}`)
      yield put(actions.purchaseDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
