import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import * as entireConfig from 'admin/store/ducks/entireConfig.duck'

export default function ErrorMessageDialog() {
  const dispatch = useDispatch()
  const error = useSelector(({ entireConfigs: { error } }) => error)

  return (
    <Dialog open={error.status} maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="alert-dialog-title">{error.title || '失敗'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {typeof error.message !== 'string' ? '發生錯誤' : error.message}
        </DialogContentText>
        { error.content ? error.content() : null }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(entireConfig.actions.setIsError(false, ''))} color="primary">
          確認
        </Button>
      </DialogActions>
    </Dialog>
  )
}
