import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_TRADE_UNIT: 'tradeUnit/CREATE_TRADE_UNIT',
  FETCH_TRADE_UNITS: 'tradeUnit/FETCH_TRADE_UNITS',
  FETCH_TRADE_UNIT: 'tradeUnit/FETCH_TRADE_UNIT',
  UPADE_TRADE_UNIT: 'tradeUnit/UPADE_TRADE_UNIT',
  DELETE_TRADE_UNIT: 'tradeUnit/DELETE_TRADE_UNIT',

  TRADE_UNIT_CREATED: 'tradeUnit/TRADE_UNIT_CREATED',
  TRADE_UNITS_LOADED: 'tradeUnit/TRADE_UNITS_LOADED',
  TRADE_UNIT_LOADED: 'tradeUnit/TRADE_UNIT_LOADED',
  TRADE_UNIT_UPDATED: 'tradeUnit/TRADE_UNIT_UPDATED',
  TRADE_UNIT_DELETED: 'tradeUnit/TRADE_UNIT_DELETED'
}

const initialState = {
  tradeUnit: null,
  tradeUnits: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_TRADE_UNIT:
      return { ...state, tradeUnit: action.payload }
    case actionTypes.FETCH_TRADE_UNITS:
      return { ...state, tradeUnits: null }
    case actionTypes.FETCH_TRADE_UNIT:
      return { ...state, tradeUnit: null }
    case actionTypes.UPADE_TRADE_UNIT:
      return { ...state, tradeUnit: action.payload }
    case actionTypes.DELETE_TRADE_UNIT:
      return state

    case actionTypes.TRADE_UNIT_CREATED:
      return { ...state, tradeUnits: [...state.tradeUnits, action.payload] }
    case actionTypes.TRADE_UNITS_LOADED:
      return { ...state, tradeUnits: action.payload }
    case actionTypes.TRADE_UNIT_LOADED:
      return { ...state, tradeUnit: action.payload }
    case actionTypes.TRADE_UNIT_UPDATED:
      return {
        ...state,
        tradeUnit: action.payload,
        tradeUnits: state.tradeUnits.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.TRADE_UNIT_DELETED:
      return { ...state, tradeUnits: state.tradeUnits.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createTradeUnit: (tradeUnit, setSubmitting) => ({ type: actionTypes.CREATE_TRADE_UNIT, payload: { tradeUnit, setSubmitting } }),
  fetchTradeUnits: () => ({ type: actionTypes.FETCH_TRADE_UNITS }),
  fetchTradeUnit: (id) => ({ type: actionTypes.FETCH_TRADE_UNIT, payload: id }),
  updateTradeUnit: (tradeUnit, setSubmitting) => ({ type: actionTypes.UPADE_TRADE_UNIT, payload: { tradeUnit, setSubmitting } }),
  deleteTradeUnit: id => ({ type: actionTypes.DELETE_TRADE_UNIT, payload: id }),
  tradeUnitCreated: (tradeUnit) => ({ type: actionTypes.TRADE_UNIT_CREATED, payload: tradeUnit }),
  tradeUnitsLoaded: (tradeUnits) => ({ type: actionTypes.TRADE_UNITS_LOADED, payload: tradeUnits }),
  tradeUnitLoaded: (tradeUnit) => ({ type: actionTypes.TRADE_UNIT_LOADED, payload: tradeUnit }),
  tradeUnitUpdated: (tradeUnit) => ({ type: actionTypes.TRADE_UNIT_UPDATED, payload: tradeUnit }),
  tradeUnitDeleted: id => ({ type: actionTypes.TRADE_UNIT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_TRADE_UNIT, function * createTradeUnitSaga (action) {
    const { tradeUnit, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'trade_units', tradeUnit)
      yield put(actions.tradeUnitCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_TRADE_UNITS, function * fetchTradeUnitsSaga () {
    try {
      const result = yield call(apiClient.get, 'trade_units')
      yield put(actions.tradeUnitsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_TRADE_UNIT, function * fetchTradeUnitSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `trade_units/${id}`)
      yield put(actions.tradeUnitLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_TRADE_UNIT, function * updateTradeUnitSaga (action) {
    const { tradeUnit, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `trade_units/${tradeUnit.id}`, tradeUnit)
      yield put(actions.tradeUnitUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_TRADE_UNIT, function * deleteTradeUnitSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `trade_units/${id}`)
      yield put(actions.tradeUnitDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
