import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_POS_INFO: 'posInfo/CREATE_POS_INFO',
  FETCH_POS_INFOS: 'posInfo/FETCH_POS_INFOS',
  FETCH_POS_INFO: 'posInfo/FETCH_POS_INFO',
  UPADE_POS_INFO: 'posInfo/UPADE_POS_INFO',
  DELETE_POS_INFO: 'posInfo/DELETE_POS_INFO',

  POS_INFO_CREATED: 'posInfo/POS_INFO_CREATED',
  POS_INFOS_LOADED: 'posInfo/POS_INFOS_LOADED',
  POS_INFO_LOADED: 'posInfo/POS_INFO_LOADED',
  POS_INFO_UPDATED: 'posInfo/POS_INFO_UPDATED',
  POS_INFO_DELETED: 'posInfo/POS_INFO_DELETED'
}

const initialState = {
  posInfo: null,
  posInfos: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_POS_INFO:
      return { ...state, posInfo: action.payload }
    case actionTypes.FETCH_POS_INFOS:
      return { ...state, posInfos: null }
    case actionTypes.FETCH_POS_INFO:
      return { ...state, posInfo: null }
    case actionTypes.UPADE_POS_INFO:
      return { ...state, posInfo: action.payload }
    case actionTypes.DELETE_POS_INFO:
      return state

    case actionTypes.POS_INFO_CREATED:
      return { ...state, posInfos: [...state.posInfos, action.payload] }
    case actionTypes.POS_INFOS_LOADED:
      return { ...state, posInfos: action.payload }
    case actionTypes.POS_INFO_LOADED:
      return { ...state, posInfo: action.payload }
    case actionTypes.POS_INFO_UPDATED:
      return {
        ...state,
        posInfo: action.payload,
        posInfos: state.posInfos.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.POS_INFO_DELETED:
      return { ...state, posInfos: state.posInfos.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPosInfo: (posInfo, setSubmitting) => ({ type: actionTypes.CREATE_POS_INFO, payload: { posInfo, setSubmitting } }),
  fetchPosInfos: () => ({ type: actionTypes.FETCH_POS_INFOS }),
  fetchPosInfo: (id) => ({ type: actionTypes.FETCH_POS_INFO, payload: id }),
  updatePosInfo: (posInfo, setSubmitting) => ({ type: actionTypes.UPADE_POS_INFO, payload: { posInfo, setSubmitting } }),
  deletePosInfo: id => ({ type: actionTypes.DELETE_POS_INFO, payload: id }),
  posInfoCreated: (posInfo) => ({ type: actionTypes.POS_INFO_CREATED, payload: posInfo }),
  posInfosLoaded: (posInfos) => ({ type: actionTypes.POS_INFOS_LOADED, payload: posInfos }),
  posInfoLoaded: (posInfo) => ({ type: actionTypes.POS_INFO_LOADED, payload: posInfo }),
  posInfoUpdated: (posInfo) => ({ type: actionTypes.POS_INFO_UPDATED, payload: posInfo }),
  posInfoDeleted: id => ({ type: actionTypes.POS_INFO_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_POS_INFO, function * createPosInfoSaga (action) {
    const { posInfo, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'pos_infos', posInfo)
      yield put(actions.posInfoCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_INFOS, function * fetchPosInfosSaga () {
    try {
      const result = yield call(apiClient.get, 'pos_infos')
      yield put(actions.posInfosLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_INFO, function * fetchPosInfoSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `pos_infos/${id}`)
      yield put(actions.posInfoLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_POS_INFO, function * updatePosInfoSaga (action) {
    const { posInfo, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `pos_infos/${posInfo.id}`, posInfo)
      yield put(actions.posInfoUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_POS_INFO, function * deletePosInfoSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `pos_infos/${id}`)
      yield put(actions.posInfoDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
