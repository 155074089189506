import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_MATERIAL: 'material/CREATE_MATERIAL',
  FETCH_MATERIALS: 'material/FETCH_MATERIALS',
  FETCH_MATERIALS_FILTERED: 'material/FETCH_MATERIALS_FILTERED',
  FETCH_MATERIAL: 'material/FETCH_MATERIAL',
  UPADE_MATERIAL: 'material/UPADE_MATERIAL',
  DELETE_MATERIAL: 'material/DELETE_MATERIAL',

  MATERIAL_CREATED: 'material/MATERIAL_CREATED',
  MATERIALS_LOADED: 'material/MATERIALS_LOADED',
  MATERIAL_LOADED: 'material/MATERIAL_LOADED',
  MATERIAL_UPDATED: 'material/MATERIAL_UPDATED',
  MATERIAL_DELETED: 'material/MATERIAL_DELETED'
}

const initialState = {
  material: null,
  materials: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_MATERIAL:
      return { ...state, material: action.payload }
    case actionTypes.FETCH_MATERIALS:
      return { ...state, materials: null }
    case actionTypes.FETCH_MATERIAL:
      return { ...state, material: null }
    case actionTypes.UPADE_MATERIAL:
      return { ...state, material: action.payload }
    case actionTypes.DELETE_MATERIAL:
      return state

    case actionTypes.MATERIAL_CREATED:
      return { ...state, materials: [...state.materials, action.payload] }
    case actionTypes.MATERIALS_LOADED:
      return { ...state, materials: action.payload }
    case actionTypes.MATERIAL_LOADED:
      return { ...state, material: action.payload }
    case actionTypes.MATERIAL_UPDATED:
      return {
        ...state,
        material: action.payload,
        materials: state.materials.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.MATERIAL_DELETED:
      return { ...state, materials: state.materials.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createMaterial: (material, setSubmitting) => ({ type: actionTypes.CREATE_MATERIAL, payload: { material, setSubmitting } }),
  fetchMaterials: () => ({ type: actionTypes.FETCH_MATERIALS }),
  fetchMaterialsFiltered: (filter) => ({ type: actionTypes.FETCH_MATERIALS_FILTERED, payload: filter }),
  fetchMaterial: (id) => ({ type: actionTypes.FETCH_MATERIAL, payload: id }),
  updateMaterial: (material, setSubmitting) => ({ type: actionTypes.UPADE_MATERIAL, payload: { material, setSubmitting } }),
  deleteMaterial: id => ({ type: actionTypes.DELETE_MATERIAL, payload: id }),
  materialCreated: (material) => ({ type: actionTypes.MATERIAL_CREATED, payload: material }),
  materialsLoaded: (materials) => ({ type: actionTypes.MATERIALS_LOADED, payload: materials }),
  materialLoaded: (material) => ({ type: actionTypes.MATERIAL_LOADED, payload: material }),
  materialUpdated: (material) => ({ type: actionTypes.MATERIAL_UPDATED, payload: material }),
  materialDeleted: id => ({ type: actionTypes.MATERIAL_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_MATERIAL, function * createMaterialSaga (action) {
    const { material, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'materials', material)
      yield put(actions.materialCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIALS, function * fetchMaterialsSaga () {
    try {
      const result = yield call(apiClient.get, 'materials')
      yield put(actions.materialsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIALS_FILTERED, function * fetchMaterialsFilteredSaga (action) {
    try {
      const result = yield call(apiClient.get, `materials${action.payload}`)
      yield put(actions.materialsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIAL, function * fetchMaterialSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `materials/${id}`)
      yield put(actions.materialLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_MATERIAL, function * updateMaterialSaga (action) {
    const { material, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `materials/${material.id}`, material)
      yield put(actions.materialUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_MATERIAL, function * deleteMaterialSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `materials/${id}`)
      yield put(actions.materialDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
