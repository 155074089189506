// Actions
export const actionTypes = {
  SET_LOAD: 'entire/SET_LOAD',
  SET_IS_ERROR: 'entire/SET_IS_ERROR,',
}

const initialState = {
  loading: false,
  error: {
    status: false,
    message: '',
    title: '',
    content: () => {},
  },
}

// Reducers (Modifies The State And Returns A New State)
export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LOAD:
      return { ...state, loading: action.payload }

    case actionTypes.SET_IS_ERROR:
      const { status, message, title, content } = action.payload
      return { ...state, error: { ...state.error, status: status, message: typeof(message) === "object" ? "失敗" : message, title: title, content: content } }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  setLoad: (trueFalse) => ({ type: actionTypes.SET_LOAD, payload: trueFalse }),
  setIsError: (status, { message, title, content }) => ({ type: actionTypes.SET_IS_ERROR, payload: { status, message, title, content } }),
}
