import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_CONFIG: 'config/CREATE_CONFIG',
  FETCH_CONFIGS: 'config/FETCH_CONFIGS',
  FETCH_CONFIG: 'config/FETCH_CONFIG',
  UPADE_CONFIG: 'config/UPADE_CONFIG',
  DELETE_CONFIG: 'config/DELETE_CONFIG',

  CONFIG_CREATED: 'config/CONFIG_CREATED',
  CONFIGS_LOADED: 'config/CONFIGS_LOADED',
  CONFIG_LOADED: 'config/CONFIG_LOADED',
  CONFIG_UPDATED: 'config/CONFIG_UPDATED',
  CONFIG_DELETED: 'config/CONFIG_DELETED'
}

const initialState = {
  config: null,
  configs: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_CONFIG:
      return { ...state, config: action.payload }
    case actionTypes.FETCH_CONFIGS:
      return { ...state, configs: null }
    case actionTypes.FETCH_CONFIG:
      return { ...state, config: null }
    case actionTypes.UPADE_CONFIG:
      return { ...state, config: action.payload }
    case actionTypes.DELETE_CONFIG:
      return state

    case actionTypes.CONFIG_CREATED:
      return { ...state, configs: [...state.configs, action.payload] }
    case actionTypes.CONFIGS_LOADED:
      return { ...state, configs: action.payload }
    case actionTypes.CONFIG_LOADED:
      return { ...state, config: action.payload }
    case actionTypes.CONFIG_UPDATED:
      return {
        ...state,
        config: action.payload,
        configs: state.configs.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.CONFIG_DELETED:
      return { ...state, configs: state.configs.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createConfig: (config, setSubmitting) => ({ type: actionTypes.CREATE_CONFIG, payload: { config, setSubmitting } }),
  fetchConfigs: () => ({ type: actionTypes.FETCH_CONFIGS }),
  fetchConfig: (id) => ({ type: actionTypes.FETCH_CONFIG, payload: id }),
  updateConfig: (config, setSubmitting) => ({ type: actionTypes.UPADE_CONFIG, payload: { config, setSubmitting } }),
  deleteConfig: id => ({ type: actionTypes.DELETE_CONFIG, payload: id }),
  configCreated: (config) => ({ type: actionTypes.CONFIG_CREATED, payload: config }),
  configsLoaded: (configs) => ({ type: actionTypes.CONFIGS_LOADED, payload: configs }),
  configLoaded: (config) => ({ type: actionTypes.CONFIG_LOADED, payload: config }),
  configUpdated: (config) => ({ type: actionTypes.CONFIG_UPDATED, payload: config }),
  configDeleted: id => ({ type: actionTypes.CONFIG_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_CONFIG, function * createConfigSaga (action) {
    const { config, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'configs', config)
      yield put(actions.configCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_CONFIGS, function * fetchConfigsSaga () {
    try {
      const result = yield call(apiClient.get, 'configs')
      yield put(actions.configsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_CONFIG, function * fetchConfigSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `configs/${id}`)
      yield put(actions.configLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_CONFIG, function * updateConfigSaga (action) {
    const { config, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `configs/${config.id}`, config)
      yield put(actions.configUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_CONFIG, function * deleteConfigSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `configs/${id}`)
      yield put(actions.configDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
