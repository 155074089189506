import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import * as auth from './auth.duck'

// Actions
export const actionTypes = {
  CREATE_USER: 'user/CREATE_USER',
  FETCH_USERS: 'user/FETCH_USERS',
  FETCH_USER: 'user/FETCH_USER',
  UPADE_USER: 'user/UPADE_USER',
  DELETE_USER: 'user/DELETE_USER',

  USER_CREATED: 'user/USER_CREATED',
  USERS_LOADED: 'user/USERS_LOADED',
  USER_LOADED: 'user/USER_LOADED',
  USER_UPDATED: 'user/USER_UPDATED',
  USER_DELETED: 'user/USER_DELETED',

  UPDATE_USER_STORE: 'user/UPDATE_USER_STORE',
  UPDATE_USER_STORE_NO_RELOAD: 'user/UPDATE_USER_STORE_NO_RELOAD'
}

const initialState = {
  user: null,
  users: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_USER:
      return { ...state, user: action.payload }
    case actionTypes.FETCH_USERS:
      return { ...state, users: null }
    case actionTypes.FETCH_USER:
      return { ...state, user: null }
    case actionTypes.UPADE_USER:
      return { ...state, user: action.payload }
    case actionTypes.DELETE_USER:
      return state

    case actionTypes.USER_CREATED:
      return { ...state, users: [...state.users, action.payload] }
    case actionTypes.USERS_LOADED:
      return { ...state, users: action.payload }
    case actionTypes.USER_LOADED:
      return { ...state, user: action.payload }
    case actionTypes.USER_UPDATED:
      return {
        ...state,
        user: action.payload,
        users: state.users == null ? [action.payload] : state.users.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.USER_DELETED:
      return { ...state, users: state.users.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createUser: (user, setSubmitting) => ({ type: actionTypes.CREATE_USER, payload: { user, setSubmitting } }),
  fetchUsers: () => ({ type: actionTypes.FETCH_USERS }),
  fetchUser: (id) => ({ type: actionTypes.FETCH_USER, payload: id }),
  updateUser: (user, setSubmitting) => ({ type: actionTypes.UPADE_USER, payload: { user, setSubmitting } }),
  updateUserStore: (data) => ({ type: actionTypes.UPDATE_USER_STORE, payload: data }),
  updateUserStoreNoReload: (data) => ({ type: actionTypes.UPDATE_USER_STORE_NO_RELOAD, payload: data }),
  deleteUser: id => ({ type: actionTypes.DELETE_USER, payload: id }),
  userCreated: (user) => ({ type: actionTypes.USER_CREATED, payload: user }),
  usersLoaded: (users) => ({ type: actionTypes.USERS_LOADED, payload: users }),
  userLoaded: (user) => ({ type: actionTypes.USER_LOADED, payload: user }),
  userUpdated: (user) => ({ type: actionTypes.USER_UPDATED, payload: user }),
  userDeleted: id => ({ type: actionTypes.USER_DELETED, payload: id })

}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_USER, function * createUserSaga (action) {
    const { user, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'users', user)
      yield put(actions.userCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_USERS, function * fetchUsersSaga () {
    try {
      const result = yield call(apiClient.get, 'users')
      yield put(actions.usersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_USER, function * fetchUserSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `users/${id}`)
      yield put(actions.userLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_USER, function * updateUserSaga (action) {
    const { user, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `users/${user.id}`, user)
      yield put(actions.userUpdated(result.data.data))
      yield put(auth.actions.requestUser())
      
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_USER, function * deleteUserSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `users/${id}`)
      yield put(actions.userDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPDATE_USER_STORE, function * updateUserStoreSaga (action) {
    try {
      const { userId, storeId } = action.payload
      yield call(apiClient.put, `users/${userId}`, { store_id: storeId })
      yield put(auth.actions.requestedAndReload())
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPDATE_USER_STORE_NO_RELOAD, function * updateUserStoreSaga (action) {
    try {
      const { userId, storeId } = action.payload
      yield call(apiClient.put, `users/${userId}`, { store_id: storeId })
      yield put(auth.actions.requestUser())
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
