export const defaultValue = {
  code: '',
  name: '',
  trade_unit_id: '',
  salable: true,
  ec_price_taxed: true,
  list_price_taxed: true,
  sale_price_taxed: true,
  retail_price_taxed: true,
  employee_price_taxed: true,
  member_price_taxed: true,
  vip_price_taxed: true,
  togo_price_taxed: true,
  uber_eats_price_taxed: true,
  standard_purchase_price_taxed: false,
  tfai_department_id: '',
  tfai_account_title_id: '',
  tfai_project_id: '',
  tfai_product_id: '',
  tfai_description: '',
  tax_type: 1
}

export const searchDefValue = {
  product_major_category_id: '',
  product_medium_category_id: '',
  product_minor_category_id: '',
}