import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PURCHASE_ITEM: 'purchaseItem/CREATE_PURCHASE_ITEM',
  FETCH_PURCHASE_ITEMS: 'purchaseItem/FETCH_PURCHASE_ITEMS',
  FETCH_PURCHASE_ITEM: 'purchaseItem/FETCH_PURCHASE_ITEM',
  UPADE_PURCHASE_ITEM: 'purchaseItem/UPADE_PURCHASE_ITEM',
  DELETE_PURCHASE_ITEM: 'purchaseItem/DELETE_PURCHASE_ITEM',

  PURCHASE_ITEM_CREATED: 'purchaseItem/PURCHASE_ITEM_CREATED',
  PURCHASE_ITEMS_LOADED: 'purchaseItem/PURCHASE_ITEMS_LOADED',
  PURCHASE_ITEM_LOADED: 'purchaseItem/PURCHASE_ITEM_LOADED',
  PURCHASE_ITEM_UPDATED: 'purchaseItem/PURCHASE_ITEM_UPDATED',
  PURCHASE_ITEM_DELETED: 'purchaseItem/PURCHASE_ITEM_DELETED'
}

const initialState = {
  purchaseItem: null,
  purchaseItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PURCHASE_ITEM:
      return { ...state, purchaseItem: action.payload }
    case actionTypes.FETCH_PURCHASE_ITEMS:
      return { ...state, purchaseItems: null }
    case actionTypes.FETCH_PURCHASE_ITEM:
      return { ...state, purchaseItem: null }
    case actionTypes.UPADE_PURCHASE_ITEM:
      return { ...state, purchaseItem: action.payload }
    case actionTypes.DELETE_PURCHASE_ITEM:
      return state

    case actionTypes.PURCHASE_ITEM_CREATED:
      return !state.purchaseItems ? { ...state, purchaseItems: [action.payload] } : { ...state, purchaseItems: [...state.purchaseItems, action.payload] }
    case actionTypes.PURCHASE_ITEMS_LOADED:
      return { ...state, purchaseItems: action.payload }
    case actionTypes.PURCHASE_ITEM_LOADED:
      return { ...state, purchaseItem: action.payload }
    case actionTypes.PURCHASE_ITEM_UPDATED:
      return {
        ...state,
        purchaseItem: action.payload,
        purchaseItems: state.purchaseItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PURCHASE_ITEM_DELETED:
      return { ...state, purchaseItems: state.purchaseItems.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPurchaseItem: (purchaseItem, setSubmitting) => ({ type: actionTypes.CREATE_PURCHASE_ITEM, payload: { purchaseItem, setSubmitting } }),
  fetchPurchaseItems: () => ({ type: actionTypes.FETCH_PURCHASE_ITEMS }),
  fetchPurchaseItem: (id) => ({ type: actionTypes.FETCH_PURCHASE_ITEM, payload: id }),
  updatePurchaseItem: (purchaseItem, setSubmitting) => ({ type: actionTypes.UPADE_PURCHASE_ITEM, payload: { purchaseItem, setSubmitting } }),
  deletePurchaseItem: id => ({ type: actionTypes.DELETE_PURCHASE_ITEM, payload: id }),
  purchaseItemCreated: (purchaseItem) => ({ type: actionTypes.PURCHASE_ITEM_CREATED, payload: purchaseItem }),
  purchaseItemsLoaded: (purchaseItems) => ({ type: actionTypes.PURCHASE_ITEMS_LOADED, payload: purchaseItems }),
  purchaseItemLoaded: (purchaseItem) => ({ type: actionTypes.PURCHASE_ITEM_LOADED, payload: purchaseItem }),
  purchaseItemUpdated: (purchaseItem) => ({ type: actionTypes.PURCHASE_ITEM_UPDATED, payload: purchaseItem }),
  purchaseItemDeleted: id => ({ type: actionTypes.PURCHASE_ITEM_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PURCHASE_ITEM, function * createPurchaseItemSaga (action) {
    const { purchaseItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'purchase_items', purchaseItem)
      yield put(actions.purchaseItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PURCHASE_ITEMS, function * fetchPurchaseItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'purchase_items')
      yield put(actions.purchaseItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PURCHASE_ITEM, function * fetchPurchaseItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `purchase_items/${id}`)
      yield put(actions.purchaseItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PURCHASE_ITEM, function * updatePurchaseItemSaga (action) {
    const { purchaseItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `purchase_items/${purchaseItem.id}`, purchaseItem)
      yield put(actions.purchaseItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PURCHASE_ITEM, function * deletePurchaseItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `purchase_items/${id}`)
      yield put(actions.purchaseItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
