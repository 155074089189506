import React, { useEffect } from 'react'
import { environment } from 'environment'
import { Routes } from 'constants/common'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle'
import * as store from 'admin/store/ducks/store.duck'
import * as user from 'admin/store/ducks/user.duck'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

const StoreSelector = (props) => {
  const dispatch = useDispatch()
  const storesPermitted = useSelector(state => state.auth.user.stores)

  useEffect(() => {
    dispatch(store.actions.fetchStoresPermitted())
  }, [dispatch])

  
  if(storesPermitted === undefined || storesPermitted.length <= 0) {
    notifyError('您無任何門市操作權限!!')
  }

  const currentStoreId = useSelector(state => state.auth.user.store_id === undefined ? 0 : state.auth.user.store_id)
  const currentStoreIndex = storesPermitted.map(item => item.id).indexOf(currentStoreId)
  const currentStore = useSelector(state => state.auth.user.stores[currentStoreIndex < 0 ? 0 : currentStoreIndex])
  const currentUserId = useSelector(state => state.auth.user.id)

  if(currentStore != undefined && currentStoreIndex < 0) {
    dispatch(user.actions.updateUserStore({ userId: currentUserId, storeId: currentStore.id }))
  }

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs store-selector-wrapper"
      drop="down" alignRight
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
        <span className="store-selector-label">
          {`${currentStore === undefined ? '' : currentStore.code}-${currentStore === undefined ? '' : currentStore.short_name}`}

        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
          {storesPermitted.map(store => (
            <li
              key={store.id}
              className={clsx('kt-nav__item', {
                'kt-nav__item--active': store.id === currentStore.id
              })}
            >
              <span
                onClick={() => {
                  dispatch(user.actions.updateUserStore({ userId: currentUserId, storeId: store.id }))
                }}
                className={clsx('kt-nav__link', {
                  'kt-nav__link--active':
                      store.id === currentStore.id
                })}
              >
                <span className="kt-nav__link-icon">
                  {store.code}
                </span>
                <span className="kt-nav__link-text">{store.short_name}</span>
              </span>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default StoreSelector
