import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { insertEmptyRow, insertRows } from 'shared/itemHelper'

// Actions
export const actionTypes = {
  CREATE_ACCOUNT_VOUCHERS_ITEM: 'accountVouchersItem/CREATE_ACCOUNT_VOUCHERS_ITEM',
  FETCH_ACCOUNT_VOUCHERS_ITEMS: 'accountVouchersItem/FETCH_ACCOUNT_VOUCHERS_ITEMS',
  FETCH_ACCOUNT_VOUCHERS_ITEM: 'accountVouchersItem/FETCH_ACCOUNT_VOUCHERS_ITEM',
  UPADE_ACCOUNT_VOUCHERS_ITEM: 'accountVouchersItem/UPADE_ACCOUNT_VOUCHERS_ITEM',
  DELETE_ACCOUNT_VOUCHERS_ITEM: 'accountVouchersItem/DELETE_ACCOUNT_VOUCHERS_ITEM',

  ACCOUNT_VOUCHERS_ITEM_CREATED: 'accountVouchersItem/ACCOUNT_VOUCHERS_ITEM_CREATED',
  ACCOUNT_VOUCHERS_ITEMS_LOADED: 'accountVouchersItem/ACCOUNT_VOUCHERS_ITEMS_LOADED',
  ACCOUNT_VOUCHERS_ITEM_LOADED: 'accountVouchersItem/ACCOUNT_VOUCHERS_ITEM_LOADED',
  ACCOUNT_VOUCHERS_ITEM_UPDATED: 'accountVouchersItem/ACCOUNT_VOUCHERS_ITEM_UPDATED',
  ACCOUNT_VOUCHERS_ITEM_DELETED: 'accountVouchersItem/ACCOUNT_VOUCHERS_ITEM_DELETED',

  INSERT_EMPTY_ROW: 'accountVouchersItem/INSERT_EMPTY_ROW',
  DELETE_ROW: 'accountVouchersItem/DELETE_ROW',

  INSERT_ACCOUNT_TITLE: 'accountVoucher/INSERT_ACCOUNT_TITLE',
  INSERT_PROJECT: 'accountVoucher/INSERT_PROJECT',
  INSERT_SUPPLIER_CUSTOMER: 'accountVoucher/INSERT_SUPPLIER_CUSTOMER'
}

const initialState = {
  accountVouchersItem: null,
  accountVouchersItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ACCOUNT_VOUCHERS_ITEM:
      return { ...state, accountVouchersItem: action.payload }
    case actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEMS:
      return { ...state, accountVouchersItems: null }
    case actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEM:
      return { ...state, accountVouchersItem: null }
    case actionTypes.UPADE_ACCOUNT_VOUCHERS_ITEM:
      return { ...state, accountVouchersItem: action.payload }
    case actionTypes.DELETE_ACCOUNT_VOUCHERS_ITEM:
      return state

    case actionTypes.ACCOUNT_VOUCHERS_ITEM_CREATED:
      return !state.accountVouchersItems ? { ...state, accountVouchersItems: [action.payload] } : { ...state, accountVouchersItems: [...state.accountVouchersItems, action.payload] }
    case actionTypes.ACCOUNT_VOUCHERS_ITEMS_LOADED:
      return { ...state, accountVouchersItems: action.payload }
    case actionTypes.ACCOUNT_VOUCHERS_ITEM_LOADED:
      return { ...state, accountVouchersItem: action.payload }
    case actionTypes.ACCOUNT_VOUCHERS_ITEM_UPDATED:
      return {
        ...state,
        accountVouchersItem: action.payload,
        accountVouchersItems: state.accountVouchersItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ACCOUNT_VOUCHERS_ITEM_DELETED:
      return { ...state, accountVouchersItems: state.accountVouchersItems.filter(item => item.id !== action.payload) }

    case actionTypes.INSERT_EMPTY_ROW:
      return {
        ...state,
        accountVouchersItem: insertEmptyRow(state.accountVouchersItem, action.payload)
      }

    case actionTypes.DELETE_ROW:
      const filtered = state.accountVouchersItem.filter(item => item.position !== action.payload)
      if (filtered.length  === 0) {
        return { ...state, accountVouchersItem: [{position: 1}]}
      } else {
        return { ...state, accountVouchersItem: filtered.map((item, index) => ({ ...item, position: index + 1 }))}
      }

      case actionTypes.INSERT_ACCOUNT_TITLE:
        const { position, row } = action.payload
        return {
          ...state,
          accountVouchersItem: state.accountVouchersItem.map(item => {
           return item.position === position ? 
              {
                ...item, 
                  account_title_code: row.data.code, 
                  name: row.data.name,
                  dc: row.data.dc,
                  is_journal: row.data.Is_journal
              } 
              : item
          })
        }

      case actionTypes.INSERT_PROJECT:
      const titlePosition = action.payload.position
      const titleRow = action.payload.row
        return {
          ...state,
          accountVouchersItem: state.accountVouchersItem.map(item => item.position === titlePosition ? { ...item, project_name: titleRow.data.name } : item)
        }

      case actionTypes.INSERT_SUPPLIER_CUSTOMER:
      const supCusPosition = action.payload.position
      const supCusRow = action.payload.row
        return {
          ...state,
          accountVouchersItem: state.accountVouchersItem.map(item => item.position === supCusPosition ? { ...item, supplier_code: supCusRow.data.code } : item)
        }      

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createAccountVouchersItem: (accountVouchersItem, setSubmitting) => ({ type: actionTypes.CREATE_ACCOUNT_VOUCHERS_ITEM, payload: { accountVouchersItem, setSubmitting } }),
  fetchAccountVouchersItems: () => ({ type: actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEMS }),
  fetchAccountVouchersItem: (id) => ({ type: actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEM, payload: id }),
  updateAccountVouchersItem: (accountVouchersItem, setSubmitting) => ({ type: actionTypes.UPADE_ACCOUNT_VOUCHERS_ITEM, payload: { accountVouchersItem, setSubmitting } }),
  deleteAccountVouchersItem: id => ({ type: actionTypes.DELETE_ACCOUNT_VOUCHERS_ITEM, payload: id }),
  accountVouchersItemCreated: (accountVouchersItem) => ({ type: actionTypes.ACCOUNT_VOUCHERS_ITEM_CREATED, payload: accountVouchersItem }),
  accountVouchersItemsLoaded: (accountVouchersItems) => ({ type: actionTypes.ACCOUNT_VOUCHERS_ITEMS_LOADED, payload: accountVouchersItems }),
  accountVouchersItemLoaded: (accountVouchersItem) => ({ type: actionTypes.ACCOUNT_VOUCHERS_ITEM_LOADED, payload: accountVouchersItem }),
  accountVouchersItemUpdated: (accountVouchersItem) => ({ type: actionTypes.ACCOUNT_VOUCHERS_ITEM_UPDATED, payload: accountVouchersItem }),
  accountVouchersItemDeleted: id => ({ type: actionTypes.ACCOUNT_VOUCHERS_ITEM_DELETED, payload: id }),

  insertEmptyRow: position => ({ type: actionTypes.INSERT_EMPTY_ROW, payload: position }),
  deleteRow: position => ({ type: actionTypes.DELETE_ROW, payload: position }),

  insertAccountTitle: (position, row) => ({ type: actionTypes.INSERT_ACCOUNT_TITLE, payload: { position, row } }),  
  insertProject: (position, row) => ({ type: actionTypes.INSERT_PROJECT, payload: { position, row } }),
  insertSupplierCustomer: (position, row) => ({ type: actionTypes.INSERT_SUPPLIER_CUSTOMER, payload: { position, row } })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ACCOUNT_VOUCHERS_ITEM, function * createAccountVouchersItemSaga (action) {
    const { accountVouchersItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'account_vouchers_items', accountVouchersItem)
      yield put(actions.accountVouchersItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEMS, function * fetchAccountVouchersItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'account_vouchers_items')
      yield put(actions.accountVouchersItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEM, function * fetchAccountVouchersItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `account_vouchers_items/${id}`)
      yield put(actions.accountVouchersItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ACCOUNT_VOUCHERS_ITEM, function * updateAccountVouchersItemSaga (action) {
    const { accountVouchersItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `account_vouchers_items/${accountVouchersItem.id}`, accountVouchersItem)
      yield put(actions.accountVouchersItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ACCOUNT_VOUCHERS_ITEM, function * deleteAccountVouchersItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `account_vouchers_items/${id}`)
      yield put(actions.accountVouchersItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
