import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_INVOICE: 'invoice/CREATE_INVOICE',
  FETCH_INVOICES: 'invoice/FETCH_INVOICES',
  FETCH_INVOICE: 'invoice/FETCH_INVOICE',
  UPADE_INVOICE: 'invoice/UPADE_INVOICE',
  DELETE_INVOICE: 'invoice/DELETE_INVOICE',

  INVOICE_CREATED: 'invoice/INVOICE_CREATED',
  INVOICES_LOADED: 'invoice/INVOICES_LOADED',
  INVOICE_LOADED: 'invoice/INVOICE_LOADED',
  INVOICE_UPDATED: 'invoice/INVOICE_UPDATED',
  INVOICE_DELETED: 'invoice/INVOICE_DELETED'
}

const initialState = {
  invoice: null,
  invoices: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_INVOICE:
      return { ...state, invoice: action.payload }
    case actionTypes.FETCH_INVOICES:
      return { ...state, invoices: null }
    case actionTypes.FETCH_INVOICE:
      return { ...state, invoice: null }
    case actionTypes.UPADE_INVOICE:
      return { ...state, invoice: action.payload }
    case actionTypes.DELETE_INVOICE:
      return state

    case actionTypes.INVOICES_LOADED:
      return { ...state, invoices: action.payload }
    case actionTypes.INVOICE_LOADED:
      return { ...state, invoice: action.payload }
    case actionTypes.INVOICE_UPDATED:
      return {
        ...state,
        invoice: action.payload,
        invoices: state.invoices.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.INVOICE_DELETED:
      return { ...state, invoices: state.invoices.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createInvoice: (invoice, setSubmitting) => ({ type: actionTypes.CREATE_INVOICE, payload: { invoice, setSubmitting } }),
  fetchInvoices: () => ({ type: actionTypes.FETCH_INVOICES }),
  fetchInvoice: (id) => ({ type: actionTypes.FETCH_INVOICE, payload: id }),
  updateInvoice: (invoice, setSubmitting) => ({ type: actionTypes.UPADE_INVOICE, payload: { invoice, setSubmitting } }),
  deleteInvoice: id => ({ type: actionTypes.DELETE_INVOICE, payload: id }),
  invoicesLoaded: (invoices) => ({ type: actionTypes.INVOICES_LOADED, payload: invoices }),
  invoiceLoaded: (invoice) => ({ type: actionTypes.INVOICE_LOADED, payload: invoice }),
  invoiceUpdated: (invoice) => ({ type: actionTypes.INVOICE_UPDATED, payload: invoice }),
  invoiceDeleted: id => ({ type: actionTypes.INVOICE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_INVOICE, function * createInvoiceSaga (action) {
    const { invoice, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'invoices', invoice)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_INVOICES, function * fetchInvoicesSaga () {
    try {
      const result = yield call(apiClient.get, 'invoices')
      yield put(actions.invoicesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_INVOICE, function * fetchInvoiceSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `invoices/${id}`)
      yield put(actions.invoiceLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_INVOICE, function * updateInvoiceSaga (action) {
    const { invoice, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `invoices/${invoice.id}`, invoice)
      yield put(actions.invoiceUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_INVOICE, function * deleteInvoiceSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `invoices/${id}`)
      yield put(actions.invoiceDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
