import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_ACCOUNT_GENERAL: 'accountGeneral/CREATE_ACCOUNT_GENERAL',
  FETCH_ACCOUNT_GENERALS: 'accountGeneral/FETCH_ACCOUNT_GENERALS',
  FETCH_ACCOUNT_GENERAL: 'accountGeneral/FETCH_ACCOUNT_GENERAL',
  UPADE_ACCOUNT_GENERAL: 'accountGeneral/UPADE_ACCOUNT_GENERAL',
  DELETE_ACCOUNT_GENERAL: 'accountGeneral/DELETE_ACCOUNT_GENERAL',

  ACCOUNT_GENERAL_CREATED: 'accountGeneral/ACCOUNT_GENERAL_CREATED',
  ACCOUNT_GENERALS_LOADED: 'accountGeneral/ACCOUNT_GENERALS_LOADED',
  ACCOUNT_GENERAL_LOADED: 'accountGeneral/ACCOUNT_GENERAL_LOADED',
  ACCOUNT_GENERAL_UPDATED: 'accountGeneral/ACCOUNT_GENERAL_UPDATED',
  ACCOUNT_GENERAL_DELETED: 'accountGeneral/ACCOUNT_GENERAL_DELETED'
}

const initialState = {
  accountGeneral: null,
  accountGenerals: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ACCOUNT_GENERAL:
      return { ...state, accountGeneral: action.payload }
    case actionTypes.FETCH_ACCOUNT_GENERALS:
      return { ...state, accountGenerals: null }
    case actionTypes.FETCH_ACCOUNT_GENERAL:
      return { ...state, accountGeneral: null }
    case actionTypes.UPADE_ACCOUNT_GENERAL:
      return { ...state, accountGeneral: action.payload }
    case actionTypes.DELETE_ACCOUNT_GENERAL:
      return state

    case actionTypes.ACCOUNT_GENERALS_LOADED:
      return { ...state, accountGenerals: action.payload }
    case actionTypes.ACCOUNT_GENERAL_LOADED:
      return { ...state, accountGeneral: action.payload }
    case actionTypes.ACCOUNT_GENERAL_UPDATED:
      return {
        ...state,
        accountGeneral: action.payload,
        accountGenerals: state.accountGenerals.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ACCOUNT_GENERAL_DELETED:
      return { ...state, accountGenerals: state.accountGenerals.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createAccountGeneral: (accountGeneral, setSubmitting) => ({ type: actionTypes.CREATE_ACCOUNT_GENERAL, payload: { accountGeneral, setSubmitting } }),
  fetchAccountGenerals: () => ({ type: actionTypes.FETCH_ACCOUNT_GENERALS }),
  fetchAccountGeneral: (id) => ({ type: actionTypes.FETCH_ACCOUNT_GENERAL, payload: id }),
  updateAccountGeneral: (accountGeneral, setSubmitting) => ({ type: actionTypes.UPADE_ACCOUNT_GENERAL, payload: { accountGeneral, setSubmitting } }),
  deleteAccountGeneral: id => ({ type: actionTypes.DELETE_ACCOUNT_GENERAL, payload: id }),
  accountGeneralsLoaded: (accountGenerals) => ({ type: actionTypes.ACCOUNT_GENERALS_LOADED, payload: accountGenerals }),
  accountGeneralLoaded: (accountGeneral) => ({ type: actionTypes.ACCOUNT_GENERAL_LOADED, payload: accountGeneral }),
  accountGeneralUpdated: (accountGeneral) => ({ type: actionTypes.ACCOUNT_GENERAL_UPDATED, payload: accountGeneral }),
  accountGeneralDeleted: id => ({ type: actionTypes.ACCOUNT_GENERAL_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ACCOUNT_GENERAL, function * createAccountGeneralSaga (action) {
    const { accountGeneral, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'account_generals', accountGeneral)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_GENERALS, function * fetchAccountGeneralsSaga () {
    try {
      const result = yield call(apiClient.get, 'account_generals')
      yield put(actions.accountGeneralsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_GENERAL, function * fetchAccountGeneralSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `account_generals/${id}`)
      yield put(actions.accountGeneralLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ACCOUNT_GENERAL, function * updateAccountGeneralSaga (action) {
    const { accountGeneral, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `account_generals/${accountGeneral.id}`, accountGeneral)
      yield put(actions.accountGeneralUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ACCOUNT_GENERAL, function * deleteAccountGeneralSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `account_generals/${id}`)
      yield put(actions.accountGeneralDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
