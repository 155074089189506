/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { _t } from '../../../../shared/sharedMethod'
import { Urls } from '../../../../admin/constants'

function BreadCrumbs (props) {
  const { items } = props
  if (!items || !items.length) {
    return ''
  }

  const length = items.length
  return (
    <div className="kt-subheader__breadcrumbs">
      <Link className="kt-subheader__breadcrumbs-home" to={Urls.HOME}>
        <i className="flaticon2-shelter" />
      </Link>
      <span className="kt-subheader__breadcrumbs-separator" />
      {items.map((item, index) => (
        <React.Fragment key={`bc${index}`}>
          <Link className="kt-subheader__breadcrumbs-link" to={ item.page ? `/${item.page}` : Urls.HOME}>
            { item.translate ? _t(item.translate) : item.title }
          </Link>
          {length !== index + 1 && (
            <span className="kt-subheader__breadcrumbs-separator" />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default BreadCrumbs
