import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_POS_TABLE: 'posTable/CREATE_POS_TABLE',
  FETCH_POS_TABLES: 'posTable/FETCH_POS_TABLES',
  FETCH_POS_TABLE: 'posTable/FETCH_POS_TABLE',
  UPADE_POS_TABLE: 'posTable/UPADE_POS_TABLE',
  DELETE_POS_TABLE: 'posTable/DELETE_POS_TABLE',

  POS_TABLE_CREATED: 'posTable/POS_TABLE_CREATED',
  POS_TABLES_LOADED: 'posTable/POS_TABLES_LOADED',
  POS_TABLE_LOADED: 'posTable/POS_TABLE_LOADED',
  POS_TABLE_UPDATED: 'posTable/POS_TABLE_UPDATED',
  POS_TABLE_DELETED: 'posTable/POS_TABLE_DELETED'
}

const initialState = {
  posTable: null,
  posTables: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_POS_TABLE:
      return { ...state, posTable: action.payload }
    case actionTypes.FETCH_POS_TABLES:
      return { ...state, posTables: null }
    case actionTypes.FETCH_POS_TABLE:
      return { ...state, posTable: null }
    case actionTypes.UPADE_POS_TABLE:
      return { ...state, posTable: action.payload }
    case actionTypes.DELETE_POS_TABLE:
      return state

    case actionTypes.POS_TABLE_CREATED:
      return { ...state, posTables: [...state.posTables, action.payload] }
    case actionTypes.POS_TABLES_LOADED:
      return { ...state, posTables: action.payload }
    case actionTypes.POS_TABLE_LOADED:
      return { ...state, posTable: action.payload }
    case actionTypes.POS_TABLE_UPDATED:
      return {
        ...state,
        posTable: action.payload,
        posTables: state.posTables.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.POS_TABLE_DELETED:
      return { ...state, posTables: state.posTables.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPosTable: (posTable, setSubmitting) => ({ type: actionTypes.CREATE_POS_TABLE, payload: { posTable, setSubmitting } }),
  fetchPosTables: () => ({ type: actionTypes.FETCH_POS_TABLES }),
  fetchPosTable: (id) => ({ type: actionTypes.FETCH_POS_TABLE, payload: id }),
  updatePosTable: (posTable, setSubmitting) => ({ type: actionTypes.UPADE_POS_TABLE, payload: { posTable, setSubmitting } }),
  deletePosTable: id => ({ type: actionTypes.DELETE_POS_TABLE, payload: id }),
  posTableCreated: (posTable) => ({ type: actionTypes.POS_TABLE_CREATED, payload: posTable }),
  posTablesLoaded: (posTables) => ({ type: actionTypes.POS_TABLES_LOADED, payload: posTables }),
  posTableLoaded: (posTable) => ({ type: actionTypes.POS_TABLE_LOADED, payload: posTable }),
  posTableUpdated: (posTable) => ({ type: actionTypes.POS_TABLE_UPDATED, payload: posTable }),
  posTableDeleted: id => ({ type: actionTypes.POS_TABLE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_POS_TABLE, function * createPosTableSaga (action) {
    const { posTable, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'pos_tables', posTable)
      yield put(actions.posTableCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_TABLES, function * fetchPosTablesSaga () {
    try {
      const result = yield call(apiClient.get, 'pos_tables')
      yield put(actions.posTablesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_TABLE, function * fetchPosTableSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `pos_tables/${id}`)
      yield put(actions.posTableLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_POS_TABLE, function * updatePosTableSaga (action) {
    const { posTable, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `pos_tables/${posTable.id}`, posTable)
      yield put(actions.posTableUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_POS_TABLE, function * deletePosTableSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `pos_tables/${id}`)
      yield put(actions.posTableDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
