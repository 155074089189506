import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import _, { isNull } from 'lodash'
import { toast } from 'react-toastify'
import ToastCard from './toast/ToastCard'
import toastConfig from '../config/toastConfig'
import { toastType } from '../admin/constants'
import { NEW_MODLE_ID } from 'constants/common'

// notify
export const notify = (text, type = toastType.ERROR, options = {}) => {
  const toastsConfig = toastConfig(options)
  toast(<ToastCard {...{ ...options, text, type }}/>, toastsConfig.config)
}

export const notifyError = (text, options = {}) => {
  notify(text, toastType.ERROR, options)
}

export const notifySuccess = (text, options = {}) => {
  notify(text, toastType.SUCCESS, options)
}

// Auth
export const getAuthToken = () => {
  const item = localStorage.getItem('persist:excalibur-auth')
  if (item) {
    const token = JSON.parse(localStorage.getItem('persist:excalibur-auth')).authToken
    if (token) {
      return JSON.parse(token)
    }
  }
  return null
}

// Localization
export const _t = (id) => {
  return <FormattedMessage id={id} defaultMessgae={id}/>
}

export const _tt = (id) => {
  const intl = useIntl()
  return intl.formatMessage({ id, defaultMessage: id })
}

export const _ti = (id, intl) => {
  return intl.formatMessage({ id, defaultMessage: id })
}

export const getFormattedOptions = (options, intl) => {
  const copyOptions = _.cloneDeep(options)
  copyOptions.map(option => (option.name = _ti(option.name, intl)))
  return copyOptions
}

export const createOptions = (items, labelFields, valueField) => {
  if (items) {
    let newItems = items.map((item) => {
      return {
        label: labelFields.map((attr) => item[attr]).join('-'),
        value: item[valueField],
      }
    })
    return [{ label: '--請選擇--', value: '' }, ...newItems]
  } else {
    return [{ label: '', value: '' }]
  }
}

export const getLocalStorageDataByEncryptKey = (keyName) => {
  return localStorage.getItem(keyName) ? JSON.parse(b64ToUtf8(localStorage.getItem(keyName))) : null
}

export const getLocalStorageDataByKey = (keyName) => {
  return localStorage.getItem(keyName) ? localStorage.getItem(keyName) : null
}

export const utf8ToB64 = (str) => {
  return window.btoa(encodeURIComponent(escape(str)))
}

export const b64ToUtf8 = (str) => {
  return unescape(decodeURIComponent(window.atob(str)))
}

export const weeksOptions = [
  { label: '一', value: 1 },
  { label: '二', value: 2 },
  { label: '三', value: 3 },
  { label: '四', value: 4 },
  { label: '五', value: 5 },
  { label: '六', value: 6 },
  { label: '日', value: 7 }
]

export const weeksAllValues = [1, 2, 3, 4, 5, 6, 7]

export const isNew = id => {
  return id && +id === NEW_MODLE_ID
}

// Ag-Grid
export const checkBoxCellRenderer = (params) => {
  if(params.value !== 'Y' && params.value !== 'N'){
    params.setValue(params.value === true || params.value === 'Y' ? 'Y' : 'N')
  }else{
     var input = document.createElement("input")
     
     input.type = "checkbox"
     input.value = params.value === true || params.value === 'Y' ? 'Y' : 'N'
     input.checked = params.value === true || params.value === 'Y' ? true : false
     
     input.onclick = function() {
      params.setValue(this.checked === true ? 'Y' : 'N')
    }
     
     return input
  }
}

// Date
export const timeStampToDate = (timeStamp) => {
    let datetime = new Date()
    datetime.setTime(timeStamp * 1000);
    let year = datetime.getFullYear();
    let month = (datetime.getMonth() + 1) < 10 ? '0' + (datetime.getMonth() + 1) : (datetime.getMonth() + 1);
    let date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
    return year + "-" + month + "-" + date
  }