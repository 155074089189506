import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

const timeStampToDate = (timeStamp) => {
  let datetime = new Date()
  datetime.setTime(timeStamp * 1000);
  let year = datetime.getFullYear();
  let month = (datetime.getMonth() + 1) < 10 ? '0' + (datetime.getMonth() + 1) : (datetime.getMonth() + 1);
  let date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
  return year + "-" + month + "-" + date
}
           

// Actions
export const actionTypes = {
  CREATE_SUPPLIER: 'supplier/CREATE_SUPPLIER',
  FETCH_SUPPLIERS: 'supplier/FETCH_SUPPLIERS',
  FETCH_SUPPLIER: 'supplier/FETCH_SUPPLIER',
  UPADE_SUPPLIER: 'supplier/UPADE_SUPPLIER',
  DELETE_SUPPLIER: 'supplier/DELETE_SUPPLIER',

  SEARCH_SUPPLIERS: 'supplier/SEARCH_SUPPLIERS',

  SUPPLIER_CREATED: 'supplier/SUPPLIER_CREATED',
  SUPPLIERS_LOADED: 'supplier/SUPPLIERS_LOADED',
  SUPPLIER_LOADED: 'supplier/SUPPLIER_LOADED',
  SUPPLIER_UPDATED: 'supplier/SUPPLIER_UPDATED',
  SUPPLIER_DELETED: 'supplier/SUPPLIER_DELETED'
}

const initialState = {
  supplier: null,
  suppliers: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SUPPLIER:
      return { ...state, supplier: action.payload }
    case actionTypes.FETCH_SUPPLIERS:
      return { ...state, suppliers: null }
    case actionTypes.FETCH_SUPPLIER:
      return { ...state, supplier: null }
    case actionTypes.UPADE_SUPPLIER:
      return { ...state, supplier: action.payload }
    case actionTypes.DELETE_SUPPLIER:
      return state

    case actionTypes.SEARCH_SUPPLIERS:
      return {...state, suppliers: null}

    case actionTypes.SUPPLIER_CREATED:
      return { ...state, suppliers: [...state.suppliers, action.payload] }
    case actionTypes.SUPPLIERS_LOADED:
      return { ...state, suppliers: action.payload }
    case actionTypes.SUPPLIER_LOADED:
      const result = Object.assign({}, {...action.payload, last_purchase_date: timeStampToDate(action.payload.last_purchase_date), 
                                                           void_date: timeStampToDate(action.payload.void_date)})
      return { ...state, supplier: result }
    case actionTypes.SUPPLIER_UPDATED:
      return {
        ...state,
        supplier: action.payload,
        suppliers: state.suppliers.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SUPPLIER_DELETED:
      return { ...state, suppliers: state.suppliers.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSupplier: (supplier, setSubmitting) => ({ type: actionTypes.CREATE_SUPPLIER, payload: { supplier, setSubmitting } }),
  fetchSuppliers: () => ({ type: actionTypes.FETCH_SUPPLIERS }),
  fetchSupplier: (id) => ({ type: actionTypes.FETCH_SUPPLIER, payload: id }),
  updateSupplier: (supplier, setSubmitting) => ({ type: actionTypes.UPADE_SUPPLIER, payload: { supplier, setSubmitting } }),
  deleteSupplier: id => ({ type: actionTypes.DELETE_SUPPLIER, payload: id }),

  searchSuppliers: (values, setSubmitting) => ({ type: actionTypes.SEARCH_SUPPLIERS, payload: { values, setSubmitting } }),

  supplierCreated: (supplier) => ({ type: actionTypes.SUPPLIER_CREATED, payload: supplier }),
  suppliersLoaded: (suppliers) => ({ type: actionTypes.SUPPLIERS_LOADED, payload: suppliers }),
  supplierLoaded: (supplier) => ({ type: actionTypes.SUPPLIER_LOADED, payload: supplier }),
  supplierUpdated: (supplier) => ({ type: actionTypes.SUPPLIER_UPDATED, payload: supplier }),
  supplierDeleted: id => ({ type: actionTypes.SUPPLIER_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SUPPLIER, function * createSupplierSaga (action) {
    const { supplier, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'suppliers', supplier)
      yield put(actions.supplierCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUPPLIERS, function * fetchSuppliersSaga () {
    try {
      const result = yield call(apiClient.get, 'suppliers')
      yield put(actions.suppliersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUPPLIER, function * fetchSupplierSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `suppliers/${id}`)
      yield put(actions.supplierLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SUPPLIER, function * updateSupplierSaga (action) {
    const { supplier, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `suppliers/${supplier.id}`, supplier)
      yield put(actions.supplierUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SUPPLIER, function * deleteSupplierSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `suppliers/${id}`)
      yield put(actions.supplierDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.SEARCH_SUPPLIERS, function * searchSuppliersSaga(action) {
    const { values, setSubmitting } = action.payload
    try{
      const result = yield call(apiClient.get, 'suppliers', { params: {
        code: !values.code ? null : values.code,
        name: !values.name ? null : values.name,
        tel: !values.tel ? null : values.tel,
        vat_no: !values.vat_no ? null : values.vat_no
      }})
      yield put(actions.suppliersLoaded(result.data.data))
    } catch(e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })
}
