import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_INVOICE_SET: 'invoiceSet/CREATE_INVOICE_SET',
  FETCH_INVOICE_SETS: 'invoiceSet/FETCH_INVOICE_SETS',
  FETCH_INVOICE_SET: 'invoiceSet/FETCH_INVOICE_SET',
  UPADE_INVOICE_SET: 'invoiceSet/UPADE_INVOICE_SET',
  DELETE_INVOICE_SET: 'invoiceSet/DELETE_INVOICE_SET',

  INVOICE_SET_CREATED: 'invoiceSet/INVOICE_SET_CREATED',
  INVOICE_SETS_LOADED: 'invoiceSet/INVOICE_SETS_LOADED',
  INVOICE_SET_LOADED: 'invoiceSet/INVOICE_SET_LOADED',
  INVOICE_SET_UPDATED: 'invoiceSet/INVOICE_SET_UPDATED',
  INVOICE_SET_DELETED: 'invoiceSet/INVOICE_SET_DELETED'
}

const initialState = {
  invoiceSet: null,
  invoiceSets: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_INVOICE_SET:
      return { ...state, invoiceSet: action.payload }
    case actionTypes.FETCH_INVOICE_SETS:
      return { ...state, invoiceSets: null }
    case actionTypes.FETCH_INVOICE_SET:
      return { ...state, invoiceSet: null }
    case actionTypes.UPADE_INVOICE_SET:
      return { ...state, invoiceSet: action.payload }
    case actionTypes.DELETE_INVOICE_SET:
      return state

    case actionTypes.INVOICE_SET_CREATED:
      return { ...state, invoiceSets: [...state.invoiceSets, action.payload] }
    case actionTypes.INVOICE_SETS_LOADED:
      return { ...state, invoiceSets: action.payload }
    case actionTypes.INVOICE_SET_LOADED:
      return { ...state, invoiceSet: action.payload }
    case actionTypes.INVOICE_SET_UPDATED:
      return {
        ...state,
        invoiceSet: action.payload,
        invoiceSets: state.invoiceSets.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.INVOICE_SET_DELETED:
      return { ...state, invoiceSets: state.invoiceSets.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createInvoiceSet: (invoiceSet, setSubmitting) => ({ type: actionTypes.CREATE_INVOICE_SET, payload: { invoiceSet, setSubmitting } }),
  fetchInvoiceSets: () => ({ type: actionTypes.FETCH_INVOICE_SETS }),
  fetchInvoiceSet: (id) => ({ type: actionTypes.FETCH_INVOICE_SET, payload: id }),
  updateInvoiceSet: (invoiceSet, setSubmitting) => ({ type: actionTypes.UPADE_INVOICE_SET, payload: { invoiceSet, setSubmitting } }),
  deleteInvoiceSet: id => ({ type: actionTypes.DELETE_INVOICE_SET, payload: id }),
  invoiceSetCreated: (invoiceSet) => ({ type: actionTypes.INVOICE_SET_CREATED, payload: invoiceSet }),
  invoiceSetsLoaded: (invoiceSets) => ({ type: actionTypes.INVOICE_SETS_LOADED, payload: invoiceSets }),
  invoiceSetLoaded: (invoiceSet) => ({ type: actionTypes.INVOICE_SET_LOADED, payload: invoiceSet }),
  invoiceSetUpdated: (invoiceSet) => ({ type: actionTypes.INVOICE_SET_UPDATED, payload: invoiceSet }),
  invoiceSetDeleted: id => ({ type: actionTypes.INVOICE_SET_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_INVOICE_SET, function * createInvoiceSetSaga (action) {
    const { invoiceSet, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'invoice_sets', invoiceSet)
      yield put(actions.invoiceSetCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_INVOICE_SETS, function * fetchInvoiceSetsSaga () {
    try {
      const result = yield call(apiClient.get, 'invoice_sets')
      yield put(actions.invoiceSetsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_INVOICE_SET, function * fetchInvoiceSetSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `invoice_sets/${id}`)
      yield put(actions.invoiceSetLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_INVOICE_SET, function * updateInvoiceSetSaga (action) {
    const { invoiceSet, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `invoice_sets/${invoiceSet.id}`, invoiceSet)
      yield put(actions.invoiceSetUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_INVOICE_SET, function * deleteInvoiceSetSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `invoice_sets/${id}`)
      yield put(actions.invoiceSetDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
