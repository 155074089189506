import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SERVICE: 'service/CREATE_SERVICE',
  FETCH_SERVICES: 'service/FETCH_SERVICES',
  FETCH_SERVICE: 'service/FETCH_SERVICE',
  UPADE_SERVICE: 'service/UPADE_SERVICE',
  DELETE_SERVICE: 'service/DELETE_SERVICE',

  SERVICE_CREATED: 'service/SERVICE_CREATED',
  SERVICES_LOADED: 'service/SERVICES_LOADED',
  SERVICE_LOADED: 'service/SERVICE_LOADED',
  SERVICE_UPDATED: 'service/SERVICE_UPDATED',
  SERVICE_DELETED: 'service/SERVICE_DELETED'
}

const initialState = {
  service: null,
  services: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SERVICE:
      return { ...state, service: action.payload }
    case actionTypes.FETCH_SERVICES:
      return { ...state, services: null }
    case actionTypes.FETCH_SERVICE:
      return { ...state, service: null }
    case actionTypes.UPADE_SERVICE:
      return { ...state, service: action.payload }
    case actionTypes.DELETE_SERVICE:
      return state

    case actionTypes.SERVICE_CREATED:
      return { ...state, services: [...state.services, action.payload] }
    case actionTypes.SERVICES_LOADED:
      return { ...state, services: action.payload }
    case actionTypes.SERVICE_LOADED:
      return { ...state, service: action.payload }
    case actionTypes.SERVICE_UPDATED:
      return {
        ...state,
        service: action.payload,
        services: state.services.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SERVICE_DELETED:
      return { ...state, services: state.services.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createService: (service, setSubmitting) => ({ type: actionTypes.CREATE_SERVICE, payload: { service, setSubmitting } }),
  fetchServices: () => ({ type: actionTypes.FETCH_SERVICES }),
  fetchService: (id) => ({ type: actionTypes.FETCH_SERVICE, payload: id }),
  updateService: (service, setSubmitting) => ({ type: actionTypes.UPADE_SERVICE, payload: { service, setSubmitting } }),
  deleteService: id => ({ type: actionTypes.DELETE_SERVICE, payload: id }),
  serviceCreated: (service) => ({ type: actionTypes.SERVICE_CREATED, payload: service }),
  servicesLoaded: (services) => ({ type: actionTypes.SERVICES_LOADED, payload: services }),
  serviceLoaded: (service) => ({ type: actionTypes.SERVICE_LOADED, payload: service }),
  serviceUpdated: (service) => ({ type: actionTypes.SERVICE_UPDATED, payload: service }),
  serviceDeleted: id => ({ type: actionTypes.SERVICE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SERVICE, function * createServiceSaga (action) {
    const { service, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'services', service)
      yield put(actions.serviceCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SERVICES, function * fetchServicesSaga () {
    try {
      const result = yield call(apiClient.get, 'services')
      yield put(actions.servicesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SERVICE, function * fetchServiceSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `services/${id}`)
      yield put(actions.serviceLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SERVICE, function * updateServiceSaga (action) {
    const { service, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `services/${service.id}`, service)
      yield put(actions.serviceUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SERVICE, function * deleteServiceSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `services/${id}`)
      yield put(actions.serviceDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
