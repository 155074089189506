import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_MATERIAL_MINOR_CATEGORY: 'materialMinorCategory/CREATE_MATERIAL_MINOR_CATEGORY',
  FETCH_MATERIAL_MINOR_CATEGORIES: 'materialMinorCategory/FETCH_MATERIAL_MINOR_CATEGORIES',
  FETCH_MATERIAL_MINOR_CATEGORY: 'materialMinorCategory/FETCH_MATERIAL_MINOR_CATEGORY',
  UPADE_MATERIAL_MINOR_CATEGORY: 'materialMinorCategory/UPADE_MATERIAL_MINOR_CATEGORY',
  DELETE_MATERIAL_MINOR_CATEGORY: 'materialMinorCategory/DELETE_MATERIAL_MINOR_CATEGORY',

  MATERIAL_MINOR_CATEGORY_CREATED: 'materialMinorCategory/MATERIAL_MINOR_CATEGORY_CREATED',
  MATERIAL_MINOR_CATEGORIES_LOADED: 'materialMinorCategory/MATERIAL_MINOR_CATEGORIES_LOADED',
  MATERIAL_MINOR_CATEGORY_LOADED: 'materialMinorCategory/MATERIAL_MINOR_CATEGORY_LOADED',
  MATERIAL_MINOR_CATEGORY_UPDATED: 'materialMinorCategory/MATERIAL_MINOR_CATEGORY_UPDATED',
  MATERIAL_MINOR_CATEGORY_DELETED: 'materialMinorCategory/MATERIAL_MINOR_CATEGORY_DELETED'
}

const initialState = {
  materialMinorCategory: null,
  materialMinorCategories: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_MATERIAL_MINOR_CATEGORY:
      return { ...state, materialMinorCategory: action.payload }
    case actionTypes.FETCH_MATERIAL_MINOR_CATEGORIES:
      return { ...state, materialMinorCategories: null }
    case actionTypes.FETCH_MATERIAL_MINOR_CATEGORY:
      return { ...state, materialMinorCategory: null }
    case actionTypes.UPADE_MATERIAL_MINOR_CATEGORY:
      return { ...state, materialMinorCategory: action.payload }
    case actionTypes.DELETE_MATERIAL_MINOR_CATEGORY:
      return state

    case actionTypes.MATERIAL_MINOR_CATEGORY_CREATED:
      return { ...state, materialMinorCategories: [...state.materialMinorCategories, action.payload] }
    case actionTypes.MATERIAL_MINOR_CATEGORIES_LOADED:
      return { ...state, materialMinorCategories: action.payload }
    case actionTypes.MATERIAL_MINOR_CATEGORY_LOADED:
      return { ...state, materialMinorCategory: action.payload }
    case actionTypes.MATERIAL_MINOR_CATEGORY_UPDATED:
      return {
        ...state,
        materialMinorCategory: action.payload,
        materialMinorCategories: state.materialMinorCategories.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.MATERIAL_MINOR_CATEGORY_DELETED:
      return { ...state, materialMinorCategories: state.materialMinorCategories.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createMaterialMinorCategory: (materialMinorCategory, setSubmitting) => ({ type: actionTypes.CREATE_MATERIAL_MINOR_CATEGORY, payload: { materialMinorCategory, setSubmitting } }),
  fetchMaterialMinorCategories: () => ({ type: actionTypes.FETCH_MATERIAL_MINOR_CATEGORIES }),
  fetchMaterialMinorCategory: (id) => ({ type: actionTypes.FETCH_MATERIAL_MINOR_CATEGORY, payload: id }),
  updateMaterialMinorCategory: (materialMinorCategory, setSubmitting) => ({ type: actionTypes.UPADE_MATERIAL_MINOR_CATEGORY, payload: { materialMinorCategory, setSubmitting } }),
  deleteMaterialMinorCategory: id => ({ type: actionTypes.DELETE_MATERIAL_MINOR_CATEGORY, payload: id }),
  materialMinorCategoryCreated: (materialMinorCategory) => ({ type: actionTypes.MATERIAL_MINOR_CATEGORY_CREATED, payload: materialMinorCategory }),
  materialMinorCategoriesLoaded: (materialMinorCategories) => ({ type: actionTypes.MATERIAL_MINOR_CATEGORIES_LOADED, payload: materialMinorCategories }),
  materialMinorCategoryLoaded: (materialMinorCategory) => ({ type: actionTypes.MATERIAL_MINOR_CATEGORY_LOADED, payload: materialMinorCategory }),
  materialMinorCategoryUpdated: (materialMinorCategory) => ({ type: actionTypes.MATERIAL_MINOR_CATEGORY_UPDATED, payload: materialMinorCategory }),
  materialMinorCategoryDeleted: id => ({ type: actionTypes.MATERIAL_MINOR_CATEGORY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_MATERIAL_MINOR_CATEGORY, function * createMaterialMinorCategorySaga (action) {
    const { materialMinorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'material_minor_categories', materialMinorCategory)
      yield put(actions.materialMinorCategoryCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIAL_MINOR_CATEGORIES, function * fetchMaterialMinorCategoriesSaga () {
    try {
      const result = yield call(apiClient.get, 'material_minor_categories')
      yield put(actions.materialMinorCategoriesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIAL_MINOR_CATEGORY, function * fetchMaterialMinorCategorySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `material_minor_categories/${id}`)
      yield put(actions.materialMinorCategoryLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_MATERIAL_MINOR_CATEGORY, function * updateMaterialMinorCategorySaga (action) {
    const { materialMinorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `material_minor_categories/${materialMinorCategory.id}`, materialMinorCategory)
      yield put(actions.materialMinorCategoryUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_MATERIAL_MINOR_CATEGORY, function * deleteMaterialMinorCategorySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `material_minor_categories/${id}`)
      yield put(actions.materialMinorCategoryDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
