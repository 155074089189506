import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_DEPARTMENT: 'department/CREATE_DEPARTMENT',
  FETCH_DEPARTMENTS: 'department/FETCH_DEPARTMENTS',
  FETCH_DEPARTMENT: 'department/FETCH_DEPARTMENT',
  UPADE_DEPARTMENT: 'department/UPADE_DEPARTMENT',
  DELETE_DEPARTMENT: 'department/DELETE_DEPARTMENT',

  DEPARTMENT_CREATED: 'department/DEPARTMENT_CREATED',
  DEPARTMENTS_LOADED: 'department/DEPARTMENTS_LOADED',
  DEPARTMENT_LOADED: 'department/DEPARTMENT_LOADED',
  DEPARTMENT_UPDATED: 'department/DEPARTMENT_UPDATED',
  DEPARTMENT_DELETED: 'department/DEPARTMENT_DELETED'
}

const initialState = {
  department: null,
  departments: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_DEPARTMENT:
      return { ...state, department: action.payload }
    case actionTypes.FETCH_DEPARTMENTS:
      return { ...state, departments: null }
    case actionTypes.FETCH_DEPARTMENT:
      return { ...state, department: null }
    case actionTypes.UPADE_DEPARTMENT:
      return { ...state, department: action.payload }
    case actionTypes.DELETE_DEPARTMENT:
      return state

    case actionTypes.DEPARTMENT_CREATED:
      return { ...state, departments: [...state.departments, action.payload] }
    case actionTypes.DEPARTMENTS_LOADED:
      return { ...state, departments: action.payload }
    case actionTypes.DEPARTMENT_LOADED:
      return { ...state, department: action.payload }
    case actionTypes.DEPARTMENT_UPDATED:
      return {
        ...state,
        department: action.payload,
        departments: state.departments.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.DEPARTMENT_DELETED:
      return { ...state, departments: state.departments.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createDepartment: (department, setSubmitting) => ({ type: actionTypes.CREATE_DEPARTMENT, payload: { department, setSubmitting } }),
  fetchDepartments: () => ({ type: actionTypes.FETCH_DEPARTMENTS }),
  fetchDepartment: (id) => ({ type: actionTypes.FETCH_DEPARTMENT, payload: id }),
  updateDepartment: (department, setSubmitting) => ({ type: actionTypes.UPADE_DEPARTMENT, payload: { department, setSubmitting } }),
  deleteDepartment: id => ({ type: actionTypes.DELETE_DEPARTMENT, payload: id }),
  departmentCreated: (department) => ({ type: actionTypes.DEPARTMENT_CREATED, payload: department }),
  departmentsLoaded: (departments) => ({ type: actionTypes.DEPARTMENTS_LOADED, payload: departments }),
  departmentLoaded: (department) => ({ type: actionTypes.DEPARTMENT_LOADED, payload: department }),
  departmentUpdated: (department) => ({ type: actionTypes.DEPARTMENT_UPDATED, payload: department }),
  departmentDeleted: id => ({ type: actionTypes.DEPARTMENT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_DEPARTMENT, function * createDepartmentSaga (action) {
    const { department, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'departments', department)
      yield put(actions.departmentCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_DEPARTMENTS, function * fetchDepartmentsSaga () {
    try {
      const result = yield call(apiClient.get, 'departments')
      yield put(actions.departmentsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_DEPARTMENT, function * fetchDepartmentSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `departments/${id}`)
      yield put(actions.departmentLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_DEPARTMENT, function * updateDepartmentSaga (action) {
    const { department, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `departments/${department.id}`, department)
      yield put(actions.departmentUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_DEPARTMENT, function * deleteDepartmentSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `departments/${id}`)
      yield put(actions.departmentDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
