import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_ERP_DEPARTMENT: 'erpDepartment/CREATE_ERP_DEPARTMENT',
  FETCH_ERP_DEPARTMENTS: 'erpDepartment/FETCH_ERP_DEPARTMENTS',
  FETCH_ERP_DEPARTMENT: 'erpDepartment/FETCH_ERP_DEPARTMENT',
  UPADE_ERP_DEPARTMENT: 'erpDepartment/UPADE_ERP_DEPARTMENT',
  DELETE_ERP_DEPARTMENT: 'erpDepartment/DELETE_ERP_DEPARTMENT',

  ERP_DEPARTMENT_CREATED: 'erpDepartment/ERP_DEPARTMENT_CREATED',
  ERP_DEPARTMENTS_LOADED: 'erpDepartment/ERP_DEPARTMENTS_LOADED',
  ERP_DEPARTMENT_LOADED: 'erpDepartment/ERP_DEPARTMENT_LOADED',
  ERP_DEPARTMENT_UPDATED: 'erpDepartment/ERP_DEPARTMENT_UPDATED',
  ERP_DEPARTMENT_DELETED: 'erpDepartment/ERP_DEPARTMENT_DELETED'
}

const initialState = {
  erpDepartment: null,
  erpDepartments: []
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ERP_DEPARTMENT:
      return { ...state, erpDepartment: action.payload }
    case actionTypes.FETCH_ERP_DEPARTMENTS:
      return { ...state, erpDepartments: [] }
    case actionTypes.FETCH_ERP_DEPARTMENT:
      return { ...state, erpDepartment: null }
    case actionTypes.UPADE_ERP_DEPARTMENT:
      return { ...state, erpDepartment: action.payload }
    case actionTypes.DELETE_ERP_DEPARTMENT:
      return state

    case actionTypes.ERP_DEPARTMENT_CREATED:
      return {...state, erpDepartments: [...state.erpDepartments, action.payload] }
    case actionTypes.ERP_DEPARTMENTS_LOADED:
      return { ...state, erpDepartments: action.payload }
    case actionTypes.ERP_DEPARTMENT_LOADED:
      return { ...state, erpDepartment: action.payload }
    case actionTypes.ERP_DEPARTMENT_UPDATED:
      return {
        ...state,
        erpDepartment: action.payload,
        erpDepartments: state.erpDepartments.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ERP_DEPARTMENT_DELETED:
      return { ...state, erpDepartments: state.erpDepartments.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createErpDepartment: (erpDepartment, setSubmitting) => ({ type: actionTypes.CREATE_ERP_DEPARTMENT, payload: { erpDepartment, setSubmitting } }),
  fetchErpDepartments: () => ({ type: actionTypes.FETCH_ERP_DEPARTMENTS }),
  fetchErpDepartment: (id) => ({ type: actionTypes.FETCH_ERP_DEPARTMENT, payload: id }),
  updateErpDepartment: (erpDepartment, setSubmitting) => ({ type: actionTypes.UPADE_ERP_DEPARTMENT, payload: { erpDepartment, setSubmitting } }),
  deleteErpDepartment: id => ({ type: actionTypes.DELETE_ERP_DEPARTMENT, payload: id }),
  erpDepartmentCreated: (erpDepartment) => ({ type: actionTypes.ERP_DEPARTMENT_CREATED, payload: erpDepartment}),
  erpDepartmentsLoaded: (erpDepartments) => ({ type: actionTypes.ERP_DEPARTMENTS_LOADED, payload: erpDepartments }),
  erpDepartmentLoaded: (erpDepartment) => ({ type: actionTypes.ERP_DEPARTMENT_LOADED, payload: erpDepartment }),
  erpDepartmentUpdated: (erpDepartment) => ({ type: actionTypes.ERP_DEPARTMENT_UPDATED, payload: erpDepartment }),
  erpDepartmentDeleted: id => ({ type: actionTypes.ERP_DEPARTMENT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ERP_DEPARTMENT, function * createErpDepartmentSaga (action) {
    const { erpDepartment, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'erp_departments', erpDepartment)
      yield put(actions.erpDepartmentCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ERP_DEPARTMENTS, function * fetchErpDepartmentsSaga () {
    try {
      const result = yield call(apiClient.get, 'erp_departments')
      yield put(actions.erpDepartmentsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ERP_DEPARTMENT, function * fetchErpDepartmentSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `erp_departments/${id}`)
      yield put(actions.erpDepartmentLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ERP_DEPARTMENT, function * updateErpDepartmentSaga (action) {
    const { erpDepartment, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `erp_departments/${erpDepartment.id}`, erpDepartment)
      yield put(actions.erpDepartmentUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ERP_DEPARTMENT, function * deleteErpDepartmentSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `erp_departments/${id}`)
      yield put(actions.erpDepartmentDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
