import { lazy } from 'react'
import { Permissions, Urls } from './constants'

const DemoReport = lazy(() => import('./pages/home/report/test'))

const Demo = lazy(() => import('./pages/home/dev/Demo'))
const DemoCreate = lazy(() => import('./pages/home/dev/DemoCreate'))
const DemoEdit = lazy(() => import('./pages/home/dev/DemoEdit'))

const HomePage = lazy(() => import('./pages/home/home/HomePage'))
const Stores = lazy(() => import('./pages/home/common/stores/Stores'))
const StoreCreate = lazy(() => import('./pages/home/common/stores/StoreCreate'))
const StoreEdit = lazy(() => import('./pages/home/common/stores/StoreEdit'))

// Component import generated by battlecry
const ProductOrders = lazy(() => import('./pages/home/common/product_orders/ProductOrders'))

const TfaiAccountTitles = lazy(() => import('./pages/home/common/tfai_account_titles/TfaiAccountTitles'))
const TfaiAccountTitleCreate = lazy(() => import('./pages/home/common/tfai_account_titles/TfaiAccountTitleCreate'))
const TfaiAccountTitleEdit = lazy(() => import('./pages/home/common/tfai_account_titles/TfaiAccountTitleEdit'))

const TfaiProducts = lazy(() => import('./pages/home/common/tfai_products/TfaiProducts'))
const TfaiProductCreate = lazy(() => import('./pages/home/common/tfai_products/TfaiProductCreate'))
const TfaiProductEdit = lazy(() => import('./pages/home/common/tfai_products/TfaiProductEdit'))

const TfaiDepartments = lazy(() => import('./pages/home/common/tfai_departments/TfaiDepartments'))
const TfaiDepartmentCreate = lazy(() => import('./pages/home/common/tfai_departments/TfaiDepartmentCreate'))
const TfaiDepartmentEdit = lazy(() => import('./pages/home/common/tfai_departments/TfaiDepartmentEdit'))

const TfaiSaleDepartments = lazy(() => import('./pages/home/common/tfai_sale_departments/TfaiSaleDepartments'))
const TfaiSaleDepartmentCreate = lazy(() => import('./pages/home/common/tfai_sale_departments/TfaiSaleDepartmentCreate'))
const TfaiSaleDepartmentEdit = lazy(() => import('./pages/home/common/tfai_sale_departments/TfaiSaleDepartmentEdit'))

const TfaiProjects = lazy(() => import('./pages/home/common/tfai_projects/TfaiProjects'))
const TfaiProjectCreate = lazy(() => import('./pages/home/common/tfai_projects/TfaiProjectCreate'))
const TfaiProjectEdit = lazy(() => import('./pages/home/common/tfai_projects/TfaiProjectEdit'))

const PosUsers = lazy(() => import('./pages/home/common/pos_users/PosUsers'))
const PosUserCreate = lazy(() => import('./pages/home/common/pos_users/PosUserCreate'))
const PosUserEdit = lazy(() => import('./pages/home/common/pos_users/PosUserEdit'))

const ProductComboItems = lazy(() => import('./pages/home/common/product_combo_items/ProductComboItems'))
const ProductComboItemCreate = lazy(() => import('./pages/home/common/product_combo_items/ProductComboItemCreate'))
const ProductComboItemEdit = lazy(() => import('./pages/home/common/product_combo_items/ProductComboItemEdit'))

const ProductCombos = lazy(() => import('./pages/home/common/product_combos/ProductCombos'))
const ProductComboCreate = lazy(() => import('./pages/home/common/product_combos/ProductComboCreate'))
const ProductComboEdit = lazy(() => import('./pages/home/common/product_combos/ProductComboEdit'))

const Promotes = lazy(() => import('./pages/home/common/promotes/Promotes'))
const PromoteCreate = lazy(() => import('./pages/home/common/promotes/PromoteCreate'))
const PromoteEdit = lazy(() => import('./pages/home/common/promotes/PromoteEdit'))

const AccountVouchersItems = lazy(() => import('./pages/home/erp/account_vouchers_items/AccountVouchersItems'))
const AccountVouchersItemCreate = lazy(() => import('./pages/home/erp/account_vouchers_items/AccountVouchersItemCreate'))
const AccountVouchersItemEdit = lazy(() => import('./pages/home/erp/account_vouchers_items/AccountVouchersItemEdit'))

const PurchaseItems = lazy(() => import('./pages/home/erp/purchase_items/PurchaseItems'))
const PurchaseItemCreate = lazy(() => import('./pages/home/erp/purchase_items/PurchaseItemCreate'))
const PurchaseItemEdit = lazy(() => import('./pages/home/erp/purchase_items/PurchaseItemEdit'))

const OrderingItems = lazy(() => import('./pages/home/erp/ordering_items/OrderingItems'))
const OrderingItemCreate = lazy(() => import('./pages/home/erp/ordering_items/OrderingItemCreate'))
const OrderingItemEdit = lazy(() => import('./pages/home/erp/ordering_items/OrderingItemEdit'))

const Reports = lazy(() => import('./pages/home/erp/reports/Reports'))
const SaleReport = lazy(() => import('./pages/home/erp/reports/SaleReport'))
const InvoiceReport = lazy(() => import('./pages/home/erp/reports/InvoiceReport'))

const Invoices = lazy(() => import('./pages/home/erp/invoices/Invoices'))
const InvoiceCreate = lazy(() => import('./pages/home/erp/invoices/InvoiceCreate'))
const InvoiceEdit = lazy(() => import('./pages/home/erp/invoices/InvoiceEdit'))

const AccountGenerals = lazy(() => import('./pages/home/erp/account_generals/AccountGenerals'))
const AccountGeneralCreate = lazy(() => import('./pages/home/erp/account_generals/AccountGeneralCreate'))
const AccountGeneralEdit = lazy(() => import('./pages/home/erp/account_generals/AccountGeneralEdit'))

const CheckPayments = lazy(() => import('./pages/home/erp/check_payments/CheckPayments'))
const CheckPaymentCreate = lazy(() => import('./pages/home/erp/check_payments/CheckPaymentCreate'))
const CheckPaymentEdit = lazy(() => import('./pages/home/erp/check_payments/CheckPaymentEdit'))

const Checks = lazy(() => import('./pages/home/erp/checks/Checks'))
const CheckCreate = lazy(() => import('./pages/home/erp/checks/CheckCreate'))
const CheckEdit = lazy(() => import('./pages/home/erp/checks/CheckEdit'))

const BankTrans = lazy(() => import('./pages/home/erp/bank_trans/BankTrans'))
const BankTranCreate = lazy(() => import('./pages/home/erp/bank_trans/BankTranCreate'))
const BankTranEdit = lazy(() => import('./pages/home/erp/bank_trans/BankTranEdit'))

const Banks = lazy(() => import('./pages/home/erp/banks/Banks'))
const BankCreate = lazy(() => import('./pages/home/erp/banks/BankCreate'))
const BankEdit = lazy(() => import('./pages/home/erp/banks/BankEdit'))

const AccountVouchers = lazy(() => import('./pages/home/erp/account_vouchers/AccountVouchers'))
const AccountVoucherCreate = lazy(() => import('./pages/home/erp/account_vouchers/AccountVoucherCreate'))
const AccountVoucherEdit = lazy(() => import('./pages/home/erp/account_vouchers/AccountVoucherEdit'))

const AccountTitles = lazy(() => import('./pages/home/erp/account_titles/AccountTitles'))
const AccountTitleCreate = lazy(() => import('./pages/home/erp/account_titles/AccountTitleCreate'))
const AccountTitleEdit = lazy(() => import('./pages/home/erp/account_titles/AccountTitleEdit'))

const Purchases = lazy(() => import('./pages/home/erp/purchases/Purchases'))
const PurchaseCreate = lazy(() => import('./pages/home/erp/purchases/PurchaseCreate'))
const PurchaseEdit = lazy(() => import('./pages/home/erp/purchases/PurchaseEdit'))

const SaleItems = lazy(() => import('./pages/home/erp/sale_items/SaleItems'))
const SaleItemCreate = lazy(() => import('./pages/home/erp/sale_items/SaleItemCreate'))
const SaleItemEdit = lazy(() => import('./pages/home/erp/sale_items/SaleItemEdit'))

const Sales = lazy(() => import('./pages/home/erp/sales/Sales'))
const SaleCreate = lazy(() => import('./pages/home/erp/sales/SaleCreate'))
const SaleEdit = lazy(() => import('./pages/home/erp/sales/SaleEdit'))

const Orderings = lazy(() => import('./pages/home/erp/orderings/Orderings'))
const OrderingCreate = lazy(() => import('./pages/home/erp/orderings/OrderingCreate'))
const OrderingEdit = lazy(() => import('./pages/home/erp/orderings/OrderingEdit'))

const ProductUnits = lazy(() => import('./pages/home/erp/product_units/ProductUnits'))
const ProductUnitCreate = lazy(() => import('./pages/home/erp/product_units/ProductUnitCreate'))
const ProductUnitEdit = lazy(() => import('./pages/home/erp/product_units/ProductUnitEdit'))

const SupplierContacts = lazy(() => import('./pages/home/erp/supplier_contacts/SupplierContacts'))
const SupplierContactCreate = lazy(() => import('./pages/home/erp/supplier_contacts/SupplierContactCreate'))
const SupplierContactEdit = lazy(() => import('./pages/home/erp/supplier_contacts/SupplierContactEdit'))

const ErpProducts = lazy(() => import('./pages/home/erp/erp_products/ErpProducts'))
const ErpProductCreate = lazy(() => import('./pages/home/erp/erp_products/ErpProductCreate'))
const ErpProductEdit = lazy(() => import('./pages/home/erp/erp_products/ErpProductEdit'))

const ErpDepartments = lazy(() => import('./pages/home/erp/erp_departments/ErpDepartments'))
const ErpDepartmentCreate = lazy(() => import('./pages/home/erp/erp_departments/ErpDepartmentCreate'))
const ErpDepartmentEdit = lazy(() => import('./pages/home/erp/erp_departments/ErpDepartmentEdit'))

const Projects = lazy(() => import('./pages/home/erp/projects/Projects'))
const ProjectCreate = lazy(() => import('./pages/home/erp/projects/ProjectCreate'))
const ProjectEdit = lazy(() => import('./pages/home/erp/projects/ProjectEdit'))

const CustomerReceives = lazy(() => import('./pages/home/erp/customer_receives/CustomerReceives'))
const CustomerReceiveCreate = lazy(() => import('./pages/home/erp/customer_receives/CustomerReceiveCreate'))
const CustomerReceiveEdit = lazy(() => import('./pages/home/erp/customer_receives/CustomerReceiveEdit'))

const CustomerContacts = lazy(() => import('./pages/home/erp/customer_contacts/CustomerContacts'))
const CustomerContactCreate = lazy(() => import('./pages/home/erp/customer_contacts/CustomerContactCreate'))
const CustomerContactEdit = lazy(() => import('./pages/home/erp/customer_contacts/CustomerContactEdit'))

const Customers = lazy(() => import('./pages/home/erp/customers/Customers'))
const CustomerCreate = lazy(() => import('./pages/home/erp/customers/CustomerCreate'))
const CustomerEdit = lazy(() => import('./pages/home/erp/customers/CustomerEdit'))

const Suppliers = lazy(() => import('./pages/home/erp/suppliers/Suppliers'))
const SupplierCreate = lazy(() => import('./pages/home/erp/suppliers/SupplierCreate'))
const SupplierEdit = lazy(() => import('./pages/home/erp/suppliers/SupplierEdit'))

const PosSales = lazy(() => import('./pages/home/common/pos_sales/PosSales'))
const PosSaleCreate = lazy(() => import('./pages/home/common/pos_sales/PosSaleCreate'))
const PosSaleEdit = lazy(() => import('./pages/home/common/pos_sales/PosSaleEdit'))

const SubscriptionPeriodItems = lazy(() => import('./pages/home/common/subscription_period_items/SubscriptionPeriodItems'))
const SubscriptionPeriodItemCreate = lazy(() => import('./pages/home/common/subscription_period_items/SubscriptionPeriodItemCreate'))
const SubscriptionPeriodItemEdit = lazy(() => import('./pages/home/common/subscription_period_items/SubscriptionPeriodItemEdit'))

const SubscriptionQuantityItems = lazy(() => import('./pages/home/common/subscription_quantity_items/SubscriptionQuantityItems'))
const SubscriptionQuantityItemCreate = lazy(() => import('./pages/home/common/subscription_quantity_items/SubscriptionQuantityItemCreate'))
const SubscriptionQuantityItemEdit = lazy(() => import('./pages/home/common/subscription_quantity_items/SubscriptionQuantityItemEdit'))

const SubscriptionQuantities = lazy(() => import('./pages/home/common/subscription_quantities/SubscriptionQuantities'))
const SubscriptionQuantityCreate = lazy(() => import('./pages/home/common/subscription_quantities/SubscriptionQuantityCreate'))
const SubscriptionQuantityEdit = lazy(() => import('./pages/home/common/subscription_quantities/SubscriptionQuantityEdit'))

const SubscriptionPeriods = lazy(() => import('./pages/home/common/subscription_periods/SubscriptionPeriods'))
const SubscriptionPeriodCreate = lazy(() => import('./pages/home/common/subscription_periods/SubscriptionPeriodCreate'))
const SubscriptionPeriodEdit = lazy(() => import('./pages/home/common/subscription_periods/SubscriptionPeriodEdit'))

const Members = lazy(() => import('./pages/home/common/members/Members'))
const MemberCreate = lazy(() => import('./pages/home/common/members/MemberCreate'))
const MemberEdit = lazy(() => import('./pages/home/common/members/MemberEdit'))

const ProductSuiteItems = lazy(() => import('./pages/home/common/product_suite_items/ProductSuiteItems'))
const ProductSuiteItemCreate = lazy(() => import('./pages/home/common/product_suite_items/ProductSuiteItemCreate'))
const ProductSuiteItemEdit = lazy(() => import('./pages/home/common/product_suite_items/ProductSuiteItemEdit'))

const ProductSuiteFolders = lazy(() => import('./pages/home/common/product_suite_folders/ProductSuiteFolders'))
const ProductSuiteFolderCreate = lazy(() => import('./pages/home/common/product_suite_folders/ProductSuiteFolderCreate'))
const ProductSuiteFolderEdit = lazy(() => import('./pages/home/common/product_suite_folders/ProductSuiteFolderEdit'))

const ProductSuites = lazy(() => import('./pages/home/common/product_suites/ProductSuites'))
const ProductSuiteCreate = lazy(() => import('./pages/home/common/product_suites/ProductSuiteCreate'))
const ProductSuiteEdit = lazy(() => import('./pages/home/common/product_suites/ProductSuiteEdit'))

const ProductComponentItems = lazy(() => import('./pages/home/common/product_component_items/ProductComponentItems'))
const ProductComponentItemCreate = lazy(() => import('./pages/home/common/product_component_items/ProductComponentItemCreate'))
const ProductComponentItemEdit = lazy(() => import('./pages/home/common/product_component_items/ProductComponentItemEdit'))

const ProductBundleItems = lazy(() => import('./pages/home/common/product_bundle_items/ProductBundleItems'))
const ProductBundleItemCreate = lazy(() => import('./pages/home/common/product_bundle_items/ProductBundleItemCreate'))
const ProductBundleItemEdit = lazy(() => import('./pages/home/common/product_bundle_items/ProductBundleItemEdit'))

const ProductOptionItems = lazy(() => import('./pages/home/common/product_option_items/ProductOptionItems'))
const ProductOptionItemCreate = lazy(() => import('./pages/home/common/product_option_items/ProductOptionItemCreate'))
const ProductOptionItemEdit = lazy(() => import('./pages/home/common/product_option_items/ProductOptionItemEdit'))

const ProductOptions = lazy(() => import('./pages/home/common/product_options/ProductOptions'))
const ProductOptionCreate = lazy(() => import('./pages/home/common/product_options/ProductOptionCreate'))
const ProductOptionEdit = lazy(() => import('./pages/home/common/product_options/ProductOptionEdit'))

const ProductPackageItems = lazy(() => import('./pages/home/common/product_package_items/ProductPackageItems'))
const ProductPackageItemCreate = lazy(() => import('./pages/home/common/product_package_items/ProductPackageItemCreate'))
const ProductPackageItemEdit = lazy(() => import('./pages/home/common/product_package_items/ProductPackageItemEdit'))

const ProductPackages = lazy(() => import('./pages/home/common/product_packages/ProductPackages'))
const ProductPackageCreate = lazy(() => import('./pages/home/common/product_packages/ProductPackageCreate'))
const ProductPackageEdit = lazy(() => import('./pages/home/common/product_packages/ProductPackageEdit'))

const MaterialMinorCategories = lazy(() => import('./pages/home/common/material_minor_categories/MaterialMinorCategories'))
const MaterialMinorCategoryCreate = lazy(() => import('./pages/home/common/material_minor_categories/MaterialMinorCategoryCreate'))
const MaterialMinorCategoryEdit = lazy(() => import('./pages/home/common/material_minor_categories/MaterialMinorCategoryEdit'))

const MaterialMediumCategories = lazy(() => import('./pages/home/common/material_medium_categories/MaterialMediumCategories'))
const MaterialMediumCategoryCreate = lazy(() => import('./pages/home/common/material_medium_categories/MaterialMediumCategoryCreate'))
const MaterialMediumCategoryEdit = lazy(() => import('./pages/home/common/material_medium_categories/MaterialMediumCategoryEdit'))

const MaterialMajorCategories = lazy(() => import('./pages/home/common/material_major_categories/MaterialMajorCategories'))
const MaterialMajorCategoryCreate = lazy(() => import('./pages/home/common/material_major_categories/MaterialMajorCategoryCreate'))
const MaterialMajorCategoryEdit = lazy(() => import('./pages/home/common/material_major_categories/MaterialMajorCategoryEdit'))

const Materials = lazy(() => import('./pages/home/common/materials/Materials'))
const MaterialCreate = lazy(() => import('./pages/home/common/materials/MaterialCreate'))
const MaterialEdit = lazy(() => import('./pages/home/common/materials/MaterialEdit'))

const InvoiceSets = lazy(() => import('./pages/home/common/invoice_sets/InvoiceSets'))
const InvoiceSetCreate = lazy(() => import('./pages/home/common/invoice_sets/InvoiceSetCreate'))
const InvoiceSetEdit = lazy(() => import('./pages/home/common/invoice_sets/InvoiceSetEdit'))

const InvoiceSources = lazy(() => import('./pages/home/common/invoice_sources/InvoiceSources'))
const InvoiceSourceCreate = lazy(() => import('./pages/home/common/invoice_sources/InvoiceSourceCreate'))
const InvoiceSourceEdit = lazy(() => import('./pages/home/common/invoice_sources/InvoiceSourceEdit'))

const PosInfos = lazy(() => import('./pages/home/common/pos_infos/PosInfos'))
const PosInfoCreate = lazy(() => import('./pages/home/common/pos_infos/PosInfoCreate'))
const PosInfoEdit = lazy(() => import('./pages/home/common/pos_infos/PosInfoEdit'))

const PosFloors = lazy(() => import('./pages/home/common/pos_floors/PosFloors'))
const PosFloorCreate = lazy(() => import('./pages/home/common/pos_floors/PosFloorCreate'))
const PosFloorEdit = lazy(() => import('./pages/home/common/pos_floors/PosFloorEdit'))
const PosFloorLayout = lazy(() => import('./pages/home/common/pos_floors/PosFloorLayout'))

const Services = lazy(() => import('./pages/home/common/services/Services'))
const ServiceCreate = lazy(() => import('./pages/home/common/services/ServiceCreate'))
const ServiceEdit = lazy(() => import('./pages/home/common/services/ServiceEdit'))

const Branches = lazy(() => import('./pages/home/common/branches/Branches'))
const BranchCreate = lazy(() => import('./pages/home/common/branches/BranchCreate'))
const BranchEdit = lazy(() => import('./pages/home/common/branches/BranchEdit'))

const Departments = lazy(() => import('./pages/home/common/departments/Departments'))
const DepartmentCreate = lazy(() => import('./pages/home/common/departments/DepartmentCreate'))
const DepartmentEdit = lazy(() => import('./pages/home/common/departments/DepartmentEdit'))

const PosTables = lazy(() => import('./pages/home/common/pos_tables/PosTables'))
const PosTableCreate = lazy(() => import('./pages/home/common/pos_tables/PosTableCreate'))
const PosTableEdit = lazy(() => import('./pages/home/common/pos_tables/PosTableEdit'))

const ProductTags = lazy(() => import('./pages/home/common/product_tags/ProductTags'))
const ProductTagCreate = lazy(() => import('./pages/home/common/product_tags/ProductTagCreate'))
const ProductTagEdit = lazy(() => import('./pages/home/common/product_tags/ProductTagEdit'))

const Products = lazy(() => import('./pages/home/common/products/Products'))
const ProductCreate = lazy(() => import('./pages/home/common/products/ProductCreate'))
const ProductEdit = lazy(() => import('./pages/home/common/products/ProductEdit'))

const ProductDepartmentCategories = lazy(() => import('./pages/home/common/product_department_categories/ProductDepartmentCategories'))
const ProductDepartmentCategoryCreate = lazy(() => import('./pages/home/common/product_department_categories/ProductDepartmentCategoryCreate'))
const ProductDepartmentCategoryEdit = lazy(() => import('./pages/home/common/product_department_categories/ProductDepartmentCategoryEdit'))

const ProductMinorCategories = lazy(() => import('./pages/home/common/product_minor_categories/ProductMinorCategories'))
const ProductMinorCategoryCreate = lazy(() => import('./pages/home/common/product_minor_categories/ProductMinorCategoryCreate'))
const ProductMinorCategoryEdit = lazy(() => import('./pages/home/common/product_minor_categories/ProductMinorCategoryEdit'))

const ProductMediumCategories = lazy(() => import('./pages/home/common/product_medium_categories/ProductMediumCategories'))
const ProductMediumCategoryCreate = lazy(() => import('./pages/home/common/product_medium_categories/ProductMediumCategoryCreate'))
const ProductMediumCategoryEdit = lazy(() => import('./pages/home/common/product_medium_categories/ProductMediumCategoryEdit'))

const ProductMajorCategories = lazy(() => import('./pages/home/common/product_major_categories/ProductMajorCategories'))
const ProductMajorCategoryCreate = lazy(() => import('./pages/home/common/product_major_categories/ProductMajorCategoryCreate'))
const ProductMajorCategoryEdit = lazy(() => import('./pages/home/common/product_major_categories/ProductMajorCategoryEdit'))

const CompanyEdit = lazy(() => import('./pages/home/common/companies/CompanyEdit'))

const StockUnits = lazy(() => import('./pages/home/common/stock_units/StockUnits'))
const StockUnitCreate = lazy(() => import('./pages/home/common/stock_units/StockUnitCreate'))
const StockUnitEdit = lazy(() => import('./pages/home/common/stock_units/StockUnitEdit'))

const TradeUnits = lazy(() => import('./pages/home/common/trade_units/TradeUnits'))
const TradeUnitCreate = lazy(() => import('./pages/home/common/trade_units/TradeUnitCreate'))
const TradeUnitEdit = lazy(() => import('./pages/home/common/trade_units/TradeUnitEdit'))

const Users = lazy(() => import('./pages/home/common/users/Users'))
const UserCreate = lazy(() => import('./pages/home/common/users/UserCreate'))
const UserEdit = lazy(() => import('./pages/home/common/users/UserEdit'))

const ConfigEdit = lazy(() => import('./pages/home/common/configs/ConfigEdit'))

const Employees = lazy(() => import('./pages/home/common/employees/Employees'))
const EmployeeCreate = lazy(() => import('./pages/home/common/employees/EmployeeCreate'))
const EmployeeEdit = lazy(() => import('./pages/home/common/employees/EmployeeEdit'))

const Warehouses = lazy(() => import('./pages/home/common/warehouses/Warehouses'))
const WarehouseCreate = lazy(() => import('./pages/home/common/warehouses/WarehouseCreate'))
const WarehouseEdit = lazy(() => import('./pages/home/common/warehouses/WarehouseEdit'))

const Dashboard = lazy(() => import('./pages/home/home/Dashboard'))

export default [
  {
    path: Urls.HOME,
    name: 'Home',
    exact: true,
    component: HomePage,
    redirect: true
    // permission: Permissions.MANAGE_DASHBOARD,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    component: Dashboard,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/demo/list',
    exact: true,
    name: 'Demo',
    component: Demo,
    permission: Permissions.MANAGE_DEMO,
    redirect: true
  }, {
    path: '/demo/report',
    exact: true,
    name: 'DemoReport',
    component: DemoReport,
    permission: Permissions.MANAGE_DEMO,
    redirect: true
  },
  {
    path: '/demo/new',
    exact: true,
    name: 'DemoCreate',
    component: DemoCreate,
    permission: Permissions.MANAGE_DEMO,
    redirect: true
  },
  {
    path: '/demo/:id/edit',
    exact: true,
    name: 'DemoEdit',
    component: DemoEdit,
    permission: Permissions.MANAGE_DEMO,
    redirect: true
  },
  // Component routes generated by battlecry
{
  path: '/common/product_orders',
  name: 'ProductOrders',
  exact: true,
  component: ProductOrders,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_account_titles',
  name: 'TfaiAccountTitles',
  exact: true,
  component: TfaiAccountTitles,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_account_titles/:id/new',
  name: 'TfaiAccountTitleCreate',
  exact: true,
  component: TfaiAccountTitleCreate,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_account_titles/:id/edit',
  name: 'TfaiAccountTitleEdit',
  exact: true,
  component: TfaiAccountTitleEdit,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_products',
  name: 'TfaiProducts',
  exact: true,
  component: TfaiProducts,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_products/:id/new',
  name: 'TfaiProductCreate',
  exact: true,
  component: TfaiProductCreate,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_products/:id/edit',
  name: 'TfaiProductEdit',
  exact: true,
  component: TfaiProductEdit,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_departments',
  name: 'TfaiDepartments',
  exact: true,
  component: TfaiDepartments,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_departments/:id/new',
  name: 'TfaiDepartmentCreate',
  exact: true,
  component: TfaiDepartmentCreate,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_departments/:id/edit',
  name: 'TfaiDepartmentEdit',
  exact: true,
  component: TfaiDepartmentEdit,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_sale_departments',
  name: 'TfaiSaleDepartments',
  exact: true,
  component: TfaiSaleDepartments,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_sale_departments/:id/new',
  name: 'TfaiSaleDepartmentCreate',
  exact: true,
  component: TfaiSaleDepartmentCreate,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_sale_departments/:id/edit',
  name: 'TfaiSaleDepartmentEdit',
  exact: true,
  component: TfaiSaleDepartmentEdit,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_projects',
  name: 'TfaiProjects',
  exact: true,
  component: TfaiProjects,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_projects/:id/new',
  name: 'TfaiProjectCreate',
  exact: true,
  component: TfaiProjectCreate,
  permission: Permissions.MANAGE_DASHBOARD
},
{
  path: '/common/tfai_projects/:id/edit',
  name: 'TfaiProjectEdit',
  exact: true,
  component: TfaiProjectEdit,
  permission: Permissions.MANAGE_DASHBOARD
},
  {
    path: '/common/pos_users',
    name: 'PosUsers',
    exact: true,
    component: PosUsers,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/pos_users/:id/new',
    name: 'PosUserCreate',
    exact: true,
    component: PosUserCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/pos_users/:id/edit',
    name: 'PosUserEdit',
    exact: true,
    component: PosUserEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_combo_items',
    name: 'ProductComboItems',
    exact: true,
    component: ProductComboItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_combo_items/:id/new',
    name: 'ProductComboItemCreate',
    exact: true,
    component: ProductComboItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_combo_items/:id/edit',
    name: 'ProductComboItemEdit',
    exact: true,
    component: ProductComboItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_combos',
    name: 'ProductCombos',
    exact: true,
    component: ProductCombos,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_combos/:id/new',
    name: 'ProductComboCreate',
    exact: true,
    component: ProductComboCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_combos/:id/edit',
    name: 'ProductComboEdit',
    exact: true,
    component: ProductComboEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/promotes',
    name: 'Promotes',
    exact: true,
    component: Promotes,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/promotes/:id/new',
    name: 'PromoteCreate',
    exact: true,
    component: PromoteCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/promotes/:id/edit',
    name: 'PromoteEdit',
    exact: true,
    component: PromoteEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_vouchers_items',
    name: 'AccountVouchersItems',
    exact: true,
    component: AccountVouchersItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_vouchers_items/:id/new',
    name: 'AccountVouchersItemCreate',
    exact: true,
    component: AccountVouchersItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_vouchers_items/:id/edit',
    name: 'AccountVouchersItemEdit',
    exact: true,
    component: AccountVouchersItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/purchase_items',
    name: 'PurchaseItems',
    exact: true,
    component: PurchaseItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/purchase_items/:id/new',
    name: 'PurchaseItemCreate',
    exact: true,
    component: PurchaseItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/purchase_items/:id/edit',
    name: 'PurchaseItemEdit',
    exact: true,
    component: PurchaseItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/ordering_items',
    name: 'OrderingItems',
    exact: true,
    component: OrderingItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/ordering_items/:id/new',
    name: 'OrderingItemCreate',
    exact: true,
    component: OrderingItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/ordering_items/:id/edit',
    name: 'OrderingItemEdit',
    exact: true,
    component: OrderingItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/reports',
    name: 'Reports',
    exact: true,
    component: Reports,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/reports/:code/:id/sale_reports',
    name: 'SaleReport',
    exact: true,
    component: SaleReport,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/reports/:code/:id/invoice_reports',
    name: 'InvoiceReport',
    exact: true,
    component: InvoiceReport,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/invoices',
    name: 'Invoices',
    exact: true,
    component: Invoices,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/invoices/:id/new',
    name: 'InvoiceCreate',
    exact: true,
    component: InvoiceCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/invoices/:id/edit',
    name: 'InvoiceEdit',
    exact: true,
    component: InvoiceEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_generals',
    name: 'AccountGenerals',
    exact: true,
    component: AccountGenerals,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_generals/:id/new',
    name: 'AccountGeneralCreate',
    exact: true,
    component: AccountGeneralCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_generals/:id/edit',
    name: 'AccountGeneralEdit',
    exact: true,
    component: AccountGeneralEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/check_payments',
    name: 'CheckPayments',
    exact: true,
    component: CheckPayments,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/check_payments/:id/new',
    name: 'CheckPaymentCreate',
    exact: true,
    component: CheckPaymentCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/check_payments/:id/edit',
    name: 'CheckPaymentEdit',
    exact: true,
    component: CheckPaymentEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/checks',
    name: 'Checks',
    exact: true,
    component: Checks,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/checks/:id/new',
    name: 'CheckCreate',
    exact: true,
    component: CheckCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/checks/:id/edit',
    name: 'CheckEdit',
    exact: true,
    component: CheckEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/bank_trans',
    name: 'BankTrans',
    exact: true,
    component: BankTrans,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/bank_trans/:id/new',
    name: 'BankTranCreate',
    exact: true,
    component: BankTranCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/bank_trans/:id/edit',
    name: 'BankTranEdit',
    exact: true,
    component: BankTranEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/banks',
    name: 'Banks',
    exact: true,
    component: Banks,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/banks/:id/new',
    name: 'BankCreate',
    exact: true,
    component: BankCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/banks/:id/edit',
    name: 'BankEdit',
    exact: true,
    component: BankEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_vouchers',
    name: 'AccountVouchers',
    exact: true,
    component: AccountVouchers,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_vouchers/:id/new',
    name: 'AccountVoucherCreate',
    exact: true,
    component: AccountVoucherCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_vouchers/:id/edit',
    name: 'AccountVoucherEdit',
    exact: true,
    component: AccountVoucherEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_titles',
    name: 'AccountTitles',
    exact: true,
    component: AccountTitles,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_titles/:id/new',
    name: 'AccountTitleCreate',
    exact: true,
    component: AccountTitleCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/account_titles/:id/edit',
    name: 'AccountTitleEdit',
    exact: true,
    component: AccountTitleEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/purchases',
    name: 'Purchases',
    exact: true,
    component: Purchases,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/purchases/:id/new',
    name: 'PurchaseCreate',
    exact: true,
    component: PurchaseCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/purchases/:id/edit',
    name: 'PurchaseEdit',
    exact: true,
    component: PurchaseEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/sale_items',
    name: 'SaleItems',
    exact: true,
    component: SaleItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/sale_items/:id/new',
    name: 'SaleItemCreate',
    exact: true,
    component: SaleItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/sale_items/:id/edit',
    name: 'SaleItemEdit',
    exact: true,
    component: SaleItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/sales',
    name: 'Sales',
    exact: true,
    component: Sales,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/sales/:id/new',
    name: 'SaleCreate',
    exact: true,
    component: SaleCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/sales/:id/edit',
    name: 'SaleEdit',
    exact: true,
    component: SaleEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/orderings',
    name: 'Orderings',
    exact: true,
    component: Orderings,
    permission: Permissions.MANAGE_ORDERINGS,
    redirect: true
  },
  {
    path: '/erp/orderings/:id/new',
    name: 'OrderingCreate',
    exact: true,
    component: OrderingCreate,
    permission: Permissions.MANAGE_ORDERINGS_CREATE,
    redirect: true
  },
  {
    path: '/erp/orderings/:id/edit',
    name: 'OrderingEdit',
    exact: true,
    component: OrderingEdit,
    permission: Permissions.MANAGE_ORDERINGS_UPDATE,
    redirect: false
  },
  {
    path: '/erp/erp_products',
    name: 'ErpProducts',
    exact: true,
    component: ErpProducts,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/erp_products/:id/new',
    name: 'ErpProductCreate',
    exact: true,
    component: ErpProductCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/erp_products/:id/edit',
    name: 'ErpProductEdit',
    exact: true,
    component: ErpProductEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/erp/product_units',
    name: 'ProductUnits',
    exact: true,
    component: ProductUnits,
    permission: Permissions.MANAGE_PRODUCT_UNITS,
    redirect: true
  },
  {
    path: '/erp/product_units/:id/new',
    name: 'ProductUnitCreate',
    exact: true,
    component: ProductUnitCreate,
    permission: Permissions.MANAGE_PRODUCT_UNITS_CREATE,
    redirect: true
  },
  {
    path: '/erp/product_units/:id/edit',
    name: 'ProductUnitEdit',
    exact: true,
    component: ProductUnitEdit,
    permission: Permissions.MANAGE_PRODUCT_UNITS_UPDATE,
    redirect: false
  },
  {
    path: '/erp/projects',
    name: 'Projects',
    exact: true,
    component: Projects,
    permission: Permissions.MANAGE_PROJECTS,
    redirect: true
  },
  {
    path: '/erp/projects/:id/new',
    name: 'ProjectCreate',
    exact: true,
    component: ProjectCreate,
    permission: Permissions.MANAGE_PROJECTS_CREATE,
    redirect: true
  },
  {
    path: '/erp/projects/:id/edit',
    name: 'ProjectEdit',
    exact: true,
    component: ProjectEdit,
    permission: Permissions.MANAGE_PROJECTS_UPDATE,
    redirect: false
  },
  {
    path: '/erp/erp_departments',
    name: 'ErpDepartments',
    exact: true,
    component: ErpDepartments,
    permission: Permissions.MANAGE_ERP_DEPARTMENTS,
    redirect: true
  },
  {
    path: '/erp/erp_departments/:id/new',
    name: 'ErpDepartmentCreate',
    exact: true,
    component: ErpDepartmentCreate,
    permission: Permissions.MANAGE_ERP_DEPARTMENTS_CREATE,
    redirect: true
  },
  {
    path: '/erp/erp_departments/:id/edit',
    name: 'ErpDepartmentEdit',
    exact: true,
    component: ErpDepartmentEdit,
    permission: Permissions.MANAGE_ERP_DEPARTMENTS_UPDATE,
    redirect: false
  },
  {
    path: '/erp/customer_receives',
    name: 'CustomerReceives',
    exact: true,
    component: CustomerReceives,
    permission: Permissions.MANAGE_CUSTOMER_RECEIVES,
    redirect: true
  },
  {
    path: '/erp/customer_receives/:id/new',
    name: 'CustomerReceiveCreate',
    exact: true,
    component: CustomerReceiveCreate,
    permission: Permissions.MANAGE_CUSTOMER_RECEIVES_CREATE,
    redirect: true
  },
  {
    path: '/erp/customer_receives/:id/edit',
    name: 'CustomerReceiveEdit',
    exact: true,
    component: CustomerReceiveEdit,
    permission: Permissions.MANAGE_CUSTOMER_RECEIVES_UPDATE,
    redirect: false
  },
  {
    path: '/erp/customer_contacts',
    name: 'CustomerContacts',
    exact: true,
    component: CustomerContacts,
    permission: Permissions.MANAGE_CUSTOMER_CONTACTS,
    redirect: true
  },
  {
    path: '/erp/customer_contacts/:id/new',
    name: 'CustomerContactCreate',
    exact: true,
    component: CustomerContactCreate,
    permission: Permissions.MANAGE_CUSTOMER_CONTACTS_CREATE,
    redirect: true
  },
  {
    path: '/erp/customer_contacts/:id/edit',
    name: 'CustomerContactEdit',
    exact: true,
    component: CustomerContactEdit,
    permission: Permissions.MANAGE_CUSTOMER_CONTACTS_UPDATE,
    redirect: false
  },
  {
    path: '/erp/customers',
    name: 'Customers',
    exact: true,
    component: Customers,
    permission: Permissions.MANAGE_CUSTOMERS,
    redirect: true
  },
  {
    path: '/erp/customers/:id/new',
    name: 'CustomerCreate',
    exact: true,
    component: CustomerCreate,
    permission: Permissions.MANAGE_CUSTOMERS_CREATE,
    redirect: true
  },
  {
    path: '/erp/customers/:id/edit',
    name: 'CustomerEdit',
    exact: true,
    component: CustomerEdit,
    permission: Permissions.MANAGE_CUSTOMERS_UPDATE,
    redirect: false
  },
  {
    path: '/erp/supplier_contacts',
    name: 'SupplierContacts',
    exact: true,
    component: SupplierContacts,
    permission: Permissions.MANAGE_SUPPLIER_CONTACTS,
    redirect: true
  },
  {
    path: '/erp/supplier_contacts/:id/new',
    name: 'SupplierContactCreate',
    exact: true,
    component: SupplierContactCreate,
    permission: Permissions.MANAGE_SUPPLIER_CONTACTS_CREATE,
    redirect: true
  },
  {
    path: '/erp/supplier_contacts/:id/edit',
    name: 'SupplierContactEdit',
    exact: true,
    component: SupplierContactEdit,
    permission: Permissions.MANAGE_SUPPLIER_CONTACTS_UPDATE,
    redirect: false
  },
  {
    path: '/erp/suppliers',
    name: 'Suppliers',
    exact: true,
    component: Suppliers,
    permission: Permissions.MANAGE_SUPPLIERS,
    redirect: true
  },
  {
    path: '/erp/suppliers/:id/new',
    name: 'SupplierCreate',
    exact: true,
    component: SupplierCreate,
    permission: Permissions.MANAGE_SUPPLIERS_CREATE,
    redirect: true
  },
  {
    path: '/erp/suppliers/:id/edit',
    name: 'SupplierEdit',
    exact: true,
    component: SupplierEdit,
    permission: Permissions.MANAGE_SUPPLIERS_UPDATE,
    redirect: false
  },
  {
    path: '/common/pos_sales',
    name: 'PosSales',
    exact: true,
    component: PosSales,
    permission: Permissions.MANAGE_POS_SALES,
    redirect: true
  },
  {
    path: '/common/pos_sales/:id/new',
    name: 'PosSaleCreate',
    exact: true,
    component: PosSaleCreate,
    permission: Permissions.MANAGE_POS_SALES_CREATE,
    redirect: true
  },
  {
    path: '/common/pos_sales/:id/edit',
    name: 'PosSaleEdit',
    exact: true,
    component: PosSaleEdit,
    permission: Permissions.MANAGE_POS_SALES_UPDATE,
    redirect: false
  },
  {
    path: '/common/subscription_period_items',
    name: 'SubscriptionPeriodItems',
    exact: true,
    component: SubscriptionPeriodItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/subscription_period_items/:id/new',
    name: 'SubscriptionPeriodItemCreate',
    exact: true,
    component: SubscriptionPeriodItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/subscription_period_items/:id/edit',
    name: 'SubscriptionPeriodItemEdit',
    exact: true,
    component: SubscriptionPeriodItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/subscription_quantity_items',
    name: 'SubscriptionQuantityItems',
    exact: true,
    component: SubscriptionQuantityItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/subscription_quantity_items/:id/new',
    name: 'SubscriptionQuantityItemCreate',
    exact: true,
    component: SubscriptionQuantityItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/subscription_quantity_items/:id/edit',
    name: 'SubscriptionQuantityItemEdit',
    exact: true,
    component: SubscriptionQuantityItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/subscription_quantities',
    name: 'SubscriptionQuantities',
    exact: true,
    component: SubscriptionQuantities,
    permission: Permissions.MANAGE_SUBSCRIPTION_QUANTITIES,
    redirect: true
  },
  {
    path: '/common/subscription_quantities/:id/new',
    name: 'SubscriptionQuantityCreate',
    exact: true,
    component: SubscriptionQuantityCreate,
    permission: Permissions.MANAGE_SUBSCRIPTION_QUANTITIES_CREATE,
    redirect: true
  },
  {
    path: '/common/subscription_quantities/:id/edit',
    name: 'SubscriptionQuantityEdit',
    exact: true,
    component: SubscriptionQuantityEdit,
    permission: Permissions.MANAGE_SUBSCRIPTION_QUANTITIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/subscription_periods',
    name: 'SubscriptionPeriods',
    exact: true,
    component: SubscriptionPeriods,
    permission: Permissions.MANAGE_SUBSCRIPTION_PERIODS,
    redirect: true
  },
  {
    path: '/common/subscription_periods/:id/new',
    name: 'SubscriptionPeriodCreate',
    exact: true,
    component: SubscriptionPeriodCreate,
    permission: Permissions.MANAGE_SUBSCRIPTION_PERIODS_CREATE,
    redirect: true
  },
  {
    path: '/common/subscription_periods/:id/edit',
    name: 'SubscriptionPeriodEdit',
    exact: true,
    component: SubscriptionPeriodEdit,
    permission: Permissions.MANAGE_SUBSCRIPTION_PERIODS_UPDATE,
    redirect: false
  },
  {
    path: '/common/members',
    name: 'Members',
    exact: true,
    component: Members,
    permission: Permissions.MANAGE_MEMBERS,
    redirect: true
  },
  {
    path: '/common/members/:id/new',
    name: 'MemberCreate',
    exact: true,
    component: MemberCreate,
    permission: Permissions.MANAGE_MEMBERS_CREATE,
    redirect: true
  },
  {
    path: '/common/members/:id/edit',
    name: 'MemberEdit',
    exact: true,
    component: MemberEdit,
    permission: Permissions.MANAGE_MEMBERS_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_suite_items',
    name: 'ProductSuiteItems',
    exact: true,
    component: ProductSuiteItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_suite_items/:id/new',
    name: 'ProductSuiteItemCreate',
    exact: true,
    component: ProductSuiteItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_suite_items/:id/edit',
    name: 'ProductSuiteItemEdit',
    exact: true,
    component: ProductSuiteItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_suite_folders',
    name: 'ProductSuiteFolders',
    exact: true,
    component: ProductSuiteFolders,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_suite_folders/:id/new',
    name: 'ProductSuiteFolderCreate',
    exact: true,
    component: ProductSuiteFolderCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_suite_folders/:id/edit',
    name: 'ProductSuiteFolderEdit',
    exact: true,
    component: ProductSuiteFolderEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_suites',
    name: 'ProductSuites',
    exact: true,
    component: ProductSuites,
    permission: Permissions.MANAGE_PRODUCT_SUITES,
    redirect: true
  },
  {
    path: '/common/product_suites/:id/new',
    name: 'ProductSuiteCreate',
    exact: true,
    component: ProductSuiteCreate,
    permission: Permissions.MANAGE_PRODUCT_SUITES_CREATE,
    redirect: true
  },
  {
    path: '/common/product_suites/:id/edit',
    name: 'ProductSuiteEdit',
    exact: true,
    component: ProductSuiteEdit,
    permission: Permissions.MANAGE_PRODUCT_SUITES_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_component_items',
    name: 'ProductComponentItems',
    exact: true,
    component: ProductComponentItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_component_items/:id/new',
    name: 'ProductComponentItemCreate',
    exact: true,
    component: ProductComponentItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_component_items/:id/edit',
    name: 'ProductComponentItemEdit',
    exact: true,
    component: ProductComponentItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_bundle_items',
    name: 'ProductBundleItems',
    exact: true,
    component: ProductBundleItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_bundle_items/:id/new',
    name: 'ProductBundleItemCreate',
    exact: true,
    component: ProductBundleItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_bundle_items/:id/edit',
    name: 'ProductBundleItemEdit',
    exact: true,
    component: ProductBundleItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_options/:parent_id/product_option_items',
    name: 'ProductOptionItems',
    exact: true,
    component: ProductOptionItems,
    permission: Permissions.MANAGE_PRODUCT_OPTIONS,
    redirect: true
  },
  {
    path: '/common/product_options/:parent_id/product_option_items/:id/new',
    name: 'ProductOptionItemCreate',
    exact: true,
    component: ProductOptionItemCreate,
    permission: Permissions.MANAGE_PRODUCT_OPTIONS_CREATE,
    redirect: true
  },
  {
    path: '/common/product_options/:parent_id/product_option_items/:id/edit',
    name: 'ProductOptionItemEdit',
    exact: true,
    component: ProductOptionItemEdit,
    permission: Permissions.MANAGE_PRODUCT_OPTIONS_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_options',
    name: 'ProductOptions',
    exact: true,
    component: ProductOptions,
    permission: Permissions.MANAGE_PRODUCT_OPTIONS,
    redirect: true
  },
  {
    path: '/common/product_options/:id/new',
    name: 'ProductOptionCreate',
    exact: true,
    component: ProductOptionCreate,
    permission: Permissions.MANAGE_PRODUCT_OPTIONS_CREATE,
    redirect: true
  },
  {
    path: '/common/product_options/:id/edit',
    name: 'ProductOptionEdit',
    exact: true,
    component: ProductOptionEdit,
    permission: Permissions.MANAGE_PRODUCT_OPTIONS_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_package_items',
    name: 'ProductPackageItems',
    exact: true,
    component: ProductPackageItems,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_package_items/:id/new',
    name: 'ProductPackageItemCreate',
    exact: true,
    component: ProductPackageItemCreate,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_package_items/:id/edit',
    name: 'ProductPackageItemEdit',
    exact: true,
    component: ProductPackageItemEdit,
    permission: Permissions.MANAGE_DASHBOARD,
    redirect: true
  },
  {
    path: '/common/product_packages',
    name: 'ProductPackages',
    exact: true,
    component: ProductPackages,
    permission: Permissions.MANAGE_PRODUCT_PACKAGES,
    redirect: true
  },
  {
    path: '/common/product_packages/:id/new',
    name: 'ProductPackageCreate',
    exact: true,
    component: ProductPackageCreate,
    permission: Permissions.MANAGE_PRODUCT_PACKAGES_CREATE,
    redirect: true
  },
  {
    path: '/common/product_packages/:id/edit',
    name: 'ProductPackageEdit',
    exact: true,
    component: ProductPackageEdit,
    permission: Permissions.MANAGE_PRODUCT_PACKAGES_UPDATE,
    redirect: false
  },
  {
    path: '/common/material_minor_categories',
    name: 'MaterialMinorCategories',
    exact: true,
    component: MaterialMinorCategories,
    permission: Permissions.MANAGE_MATERIAL_MINOR_CATEGORIES,
    redirect: true
  },
  {
    path: '/common/material_minor_categories/:id/new',
    name: 'MaterialMinorCategoryCreate',
    exact: true,
    component: MaterialMinorCategoryCreate,
    permission: Permissions.MANAGE_MATERIAL_MINOR_CATEGORIES_CREATE,
    redirect: true
  },
  {
    path: '/common/material_minor_categories/:id/edit',
    name: 'MaterialMinorCategoryEdit',
    exact: true,
    component: MaterialMinorCategoryEdit,
    permission: Permissions.MANAGE_MATERIAL_MINOR_CATEGORIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/material_medium_categories',
    name: 'MaterialMediumCategories',
    exact: true,
    component: MaterialMediumCategories,
    permission: Permissions.MANAGE_MATERIAL_MEDIUM_CATEGORIES,
    redirect: true
  },
  {
    path: '/common/material_medium_categories/:id/new',
    name: 'MaterialMediumCategoryCreate',
    exact: true,
    component: MaterialMediumCategoryCreate,
    permission: Permissions.MANAGE_MATERIAL_MEDIUM_CATEGORIES_CREATE,
    redirect: true
  },
  {
    path: '/common/material_medium_categories/:id/edit',
    name: 'MaterialMediumCategoryEdit',
    exact: true,
    component: MaterialMediumCategoryEdit,
    permission: Permissions.MANAGE_MATERIAL_MEDIUM_CATEGORIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/material_major_categories',
    name: 'MaterialMajorCategories',
    exact: true,
    component: MaterialMajorCategories,
    permission: Permissions.MANAGE_MATERIAL_MAJOR_CATEGORIES,
    redirect: true
  },
  {
    path: '/common/material_major_categories/:id/new',
    name: 'MaterialMajorCategoryCreate',
    exact: true,
    component: MaterialMajorCategoryCreate,
    permission: Permissions.MANAGE_MATERIAL_MAJOR_CATEGORIES_CREATE,
    redirect: true
  },
  {
    path: '/common/material_major_categories/:id/edit',
    name: 'MaterialMajorCategoryEdit',
    exact: true,
    component: MaterialMajorCategoryEdit,
    permission: Permissions.MANAGE_MATERIAL_MAJOR_CATEGORIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/materials',
    name: 'Materials',
    exact: true,
    component: Materials,
    permission: Permissions.MANAGE_MATERIALS,
    redirect: true
  },
  {
    path: '/common/materials/:id/new',
    name: 'MaterialCreate',
    exact: true,
    component: MaterialCreate,
    permission: Permissions.MANAGE_MATERIALS_CREATE,
    redirect: true
  },
  {
    path: '/common/materials/:id/edit',
    name: 'MaterialEdit',
    exact: true,
    component: MaterialEdit,
    permission: Permissions.MANAGE_MATERIALS_UPDATE,
    redirect: false
  },
  {
    path: '/common/invoice_sets',
    name: 'InvoiceSets',
    exact: true,
    component: InvoiceSets,
    permission: Permissions.MANAGE_INVOICE_SETS,
    redirect: true
  },
  {
    path: '/common/invoice_sets/:id/new',
    name: 'InvoiceSetCreate',
    exact: true,
    component: InvoiceSetCreate,
    permission: Permissions.MANAGE_INVOICE_SETS,
    redirect: true
  },
  {
    path: '/common/invoice_sets/:id/edit',
    name: 'InvoiceSetEdit',
    exact: true,
    component: InvoiceSetEdit,
    permission: Permissions.MANAGE_INVOICE_SETS,
    redirect: true
  },
  {
    path: '/common/invoice_sources',
    name: 'InvoiceSources',
    exact: true,
    component: InvoiceSources,
    permission: Permissions.MANAGE_INVOICE_SOURCES,
    redirect: true
  },
  {
    path: '/common/invoice_sources/:id/new',
    name: 'InvoiceSourceCreate',
    exact: true,
    component: InvoiceSourceCreate,
    permission: Permissions.MANAGE_INVOICE_SOURCES_CREATE,
    redirect: true
  },
  {
    path: '/common/invoice_sources/:id/edit',
    name: 'InvoiceSourceEdit',
    exact: true,
    component: InvoiceSourceEdit,
    permission: Permissions.MANAGE_INVOICE_SOURCES_UPDATE,
    redirect: false
  },
  {
    path: '/common/pos_infos',
    name: 'PosInfos',
    exact: true,
    component: PosInfos,
    permission: Permissions.MANAGE_POS_INFOS,
    redirect: true
  },
  {
    path: '/common/pos_infos/:id/new',
    name: 'PosInfoCreate',
    exact: true,
    component: PosInfoCreate,
    permission: Permissions.MANAGE_POS_INFOS_CREATE,
    redirect: true
  },
  {
    path: '/common/pos_infos/:id/edit',
    name: 'PosInfoEdit',
    exact: true,
    component: PosInfoEdit,
    permission: Permissions.MANAGE_POS_INFOS_UPDATE,
    redirect: false
  },
  {
    path: '/common/pos_floors',
    name: 'PosFloors',
    exact: true,
    component: PosFloors,
    permission: Permissions.MANAGE_POS_FLOORS,
    redirect: true
  },
  {
    path: '/common/pos_floors/:id/new',
    name: 'PosFloorCreate',
    exact: true,
    component: PosFloorCreate,
    permission: Permissions.MANAGE_POS_FLOORS_CREATE,
    redirect: true
  },
  {
    path: '/common/pos_floors/:id/edit',
    name: 'PosFloorEdit',
    exact: true,
    component: PosFloorEdit,
    permission: Permissions.MANAGE_POS_FLOORS_UPDATE,
    redirect: false
  },
  {
    path: '/common/pos_floors/:id/layout',
    name: 'PosFloorLayout',
    exact: true,
    component: PosFloorLayout,
    permission: Permissions.MANAGE_POS_FLOORS,
    redirect: true
  },
  {
    path: '/common/services',
    name: 'Services',
    exact: true,
    component: Services,
    permission: Permissions.MANAGE_SERVICES,
    redirect: true
  },
  {
    path: '/common/services/:id/new',
    name: 'ServiceCreate',
    exact: true,
    component: ServiceCreate,
    permission: Permissions.MANAGE_SERVICES_CREATE,
    redirect: true
  },
  {
    path: '/common/services/:id/edit',
    name: 'ServiceEdit',
    exact: true,
    component: ServiceEdit,
    permission: Permissions.MANAGE_SERVICES_UPDATE,
    redirect: false
  },
  {
    path: '/common/branches',
    name: 'Branches',
    exact: true,
    component: Branches,
    permission: Permissions.MANAGE_BRANCHES,
    redirect: true
  },
  {
    path: '/common/branches/:id/new',
    name: 'BranchCreate',
    exact: true,
    component: BranchCreate,
    permission: Permissions.MANAGE_BRANCHES_CREATE,
    redirect: true
  },
  {
    path: '/common/branches/:id/edit',
    name: 'BranchEdit',
    exact: true,
    component: BranchEdit,
    permission: Permissions.MANAGE_BRANCHES_UPDATE,
    redirect: false
  },
  {
    path: '/common/departments',
    name: 'Departments',
    exact: true,
    component: Departments,
    permission: Permissions.MANAGE_DEPARTMENTS,
    redirect: true
  },
  {
    path: '/common/departments/:id/new',
    name: 'DepartmentCreate',
    exact: true,
    component: DepartmentCreate,
    permission: Permissions.MANAGE_DEPARTMENTS_CREATE,
    redirect: true
  },
  {
    path: '/common/departments/:id/edit',
    name: 'DepartmentEdit',
    exact: true,
    component: DepartmentEdit,
    permission: Permissions.MANAGE_DEPARTMENTS_UPDATE,
    redirect: false
  },
  {
    path: '/common/pos_tables',
    name: 'PosTables',
    exact: true,
    component: PosTables,
    permission: Permissions.MANAGE_POS_TABLES,
    redirect: true
  },
  {
    path: '/common/pos_tables/:id/new',
    name: 'PosTableCreate',
    exact: true,
    component: PosTableCreate,
    permission: Permissions.MANAGE_POS_TABLES_CREATE,
    redirect: true
  },
  {
    path: '/common/pos_tables/:id/edit',
    name: 'PosTableEdit',
    exact: true,
    component: PosTableEdit,
    permission: Permissions.MANAGE_POS_TABLES_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_tags',
    name: 'ProductTags',
    exact: true,
    component: ProductTags,
    permission: Permissions.MANAGE_PRODUCT_TAGS,
    redirect: true
  },
  {
    path: '/common/product_tags/:id/new',
    name: 'ProductTagCreate',
    exact: true,
    component: ProductTagCreate,
    permission: Permissions.MANAGE_PRODUCT_TAGS_CREATE,
    redirect: true
  },
  {
    path: '/common/product_tags/:id/edit',
    name: 'ProductTagEdit',
    exact: true,
    component: ProductTagEdit,
    permission: Permissions.MANAGE_PRODUCT_TAGS_UPDATE,
    redirect: false
  },
  {
    path: '/common/products',
    name: 'Products',
    exact: true,
    component: Products,
    permission: Permissions.MANAGE_PRODUCTS,
    redirect: true
  },
  {
    path: '/common/products/:id/new',
    name: 'ProductCreate',
    exact: true,
    component: ProductCreate,
    permission: Permissions.MANAGE_PRODUCTS_CREATE,
    redirect: true
  },
  {
    path: '/common/products/:id/edit',
    name: 'ProductEdit',
    exact: true,
    component: ProductEdit,
    permission: Permissions.MANAGE_PRODUCTS_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_department_categories',
    name: 'ProductDepartmentCategories',
    exact: true,
    component: ProductDepartmentCategories,
    permission: Permissions.MANAGE_PRODUCT_DEPARTMENT_CATEGORIES,
    redirect: true
  },
  {
    path: '/common/product_department_categories/:id/new',
    name: 'ProductDepartmentCategoryCreate',
    exact: true,
    component: ProductDepartmentCategoryCreate,
    permission: Permissions.MANAGE_PRODUCT_DEPARTMENT_CATEGORIES_CREATE,
    redirect: true
  },
  {
    path: '/common/product_department_categories/:id/edit',
    name: 'ProductDepartmentCategoryEdit',
    exact: true,
    component: ProductDepartmentCategoryEdit,
    permission: Permissions.MANAGE_PRODUCT_DEPARTMENT_CATEGORIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_minor_categories',
    name: 'ProductMinorCategories',
    exact: true,
    component: ProductMinorCategories,
    permission: Permissions.MANAGE_PRODUCT_MINOR_CATEGORIES,
    redirect: true
  },
  {
    path: '/common/product_minor_categories/:id/new',
    name: 'ProductMinorCategoryCreate',
    exact: true,
    component: ProductMinorCategoryCreate,
    permission: Permissions.MANAGE_PRODUCT_MINOR_CATEGORIES_CREATE,
    redirect: true
  },
  {
    path: '/common/product_minor_categories/:id/edit',
    name: 'ProductMinorCategoryEdit',
    exact: true,
    component: ProductMinorCategoryEdit,
    permission: Permissions.MANAGE_PRODUCT_MINOR_CATEGORIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_medium_categories',
    name: 'ProductMediumCategories',
    exact: true,
    component: ProductMediumCategories,
    permission: Permissions.MANAGE_PRODUCT_MEDIUM_CATEGORIES,
    redirect: true
  },
  {
    path: '/common/product_medium_categories/:id/new',
    name: 'ProductMediumCategoryCreate',
    exact: true,
    component: ProductMediumCategoryCreate,
    permission: Permissions.MANAGE_PRODUCT_MEDIUM_CATEGORIES_CREATE,
    redirect: true
  },
  {
    path: '/common/product_medium_categories/:id/edit',
    name: 'ProductMediumCategoryEdit',
    exact: true,
    component: ProductMediumCategoryEdit,
    permission: Permissions.MANAGE_PRODUCT_MEDIUM_CATEGORIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/product_major_categories',
    name: 'ProductMajorCategories',
    exact: true,
    component: ProductMajorCategories,
    permission: Permissions.MANAGE_PRODUCT_MAJOR_CATEGORIES,
    redirect: true
  },
  {
    path: '/common/product_major_categories/:id/new',
    name: 'ProductMajorCategoryCreate',
    exact: true,
    component: ProductMajorCategoryCreate,
    permission: Permissions.MANAGE_PRODUCT_MAJOR_CATEGORIES_CREATE,
    redirect: true
  },
  {
    path: '/common/product_major_categories/:id/edit',
    name: 'ProductMajorCategoryEdit',
    exact: true,
    component: ProductMajorCategoryEdit,
    permission: Permissions.MANAGE_PRODUCT_MAJOR_CATEGORIES_UPDATE,
    redirect: false
  },
  {
    path: '/common/stock_units',
    name: 'StockUnits',
    exact: true,
    component: StockUnits,
    permission: Permissions.MANAGE_STOCK_UNITS,
    redirect: true
  },
  {
    path: '/common/stock_units/:id/new',
    name: 'StockUnitCreate',
    exact: true,
    component: StockUnitCreate,
    permission: Permissions.MANAGE_STOCK_UNITS_CREATE,
    redirect: true
  },
  {
    path: '/common/stock_units/:id/edit',
    name: 'StockUnitEdit',
    exact: true,
    component: StockUnitEdit,
    permission: Permissions.MANAGE_STOCK_UNITS_UPDATE,
    redirect: false
  },
  {
    path: '/common/stock_units/:stock_unit_id/trade_units',
    name: 'TradeUnits',
    exact: true,
    component: TradeUnits,
    permission: Permissions.MANAGE_STOCK_UNITS,
    redirect: true
  },
  {
    path: '/common/stock_units/:stock_unit_id/trade_units/:id/new',
    name: 'TradeUnitCreate',
    exact: true,
    component: TradeUnitCreate,
    permission: Permissions.MANAGE_STOCK_UNITS,
    redirect: true
  },
  {
    path: '/common/stock_units/:stock_unit_id/trade_units/:id/edit',
    name: 'TradeUnitEdit',
    exact: true,
    component: TradeUnitEdit,
    permission: Permissions.MANAGE_STOCK_UNITS,
    redirect: true
  },
  {
    path: '/common/configs',
    name: 'ConfigEdit',
    exact: true,
    component: ConfigEdit,
    permission: Permissions.MANAGE_CONFIGS_UPDATE,
    redirect: false
  },
  {
    path: '/common/companies',
    name: 'CompanyEdit',
    exact: true,
    component: CompanyEdit,
    permission: Permissions.MANAGE_COMPANIES_UPDATE,
    redirect: true
  },
  {
    path: '/common/stores',
    name: 'Stores',
    exact: true,
    component: Stores,
    permission: Permissions.MANAGE_STORES,
    redirect: true
  },
  {
    path: '/common/stores/:id/new',
    name: 'StoreCreate',
    exact: true,
    component: StoreCreate,
    permission: Permissions.MANAGE_STORES_CREATE,
    redirect: true
  },
  {
    path: '/common/stores/:id/edit',
    name: 'StoreEdit',
    exact: true,
    component: StoreEdit,
    permission: Permissions.MANAGE_STORES_UPDATE,
    redirect: false
  },
  {
    path: '/common/employees',
    name: 'Employees',
    exact: true,
    component: Employees,
    permission: Permissions.MANAGE_EMPLOYEES,
    redirect: true
  },
  {
    path: '/common/employees/:id/new',
    name: 'EmployeeCreate',
    exact: true,
    component: EmployeeCreate,
    permission: Permissions.MANAGE_EMPLOYEES_CREATE,
    redirect: true
  },
  {
    path: '/common/employees/:id/edit',
    name: 'EmployeeEdit',
    exact: true,
    component: EmployeeEdit,
    permission: Permissions.MANAGE_EMPLOYEES_UPDATE,
    redirect: false
  },
  {
    path: '/common/warehouses',
    name: 'Warehouses',
    exact: true,
    component: Warehouses,
    permission: Permissions.MANAGE_WAREHOUSES,
    redirect: true
  },
  {
    path: '/common/warehouses/:id/new',
    name: 'WarehouseCreate',
    exact: true,
    component: WarehouseCreate,
    permission: Permissions.MANAGE_WAREHOUSES_CREATE,
    redirect: true
  },
  {
    path: '/common/warehouses/:id/edit',
    name: 'WarehouseEdit',
    exact: true,
    component: WarehouseEdit,
    permission: Permissions.MANAGE_WAREHOUSES_UPDATE,
    redirect: false
  },
  {
    path: '/common/users',
    name: 'Users',
    exact: true,
    component: Users,
    permission: Permissions.MANAGE_USERS,
    redirect: true
  },
  {
    path: '/common/users/:id/new',
    name: 'UserCreate',
    exact: true,
    component: UserCreate,
    permission: Permissions.MANAGE_USERS_CREATE,
    redirect: true
  },
  {
    path: '/common/users/:id/edit',
    name: 'UserEdit',
    exact: true,
    component: UserEdit,
    permission: Permissions.MANAGE_USERS_UPDATE,
    redirect: false
  },
]
