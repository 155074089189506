import React, { Suspense } from 'react'
import { useSelector, connect } from 'react-redux'
import objectPath from 'object-path'
import Header from './header/Header'
import SubHeader from './sub-header/SubHeader'
import HeaderMobile from './header/HeaderMobile'
import AsideLeft from './aside/AsideLeft'
import Footer from './footer/Footer'
import ScrollTop from '../../admin/partials/layout/ScrollTop'
import HTMLClassService from './HTMLClassService'
import LayoutConfig from './LayoutConfig'
import MenuConfig from './MenuConfig'
import LayoutInitializer from './LayoutInitializer'
import QuickPanel from '../../admin/partials/layout/QuickPanel'
import KtContent from './KtContent'
import routes from '../../admin/routes'
import './assets/Base.scss'
import { Route, Redirect, Switch } from 'react-router-dom'
import { LayoutSplashScreen } from './LayoutContext'
import { Permissions, Urls } from '../../admin/constants'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

const htmlClassService = new HTMLClassService()

function Layout ({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  isAuthorized
}) {
  htmlClassService.setConfig(layoutConfig)
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(' ')
  const auth = useSelector(({ auth }) => auth).user
  const authPermissions = auth.permissions.map(item => item.name)

  const renderRoutes = (newRoutes, location) => {
    return routes.map((route, index) => {
      return route.component ?  (
        <Route key={index} path={route.path} exact={route.exact} name={route.name} render={props => {
          if(auth.permissions === undefined || auth.permissions.length <= 0 && route.redirect) {
            if(route.path != Urls.HOME) {
              notifyError('您無操作權限')
              return <Redirect to={Urls.HOME} />
            }
          }

          if(route.permission != undefined && authPermissions.indexOf(route.permission) < 0 && route.redirect) {
            notifyError('您無操作權限')
            return <Redirect to={Urls.HOME} />
          }
          return isAuthorized
            ? <route.component {...props}/>
            : <Redirect to="/auth/login" />
        }}/>
      ) : (null)
    })
  }

  return selfLayout !== 'blank' ? (
    <LayoutInitializer
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && (
            <>
              <AsideLeft />
            </>
          )}
          {/* <!-- end:: Aside Left --> */}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}

            <Header />
            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              {/* <!-- begin:: Content Head --> */}
              {subheaderDisplay && (
                <SubHeader />
              )}
              {/* <!-- end:: Content Head --> */}

              {/* <!-- begin:: Content Body --> */}
              {/* TODO: add class to animate  kt-grid--animateContent-finished */}
              <KtContent>
                <Suspense fallback={<LayoutSplashScreen />}>
                  <Switch>
                    {/* Redirect from root URL to /dashboard. */}
                    <Redirect exact from="/" to="/dashboard" />
                    { renderRoutes() }
                    <Redirect to="/error/error-v1" />
                  </Switch>
                </Suspense>
              </KtContent>
              {/* <!-- end:: Content Body --> */}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      <QuickPanel />
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>
        {children}
      </KtContent>
    </div>
  )
}

const mapStateToProps = ({ auth, builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, 'self.layout'),
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    'header.self.fixed.desktop'
  ),
  contentContainerClasses: '',
  isAuthorized: auth.user != null
  // contentContainerClasses: builder.selectors.getClasses(store, {
  //   path: "content_container",
  //   toString: true
  // })
})

export default connect(mapStateToProps)(Layout)
