import { put, call, fork, takeLatest, takeEvery, select } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { defaultValue } from 'admin/pages/home/common/tfai_sale_departments/tfaiSaleDepartmentDefaultValue'
import * as entireConfigDuck from './entireConfig.duck'
import * as loadingDuck from './loading.duck'
//utils
import { resortPosition } from 'shared/utils/arrayMethod'

// Actions
export const actionTypes = {
  CREATE_TFAI_SALE_DEPARTMENT: 'tfaiSaleDepartment/CREATE_TFAI_SALE_DEPARTMENT',
  FETCH_TFAI_SALE_DEPARTMENTS: 'tfaiSaleDepartment/FETCH_TFAI_SALE_DEPARTMENTS',
  FETCH_TFAI_SALE_DEPARTMENT: 'tfaiSaleDepartment/FETCH_TFAI_SALE_DEPARTMENT',
  UPADE_TFAI_SALE_DEPARTMENT: 'tfaiSaleDepartment/UPADE_TFAI_SALE_DEPARTMENT',
  DELETE_TFAI_SALE_DEPARTMENT: 'tfaiSaleDepartment/DELETE_TFAI_SALE_DEPARTMENT',
  DELETE_TFAI_SALE_DEPARTMENT_BY_POSITION: 'tfaiSaleDepartment/DELETE_TFAI_SALE_DEPARTMENT_BY_POSITION:',

  TFAI_SALE_DEPARTMENT_CREATED: 'tfaiSaleDepartment/TFAI_SALE_DEPARTMENT_CREATED',
  TFAI_SALE_DEPARTMENTS_LOADED: 'tfaiSaleDepartment/TFAI_SALE_DEPARTMENTS_LOADED',
  TFAI_SALE_DEPARTMENT_LOADED: 'tfaiSaleDepartment/TFAI_SALE_DEPARTMENT_LOADED',
  TFAI_SALE_DEPARTMENT_UPDATED: 'tfaiSaleDepartment/TFAI_SALE_DEPARTMENT_UPDATED',
  TFAI_SALE_DEPARTMENT_DELETED: 'tfaiSaleDepartment/TFAI_SALE_DEPARTMENT_DELETED',

  INSERT_NEW_ROW: 'tfaiSaleDepartment/INSERT_NEW_ROW',
  INSERT_TFAI_DEPARTMENT: 'tfaiSaleDepartment/INSERT_TFAI_DEPARTMENT',

  UPDATE_GRID_INDEX: 'tfaiSaleDepartment/UPDATE_GRID_INDEX',
}

const initialState = {
  tfaiSaleDepartment: null,
  tfaiSaleDepartments: null,
  agGridIndex: 0,
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_TFAI_SALE_DEPARTMENT:
      return { ...state, tfaiSaleDepartment: action.payload }
    case actionTypes.FETCH_TFAI_SALE_DEPARTMENTS:
      return { ...state, tfaiSaleDepartments: null }
    case actionTypes.FETCH_TFAI_SALE_DEPARTMENT:
      return { ...state, tfaiSaleDepartment: null }
    case actionTypes.UPADE_TFAI_SALE_DEPARTMENT:
      return { ...state, tfaiSaleDepartment: action.payload }
    case actionTypes.DELETE_TFAI_SALE_DEPARTMENT:
      return state

    case actionTypes.TFAI_SALE_DEPARTMENT_CREATED:
      return !state.tfaiSaleDepartments ? { ...state, tfaiSaleDepartments: [action.payload] } : { ...state, tfaiSaleDepartments: [...state.tfaiSaleDepartments, action.payload] }
    case actionTypes.TFAI_SALE_DEPARTMENTS_LOADED:
      return { ...state, tfaiSaleDepartments: resortPosition(action.payload) }
    case actionTypes.TFAI_SALE_DEPARTMENT_LOADED:
      return { ...state, tfaiSaleDepartment: action.payload }
    case actionTypes.TFAI_SALE_DEPARTMENT_UPDATED:
      return {
        ...state,
        tfaiSaleDepartment: action.payload,
        tfaiSaleDepartments: state.tfaiSaleDepartments.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.TFAI_SALE_DEPARTMENT_DELETED:
      const { position: deleteIdPosition, id: deleteId } = action.payload
      return {
        ...state,
        tfaiSaleDepartments: resortPosition(state.tfaiSaleDepartments.filter(item => item.id !== deleteId)),
        agGridIndex: deleteIdPosition
      }
    
    case actionTypes.DELETE_TFAI_SALE_DEPARTMENT_BY_POSITION:
      const { position: deletePosition } = action.payload
      return {
        ...state,
        tfaiSaleDepartments: resortPosition(state.tfaiSaleDepartments.filter(item => item.position !== deletePosition)),
        agGridIndex: deletePosition
      }
    
    case actionTypes.INSERT_NEW_ROW:
      return {
        ...state,
        tfaiSaleDepartments: state?.tfaiSaleDepartments ? resortPosition([...state.tfaiSaleDepartments, { ...defaultValue }]) : [{...defaultValue}],
        agGridIndex: state?.tfaiSaleDepartments?.length ? state?.tfaiSaleDepartments?.length : 0
      }
    case actionTypes.INSERT_TFAI_DEPARTMENT:
      const { position: tfaiDepartmentPosition, row: tfaiDepartmentRow } = action.payload
      return {
        ...state,
        tfaiSaleDepartments: state.tfaiSaleDepartments.map((item) =>
          item.position === tfaiDepartmentPosition
            ? { ...item, tfai_department_id: tfaiDepartmentRow.data.id, tfai_department: tfaiDepartmentRow.data }
            : item,
        ),
        agGridIndex: tfaiDepartmentPosition
      }

    case actionTypes.UPDATE_GRID_INDEX:
      const { index: gridIndex } = action.payload
      return { ...state, agGridIndex: gridIndex}
    
    default:
      return state
  }
}

// Action Creators
export const actions = {
  createTfaiSaleDepartment: (tfaiSaleDepartment, { callback, isLast }) => ({ type: actionTypes.CREATE_TFAI_SALE_DEPARTMENT, payload: { tfaiSaleDepartment, callback, isLast } }),
  fetchTfaiSaleDepartments: () => ({ type: actionTypes.FETCH_TFAI_SALE_DEPARTMENTS }),
  fetchTfaiSaleDepartment: (id) => ({ type: actionTypes.FETCH_TFAI_SALE_DEPARTMENT, payload: id }),
  updateTfaiSaleDepartment: (tfaiSaleDepartment, { callback, isLast }) => ({ type: actionTypes.UPADE_TFAI_SALE_DEPARTMENT, payload: { tfaiSaleDepartment, callback, isLast } }),
  deleteTfaiSaleDepartment: (id, position) => ({ type: actionTypes.DELETE_TFAI_SALE_DEPARTMENT, payload: { id, position } }),
  deleteTfaiSaleDepartmentByPosition: (position) => ({ type: actionTypes.DELETE_TFAI_SALE_DEPARTMENT_BY_POSITION, payload: { position } }),
  tfaiSaleDepartmentCreated: (tfaiSaleDepartment) => ({ type: actionTypes.TFAI_SALE_DEPARTMENT_CREATED, payload: tfaiSaleDepartment }),
  tfaiSaleDepartmentsLoaded: (tfaiSaleDepartments) => ({ type: actionTypes.TFAI_SALE_DEPARTMENTS_LOADED, payload: tfaiSaleDepartments }),
  tfaiSaleDepartmentLoaded: (tfaiSaleDepartment) => ({ type: actionTypes.TFAI_SALE_DEPARTMENT_LOADED, payload: tfaiSaleDepartment }),
  tfaiSaleDepartmentUpdated: (tfaiSaleDepartment) => ({ type: actionTypes.TFAI_SALE_DEPARTMENT_UPDATED, payload: tfaiSaleDepartment }),
  tfaiSaleDepartmentDeleted: (id, position) => ({ type: actionTypes.TFAI_SALE_DEPARTMENT_DELETED, payload: { id, position } }),

  insertNewRow: () => ({ type: actionTypes.INSERT_NEW_ROW }),
  insertTfaiDepartment: (position, row) => ({ type: actionTypes.INSERT_TFAI_DEPARTMENT, payload: { position, row }}),

  updateGridIndex: (index) => ({ type: actionTypes.UPDATE_GRID_INDEX, payload: { index }}),
}

// Saga
export function * saga () {
  yield takeEvery(actionTypes.CREATE_TFAI_SALE_DEPARTMENT, function * createTfaiSaleDepartmentSaga (action) {
    const { tfaiSaleDepartment, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.post, 'tfai_sale_departments', tfaiSaleDepartment)
      if (isLast) {
        yield put(actions.fetchTfaiSaleDepartments())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_SALE_DEPARTMENTS, function * fetchTfaiSaleDepartmentsSaga () {
    try {
      yield put(loadingDuck.actions.setLoading(true))
      const result = yield call(apiClient.get, 'tfai_sale_departments')
      yield put(actions.tfaiSaleDepartmentsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      yield put(loadingDuck.actions.setLoading(false))
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_SALE_DEPARTMENT, function * fetchTfaiSaleDepartmentSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `tfai_sale_departments/${id}`)
      yield put(actions.tfaiSaleDepartmentLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeEvery(actionTypes.UPADE_TFAI_SALE_DEPARTMENT, function * updateTfaiSaleDepartmentSaga (action) {
    const { tfaiSaleDepartment, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.put, `tfai_sale_departments/${tfaiSaleDepartment.id}`, tfaiSaleDepartment)
      if (isLast) {
        yield put(actions.fetchTfaiSaleDepartments())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if(callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.DELETE_TFAI_SALE_DEPARTMENT, function * deleteTfaiSaleDepartmentSaga (action) {
    try {
      const { id, position } = action.payload
      yield call(apiClient.delete, `tfai_sale_departments/${id}`)
      yield put(actions.tfaiSaleDepartmentDeleted(id, position))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
