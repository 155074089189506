import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_STOCK_UNIT: 'stockUnit/CREATE_STOCK_UNIT',
  FETCH_STOCK_UNITS: 'stockUnit/FETCH_STOCK_UNITS',
  FETCH_STOCK_UNIT: 'stockUnit/FETCH_STOCK_UNIT',
  UPADE_STOCK_UNIT: 'stockUnit/UPADE_STOCK_UNIT',
  DELETE_STOCK_UNIT: 'stockUnit/DELETE_STOCK_UNIT',

  STOCK_UNIT_CREATED: 'stockUnit/STOCK_UNIT_CREATED',
  STOCK_UNITS_LOADED: 'stockUnit/STOCK_UNITS_LOADED',
  STOCK_UNIT_LOADED: 'stockUnit/STOCK_UNIT_LOADED',
  STOCK_UNIT_UPDATED: 'stockUnit/STOCK_UNIT_UPDATED',
  STOCK_UNIT_DELETED: 'stockUnit/STOCK_UNIT_DELETED'
}

const initialState = {
  stockUnit: null,
  stockUnits: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_STOCK_UNIT:
      return { ...state, stockUnit: action.payload }
    case actionTypes.FETCH_STOCK_UNITS:
      return { ...state, stockUnits: null }
    case actionTypes.FETCH_STOCK_UNIT:
      return { ...state, stockUnit: null }
    case actionTypes.UPADE_STOCK_UNIT:
      return { ...state, stockUnit: action.payload }
    case actionTypes.DELETE_STOCK_UNIT:
      return state

    case actionTypes.STOCK_UNIT_CREATED:
      return { ...state, stockUnits: [...state.stockUnits, action.payload] }
    case actionTypes.STOCK_UNITS_LOADED:
      return { ...state, stockUnits: action.payload }
    case actionTypes.STOCK_UNIT_LOADED:
      return { ...state, stockUnit: action.payload }
    case actionTypes.STOCK_UNIT_UPDATED:
      return {
        ...state,
        stockUnit: action.payload,
        stockUnits: state.stockUnits.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.STOCK_UNIT_DELETED:
      return { ...state, stockUnits: state.stockUnits.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createStockUnit: (stockUnit, setSubmitting) => ({ type: actionTypes.CREATE_STOCK_UNIT, payload: { stockUnit, setSubmitting } }),
  fetchStockUnits: () => ({ type: actionTypes.FETCH_STOCK_UNITS }),
  fetchStockUnit: (id) => ({ type: actionTypes.FETCH_STOCK_UNIT, payload: id }),
  updateStockUnit: (stockUnit, setSubmitting) => ({ type: actionTypes.UPADE_STOCK_UNIT, payload: { stockUnit, setSubmitting } }),
  deleteStockUnit: id => ({ type: actionTypes.DELETE_STOCK_UNIT, payload: id }),
  stockUnitCreated: (stockUnit) => ({ type: actionTypes.STOCK_UNIT_CREATED, payload: stockUnit }),
  stockUnitsLoaded: (stockUnits) => ({ type: actionTypes.STOCK_UNITS_LOADED, payload: stockUnits }),
  stockUnitLoaded: (stockUnit) => ({ type: actionTypes.STOCK_UNIT_LOADED, payload: stockUnit }),
  stockUnitUpdated: (stockUnit) => ({ type: actionTypes.STOCK_UNIT_UPDATED, payload: stockUnit }),
  stockUnitDeleted: id => ({ type: actionTypes.STOCK_UNIT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_STOCK_UNIT, function * createStockUnitSaga (action) {
    const { stockUnit, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'stock_units', stockUnit)
      yield put(actions.stockUnitCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_STOCK_UNITS, function * fetchStockUnitsSaga () {
    try {
      const result = yield call(apiClient.get, 'stock_units')
      yield put(actions.stockUnitsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_STOCK_UNIT, function * fetchStockUnitSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `stock_units/${id}`)
      yield put(actions.stockUnitLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_STOCK_UNIT, function * updateStockUnitSaga (action) {
    const { stockUnit, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `stock_units/${stockUnit.id}`, stockUnit)
      yield put(actions.stockUnitUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_STOCK_UNIT, function * deleteStockUnitSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `stock_units/${id}`)
      yield put(actions.stockUnitDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
