import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from './ducks/auth.duck'
import * as loading from './ducks/loading.duck'
import * as entireConfig from './ducks/entireConfig.duck'
import { metronic } from '../../_metronic'
import * as store from './ducks/store.duck'
import * as employee from './ducks/employee.duck'
import * as warehouse from './ducks/warehouse.duck'
import * as config from './ducks/config.duck'
import * as user from './ducks/user.duck'
import * as tradeUnit from './ducks/tradeUnit.duck'
import * as stockUnit from './ducks/stockUnit.duck'
import * as company from './ducks/company.duck'
import * as productMajorCategory from './ducks/productMajorCategory.duck'
import * as productMediumCategory from './ducks/productMediumCategory.duck'
import * as productMinorCategory from './ducks/productMinorCategory.duck'
import * as productDepartmentCategory from './ducks/productDepartmentCategory.duck'
import * as product from './ducks/product.duck'
import * as productTag from './ducks/productTag.duck'
import * as posTable from './ducks/posTable.duck'
import * as department from './ducks/department.duck'
import * as branch from './ducks/branch.duck'
import * as service from './ducks/service.duck'
import * as posFloor from './ducks/posFloor.duck'
import * as posInfo from './ducks/posInfo.duck'
import * as invoiceSource from './ducks/invoiceSource.duck'
import * as invoiceSet from './ducks/invoiceSet.duck'
import * as material from './ducks/material.duck'
import * as materialMajorCategory from './ducks/materialMajorCategory.duck'
import * as materialMediumCategory from './ducks/materialMediumCategory.duck'
import * as materialMinorCategory from './ducks/materialMinorCategory.duck'
import * as productPackage from './ducks/productPackage.duck'
import * as productPackageItem from './ducks/productPackageItem.duck'
import * as productOption from './ducks/productOption.duck'
import * as productOptionItem from './ducks/productOptionItem.duck'
import * as productBundleItem from './ducks/productBundleItem.duck'
import * as productComponentItem from './ducks/productComponentItem.duck'
import * as productSuite from './ducks/productSuite.duck'
import * as productSuiteFolder from './ducks/productSuiteFolder.duck'
import * as productSuiteItem from './ducks/productSuiteItem.duck'
import * as member from './ducks/member.duck'
import * as subscriptionPeriod from './ducks/subscriptionPeriod.duck'
import * as subscriptionQuantity from './ducks/subscriptionQuantity.duck'
import * as subscriptionQuantityItem from './ducks/subscriptionQuantityItem.duck'
import * as subscriptionPeriodItem from './ducks/subscriptionPeriodItem.duck'
import * as posSale from './ducks/posSale.duck'
import * as supplier from './ducks/supplier.duck'
import * as customer from './ducks/customer.duck'
import * as customerContact from './ducks/customerContact.duck'
import * as customerReceive from './ducks/customerReceive.duck'
import * as project from './ducks/project.duck'
import * as erpDepartment from './ducks/erpDepartment.duck'
import * as erpProduct from './ducks/erpProduct.duck'
import * as supplierContact from './ducks/supplierContact.duck'
import * as productUnit from './ducks/productUnit.duck'
import * as ordering from './ducks/ordering.duck'
import * as permission from './ducks/permission.duck'
import * as sale from './ducks/sale.duck'
import * as saleItem from './ducks/saleItem.duck'
import * as purchase from './ducks/purchase.duck'
import * as accountTitle from './ducks/accountTitle.duck'
import * as accountVoucher from './ducks/accountVoucher.duck'
import * as bank from './ducks/bank.duck'
import * as bankTran from './ducks/bankTran.duck'
import * as check from './ducks/check.duck'
import * as checkPayment from './ducks/checkPayment.duck'
import * as accountGeneral from './ducks/accountGeneral.duck'
import * as invoice from './ducks/invoice.duck'
import * as orderingItem from './ducks/orderingItem.duck'
import * as purchaseItem from './ducks/purchaseItem.duck'
import * as accountVouchersItem from './ducks/accountVouchersItem.duck'
import * as promote from './ducks/promote.duck'
import * as productCombo from './ducks/productCombo.duck'
import * as productComboItem from './ducks/productComboItem.duck'
import * as posUser from './ducks/posUser.duck'
import * as tfaiProject from './ducks/tfaiProject.duck'
import * as tfaiSaleDepartment from './ducks/tfaiSaleDepartment.duck'
import * as tfaiDepartment from './ducks/tfaiDepartment.duck'
import * as tfaiProduct from './ducks/tfaiProduct.duck'
import * as tfaiAccountTitle from './ducks/tfaiAccountTitle.duck'
import * as productOrder from './ducks/productOrder.duck'

export const rootReducer = combineReducers({
  productOrders: productOrder.reducer,
  tfaiAccountTitles: tfaiAccountTitle.reducer,
  tfaiProducts: tfaiProduct.reducer,
  tfaiDepartments: tfaiDepartment.reducer,
  tfaiSaleDepartments: tfaiSaleDepartment.reducer,
  tfaiProjects: tfaiProject.reducer,
  posUsers: posUser.reducer,
  productComboItems: productComboItem.reducer,
  productCombos: productCombo.reducer,
  promotes: promote.reducer,
  accountVouchersItems: accountVouchersItem.reducer,
  purchaseItems: purchaseItem.reducer,
  orderingItems: orderingItem.reducer,
  invoices: invoice.reducer,
  accountGenerals: accountGeneral.reducer,
  checkPayments: checkPayment.reducer,
  checks: check.reducer,
  bankTrans: bankTran.reducer,
  banks: bank.reducer,
  accountVouchers: accountVoucher.reducer,
  accountTitles: accountTitle.reducer,
  purchases: purchase.reducer,
  saleItems: saleItem.reducer,
  sales: sale.reducer,
  permission: permission.reducer,
  orderings: ordering.reducer,
  productUnits: productUnit.reducer,
  supplierContacts: supplierContact.reducer,
  erpProducts: erpProduct.reducer,
  erpDepartments: erpDepartment.reducer,
  projects: project.reducer,
  customerReceives: customerReceive.reducer,
  customerContacts: customerContact.reducer,
  customers: customer.reducer,
  suppliers: supplier.reducer,
  posSales: posSale.reducer,
  subscriptionPeriodItems: subscriptionPeriodItem.reducer,
  subscriptionQuantityItems: subscriptionQuantityItem.reducer,
  subscriptionQuantities: subscriptionQuantity.reducer,
  subscriptionPeriods: subscriptionPeriod.reducer,
  members: member.reducer,
  productSuiteItems: productSuiteItem.reducer,
  productSuiteFolders: productSuiteFolder.reducer,
  productSuites: productSuite.reducer,
  productComponentItems: productComponentItem.reducer,
  productBundleItems: productBundleItem.reducer,
  productOptionItems: productOptionItem.reducer,
  productOptions: productOption.reducer,
  productPackageItems: productPackageItem.reducer,
  productPackages: productPackage.reducer,
  materialMinorCategories: materialMinorCategory.reducer,
  materialMediumCategories: materialMediumCategory.reducer,
  materialMajorCategories: materialMajorCategory.reducer,
  materials: material.reducer,
  invoiceSets: invoiceSet.reducer,
  invoiceSources: invoiceSource.reducer,
  posInfos: posInfo.reducer,
  posFloors: posFloor.reducer,
  services: service.reducer,
  branches: branch.reducer,
  departments: department.reducer,
  posTables: posTable.reducer,
  productTags: productTag.reducer,
  products: product.reducer,
  productDepartmentCategories: productDepartmentCategory.reducer,
  productMinorCategories: productMinorCategory.reducer,
  productMediumCategories: productMediumCategory.reducer,
  productMajorCategories: productMajorCategory.reducer,
  companies: company.reducer,
  stockUnits: stockUnit.reducer,
  tradeUnits: tradeUnit.reducer,
  users: user.reducer,
  configs: config.reducer,
  warehouses: warehouse.reducer,
  employees: employee.reducer,
  stores: store.reducer,
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  entireConfigs: entireConfig.reducer,
  isLoading: loading.reducer
})

export function * rootSaga () {
  yield all([
    productOrder.saga(),
    tfaiAccountTitle.saga(),
    tfaiProduct.saga(),
    tfaiDepartment.saga(),
    tfaiSaleDepartment.saga(),
    tfaiProject.saga(),
    posUser.saga(),
    productComboItem.saga(),
    productCombo.saga(),
    promote.saga(),
    accountVouchersItem.saga(),
    purchaseItem.saga(),
    orderingItem.saga(),
    invoice.saga(),
    accountGeneral.saga(),
    checkPayment.saga(),
    check.saga(),
    bankTran.saga(),
    bank.saga(),
    accountVoucher.saga(),
    accountTitle.saga(),
    purchase.saga(),
    saleItem.saga(),
    sale.saga(),
    permission.saga(),
    ordering.saga(),
    productUnit.saga(),
    supplierContact.saga(),
    erpProduct.saga(),
    erpDepartment.saga(),
    project.saga(),
    customerReceive.saga(),
    customerContact.saga(),
    customer.saga(),
    supplier.saga(),
    posSale.saga(),
    subscriptionPeriodItem.saga(),
    subscriptionQuantityItem.saga(),
    subscriptionQuantity.saga(),
    subscriptionPeriod.saga(),
    member.saga(),
    productSuiteItem.saga(),
    productSuiteFolder.saga(),
    productSuite.saga(),
    productComponentItem.saga(),
    productBundleItem.saga(),
    productOptionItem.saga(),
    productOption.saga(),
    productPackageItem.saga(),
    productPackage.saga(),
    materialMinorCategory.saga(),
    materialMediumCategory.saga(),
    materialMajorCategory.saga(),
    material.saga(),
    invoiceSet.saga(),
    invoiceSource.saga(),
    posInfo.saga(),
    posFloor.saga(),
    service.saga(),
    branch.saga(),
    department.saga(),
    posTable.saga(),
    productTag.saga(),
    product.saga(),
    productDepartmentCategory.saga(),
    productMinorCategory.saga(),
    productMediumCategory.saga(),
    productMajorCategory.saga(),
    company.saga(),
    stockUnit.saga(),
    tradeUnit.saga(),
    user.saga(),
    config.saga(),
    warehouse.saga(),
    employee.saga(),
    store.saga(),
    auth.saga()
  ])
}
