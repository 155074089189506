import React from 'react'
import SearchDropdown from '../../../admin/partials/layout/SearchDropdown'
import UserNotifications from '../../../admin/partials/layout/UserNotifications'
import MyCart from '../../../admin/partials/layout/MyCart'
import QuickActionsPanel from '../../../admin/partials/layout/QuickActionsPanel'
import QuickPanelToggler from './QuickPanelToggle'
import LanguageSelector from '../../../admin/partials/layout/LanguageSelector'
import UserProfile from '../../../admin/partials/layout/UserProfile'
import { toAbsoluteUrl } from '../../utils/utils'
import StoreSelector from 'admin/partials/layout/StoreSelector'

export default class Topbar extends React.Component {
  render () {
    return (
      <div className="kt-header__topbar">
        {false &&
      <>
        <SearchDropdown useSVG="true" />

        <UserNotifications
          bgImage={toAbsoluteUrl('/media/misc/bg-1.jpg')}
          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          type="success"
          useSVG="true"
          dot="false"
        />

        <QuickActionsPanel
          bgImage={toAbsoluteUrl('/media/misc/bg-2.jpg')}
          skin="dark"
          iconType=""
          useSVG="true"
          gridNavSkin="light"
        />

        <MyCart
          iconType=""
          useSVG="true"
          bgImage={toAbsoluteUrl('/media/misc/bg-1.jpg')}
        />

        <QuickPanelToggler />

        <LanguageSelector iconType="" />
      </>

        }
        <StoreSelector />

        <UserProfile showAvatar={false} showName={true} showBadge={false} />
      </div>
    )
  }
}
