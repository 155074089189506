import { toast, Slide } from 'react-toastify'

let id = 0

const defaultOptions = {
  config: {
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_RIGHT,
    closeButton: true,
    transition: Slide,
    hideProgressBar: false,
    pauseOnHover: true
  }
}

export default (options = {}) => {
  const _options = { ...defaultOptions, ...options, id: id++ }
  _options.config.toastId = _options.id
  return _options
}
