import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_CHECK: 'check/CREATE_CHECK',
  FETCH_CHECKS: 'check/FETCH_CHECKS',
  FETCH_CHECK: 'check/FETCH_CHECK',
  UPADE_CHECK: 'check/UPADE_CHECK',
  DELETE_CHECK: 'check/DELETE_CHECK',

  CHECK_CREATED: 'check/CHECK_CREATED',
  CHECKS_LOADED: 'check/CHECKS_LOADED',
  CHECK_LOADED: 'check/CHECK_LOADED',
  CHECK_UPDATED: 'check/CHECK_UPDATED',
  CHECK_DELETED: 'check/CHECK_DELETED'
}

const initialState = {
  check: null,
  checks: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_CHECK:
      return { ...state, check: action.payload }
    case actionTypes.FETCH_CHECKS:
      return { ...state, checks: null }
    case actionTypes.FETCH_CHECK:
      return { ...state, check: null }
    case actionTypes.UPADE_CHECK:
      return { ...state, check: action.payload }
    case actionTypes.DELETE_CHECK:
      return state

    case actionTypes.CHECKS_LOADED:
      return { ...state, checks: action.payload }
    case actionTypes.CHECK_LOADED:
      return { ...state, check: action.payload }
    case actionTypes.CHECK_UPDATED:
      return {
        ...state,
        check: action.payload,
        checks: state.checks.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.CHECK_DELETED:
      return { ...state, checks: state.checks.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createCheck: (check, setSubmitting) => ({ type: actionTypes.CREATE_CHECK, payload: { check, setSubmitting } }),
  fetchChecks: () => ({ type: actionTypes.FETCH_CHECKS }),
  fetchCheck: (id) => ({ type: actionTypes.FETCH_CHECK, payload: id }),
  updateCheck: (check, setSubmitting) => ({ type: actionTypes.UPADE_CHECK, payload: { check, setSubmitting } }),
  deleteCheck: id => ({ type: actionTypes.DELETE_CHECK, payload: id }),
  checksLoaded: (checks) => ({ type: actionTypes.CHECKS_LOADED, payload: checks }),
  checkLoaded: (check) => ({ type: actionTypes.CHECK_LOADED, payload: check }),
  checkUpdated: (check) => ({ type: actionTypes.CHECK_UPDATED, payload: check }),
  checkDeleted: id => ({ type: actionTypes.CHECK_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_CHECK, function * createCheckSaga (action) {
    const { check, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'checks', check)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_CHECKS, function * fetchChecksSaga () {
    try {
      const result = yield call(apiClient.get, 'checks')
      yield put(actions.checksLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_CHECK, function * fetchCheckSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `checks/${id}`)
      yield put(actions.checkLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_CHECK, function * updateCheckSaga (action) {
    const { check, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `checks/${check.id}`, check)
      yield put(actions.checkUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_CHECK, function * deleteCheckSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `checks/${id}`)
      yield put(actions.checkDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
