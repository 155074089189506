import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_POS_FLOOR: 'posFloor/CREATE_POS_FLOOR',
  FETCH_POS_FLOORS: 'posFloor/FETCH_POS_FLOORS',
  FETCH_POS_FLOOR: 'posFloor/FETCH_POS_FLOOR',
  UPADE_POS_FLOOR: 'posFloor/UPADE_POS_FLOOR',
  DELETE_POS_FLOOR: 'posFloor/DELETE_POS_FLOOR',

  POS_FLOOR_CREATED: 'posFloor/POS_FLOOR_CREATED',
  POS_FLOORS_LOADED: 'posFloor/POS_FLOORS_LOADED',
  POS_FLOOR_LOADED: 'posFloor/POS_FLOOR_LOADED',
  POS_FLOOR_UPDATED: 'posFloor/POS_FLOOR_UPDATED',
  POS_FLOOR_DELETED: 'posFloor/POS_FLOOR_DELETED'
}

const initialState = {
  posFloor: null,
  posFloors: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_POS_FLOOR:
      return { ...state, posFloor: action.payload }
    case actionTypes.FETCH_POS_FLOORS:
      return { ...state, posFloors: null }
    case actionTypes.FETCH_POS_FLOOR:
      return { ...state, posFloor: null }
    case actionTypes.UPADE_POS_FLOOR:
      return { ...state, posFloor: action.payload }
    case actionTypes.DELETE_POS_FLOOR:
      return state

    case actionTypes.POS_FLOOR_CREATED:
      return { ...state, posFloors: [...state.posFloors, action.payload] }
    case actionTypes.POS_FLOORS_LOADED:
      return { ...state, posFloors: action.payload }
    case actionTypes.POS_FLOOR_LOADED:
      return { ...state, posFloor: action.payload }
    case actionTypes.POS_FLOOR_UPDATED:
      return {
        ...state,
        posFloor: action.payload,
        posFloors: state.posFloors.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.POS_FLOOR_DELETED:
      return { ...state, posFloors: state.posFloors.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPosFloor: (posFloor, setSubmitting) => ({ type: actionTypes.CREATE_POS_FLOOR, payload: { posFloor, setSubmitting } }),
  fetchPosFloors: () => ({ type: actionTypes.FETCH_POS_FLOORS }),
  fetchPosFloor: (id) => ({ type: actionTypes.FETCH_POS_FLOOR, payload: id }),
  updatePosFloor: (posFloor, setSubmitting) => ({ type: actionTypes.UPADE_POS_FLOOR, payload: { posFloor, setSubmitting } }),
  deletePosFloor: id => ({ type: actionTypes.DELETE_POS_FLOOR, payload: id }),
  posFloorCreated: (posFloor) => ({ type: actionTypes.POS_FLOOR_CREATED, payload: posFloor }),
  posFloorsLoaded: (posFloors) => ({ type: actionTypes.POS_FLOORS_LOADED, payload: posFloors }),
  posFloorLoaded: (posFloor) => ({ type: actionTypes.POS_FLOOR_LOADED, payload: posFloor }),
  posFloorUpdated: (posFloor) => ({ type: actionTypes.POS_FLOOR_UPDATED, payload: posFloor }),
  posFloorDeleted: id => ({ type: actionTypes.POS_FLOOR_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_POS_FLOOR, function * createPosFloorSaga (action) {
    const { posFloor, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'pos_floors', posFloor)
      yield put(actions.posFloorCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_FLOORS, function * fetchPosFloorsSaga () {
    try {
      const result = yield call(apiClient.get, 'pos_floors')
      yield put(actions.posFloorsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_FLOOR, function * fetchPosFloorSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `pos_floors/${id}`)
      yield put(actions.posFloorLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_POS_FLOOR, function * updatePosFloorSaga (action) {
    const { posFloor, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `pos_floors/${posFloor.id}`, posFloor)
      yield put(actions.posFloorUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_POS_FLOOR, function * deletePosFloorSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `pos_floors/${id}`)
      yield put(actions.posFloorDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
