import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_CHECK_PAYMENT: 'checkPayment/CREATE_CHECK_PAYMENT',
  FETCH_CHECK_PAYMENTS: 'checkPayment/FETCH_CHECK_PAYMENTS',
  FETCH_CHECK_PAYMENT: 'checkPayment/FETCH_CHECK_PAYMENT',
  UPADE_CHECK_PAYMENT: 'checkPayment/UPADE_CHECK_PAYMENT',
  DELETE_CHECK_PAYMENT: 'checkPayment/DELETE_CHECK_PAYMENT',

  CHECK_PAYMENT_CREATED: 'checkPayment/CHECK_PAYMENT_CREATED',
  CHECK_PAYMENTS_LOADED: 'checkPayment/CHECK_PAYMENTS_LOADED',
  CHECK_PAYMENT_LOADED: 'checkPayment/CHECK_PAYMENT_LOADED',
  CHECK_PAYMENT_UPDATED: 'checkPayment/CHECK_PAYMENT_UPDATED',
  CHECK_PAYMENT_DELETED: 'checkPayment/CHECK_PAYMENT_DELETED'
}

const initialState = {
  checkPayment: null,
  checkPayments: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_CHECK_PAYMENT:
      return { ...state, checkPayment: action.payload }
    case actionTypes.FETCH_CHECK_PAYMENTS:
      return { ...state, checkPayments: null }
    case actionTypes.FETCH_CHECK_PAYMENT:
      return { ...state, checkPayment: null }
    case actionTypes.UPADE_CHECK_PAYMENT:
      return { ...state, checkPayment: action.payload }
    case actionTypes.DELETE_CHECK_PAYMENT:
      return state

    case actionTypes.CHECK_PAYMENTS_LOADED:
      return { ...state, checkPayments: action.payload }
    case actionTypes.CHECK_PAYMENT_LOADED:
      return { ...state, checkPayment: action.payload }
    case actionTypes.CHECK_PAYMENT_UPDATED:
      return {
        ...state,
        checkPayment: action.payload,
        checkPayments: state.checkPayments.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.CHECK_PAYMENT_DELETED:
      return { ...state, checkPayments: state.checkPayments.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createCheckPayment: (checkPayment, setSubmitting) => ({ type: actionTypes.CREATE_CHECK_PAYMENT, payload: { checkPayment, setSubmitting } }),
  fetchCheckPayments: () => ({ type: actionTypes.FETCH_CHECK_PAYMENTS }),
  fetchCheckPayment: (id) => ({ type: actionTypes.FETCH_CHECK_PAYMENT, payload: id }),
  updateCheckPayment: (checkPayment, setSubmitting) => ({ type: actionTypes.UPADE_CHECK_PAYMENT, payload: { checkPayment, setSubmitting } }),
  deleteCheckPayment: id => ({ type: actionTypes.DELETE_CHECK_PAYMENT, payload: id }),
  checkPaymentsLoaded: (checkPayments) => ({ type: actionTypes.CHECK_PAYMENTS_LOADED, payload: checkPayments }),
  checkPaymentLoaded: (checkPayment) => ({ type: actionTypes.CHECK_PAYMENT_LOADED, payload: checkPayment }),
  checkPaymentUpdated: (checkPayment) => ({ type: actionTypes.CHECK_PAYMENT_UPDATED, payload: checkPayment }),
  checkPaymentDeleted: id => ({ type: actionTypes.CHECK_PAYMENT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_CHECK_PAYMENT, function * createCheckPaymentSaga (action) {
    const { checkPayment, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'check_payments', checkPayment)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_CHECK_PAYMENTS, function * fetchCheckPaymentsSaga () {
    try {
      const result = yield call(apiClient.get, 'check_payments')
      yield put(actions.checkPaymentsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_CHECK_PAYMENT, function * fetchCheckPaymentSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `check_payments/${id}`)
      yield put(actions.checkPaymentLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_CHECK_PAYMENT, function * updateCheckPaymentSaga (action) {
    const { checkPayment, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `check_payments/${checkPayment.id}`, checkPayment)
      yield put(actions.checkPaymentUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_CHECK_PAYMENT, function * deleteCheckPaymentSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `check_payments/${id}`)
      yield put(actions.checkPaymentDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
