export const toastType = {
  SUCCESS: 'success',
  ERROR: 'error'
}

export const Permissions = {
  MANAGE_DASHBOARD: 'manage_dashboard',
  MANAGE_DEMO: 'manage_demo',
  MANAGE_POS_SALES: 'manage_pos_sales',
  MANAGE_POS_SALES_CREATE: 'manage_pos_sales_create',
  MANAGE_POS_SALES_UPDATE: 'manage_pos_sales_update',
  MANAGE_POS_SALES_DELETE: 'manage_pos_sales_delete',
  MANAGE_COMPANIES: 'manage_companies',
  MANAGE_COMPANIES_CREATE: 'manage_companies_create',
  MANAGE_COMPANIES_UPDATE: 'manage_companies_update',
  MANAGE_COMPANIES_DELETE: 'manage_companies_delete',
  MANAGE_DEPARTMENTS: 'manage_departments',
  MANAGE_DEPARTMENTS_CREATE: 'manage_departments_create',
  MANAGE_DEPARTMENTS_UPDATE: 'manage_departments_update',
  MANAGE_DEPARTMENTS_DELETE: 'manage_departments_delete',
  MANAGE_STORES: 'manage_stores',
  MANAGE_STORES_CREATE: 'manage_stores_create',
  MANAGE_STORES_UPDATE: 'manage_stores_update',
  MANAGE_STORES_DELETE: 'manage_stores_delete',
  MANAGE_POS_FLOORS: 'manage_pos_floors',
  MANAGE_POS_FLOORS_CREATE: 'manage_pos_floors_create',
  MANAGE_POS_FLOORS_UPDATE: 'manage_pos_floors_update',
  MANAGE_POS_FLOORS_DELETE: 'manage_pos_floors_delete',
  MANAGE_POS_TABLES: 'manage_pos_tables',
  MANAGE_POS_TABLES_CREATE: 'manage_pos_tables_create',
  MANAGE_POS_TABLES_UPDATE: 'manage_pos_tables_update',
  MANAGE_POS_TABLES_DELETE: 'manage_pos_tables_delete',
  MANAGE_SERVICES: 'manage_services',
  MANAGE_SERVICES_CREATE: 'manage_services_create',
  MANAGE_SERVICES_UPDATE: 'manage_services_update',
  MANAGE_SERVICES_DELETE: 'manage_services_delete',
  MANAGE_BRANCHES: 'manage_branches',
  MANAGE_BRANCHES_CREATE: 'manage_branches_create',
  MANAGE_BRANCHES_UPDATE: 'manage_branches_update',
  MANAGE_BRANCHES_DELETE: 'manage_branches_delete',
  MANAGE_USERS: 'manage_users',
  MANAGE_USERS_CREATE: 'manage_users_create',
  MANAGE_USERS_UPDATE: 'manage_users_update',
  MANAGE_USERS_DELETE: 'manage_users_delete',
  MANAGE_EMPLOYEES: 'manage_employees',
  MANAGE_EMPLOYEES_CREATE: 'manage_employees_create',
  MANAGE_EMPLOYEES_UPDATE: 'manage_employees_update',
  MANAGE_EMPLOYEES_DELETE: 'manage_employees_delete',
  MANAGE_POS_INFOS: 'manage_pos_infos',
  MANAGE_POS_INFOS_CREATE: 'manage_pos_infos_create',
  MANAGE_POS_INFOS_UPDATE: 'manage_pos_infos_update',
  MANAGE_POS_INFOS_DELETE: 'manage_pos_infos_delete',
  MANAGE_INVOICE_SETS: 'manage_invoice_sets',
  MANAGE_INVOICE_SOURCES: 'manage_invoice_sources',
  MANAGE_INVOICE_SOURCES_CREATE: 'manage_invoice_sources_create',
  MANAGE_INVOICE_SOURCES_UPDATE: 'manage_invoice_sources_update',
  MANAGE_INVOICE_SOURCES_DELETE: 'manage_invoice_sources_delete',
  MANAGE_STOCK_UNITS: 'manage_stock_units',
  MANAGE_STOCK_UNITS_CREATE: 'manage_stock_units_create',
  MANAGE_STOCK_UNITS_UPDATE: 'manage_stock_units_update',
  MANAGE_STOCK_UNITS_DELETE: 'manage_stock_units_delete',
  MANAGE_MEMBERS: 'manage_members',
  MANAGE_MEMBERS_CREATE: 'manage_members_create',
  MANAGE_MEMBERS_UPDATE: 'manage_members_update',
  MANAGE_MEMBERS_DELETE: 'manage_members_delete',
  MANAGE_MATERIAL_MAJOR_CATEGORIES: 'manage_material_major_categories',
  MANAGE_MATERIAL_MAJOR_CATEGORIES_CREATE: 'manage_material_major_categories_create',
  MANAGE_MATERIAL_MAJOR_CATEGORIES_UPDATE: 'manage_material_major_categories_update',
  MANAGE_MATERIAL_MAJOR_CATEGORIES_DELETE: 'manage_material_major_categories_delete',
  MANAGE_MATERIAL_MEDIUM_CATEGORIES: 'manage_material_medium_categories',
  MANAGE_MATERIAL_MEDIUM_CATEGORIES_CREATE: 'manage_material_medium_categories_create',
  MANAGE_MATERIAL_MEDIUM_CATEGORIES_UPDATE: 'manage_material_medium_categories_update',
  MANAGE_MATERIAL_MEDIUM_CATEGORIES_DELETE: 'manage_material_medium_categories_delete',
  MANAGE_MATERIAL_MINOR_CATEGORIES: 'manage_material_minor_categories',
  MANAGE_MATERIAL_MINOR_CATEGORIES_CREATE: 'manage_material_minor_categories_create',
  MANAGE_MATERIAL_MINOR_CATEGORIES_UPDATE: 'manage_material_minor_categories_update',
  MANAGE_MATERIAL_MINOR_CATEGORIES_DELETE: 'manage_material_minor_categories_delete',
  MANAGE_MATERIALS: 'manage_materials',
  MANAGE_MATERIALS_CREATE: 'manage_materials_create',
  MANAGE_MATERIALS_UPDATE: 'manage_materials_update',
  MANAGE_MATERIALS_DELETE: 'manage_materials_delete',
  MANAGE_PRODUCT_DEPARTMENT_CATEGORIES: 'manage_product_department_categories',
  MANAGE_PRODUCT_DEPARTMENT_CATEGORIES_CREATE: 'manage_product_department_categories_create',
  MANAGE_PRODUCT_DEPARTMENT_CATEGORIES_UPDATE: 'manage_product_department_categories_update',
  MANAGE_PRODUCT_DEPARTMENT_CATEGORIES_DELETE: 'manage_product_department_categories_delete',
  MANAGE_PRODUCT_MAJOR_CATEGORIES: 'manage_product_major_categories',
  MANAGE_PRODUCT_MAJOR_CATEGORIES_CREATE: 'manage_product_major_categories_create',
  MANAGE_PRODUCT_MAJOR_CATEGORIES_UPDATE: 'manage_product_major_categories_update',
  MANAGE_PRODUCT_MAJOR_CATEGORIES_DELETE: 'manage_product_major_categories_delete',
  MANAGE_PRODUCT_MEDIUM_CATEGORIES: 'manage_product_medium_categories',
  MANAGE_PRODUCT_MEDIUM_CATEGORIES_CREATE: 'manage_product_medium_categories_create',
  MANAGE_PRODUCT_MEDIUM_CATEGORIES_UPDATE: 'manage_product_medium_categories_update',
  MANAGE_PRODUCT_MEDIUM_CATEGORIES_DELETE: 'manage_product_medium_categories_delete',
  MANAGE_PRODUCT_MINOR_CATEGORIES: 'manage_product_minor_categories',
  MANAGE_PRODUCT_MINOR_CATEGORIES_CREATE: 'manage_product_minor_categories_create',
  MANAGE_PRODUCT_MINOR_CATEGORIES_UPDATE: 'manage_product_minor_categories_update',
  MANAGE_PRODUCT_MINOR_CATEGORIES_DELETE: 'manage_product_minor_categories_delete',
  MANAGE_PRODUCTS: 'manage_products',
  MANAGE_PRODUCTS_CREATE: 'manage_products_create',
  MANAGE_PRODUCTS_UPDATE: 'manage_products_update',
  MANAGE_PRODUCTS_DELETE: 'manage_products_delete',
  MANAGE_PRODUCT_SUITES: 'manage_product_suites',
  MANAGE_PRODUCT_SUITES_CREATE: 'manage_product_suites_create',
  MANAGE_PRODUCT_SUITES_UPDATE: 'manage_product_suites_update',
  MANAGE_PRODUCT_SUITES_DELETE: 'manage_product_suites_delete',
  MANAGE_SUBSCRIPTION_PERIODS: 'manage_subscription_periods',
  MANAGE_SUBSCRIPTION_PERIODS_CREATE: 'manage_subscription_periods_create',
  MANAGE_SUBSCRIPTION_PERIODS_UPDATE: 'manage_subscription_periods_update',
  MANAGE_SUBSCRIPTION_PERIODS_DELETE: 'manage_subscription_periods_delete',
  MANAGE_SUBSCRIPTION_QUANTITIES: 'manage_subscription_quantities',
  MANAGE_SUBSCRIPTION_QUANTITIES_CREATE: 'manage_subscription_quantities_create',
  MANAGE_SUBSCRIPTION_QUANTITIES_UPDATE: 'manage_subscription_quantities_update',
  MANAGE_SUBSCRIPTION_QUANTITIES_DELETE: 'manage_subscription_quantities_delete',
  MANAGE_PRODUCT_PACKAGES: 'manage_product_packages',
  MANAGE_PRODUCT_PACKAGES_CREATE: 'manage_product_packages_create',
  MANAGE_PRODUCT_PACKAGES_UPDATE: 'manage_product_packages_update',
  MANAGE_PRODUCT_PACKAGES_DELETE: 'manage_product_packages_delete',
  MANAGE_PRODUCT_TAGS: 'manage_product_tags',
  MANAGE_PRODUCT_TAGS_CREATE: 'manage_product_tags_create',
  MANAGE_PRODUCT_TAGS_UPDATE: 'manage_product_tags_update',
  MANAGE_PRODUCT_TAGS_DELETE: 'manage_product_tags_delete',
  MANAGE_PRODUCT_OPTIONS: 'manage_product_options',
  MANAGE_PRODUCT_OPTIONS_CREATE: 'manage_product_options_create',
  MANAGE_PRODUCT_OPTIONS_UPDATE: 'manage_product_options_update',
  MANAGE_PRODUCT_OPTIONS_DELETE: 'manage_product_options_delete',
  MANAGE_CONFIGS: 'manage_configs',
  MANAGE_CONFIGS_CREATE: 'manage_configs_create',
  MANAGE_CONFIGS_UPDATE: 'manage_configs_update',
  MANAGE_CONFIGS_DELETE: 'manage_configs_delete',
  MANAGE_WAREHOUSES: 'manage_warehouses',
  MANAGE_WAREHOUSES_CREATE: 'manage_warehouses_create',
  MANAGE_WAREHOUSES_UPDATE: 'manage_warehouses_update',
  MANAGE_WAREHOUSES_DELETE: 'manage_warehouses_delete',
  MANAGE_SUPPLIERS: 'manage_suppliers',
  MANAGE_SUPPLIERS_CREATE: 'manage_suppliers_create',
  MANAGE_SUPPLIERS_UPDATE: 'manage_suppliers_update',
  MANAGE_SUPPLIERS_DELETE: 'manage_suppliers_delete',
  MANAGE_SUPPLIER_CONTACTS: 'manage_supplier_contacts',
  MANAGE_SUPPLIER_CONTACTS_CREATE: 'manage_supplier_contacts_create',
  MANAGE_SUPPLIER_CONTACTS_UPDATE: 'manage_supplier_contacts_update',
  MANAGE_SUPPLIER_CONTACTS_DELETE: 'manage_supplier_contacts_delete',
  MANAGE_CUSTOMERS: 'manage_customers',
  MANAGE_CUSTOMERS_CREATE: 'manage_customers_create',
  MANAGE_CUSTOMERS_UPDATE: 'manage_customers_update',
  MANAGE_CUSTOMERS_DELETE: 'manage_customers_delete',
  MANAGE_CUSTOMER_CONTACTS: 'manage_customer_contacts',
  MANAGE_CUSTOMER_CONTACTS_CREATE: 'manage_customer_contacts_create',
  MANAGE_CUSTOMER_CONTACTS_UPDATE: 'manage_customer_contacts_update',
  MANAGE_CUSTOMER_CONTACTS_DELETE: 'manage_customer_contacts_delete',
  MANAGE_CUSTOMER_RECEIVES: 'manage_customer_receives',
  MANAGE_CUSTOMER_RECEIVES_CREATE: 'manage_customer_receives_create',
  MANAGE_CUSTOMER_RECEIVES_UPDATE: 'manage_customer_receives_update',
  MANAGE_CUSTOMER_RECEIVES_DELETE: 'manage_customer_receives_delete',
  MANAGE_ERP_DEPARTMENTS: 'manage_erp_departments',
  MANAGE_ERP_DEPARTMENTS_CREATE: 'manage_erp_departments_create',
  MANAGE_ERP_DEPARTMENTS_UPDATE: 'manage_erp_departments_update',
  MANAGE_ERP_DEPARTMENTS_DELETE: 'manage_erp_departments_delete',
  MANAGE_PROJECTS: 'manage_projects',
  MANAGE_PROJECTS_CREATE: 'manage_projects_create',
  MANAGE_PROJECTS_UPDATE: 'manage_projects_update',
  MANAGE_PROJECTS_DELETE: 'manage_projects_delete',
  MANAGE_PRODUCT_UNITS: 'manage_product_units',
  MANAGE_PRODUCT_UNITS_CREATE: 'manage_product_units_create',
  MANAGE_PRODUCT_UNITS_UPDATE: 'manage_product_units_update',
  MANAGE_PRODUCT_UNITS_DELETE: 'manage_product_units_delete',
  MANAGE_ORDERINGS: 'manage_orderings',
  MANAGE_ORDERINGS_CREATE: 'manage_orderings_create',
  MANAGE_ORDERINGS_UPDATE: 'manage_orderings_update',
  MANAGE_ORDERINGS_DELETE: 'manage_orderings_delete',
}

export const Urls = {
  HOME: '/home'
}
