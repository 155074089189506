import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import AdminApp from './admin'
import './index.scss'

const App = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" name="Admin Home" render={props => <AdminApp {...props}/>}/>
        <Redirect from="*" to="/" exact={true}/>
      </Switch>
    </HashRouter>
  )
}

export default App
