import { put, call, fork, takeLatest, select } from 'redux-saga/effects'
import apiClient, { apiClientBlob } from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { getDayAfter } from 'shared/utils/dateMethod'
import { searchIntValue } from 'admin/pages/home/common/pos_sales/posSaleDefaultValue'
import * as entireConfigDuck from './entireConfig.duck'
import * as loadingDuck from './loading.duck'

// Actions
export const actionTypes = {
  CREATE_POS_SALE: 'posSale/CREATE_POS_SALE',
  FETCH_POS_SALES: 'posSale/FETCH_POS_SALES',
  FETCH_POS_SALE: 'posSale/FETCH_POS_SALE',
  UPADE_POS_SALE: 'posSale/UPADE_POS_SALE',
  DELETE_POS_SALE: 'posSale/DELETE_POS_SALE',
  GET_SEARCH_VALUES: 'posSale/GET_SEARCH_VALUES',
  SEARCH_POS_SALES: 'posSale/SEARCH_POS_SALES',
  EXPORT_POS_SALES_EXCEL: 'posSale/EXPORT_POS_SALES_EXCEL',

  POS_SALE_CREATED: 'posSale/POS_SALE_CREATED',
  POS_SALES_LOADED: 'posSale/POS_SALES_LOADED',
  POS_SALE_LOADED: 'posSale/POS_SALE_LOADED',
  POS_SALE_UPDATED: 'posSale/POS_SALE_UPDATED',
  POS_SALE_DELETED: 'posSale/POS_SALE_DELETED',
  SEARCH_POS_SALES_LOADED: 'posSale/SEARCH_POS_SALES_LOADED'
}

const initialState = {
  posSale: null,
  posSales: null,
  searchValues: { ...searchIntValue },
  searchPosSales: null,
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_POS_SALE:
      return { ...state, posSale: action.payload }
    case actionTypes.FETCH_POS_SALES:
      return { ...state, posSales: null }
    case actionTypes.FETCH_POS_SALE:
      return { ...state, posSale: null }
    case actionTypes.UPADE_POS_SALE:
      return { ...state, posSale: action.payload }
    case actionTypes.DELETE_POS_SALE:
      return state
    case actionTypes.GET_SEARCH_VALUES:
      return { ...state, searchValues: { ...state.searchValues, ...action.payload } }
    case actionTypes.SEARCH_POS_SALES:
      return { ...state, searchPosSales: null }

    case actionTypes.POS_SALE_CREATED:
      return { ...state, posSales: [...state.posSales, action.payload] }
    case actionTypes.POS_SALES_LOADED:
      return { ...state, posSales: action.payload }
    case actionTypes.POS_SALE_LOADED:
      return { ...state, posSale: action.payload }
    case actionTypes.POS_SALE_UPDATED:
      return {
        ...state,
        posSale: action.payload,
        posSales: state.posSales.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.POS_SALE_DELETED:
      return { ...state, posSales: state.posSales.filter(item => item.id !== action.payload) }
     case actionTypes.SEARCH_POS_SALES_LOADED:
      return { ...state, searchPosSales: action.payload }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPosSale: (posSale, setSubmitting) => ({ type: actionTypes.CREATE_POS_SALE, payload: { posSale, setSubmitting } }),
  fetchPosSales: () => ({ type: actionTypes.FETCH_POS_SALES }),
  fetchPosSale: (id) => ({ type: actionTypes.FETCH_POS_SALE, payload: id }),
  updatePosSale: (posSale, setSubmitting) => ({ type: actionTypes.UPADE_POS_SALE, payload: { posSale, setSubmitting } }),
  deletePosSale: id => ({ type: actionTypes.DELETE_POS_SALE, payload: id }),
  getSearchValues: (obj) => ({ type: actionTypes.GET_SEARCH_VALUES, payload: obj }),
  searchPosSales: (searchObj) => ({ type: actionTypes.SEARCH_POS_SALES, payload: searchObj }),
  exportPosSalesExcel: (obj) => ({ type: actionTypes.EXPORT_POS_SALES_EXCEL, payload: obj }),

  posSaleCreated: (posSale) => ({ type: actionTypes.POS_SALE_CREATED, payload: posSale }),
  posSalesLoaded: (posSales) => ({ type: actionTypes.POS_SALES_LOADED, payload: posSales }),
  posSaleLoaded: (posSale) => ({ type: actionTypes.POS_SALE_LOADED, payload: posSale }),
  posSaleUpdated: (posSale) => ({ type: actionTypes.POS_SALE_UPDATED, payload: posSale }),
  posSaleDeleted: id => ({ type: actionTypes.POS_SALE_DELETED, payload: id }),
  searchPosSalesLoaded: (searchPosSales) => ({
    type: actionTypes.SEARCH_POS_SALES_LOADED,
    payload: searchPosSales,
  }),
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_POS_SALE, function * createPosSaleSaga (action) {
    const { posSale, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'pos_sales', posSale)
      yield put(actions.posSaleCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_SALES, function * fetchPosSalesSaga () {
    try {
      const result = yield call(apiClient.get, 'pos_sales')
      yield put(actions.posSalesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_SALE, function * fetchPosSaleSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `pos_sales/${id}`)
      yield put(actions.posSaleLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_POS_SALE, function * updatePosSaleSaga (action) {
    const { posSale, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `pos_sales/${posSale.id}`, posSale)
      yield put(actions.posSaleUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_POS_SALE, function * deletePosSaleSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `pos_sales/${id}`)
      yield put(actions.posSaleDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.SEARCH_POS_SALES, function* searchPosSalesSaga(action) {
    const schObj = action.payload
    try {
      const result = yield call(apiClient.get, 'pos_sales', {
        params: {
          gte_sale_date: !schObj.start_date ? null : schObj.start_date,
          lt_sale_date: !schObj.end_date ? null : getDayAfter.hyphen(1, schObj.end_date),
        },
      })
      yield put(actions.searchPosSalesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (schObj.setSubmitting) {
        schObj.setSubmitting(false)
      }
    }
  })

  yield takeLatest(actionTypes.EXPORT_POS_SALES_EXCEL, function* exportPosSalesExcelSaga(action) {
    const { searchValues } = yield select(state => state.posSales)
    try {
      yield put(loadingDuck.actions.setLoading(true))
      const result = yield call(apiClientBlob.post, `admin/pos_sales/export_excel?gte_sale_date=${searchValues.start_date}&lt_sale_date=${getDayAfter.hyphen(1, searchValues.end_date)}`)
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${searchValues.start_date}~${searchValues.end_date}銷售紀錄.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      yield put(loadingDuck.actions.setLoading(false))
    }
  })
}
