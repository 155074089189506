import React from 'react'
import "./SpinBar.css"

const SpinBar = () => {
    return (
        <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
}

export default SpinBar