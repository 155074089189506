import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  FETCH_PRODUCT_ORDERS: 'productOrder/FETCH_PRODUCT_ORDERS',
  UPADE_PRODUCT_ORDER: 'productOrder/UPADE_PRODUCT_ORDER',

  PRODUCT_ORDERS_LOADED: 'productOrder/PRODUCT_ORDERS_LOADED',
  PRODUCT_ORDER_UPDATED: 'productOrder/PRODUCT_ORDER_UPDATED',

  UPDATE_GRID_INDEX: 'productOrder/UPDATE_GRID_INDEX',
}

const initialState = {
  productOrder: null,
  productOrders: null,
  agGridIndex: 0,
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_ORDERS:
      return { ...state, productOrders: null }
    case actionTypes.UPADE_PRODUCT_ORDER:
      return { ...state, productOrder: action.payload }

    case actionTypes.PRODUCT_ORDERS_LOADED:
      return { ...state, productOrders: action.payload }
    case actionTypes.PRODUCT_ORDER_UPDATED:
      return {
        ...state,
        productOrder: action.payload,
        productOrders: state.productOrders.map(item => item.id === +action.payload.id ? action.payload : item)
      }

    case actionTypes.UPDATE_GRID_INDEX:
      const { index: gridIndex } = action.payload
      return { ...state, agGridIndex: gridIndex}

    default:
      return state
  }
}

// Action Creators
export const actions = {
  fetchProductOrders: () => ({ type: actionTypes.FETCH_PRODUCT_ORDERS }),
  updateProductOrder: (productOrder, { callback, isLast }) => ({ type: actionTypes.UPADE_PRODUCT_ORDER, payload: { productOrder, callback, isLast  } }),
  productOrdersLoaded: (productOrders) => ({ type: actionTypes.PRODUCT_ORDERS_LOADED, payload: productOrders }),
  productOrderUpdated: (productOrder) => ({ type: actionTypes.PRODUCT_ORDER_UPDATED, payload: productOrder }),

  updateGridIndex: (index) => ({ type: actionTypes.UPDATE_GRID_INDEX, payload: { index }}),
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.FETCH_PRODUCT_ORDERS, function * fetchProductOrdersSaga () {
    try {
      const result = yield call(apiClient.get, 'products')
      yield put(actions.productOrdersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_ORDER, function * updateProductOrderSaga (action) {
    const { productOrder, callback, isLast } = action.payload
    const updatedData = {
      id: productOrder.id,
      position: productOrder.position,
      productBundleItems: [],
      productComponentItems: []
    }
    try {
      const result = yield call(apiClient.put, `products/${productOrder.id}`, updatedData)
      if(isLast) {
        yield put(actions.fetchProductOrders())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if(callback && isLast) {
        callback()
      }
    }
  })
}
