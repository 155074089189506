/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { connect } from 'react-redux'
import objectPath from 'object-path'
import { withRouter } from 'react-router-dom'
import { QuickActions } from './components/QuickActions'
import { LayoutContextConsumer } from '../LayoutContext'
import * as builder from '../../ducks/builder'
import BreadCrumbs from './components/BreadCrumbs'

class SubHeader extends React.Component {
  render () {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses
    } = this.props
    return (
      <div
        id="kt_subheader"
        className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
      >
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className="kt-subheader__main">

            <LayoutContextConsumer>
              {/* {({ subheader: { title, breadcrumb } }) => ( */}

              {({ subheader: { title, breadcrumb } }) => (
                <>
                  <BreadCrumbs items={breadcrumb} />
                </>
              )}
            </LayoutContextConsumer>

          </div>

          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              <QuickActions />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    'subheader.mobile-toggle'
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true
  })
})

export default withRouter(connect(mapStateToProps)(SubHeader))
