import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_MATERIAL_MEDIUM_CATEGORY: 'materialMediumCategory/CREATE_MATERIAL_MEDIUM_CATEGORY',
  FETCH_MATERIAL_MEDIUM_CATEGORIES: 'materialMediumCategory/FETCH_MATERIAL_MEDIUM_CATEGORIES',
  FETCH_MATERIAL_MEDIUM_CATEGORY: 'materialMediumCategory/FETCH_MATERIAL_MEDIUM_CATEGORY',
  UPADE_MATERIAL_MEDIUM_CATEGORY: 'materialMediumCategory/UPADE_MATERIAL_MEDIUM_CATEGORY',
  DELETE_MATERIAL_MEDIUM_CATEGORY: 'materialMediumCategory/DELETE_MATERIAL_MEDIUM_CATEGORY',

  MATERIAL_MEDIUM_CATEGORY_CREATED: 'materialMediumCategory/MATERIAL_MEDIUM_CATEGORY_CREATED',
  MATERIAL_MEDIUM_CATEGORIES_LOADED: 'materialMediumCategory/MATERIAL_MEDIUM_CATEGORIES_LOADED',
  MATERIAL_MEDIUM_CATEGORY_LOADED: 'materialMediumCategory/MATERIAL_MEDIUM_CATEGORY_LOADED',
  MATERIAL_MEDIUM_CATEGORY_UPDATED: 'materialMediumCategory/MATERIAL_MEDIUM_CATEGORY_UPDATED',
  MATERIAL_MEDIUM_CATEGORY_DELETED: 'materialMediumCategory/MATERIAL_MEDIUM_CATEGORY_DELETED'
}

const initialState = {
  materialMediumCategory: null,
  materialMediumCategories: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_MATERIAL_MEDIUM_CATEGORY:
      return { ...state, materialMediumCategory: action.payload }
    case actionTypes.FETCH_MATERIAL_MEDIUM_CATEGORIES:
      return { ...state, materialMediumCategories: null }
    case actionTypes.FETCH_MATERIAL_MEDIUM_CATEGORY:
      return { ...state, materialMediumCategory: null }
    case actionTypes.UPADE_MATERIAL_MEDIUM_CATEGORY:
      return { ...state, materialMediumCategory: action.payload }
    case actionTypes.DELETE_MATERIAL_MEDIUM_CATEGORY:
      return state

    case actionTypes.MATERIAL_MEDIUM_CATEGORY_CREATED:
      return { ...state, materialMediumCategories: [...state.materialMediumCategories, action.payload] }
    case actionTypes.MATERIAL_MEDIUM_CATEGORIES_LOADED:
      return { ...state, materialMediumCategories: action.payload }
    case actionTypes.MATERIAL_MEDIUM_CATEGORY_LOADED:
      return { ...state, materialMediumCategory: action.payload }
    case actionTypes.MATERIAL_MEDIUM_CATEGORY_UPDATED:
      return {
        ...state,
        materialMediumCategory: action.payload,
        materialMediumCategories: state.materialMediumCategories.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.MATERIAL_MEDIUM_CATEGORY_DELETED:
      return { ...state, materialMediumCategories: state.materialMediumCategories.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createMaterialMediumCategory: (materialMediumCategory, setSubmitting) => ({ type: actionTypes.CREATE_MATERIAL_MEDIUM_CATEGORY, payload: { materialMediumCategory, setSubmitting } }),
  fetchMaterialMediumCategories: () => ({ type: actionTypes.FETCH_MATERIAL_MEDIUM_CATEGORIES }),
  fetchMaterialMediumCategory: (id) => ({ type: actionTypes.FETCH_MATERIAL_MEDIUM_CATEGORY, payload: id }),
  updateMaterialMediumCategory: (materialMediumCategory, setSubmitting) => ({ type: actionTypes.UPADE_MATERIAL_MEDIUM_CATEGORY, payload: { materialMediumCategory, setSubmitting } }),
  deleteMaterialMediumCategory: id => ({ type: actionTypes.DELETE_MATERIAL_MEDIUM_CATEGORY, payload: id }),
  materialMediumCategoryCreated: (materialMediumCategory) => ({ type: actionTypes.MATERIAL_MEDIUM_CATEGORY_CREATED, payload: materialMediumCategory }),
  materialMediumCategoriesLoaded: (materialMediumCategories) => ({ type: actionTypes.MATERIAL_MEDIUM_CATEGORIES_LOADED, payload: materialMediumCategories }),
  materialMediumCategoryLoaded: (materialMediumCategory) => ({ type: actionTypes.MATERIAL_MEDIUM_CATEGORY_LOADED, payload: materialMediumCategory }),
  materialMediumCategoryUpdated: (materialMediumCategory) => ({ type: actionTypes.MATERIAL_MEDIUM_CATEGORY_UPDATED, payload: materialMediumCategory }),
  materialMediumCategoryDeleted: id => ({ type: actionTypes.MATERIAL_MEDIUM_CATEGORY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_MATERIAL_MEDIUM_CATEGORY, function * createMaterialMediumCategorySaga (action) {
    const { materialMediumCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'material_medium_categories', materialMediumCategory)
      yield put(actions.materialMediumCategoryCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIAL_MEDIUM_CATEGORIES, function * fetchMaterialMediumCategoriesSaga () {
    try {
      const result = yield call(apiClient.get, 'material_medium_categories')
      yield put(actions.materialMediumCategoriesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIAL_MEDIUM_CATEGORY, function * fetchMaterialMediumCategorySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `material_medium_categories/${id}`)
      yield put(actions.materialMediumCategoryLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_MATERIAL_MEDIUM_CATEGORY, function * updateMaterialMediumCategorySaga (action) {
    const { materialMediumCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `material_medium_categories/${materialMediumCategory.id}`, materialMediumCategory)
      yield put(actions.materialMediumCategoryUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_MATERIAL_MEDIUM_CATEGORY, function * deleteMaterialMediumCategorySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `material_medium_categories/${id}`)
      yield put(actions.materialMediumCategoryDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
