import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { v4 as uuidv4 } from 'uuid'
import { insertEmptyRow, insertRows } from 'shared/itemHelper'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_OPTION_ITEM: 'productOptionItem/CREATE_PRODUCT_OPTION_ITEM',
  FETCH_PRODUCT_OPTION_ITEMS: 'productOptionItem/FETCH_PRODUCT_OPTION_ITEMS',
  FETCH_PRODUCT_OPTION_ITEM: 'productOptionItem/FETCH_PRODUCT_OPTION_ITEM',
  UPADE_PRODUCT_OPTION_ITEM: 'productOptionItem/UPADE_PRODUCT_OPTION_ITEM',
  DELETE_PRODUCT_OPTION_ITEM: 'productOptionItem/DELETE_PRODUCT_OPTION_ITEM',

  PRODUCT_OPTION_ITEM_CREATED: 'productOptionItem/PRODUCT_OPTION_ITEM_CREATED',
  PRODUCT_OPTION_ITEMS_LOADED: 'productOptionItem/PRODUCT_OPTION_ITEMS_LOADED',
  PRODUCT_OPTION_ITEM_LOADED: 'productOptionItem/PRODUCT_OPTION_ITEM_LOADED',
  PRODUCT_OPTION_ITEM_UPDATED: 'productOptionItem/PRODUCT_OPTION_ITEM_UPDATED',
  PRODUCT_OPTION_ITEM_DELETED: 'productOptionItem/PRODUCT_OPTION_ITEM_DELETED',

  INSERT_EMPTY_ROW: 'productOptionItem/INSERT_EMPTY_ROW',
  DELETE_ROW: 'productOptionItem/DELETE_ROW',
  INSERT_ROWS: 'productOptionItem/INSERT_ROWS'
}

const initialState = {
  productOptionItem: null,
  productOptionItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_OPTION_ITEM:
      return { ...state, productOptionItem: action.payload }
    case actionTypes.ADD_EMPTY_PRODUCT_OPTION_ITEM:
      return { ...state, productOptionItems: [...state.productOptionItems, { product_option_id: action.payload, id: uuidv4() }] }
    case actionTypes.FETCH_PRODUCT_OPTION_ITEMS:
      return { ...state, productOptionItems: null }
    case actionTypes.FETCH_PRODUCT_OPTION_ITEM:
      return { ...state, productOptionItem: null }
    case actionTypes.UPADE_PRODUCT_OPTION_ITEM:
      return { ...state, productOptionItem: action.payload }
    case actionTypes.DELETE_PRODUCT_OPTION_ITEM:
      return state

    case actionTypes.PRODUCT_OPTION_ITEM_CREATED:
      return { ...state, productOptionItems: [...state.productOptionItems, action.payload] }
    case actionTypes.PRODUCT_OPTION_ITEMS_LOADED:
      return { ...state, productOptionItems: action.payload }
    case actionTypes.PRODUCT_OPTION_ITEM_LOADED:
      return { ...state, productOptionItem: action.payload }
    case actionTypes.PRODUCT_OPTION_ITEM_UPDATED:
      return {
        ...state,
        productOptionItem: action.payload,
        productOptionItems: state.productOptionItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_OPTION_ITEM_DELETED:
      return { ...state, productOptionItems: state.productOptionItems.filter(item => item.id !== action.payload) }

    case actionTypes.INSERT_EMPTY_ROW:
      return {
        ...state,
        productOptionItems: insertEmptyRow(state.productOptionItems, action.payload, uuidv4())
      }
    case actionTypes.DELETE_ROW: {
      const filterd = state.productOptionItems.filter(item => item.position !== action.payload)
      if (filterd.length === 0) {
        return { ...state, productOptionItems: [{ position: 1, id: uuidv4() }] }
      } else {
        return { ...state, productOptionItems: filterd.map((item, index) => ({ ...item, position: index + 1 })) }
      }
    }
    case actionTypes.INSERT_ROWS: {
      const nodes = action.payload.rows
      return {
        ...state,
        productOptionItems: insertRows(
          state.productOptionItems,
          nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              product_option_id: node.data.id,
              id: uuidv4()
            }
          })),
          action.payload.position)
      }
    }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductOptionItem: (productOptionItem, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_OPTION_ITEM, payload: { productOptionItem, setSubmitting } }),
  fetchProductOptionItems: () => ({ type: actionTypes.FETCH_PRODUCT_OPTION_ITEMS }),
  fetchProductOptionItem: (id) => ({ type: actionTypes.FETCH_PRODUCT_OPTION_ITEM, payload: id }),
  updateProductOptionItem: (productOptionItem, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_OPTION_ITEM, payload: { productOptionItem, setSubmitting } }),
  deleteProductOptionItem: id => ({ type: actionTypes.DELETE_PRODUCT_OPTION_ITEM, payload: id }),
  productOptionItemCreated: (productOptionItem) => ({ type: actionTypes.PRODUCT_OPTION_ITEM_CREATED, payload: productOptionItem }),
  productOptionItemsLoaded: (productOptionItems) => ({ type: actionTypes.PRODUCT_OPTION_ITEMS_LOADED, payload: productOptionItems }),
  productOptionItemLoaded: (productOptionItem) => ({ type: actionTypes.PRODUCT_OPTION_ITEM_LOADED, payload: productOptionItem }),
  productOptionItemUpdated: (productOptionItem) => ({ type: actionTypes.PRODUCT_OPTION_ITEM_UPDATED, payload: productOptionItem }),
  productOptionItemDeleted: id => ({ type: actionTypes.PRODUCT_OPTION_ITEM_DELETED, payload: id }),
  inertEmptyRow: position => ({ type: actionTypes.INSERT_EMPTY_ROW, payload: position }),
  deleteRow: position => ({ type: actionTypes.DELETE_ROW, payload: position }),
  inertRows: (position, rows) => ({ type: actionTypes.INSERT_ROWS, payload: { position, rows } })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_OPTION_ITEM, function * createProductOptionItemSaga (action) {
    const { productOptionItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_option_items', productOptionItem)
      yield put(actions.productOptionItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_OPTION_ITEMS, function * fetchProductOptionItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_option_items')
      yield put(actions.productOptionItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_OPTION_ITEM, function * fetchProductOptionItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_option_items/${id}`)
      yield put(actions.productOptionItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_OPTION_ITEM, function * updateProductOptionItemSaga (action) {
    const { productOptionItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_option_items/${productOptionItem.id}`, productOptionItem)
      yield put(actions.productOptionItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_OPTION_ITEM, function * deleteProductOptionItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_option_items/${id}`)
      yield put(actions.productOptionItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
