import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { insertEmptyRow, insertRows } from 'shared/itemHelper'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_COMPONENT_ITEM: 'productComponentItem/CREATE_PRODUCT_COMPONENT_ITEM',
  FETCH_PRODUCT_COMPONENT_ITEMS: 'productComponentItem/FETCH_PRODUCT_COMPONENT_ITEMS',
  FETCH_PRODUCT_COMPONENT_ITEM: 'productComponentItem/FETCH_PRODUCT_COMPONENT_ITEM',
  UPADE_PRODUCT_COMPONENT_ITEM: 'productComponentItem/UPADE_PRODUCT_COMPONENT_ITEM',
  DELETE_PRODUCT_COMPONENT_ITEM: 'productComponentItem/DELETE_PRODUCT_COMPONENT_ITEM',

  PRODUCT_COMPONENT_ITEM_CREATED: 'productComponentItem/PRODUCT_COMPONENT_ITEM_CREATED',
  PRODUCT_COMPONENT_ITEMS_LOADED: 'productComponentItem/PRODUCT_COMPONENT_ITEMS_LOADED',
  PRODUCT_COMPONENT_ITEM_LOADED: 'productComponentItem/PRODUCT_COMPONENT_ITEM_LOADED',
  PRODUCT_COMPONENT_ITEM_UPDATED: 'productComponentItem/PRODUCT_COMPONENT_ITEM_UPDATED',
  PRODUCT_COMPONENT_ITEM_DELETED: 'productComponentItem/PRODUCT_COMPONENT_ITEM_DELETED',
  INSERT_EMPTY_ROW: 'productComponentItem/INSERT_EMPTY_ROW',
  DELETE_ROW: 'productComponentItem/DELETE_ROW',
  INSERT_ROWS: 'productComponentItem/INSERT_ROWS'

}

const initialState = {
  productComponentItem: null,
  productComponentItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_COMPONENT_ITEM:
      return { ...state, productComponentItem: action.payload }
    case actionTypes.FETCH_PRODUCT_COMPONENT_ITEMS:
      return { ...state, productComponentItems: null }
    case actionTypes.FETCH_PRODUCT_COMPONENT_ITEM:
      return { ...state, productComponentItem: null }
    case actionTypes.UPADE_PRODUCT_COMPONENT_ITEM:
      return { ...state, productComponentItem: action.payload }
    case actionTypes.DELETE_PRODUCT_COMPONENT_ITEM:
      return state

    case actionTypes.PRODUCT_COMPONENT_ITEM_CREATED:
      return { ...state, productComponentItems: [...state.productComponentItems, action.payload] }
    case actionTypes.PRODUCT_COMPONENT_ITEMS_LOADED:
      return { ...state, productComponentItems: action.payload }
    case actionTypes.PRODUCT_COMPONENT_ITEM_LOADED:
      return { ...state, productComponentItem: action.payload }
    case actionTypes.PRODUCT_COMPONENT_ITEM_UPDATED:
      return {
        ...state,
        productComponentItem: action.payload,
        productComponentItems: state.productComponentItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_COMPONENT_ITEM_DELETED:
      return { ...state, productComponentItems: state.productComponentItems.filter(item => item.id !== action.payload) }
    case actionTypes.INSERT_EMPTY_ROW: {
      return {
        ...state,
        productComponentItems: insertEmptyRow(state.productComponentItems, action.payload)
      }
    }
    case actionTypes.DELETE_ROW: {
      const filterd = state.productComponentItems.filter(item => item.position !== action.payload)
      if (filterd.length === 0) {
        return { ...state, productComponentItems: [{ position: 1 }] }
      } else {
        return { ...state, productComponentItems: filterd.map((item, index) => ({ ...item, position: index + 1 })) }
      }
    }

    case actionTypes.INSERT_ROWS: {
      const nodes = action.payload.rows
      return {
        ...state,
        productComponentItems: insertRows(
          state.productComponentItems,
          nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              qty: 0,
              material_id: node.data.id,
              id: null
            }
          })),
          action.payload.position)
      }
    }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductComponentItem: (productComponentItem, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_COMPONENT_ITEM, payload: { productComponentItem, setSubmitting } }),
  fetchProductComponentItems: () => ({ type: actionTypes.FETCH_PRODUCT_COMPONENT_ITEMS }),
  fetchProductComponentItem: (id) => ({ type: actionTypes.FETCH_PRODUCT_COMPONENT_ITEM, payload: id }),
  updateProductComponentItem: (productComponentItem, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_COMPONENT_ITEM, payload: { productComponentItem, setSubmitting } }),
  deleteProductComponentItem: id => ({ type: actionTypes.DELETE_PRODUCT_COMPONENT_ITEM, payload: id }),
  productComponentItemCreated: (productComponentItem) => ({ type: actionTypes.PRODUCT_COMPONENT_ITEM_CREATED, payload: productComponentItem }),
  productComponentItemsLoaded: (productComponentItems) => ({ type: actionTypes.PRODUCT_COMPONENT_ITEMS_LOADED, payload: productComponentItems }),
  productComponentItemLoaded: (productComponentItem) => ({ type: actionTypes.PRODUCT_COMPONENT_ITEM_LOADED, payload: productComponentItem }),
  productComponentItemUpdated: (productComponentItem) => ({ type: actionTypes.PRODUCT_COMPONENT_ITEM_UPDATED, payload: productComponentItem }),
  productComponentItemDeleted: id => ({ type: actionTypes.PRODUCT_COMPONENT_ITEM_DELETED, payload: id }),
  inertEmptyRow: position => ({ type: actionTypes.INSERT_EMPTY_ROW, payload: position }),
  deleteRow: position => ({ type: actionTypes.DELETE_ROW, payload: position }),
  inertRows: (position, rows) => ({ type: actionTypes.INSERT_ROWS, payload: { position, rows } })

}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_COMPONENT_ITEM, function * createProductComponentItemSaga (action) {
    const { productComponentItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_component_items', productComponentItem)
      yield put(actions.productComponentItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMPONENT_ITEMS, function * fetchProductComponentItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_component_items')
      yield put(actions.productComponentItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMPONENT_ITEM, function * fetchProductComponentItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_component_items/${id}`)
      yield put(actions.productComponentItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_COMPONENT_ITEM, function * updateProductComponentItemSaga (action) {
    const { productComponentItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_component_items/${productComponentItem.id}`, productComponentItem)
      yield put(actions.productComponentItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_COMPONENT_ITEM, function * deleteProductComponentItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_component_items/${id}`)
      yield put(actions.productComponentItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
