import { put, call, fork, takeLatest, takeEvery, select } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { defaultValue } from 'admin/pages/home/common/tfai_departments/tfaiDepartmentDefaultValue'
import * as entireConfigDuck from './entireConfig.duck'
import * as loadingDuck from './loading.duck'
//utils
import { resortPosition } from 'shared/utils/arrayMethod'

// Actions
export const actionTypes = {
  CREATE_TFAI_DEPARTMENT: 'tfaiDepartment/CREATE_TFAI_DEPARTMENT',
  FETCH_TFAI_DEPARTMENTS: 'tfaiDepartment/FETCH_TFAI_DEPARTMENTS',
  FETCH_TFAI_DEPARTMENT: 'tfaiDepartment/FETCH_TFAI_DEPARTMENT',
  UPADE_TFAI_DEPARTMENT: 'tfaiDepartment/UPADE_TFAI_DEPARTMENT',
  DELETE_TFAI_DEPARTMENT: 'tfaiDepartment/DELETE_TFAI_DEPARTMENT',
  DELETE_TFAI_DEPARTMENT_BY_POSITION: 'tfaiDepartment/DELETE_TFAI_DEPARTMENT_BY_POSITION',

  TFAI_DEPARTMENT_CREATED: 'tfaiDepartment/TFAI_DEPARTMENT_CREATED',
  TFAI_DEPARTMENTS_LOADED: 'tfaiDepartment/TFAI_DEPARTMENTS_LOADED',
  TFAI_DEPARTMENT_LOADED: 'tfaiDepartment/TFAI_DEPARTMENT_LOADED',
  TFAI_DEPARTMENT_UPDATED: 'tfaiDepartment/TFAI_DEPARTMENT_UPDATED',
  TFAI_DEPARTMENT_DELETED: 'tfaiDepartment/TFAI_DEPARTMENT_DELETED',

  INSERT_NEW_ROW: 'tfaiDepartment/INSERT_NEW_ROW',

  UPDATE_GRID_INDEX: 'tfaiDepartment/UPDATE_GRID_INDEX',
}

const initialState = {
  tfaiDepartment: null,
  tfaiDepartments: null,
  agGridIndex: 0,
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_TFAI_DEPARTMENT:
      return { ...state, tfaiDepartment: action.payload }
    case actionTypes.FETCH_TFAI_DEPARTMENTS:
      return { ...state, tfaiDepartments: null }
    case actionTypes.FETCH_TFAI_DEPARTMENT:
      return { ...state, tfaiDepartment: null }
    case actionTypes.UPADE_TFAI_DEPARTMENT:
      return { ...state, tfaiDepartment: action.payload }
    case actionTypes.DELETE_TFAI_DEPARTMENT:
      return state

    case actionTypes.TFAI_DEPARTMENT_CREATED:
      return !state.tfaiDepartments ? { ...state, tfaiDepartments: [action.payload] } : { ...state, tfaiDepartments: [...state.tfaiDepartments, action.payload] }
    case actionTypes.TFAI_DEPARTMENTS_LOADED:
      return { ...state, tfaiDepartments: resortPosition(action.payload) }
    case actionTypes.TFAI_DEPARTMENT_LOADED:
      return { ...state, tfaiDepartment: action.payload }
    case actionTypes.TFAI_DEPARTMENT_UPDATED:
      return {
        ...state,
        tfaiDepartment: action.payload,
        tfaiDepartments: state.tfaiDepartments.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.TFAI_DEPARTMENT_DELETED:
      const { position: deleteIdPosition, id: deleteId } = action.payload
      return {
        ...state,
        tfaiDepartments: resortPosition(state.tfaiDepartments.filter(item => item.id !== deleteId)),
        agGridIndex: deleteIdPosition
      }

    case actionTypes.DELETE_TFAI_DEPARTMENT_BY_POSITION:
      const { position: deletePosition } = action.payload
      return {
        ...state,
        tfaiDepartments: resortPosition(state.tfaiDepartments.filter(item => item.position !== deletePosition)),
        agGridIndex: deletePosition
      }

    case actionTypes.INSERT_NEW_ROW:
      return {
        ...state,
        tfaiDepartments: state?.tfaiDepartments ? resortPosition([...state.tfaiDepartments, { ...defaultValue }]) : [{ ...defaultValue}],
        agGridIndex: state?.tfaiDepartments?.length ? state?.tfaiDepartments?.length : 0
      }

    case actionTypes.UPDATE_GRID_INDEX:
      const { index: gridIndex } = action.payload
      return { ...state, agGridIndex: gridIndex}

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createTfaiDepartment: (tfaiDepartment, {callback, isLast}) => ({ type: actionTypes.CREATE_TFAI_DEPARTMENT, payload: { tfaiDepartment, callback, isLast } }),
  fetchTfaiDepartments: () => ({ type: actionTypes.FETCH_TFAI_DEPARTMENTS }),
  fetchTfaiDepartment: (id) => ({ type: actionTypes.FETCH_TFAI_DEPARTMENT, payload: id }),
  updateTfaiDepartment: (tfaiDepartment, { callback, isLast }) => ({ type: actionTypes.UPADE_TFAI_DEPARTMENT, payload: { tfaiDepartment, callback, isLast } }),
  deleteTfaiDepartment: (id, position) => ({ type: actionTypes.DELETE_TFAI_DEPARTMENT, payload: { id, position } }),
  deleteTfaiDepartmentByPosition: (position) => ({ type: actionTypes.DELETE_TFAI_DEPARTMENT_BY_POSITION, payload: { position } }),
  tfaiDepartmentCreated: (tfaiDepartment) => ({ type: actionTypes.TFAI_DEPARTMENT_CREATED, payload: tfaiDepartment }),
  tfaiDepartmentsLoaded: (tfaiDepartments) => ({ type: actionTypes.TFAI_DEPARTMENTS_LOADED, payload: tfaiDepartments }),
  tfaiDepartmentLoaded: (tfaiDepartment) => ({ type: actionTypes.TFAI_DEPARTMENT_LOADED, payload: tfaiDepartment }),
  tfaiDepartmentUpdated: (tfaiDepartment) => ({ type: actionTypes.TFAI_DEPARTMENT_UPDATED, payload: tfaiDepartment }),
  tfaiDepartmentDeleted: (id, position) => ({ type: actionTypes.TFAI_DEPARTMENT_DELETED, payload: { id, position } }),

  insertNewRow: () => ({ type: actionTypes.INSERT_NEW_ROW }),

  updateGridIndex: (index) => ({ type: actionTypes.UPDATE_GRID_INDEX, payload: { index }}),
}

// Saga
export function * saga () {
  yield takeEvery(actionTypes.CREATE_TFAI_DEPARTMENT, function * createTfaiDepartmentSaga (action) {
    const { tfaiDepartment, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.post, 'tfai_departments', tfaiDepartment)
      if (isLast) {
        yield put(actions.fetchTfaiDepartments())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_DEPARTMENTS, function * fetchTfaiDepartmentsSaga () {
    try {
      yield put(loadingDuck.actions.setLoading(true))
      const result = yield call(apiClient.get, 'tfai_departments')
      yield put(actions.tfaiDepartmentsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      yield put(loadingDuck.actions.setLoading(false))
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_DEPARTMENT, function * fetchTfaiDepartmentSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `tfai_departments/${id}`)
      yield put(actions.tfaiDepartmentLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeEvery(actionTypes.UPADE_TFAI_DEPARTMENT, function * updateTfaiDepartmentSaga (action) {
    const { tfaiDepartment, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.put, `tfai_departments/${tfaiDepartment.id}`, tfaiDepartment)
      if (isLast) {
        yield put(actions.fetchTfaiDepartments())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.DELETE_TFAI_DEPARTMENT, function * deleteTfaiDepartmentSaga (action) {
    try {
      const { id, position } = action.payload
      yield call(apiClient.delete, `tfai_departments/${id}`)
      yield put(actions.tfaiDepartmentDeleted(id, position))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
