import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_STORE: 'store/CREATE_STORE',
  FETCH_STORES: 'store/FETCH_STORES',
  FETCH_STORES_PERMITTED: 'store/FETCH_STORES_PERMITTED',
  FETCH_STORE: 'store/FETCH_STORE',
  UPADE_STORE: 'store/UPADE_STORE',
  DELETE_STORE: 'store/DELETE_STORE',

  STORE_CREATED: 'store/STORE_CREATED',
  STORES_LOADED: 'store/STORES_LOADED',
  STORES_PERMITTED_LOADED: 'store/STORES_PERMITTED_LOADED',
  STORE_LOADED: 'store/STORE_LOADED',
  STORE_UPDATED: 'store/STORE_UPDATED',
  STORE_DELETED: 'store/STORE_DELETED'
}

const initialState = {
  store: null,
  stores: null,
  storesPermitted: []
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_STORE:
      return { ...state, store: action.payload }
    case actionTypes.FETCH_STORES:
      return { ...state, stores: null }
    case actionTypes.FETCH_STORE:
      return { ...state, store: null }
    case actionTypes.FETCH_STORES_PERMITTED:
      return { ...state, storesPermitted: [] }
    case actionTypes.UPADE_STORE:
      return { ...state, store: action.payload }
    case actionTypes.DELETE_STORE:
      return state
    case actionTypes.STORE_CREATED:
      return { ...state, stores: [...state.stores, action.payload] }
    case actionTypes.STORES_LOADED:
      return { ...state, stores: action.payload }
    case actionTypes.STORES_PERMITTED_LOADED:
      return { ...state, storesPermitted: action.payload }
    case actionTypes.STORE_LOADED:
      return { ...state, store: action.payload }
    case actionTypes.STORE_UPDATED:
      return {
        ...state,
        store: action.payload,
        stores: state.stores.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.STORE_DELETED:
      return { ...state, stores: state.stores.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createStore: (store, setSubmitting) => ({ type: actionTypes.CREATE_STORE, payload: { store, setSubmitting } }),
  fetchStores: () => ({ type: actionTypes.FETCH_STORES }),
  fetchStoresPermitted: () => ({ type: actionTypes.FETCH_STORES_PERMITTED }),
  fetchStore: (id) => ({ type: actionTypes.FETCH_STORE, payload: id }),
  updateStore: (store, setSubmitting) => ({ type: actionTypes.UPADE_STORE, payload: { store, setSubmitting } }),
  deleteStore: id => ({ type: actionTypes.DELETE_STORE, payload: id }),
  storeCreated: (store) => ({ type: actionTypes.STORE_CREATED, payload: store }),
  storesLoaded: (stores) => ({ type: actionTypes.STORES_LOADED, payload: stores }),
  storesPermittedLoaded: (stores) => ({ type: actionTypes.STORES_PERMITTED_LOADED, payload: stores }),
  storeLoaded: (store) => ({ type: actionTypes.STORE_LOADED, payload: store }),
  storeUpdated: (store) => ({ type: actionTypes.STORE_UPDATED, payload: store }),
  storeDeleted: id => ({ type: actionTypes.STORE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_STORE, function * createStoreSaga (action) {
    const { store, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'stores', store)
      yield put(actions.storeCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_STORES, function * fetchStoresSaga () {
    try {
      const result = yield call(apiClient.get, 'stores')
      yield put(actions.storesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_STORES_PERMITTED, function * fetchStoresSaga () {
    try {
      const result = yield call(apiClient.get, 'stores')
      yield put(actions.storesPermittedLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_STORE, function * fetchStoreSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `stores/${id}`)
      yield put(actions.storeLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_STORE, function * updateStoreSaga (action) {
    const { store, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `stores/${store.id}`, store)
      yield put(actions.storeUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_STORE, function * deleteStoreSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `stores/${id}`)
      yield put(actions.storeDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
