export const insertEmptyRow = (items, position, id = null) => {
  const rows = []
  let index = 1

  for (const item of items) {
    rows.push({ ...item, position: index })
    index++
    if (item.position === position) {
      rows.push({ position: index, id })
      index++
    }
  }
  return rows
}

export const insertRows = (items, addRows, position) => {
  const rows = []
  let index = 1

  for (const item of items) {
    if (item.position === position) {
      for (const row of addRows) {
        rows.push({ ...row.data, position: index })
        index++
      }
    } else {
      rows.push({ ...item, position: index })
      index++
    }
  }
  return rows
}
