import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SUBSCRIPTION_QUANTITY_ITEM: 'subscriptionQuantityItem/CREATE_SUBSCRIPTION_QUANTITY_ITEM',
  FETCH_SUBSCRIPTION_QUANTITY_ITEMS: 'subscriptionQuantityItem/FETCH_SUBSCRIPTION_QUANTITY_ITEMS',
  FETCH_SUBSCRIPTION_QUANTITY_ITEM: 'subscriptionQuantityItem/FETCH_SUBSCRIPTION_QUANTITY_ITEM',
  UPADE_SUBSCRIPTION_QUANTITY_ITEM: 'subscriptionQuantityItem/UPADE_SUBSCRIPTION_QUANTITY_ITEM',
  DELETE_SUBSCRIPTION_QUANTITY_ITEM: 'subscriptionQuantityItem/DELETE_SUBSCRIPTION_QUANTITY_ITEM',

  SUBSCRIPTION_QUANTITY_ITEM_CREATED: 'subscriptionQuantityItem/SUBSCRIPTION_QUANTITY_ITEM_CREATED',
  SUBSCRIPTION_QUANTITY_ITEMS_LOADED: 'subscriptionQuantityItem/SUBSCRIPTION_QUANTITY_ITEMS_LOADED',
  SUBSCRIPTION_QUANTITY_ITEM_LOADED: 'subscriptionQuantityItem/SUBSCRIPTION_QUANTITY_ITEM_LOADED',
  SUBSCRIPTION_QUANTITY_ITEM_UPDATED: 'subscriptionQuantityItem/SUBSCRIPTION_QUANTITY_ITEM_UPDATED',
  SUBSCRIPTION_QUANTITY_ITEM_DELETED: 'subscriptionQuantityItem/SUBSCRIPTION_QUANTITY_ITEM_DELETED'
}

const initialState = {
  subscriptionQuantityItem: null,
  subscriptionQuantityItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SUBSCRIPTION_QUANTITY_ITEM:
      return { ...state, subscriptionQuantityItem: action.payload }
    case actionTypes.FETCH_SUBSCRIPTION_QUANTITY_ITEMS:
      return { ...state, subscriptionQuantityItems: null }
    case actionTypes.FETCH_SUBSCRIPTION_QUANTITY_ITEM:
      return { ...state, subscriptionQuantityItem: null }
    case actionTypes.UPADE_SUBSCRIPTION_QUANTITY_ITEM:
      return { ...state, subscriptionQuantityItem: action.payload }
    case actionTypes.DELETE_SUBSCRIPTION_QUANTITY_ITEM:
      return state

    case actionTypes.SUBSCRIPTION_QUANTITY_ITEM_CREATED:
      return { ...state, subscriptionQuantityItems: [...state.subscriptionQuantityItems, action.payload] }
    case actionTypes.SUBSCRIPTION_QUANTITY_ITEMS_LOADED:
      return { ...state, subscriptionQuantityItems: action.payload }
    case actionTypes.SUBSCRIPTION_QUANTITY_ITEM_LOADED:
      return { ...state, subscriptionQuantityItem: action.payload }
    case actionTypes.SUBSCRIPTION_QUANTITY_ITEM_UPDATED:
      return {
        ...state,
        subscriptionQuantityItem: action.payload,
        subscriptionQuantityItems: state.subscriptionQuantityItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SUBSCRIPTION_QUANTITY_ITEM_DELETED:
      return { ...state, subscriptionQuantityItems: state.subscriptionQuantityItems.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSubscriptionQuantityItem: (subscriptionQuantityItem, setSubmitting) => ({ type: actionTypes.CREATE_SUBSCRIPTION_QUANTITY_ITEM, payload: { subscriptionQuantityItem, setSubmitting } }),
  fetchSubscriptionQuantityItems: () => ({ type: actionTypes.FETCH_SUBSCRIPTION_QUANTITY_ITEMS }),
  fetchSubscriptionQuantityItem: (id) => ({ type: actionTypes.FETCH_SUBSCRIPTION_QUANTITY_ITEM, payload: id }),
  updateSubscriptionQuantityItem: (subscriptionQuantityItem, setSubmitting) => ({ type: actionTypes.UPADE_SUBSCRIPTION_QUANTITY_ITEM, payload: { subscriptionQuantityItem, setSubmitting } }),
  deleteSubscriptionQuantityItem: id => ({ type: actionTypes.DELETE_SUBSCRIPTION_QUANTITY_ITEM, payload: id }),
  subscriptionQuantityItemCreated: (subscriptionQuantityItem) => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_ITEM_CREATED, payload: subscriptionQuantityItem }),
  subscriptionQuantityItemsLoaded: (subscriptionQuantityItems) => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_ITEMS_LOADED, payload: subscriptionQuantityItems }),
  subscriptionQuantityItemLoaded: (subscriptionQuantityItem) => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_ITEM_LOADED, payload: subscriptionQuantityItem }),
  subscriptionQuantityItemUpdated: (subscriptionQuantityItem) => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_ITEM_UPDATED, payload: subscriptionQuantityItem }),
  subscriptionQuantityItemDeleted: id => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_ITEM_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SUBSCRIPTION_QUANTITY_ITEM, function * createSubscriptionQuantityItemSaga (action) {
    const { subscriptionQuantityItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'subscription_quantity_items', subscriptionQuantityItem)
      yield put(actions.subscriptionQuantityItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_QUANTITY_ITEMS, function * fetchSubscriptionQuantityItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'subscription_quantity_items')
      yield put(actions.subscriptionQuantityItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_QUANTITY_ITEM, function * fetchSubscriptionQuantityItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `subscription_quantity_items/${id}`)
      yield put(actions.subscriptionQuantityItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SUBSCRIPTION_QUANTITY_ITEM, function * updateSubscriptionQuantityItemSaga (action) {
    const { subscriptionQuantityItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `subscription_quantity_items/${subscriptionQuantityItem.id}`, subscriptionQuantityItem)
      yield put(actions.subscriptionQuantityItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SUBSCRIPTION_QUANTITY_ITEM, function * deleteSubscriptionQuantityItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `subscription_quantity_items/${id}`)
      yield put(actions.subscriptionQuantityItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
