import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SALE_ITEM: 'saleItem/CREATE_SALE',
  FETCH_SALE_ITEMS: 'saleItem/FETCH_SALES',
  FETCH_SALE_ITEM: 'saleItem/FETCH_SALE',
  UPADE_SALE_ITEM: 'saleItem/UPADE_SALE',
  DELETE_SALE_ITEM: 'saleItem/DELETE_SALE',

  SALE_ITEM_CREATED: 'saleItem/SALE_CREATED',
  SALE_ITEMS_LOADED: 'saleItem/SALES_LOADED',
  SALE_ITEM_LOADED: 'saleItem/SALE_LOADED',
  SALE_ITEM_UPDATED: 'saleItem/SALE_UPDATED',
  SALE_ITEM_DELETED: 'saleItem/SALE_DELETED'
}

const initialState = {
  saleItem: null,
  saleItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SALE_ITEM:
      return { ...state, saleItem: action.payload }
    case actionTypes.FETCH_SALE_ITEMS:
      return { ...state, saleItems: null }
    case actionTypes.FETCH_SALE_ITEM:
      return { ...state, saleItem: null }
    case actionTypes.UPADE_SALE_ITEM:
      return { ...state, saleItem: action.payload }
    case actionTypes.DELETE_SALE_ITEM:
      return state

    case actionTypes.SALE_ITEMS_LOADED:
      return { ...state, saleItems: action.payload }
    case actionTypes.SALE_ITEM_LOADED:
      return { ...state, saleItem: action.payload }
    case actionTypes.SALE_ITEM_UPDATED:
      return {
        ...state,
        saleItem: action.payload,
        saleItems: state.saleItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SALE_ITEM_DELETED:
      return { ...state, saleItems: state.saleItems.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSaleItem: (saleItem, setSubmitting) => ({ type: actionTypes.CREATE_SALE_ITEM, payload: { saleItem, setSubmitting } }),
  fetchSaleItems: () => ({ type: actionTypes.FETCH_SALE_ITEMS }),
  fetchSaleItem: (id) => ({ type: actionTypes.FETCH_SALE_ITEM, payload: id }),
  updateSaleItem: (saleItem, setSubmitting) => ({ type: actionTypes.UPADE_SALE_ITEM, payload: { saleItem, setSubmitting } }),
  deleteSaleItem: id => ({ type: actionTypes.DELETE_SALE_ITEM, payload: id }),
  saleItemsLoaded: (saleItems) => ({ type: actionTypes.SALE_ITEMS_LOADED, payload: saleItems }),
  saleItemLoaded: (saleItem) => ({ type: actionTypes.SALE_ITEM_LOADED, payload: saleItem }),
  saleItemUpdated: (saleItem) => ({ type: actionTypes.SALE_ITEM_UPDATED, payload: saleItem }),
  saleItemDeleted: id => ({ type: actionTypes.SALE_ITEM_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SALE_ITEM, function * createSaleItemSaga (action) {
    const { saleItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'sale_items', saleItem)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SALE_ITEMS, function * fetchSalesItemSaga () {
    try {
      const result = yield call(apiClient.get, 'sale_items')
      yield put(actions.saleItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SALE_ITEM, function * fetchSaleItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `sale_items/${id}`)
      yield put(actions.saleItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SALE_ITEM, function * updateSaleItemSaga (action) {
    const { saleItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `sale_items/${saleItem.id}`, saleItem)
      yield put(actions.saleItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SALE_ITEM, function * deleteSaleItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `sale_items/${id}`)
      yield put(actions.saleItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}