import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PROMOTE: 'promote/CREATE_PROMOTE',
  FETCH_PROMOTES: 'promote/FETCH_PROMOTES',
  FETCH_PROMOTE: 'promote/FETCH_PROMOTE',
  UPADE_PROMOTE: 'promote/UPADE_PROMOTE',
  DELETE_PROMOTE: 'promote/DELETE_PROMOTE',

  PROMOTE_CREATED: 'promote/PROMOTE_CREATED',
  PROMOTES_LOADED: 'promote/PROMOTES_LOADED',
  PROMOTE_LOADED: 'promote/PROMOTE_LOADED',
  PROMOTE_UPDATED: 'promote/PROMOTE_UPDATED',
  PROMOTE_DELETED: 'promote/PROMOTE_DELETED'
}

const initialState = {
  promote: null,
  promotes: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PROMOTE:
      return { ...state, promote: action.payload }
    case actionTypes.FETCH_PROMOTES:
      return { ...state, promotes: null }
    case actionTypes.FETCH_PROMOTE:
      return { ...state, promote: null }
    case actionTypes.UPADE_PROMOTE:
      return { ...state, promote: action.payload }
    case actionTypes.DELETE_PROMOTE:
      return state

    case actionTypes.PROMOTE_CREATED:
      return !state.promotes ? { ...state, promotes: [action.payload] } : { ...state, promotes: [...state.promotes, action.payload] }
    case actionTypes.PROMOTES_LOADED:
      return { ...state, promotes: action.payload }
    case actionTypes.PROMOTE_LOADED:
      return { ...state, promote: action.payload }
    case actionTypes.PROMOTE_UPDATED:
      return {
        ...state,
        promote: action.payload
      }
    case actionTypes.PROMOTE_DELETED:
      return { ...state, promotes: state.promotes.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPromote: (promote, setSubmitting) => ({ type: actionTypes.CREATE_PROMOTE, payload: { promote, setSubmitting } }),
  fetchPromotes: () => ({ type: actionTypes.FETCH_PROMOTES }),
  fetchPromote: (id) => ({ type: actionTypes.FETCH_PROMOTE, payload: id }),
  updatePromote: (promote, setSubmitting) => ({ type: actionTypes.UPADE_PROMOTE, payload: { promote, setSubmitting } }),
  deletePromote: id => ({ type: actionTypes.DELETE_PROMOTE, payload: id }),
  promoteCreated: (promote) => ({ type: actionTypes.PROMOTE_CREATED, payload: promote }),
  promotesLoaded: (promotes) => ({ type: actionTypes.PROMOTES_LOADED, payload: promotes }),
  promoteLoaded: (promote) => ({ type: actionTypes.PROMOTE_LOADED, payload: promote }),
  promoteUpdated: (promote) => ({ type: actionTypes.PROMOTE_UPDATED, payload: promote }),
  promoteDeleted: id => ({ type: actionTypes.PROMOTE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PROMOTE, function * createPromoteSaga (action) {
    const { promote, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'promotes', promote)
      yield put(actions.promoteCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PROMOTES, function * fetchPromotesSaga () {
    try {
      const result = yield call(apiClient.get, 'promotes')
      yield put(actions.promotesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PROMOTE, function * fetchPromoteSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `promotes/${id}`)
      yield put(actions.promoteLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PROMOTE, function * updatePromoteSaga (action) {
    const { promote, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `promotes/${promote.id}`, promote)
      yield put(actions.promoteUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PROMOTE, function * deletePromoteSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `promotes/${id}`)
      yield put(actions.promoteDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
