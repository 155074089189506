import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_POS_USER: 'posUser/CREATE_POS_USER',
  FETCH_POS_USERS: 'posUser/FETCH_POS_USERS',
  FETCH_POS_USER: 'posUser/FETCH_POS_USER',
  UPADE_POS_USER: 'posUser/UPADE_POS_USER',
  DELETE_POS_USER: 'posUser/DELETE_POS_USER',

  POS_USER_CREATED: 'posUser/POS_USER_CREATED',
  POS_USERS_LOADED: 'posUser/POS_USERS_LOADED',
  POS_USER_LOADED: 'posUser/POS_USER_LOADED',
  POS_USER_UPDATED: 'posUser/POS_USER_UPDATED',
  POS_USER_DELETED: 'posUser/POS_USER_DELETED'
}

const initialState = {
  posUser: null,
  posUsers: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_POS_USER:
      return { ...state, posUser: action.payload }
    case actionTypes.FETCH_POS_USERS:
      return { ...state, posUsers: null }
    case actionTypes.FETCH_POS_USER:
      return { ...state, posUser: null }
    case actionTypes.UPADE_POS_USER:
      return { ...state, posUser: action.payload }
    case actionTypes.DELETE_POS_USER:
      return state

    case actionTypes.POS_USER_CREATED:
      return !state.posUsers ? { ...state, posUsers: [action.payload] } : { ...state, posUsers: [...state.posUsers, action.payload] }
    case actionTypes.POS_USERS_LOADED:
      return { ...state, posUsers: action.payload }
    case actionTypes.POS_USER_LOADED:
      return { ...state, posUser: action.payload }
    case actionTypes.POS_USER_UPDATED:
      return {
        ...state,
        posUser: action.payload,
        posUsers: state.posUsers.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.POS_USER_DELETED:
      return { ...state, posUsers: state.posUsers.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPosUser: (posUser, setSubmitting) => ({ type: actionTypes.CREATE_POS_USER, payload: { posUser, setSubmitting } }),
  fetchPosUsers: () => ({ type: actionTypes.FETCH_POS_USERS }),
  fetchPosUser: (id) => ({ type: actionTypes.FETCH_POS_USER, payload: id }),
  updatePosUser: (posUser, setSubmitting) => ({ type: actionTypes.UPADE_POS_USER, payload: { posUser, setSubmitting } }),
  deletePosUser: id => ({ type: actionTypes.DELETE_POS_USER, payload: id }),
  posUserCreated: (posUser) => ({ type: actionTypes.POS_USER_CREATED, payload: posUser }),
  posUsersLoaded: (posUsers) => ({ type: actionTypes.POS_USERS_LOADED, payload: posUsers }),
  posUserLoaded: (posUser) => ({ type: actionTypes.POS_USER_LOADED, payload: posUser }),
  posUserUpdated: (posUser) => ({ type: actionTypes.POS_USER_UPDATED, payload: posUser }),
  posUserDeleted: id => ({ type: actionTypes.POS_USER_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_POS_USER, function * createPosUserSaga (action) {
    const { posUser, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'pos_users', posUser)
      yield put(actions.posUserCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_USERS, function * fetchPosUsersSaga () {
    try {
      const result = yield call(apiClient.get, 'pos_users')
      yield put(actions.posUsersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_POS_USER, function * fetchPosUserSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `pos_users/${id}`)
      yield put(actions.posUserLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_POS_USER, function * updatePosUserSaga (action) {
    const { posUser, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `pos_users/${posUser.id}`, posUser)
      yield put(actions.posUserUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_POS_USER, function * deletePosUserSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `pos_users/${id}`)
      yield put(actions.posUserDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
