import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_SUITE: 'productSuite/CREATE_PRODUCT_SUITE',
  FETCH_PRODUCT_SUITES: 'productSuite/FETCH_PRODUCT_SUITES',
  FETCH_PRODUCT_SUITE: 'productSuite/FETCH_PRODUCT_SUITE',
  UPADE_PRODUCT_SUITE: 'productSuite/UPADE_PRODUCT_SUITE',
  DELETE_PRODUCT_SUITE: 'productSuite/DELETE_PRODUCT_SUITE',

  PRODUCT_SUITE_CREATED: 'productSuite/PRODUCT_SUITE_CREATED',
  PRODUCT_SUITES_LOADED: 'productSuite/PRODUCT_SUITES_LOADED',
  PRODUCT_SUITE_LOADED: 'productSuite/PRODUCT_SUITE_LOADED',
  PRODUCT_SUITE_UPDATED: 'productSuite/PRODUCT_SUITE_UPDATED',
  PRODUCT_SUITE_DELETED: 'productSuite/PRODUCT_SUITE_DELETED',

  RESET: 'productSuite/RESET'
}

const initialState = {
  productSuite: null,
  productSuites: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_SUITE:
      return { ...state, productSuite: action.payload }
    case actionTypes.FETCH_PRODUCT_SUITES:
      return { ...state, productSuites: null }
    // case actionTypes.FETCH_PRODUCT_SUITE:
    //   return { ...state, productSuite: null }
    case actionTypes.UPADE_PRODUCT_SUITE:
      return { ...state, productSuite: action.payload }
    case actionTypes.DELETE_PRODUCT_SUITE:
      return state

    case actionTypes.PRODUCT_SUITE_CREATED:
      return { ...state, productSuites: [...state.productSuites, action.payload] }
    case actionTypes.PRODUCT_SUITES_LOADED:
      return { ...state, productSuites: action.payload }
    case actionTypes.PRODUCT_SUITE_LOADED:
      return { ...state, productSuite: action.payload }
    case actionTypes.PRODUCT_SUITE_UPDATED:
      return {
        ...state,
        productSuite: action.payload,
        productSuites: state.productSuites.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_SUITE_DELETED:
      return { ...state, productSuites: state.productSuites.filter(item => item.id !== action.payload) }
    case actionTypes.RESET:
      return initialState

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductSuite: (productSuite, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_SUITE, payload: { productSuite, setSubmitting } }),
  fetchProductSuites: () => ({ type: actionTypes.FETCH_PRODUCT_SUITES }),
  fetchProductSuite: (id) => ({ type: actionTypes.FETCH_PRODUCT_SUITE, payload: id }),
  updateProductSuite: (productSuite, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_SUITE, payload: { productSuite, setSubmitting } }),
  deleteProductSuite: id => ({ type: actionTypes.DELETE_PRODUCT_SUITE, payload: id }),
  productSuiteCreated: (productSuite) => ({ type: actionTypes.PRODUCT_SUITE_CREATED, payload: productSuite }),
  productSuitesLoaded: (productSuites) => ({ type: actionTypes.PRODUCT_SUITES_LOADED, payload: productSuites }),
  productSuiteLoaded: (productSuite) => ({ type: actionTypes.PRODUCT_SUITE_LOADED, payload: productSuite }),
  productSuiteUpdated: (productSuite) => ({ type: actionTypes.PRODUCT_SUITE_UPDATED, payload: productSuite }),
  productSuiteDeleted: id => ({ type: actionTypes.PRODUCT_SUITE_DELETED, payload: id }),
  reset: () => ({ type: actionTypes.RESET })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_SUITE, function * createProductSuiteSaga (action) {
    const { productSuite, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_suites', productSuite)
      yield put(actions.productSuiteCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUITES, function * fetchProductSuitesSaga () {
    try {
      const result = yield call(apiClient.get, 'product_suites')
      yield put(actions.productSuitesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUITE, function * fetchProductSuiteSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `products/${id}`)
      yield put(actions.productSuiteLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_SUITE, function * updateProductSuiteSaga (action) {
    const { productSuite, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_suites/${productSuite.id}`, productSuite)
      yield put(actions.productSuiteUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_SUITE, function * deleteProductSuiteSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_suites/${id}`)
      yield put(actions.productSuiteDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
