import { put, call, fork, takeLatest, takeEvery, select } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { defaultValue } from 'admin/pages/home/common/tfai_products/tfaiProductDefaultValue'
import * as entireConfigDuck from './entireConfig.duck'
import * as loadingDuck from './loading.duck'
//utils
import { resortPosition } from 'shared/utils/arrayMethod'

// Actions
export const actionTypes = {
  CREATE_TFAI_PRODUCT: 'tfaiProduct/CREATE_TFAI_PRODUCT',
  FETCH_TFAI_PRODUCTS: 'tfaiProduct/FETCH_TFAI_PRODUCTS',
  FETCH_TFAI_PRODUCT: 'tfaiProduct/FETCH_TFAI_PRODUCT',
  UPADE_TFAI_PRODUCT: 'tfaiProduct/UPADE_TFAI_PRODUCT',
  DELETE_TFAI_PRODUCT: 'tfaiProduct/DELETE_TFAI_PRODUCT',
  DELETE_TFAI_PRODUCT_BY_POSITION: 'tfaiProduct/DELETE_TFAI_PRODUCT_BY_POSITION',

  TFAI_PRODUCT_CREATED: 'tfaiProduct/TFAI_PRODUCT_CREATED',
  TFAI_PRODUCTS_LOADED: 'tfaiProduct/TFAI_PRODUCTS_LOADED',
  TFAI_PRODUCT_LOADED: 'tfaiProduct/TFAI_PRODUCT_LOADED',
  TFAI_PRODUCT_UPDATED: 'tfaiProduct/TFAI_PRODUCT_UPDATED',
  TFAI_PRODUCT_DELETED: 'tfaiProduct/TFAI_PRODUCT_DELETED',

  INSERT_NEW_ROW: 'tfaiProduct/INSERT_NEW_ROW',

  UPDATE_GRID_INDEX: 'tfaiProduct/UPDATE_GRID_INDEX',
}

const initialState = {
  tfaiProduct: null,
  tfaiProducts: null,
  agGridIndex: 0,
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_TFAI_PRODUCT:
      return { ...state, tfaiProduct: action.payload }
    case actionTypes.FETCH_TFAI_PRODUCTS:
      return { ...state, tfaiProducts: null }
    case actionTypes.FETCH_TFAI_PRODUCT:
      return { ...state, tfaiProduct: null }
    case actionTypes.UPADE_TFAI_PRODUCT:
      return { ...state, tfaiProduct: action.payload }
    case actionTypes.DELETE_TFAI_PRODUCT:
      return state

    case actionTypes.TFAI_PRODUCT_CREATED:
      return !state.tfaiProducts ? { ...state, tfaiProducts: [action.payload] } : { ...state, tfaiProducts: [...state.tfaiProducts, action.payload] }
    case actionTypes.TFAI_PRODUCTS_LOADED:
      return { ...state, tfaiProducts: resortPosition(action.payload) }
    case actionTypes.TFAI_PRODUCT_LOADED:
      return { ...state, tfaiProduct: action.payload }
    case actionTypes.TFAI_PRODUCT_UPDATED:
      return {
        ...state,
        tfaiProduct: action.payload,
        tfaiProducts: state.tfaiProducts.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.TFAI_PRODUCT_DELETED:
      const { position: deleteIdPosition, id: deleteId } = action.payload
      return {
        ...state,
        tfaiProducts: resortPosition(state.tfaiProducts.filter(item => item.id !== deleteId)),
        agGridIndex: deleteIdPosition
      }
    
    case actionTypes.DELETE_TFAI_PRODUCT_BY_POSITION:
      const {position: deletePosition} = action.payload
      return {
        ...state,
        tfaiProducts: resortPosition(state.tfaiProducts.filter(item => item.position !== deletePosition)),
        agGridIndex: deletePosition
      }

    case actionTypes.INSERT_NEW_ROW:
      return {
        ...state,
        tfaiProducts: state?.tfaiProducts ? resortPosition([...state.tfaiProducts, { ...defaultValue }] ): [{ ...defaultValue }],
        agGridIndex: state?.tfaiProducts?.length ? state?.tfaiProducts?.length : 0
      }

    case actionTypes.UPDATE_GRID_INDEX:
      const { index: gridIndex } = action.payload
      return { ...state, agGridIndex: gridIndex}

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createTfaiProduct: (tfaiProduct, { callback, isLast }) => ({ type: actionTypes.CREATE_TFAI_PRODUCT, payload: { tfaiProduct, callback, isLast } }),
  fetchTfaiProducts: () => ({ type: actionTypes.FETCH_TFAI_PRODUCTS }),
  fetchTfaiProduct: (id) => ({ type: actionTypes.FETCH_TFAI_PRODUCT, payload: id }),
  updateTfaiProduct: (tfaiProduct, { callback, isLast }) => ({ type: actionTypes.UPADE_TFAI_PRODUCT, payload: { tfaiProduct, callback, isLast } }),
  deleteTfaiProduct: (id, position) => ({ type: actionTypes.DELETE_TFAI_PRODUCT, payload: { id, position } }),
  deleteTfaiProductByPosition: (position) => ({ type: actionTypes.DELETE_TFAI_PRODUCT_BY_POSITION, payload: {position} }),
  tfaiProductCreated: (tfaiProduct) => ({ type: actionTypes.TFAI_PRODUCT_CREATED, payload: tfaiProduct }),
  tfaiProductsLoaded: (tfaiProducts) => ({ type: actionTypes.TFAI_PRODUCTS_LOADED, payload: tfaiProducts }),
  tfaiProductLoaded: (tfaiProduct) => ({ type: actionTypes.TFAI_PRODUCT_LOADED, payload: tfaiProduct }),
  tfaiProductUpdated: (tfaiProduct) => ({ type: actionTypes.TFAI_PRODUCT_UPDATED, payload: tfaiProduct }),
  tfaiProductDeleted: (id, position) => ({ type: actionTypes.TFAI_PRODUCT_DELETED, payload: { id, position } }),
  
  insertNewRow: () => ({ type: actionTypes.INSERT_NEW_ROW}),

  updateGridIndex: (index) => ({ type: actionTypes.UPDATE_GRID_INDEX, payload: { index }}),
}

// Saga
export function * saga () {
  yield takeEvery(actionTypes.CREATE_TFAI_PRODUCT, function * createTfaiProductSaga (action) {
    const { tfaiProduct, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.post, 'tfai_products', tfaiProduct)
      if (isLast) {
        yield put(actions.fetchTfaiProducts())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_PRODUCTS, function * fetchTfaiProductsSaga () {
    try {
      yield put(loadingDuck.actions.setLoading(true))
      const result = yield call(apiClient.get, 'tfai_products')
      yield put(actions.tfaiProductsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      yield put(loadingDuck.actions.setLoading(false))
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_PRODUCT, function * fetchTfaiProductSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `tfai_products/${id}`)
      yield put(actions.tfaiProductLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeEvery(actionTypes.UPADE_TFAI_PRODUCT, function * updateTfaiProductSaga (action) {
    const { tfaiProduct, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.put, `tfai_products/${tfaiProduct.id}`, tfaiProduct)
      if (isLast) {
        yield put(actions.fetchTfaiProducts())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.DELETE_TFAI_PRODUCT, function * deleteTfaiProductSaga (action) {
    try {
      const { id, position } = action.payload
      yield call(apiClient.delete, `tfai_products/${id}`)
      yield put(actions.tfaiProductDeleted(id, position))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
