import axios from 'axios'
import axiosInterceptor from './axiosInterceptor'
import { environment } from '../../environment'

const apiServer = environment.API_URL + '/api/b1/'

const apiClient = axios.create({
  baseURL: apiServer
})

export const apiClientBlob = axios.create({
  baseURL: apiServer,
  responseType: 'blob',
  headers: {
    Authorization: 'Bearer cf50afbcac5e7a424e97bbd6ba6159eacd4b7de987bc49fc2d1e302496fe4c8c499770db96d67633673c0c9fe45bcaea613457ccfe7f2763c5e802de4a11b9a4'
  }
})

axiosInterceptor.setupInterceptors(apiClient)
export default apiClient
