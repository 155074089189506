import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_MATERIAL_MAJOR_CATEGORY: 'materialMajorCategory/CREATE_MATERIAL_MAJOR_CATEGORY',
  FETCH_MATERIAL_MAJOR_CATEGORIES: 'materialMajorCategory/FETCH_MATERIAL_MAJOR_CATEGORIES',
  FETCH_MATERIAL_MAJOR_CATEGORY: 'materialMajorCategory/FETCH_MATERIAL_MAJOR_CATEGORY',
  UPADE_MATERIAL_MAJOR_CATEGORY: 'materialMajorCategory/UPADE_MATERIAL_MAJOR_CATEGORY',
  DELETE_MATERIAL_MAJOR_CATEGORY: 'materialMajorCategory/DELETE_MATERIAL_MAJOR_CATEGORY',

  MATERIAL_MAJOR_CATEGORY_CREATED: 'materialMajorCategory/MATERIAL_MAJOR_CATEGORY_CREATED',
  MATERIAL_MAJOR_CATEGORIES_LOADED: 'materialMajorCategory/MATERIAL_MAJOR_CATEGORIES_LOADED',
  MATERIAL_MAJOR_CATEGORY_LOADED: 'materialMajorCategory/MATERIAL_MAJOR_CATEGORY_LOADED',
  MATERIAL_MAJOR_CATEGORY_UPDATED: 'materialMajorCategory/MATERIAL_MAJOR_CATEGORY_UPDATED',
  MATERIAL_MAJOR_CATEGORY_DELETED: 'materialMajorCategory/MATERIAL_MAJOR_CATEGORY_DELETED'
}

const initialState = {
  materialMajorCategory: null,
  materialMajorCategories: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_MATERIAL_MAJOR_CATEGORY:
      return { ...state, materialMajorCategory: action.payload }
    case actionTypes.FETCH_MATERIAL_MAJOR_CATEGORIES:
      return { ...state, materialMajorCategories: null }
    case actionTypes.FETCH_MATERIAL_MAJOR_CATEGORY:
      return { ...state, materialMajorCategory: null }
    case actionTypes.UPADE_MATERIAL_MAJOR_CATEGORY:
      return { ...state, materialMajorCategory: action.payload }
    case actionTypes.DELETE_MATERIAL_MAJOR_CATEGORY:
      return state

    case actionTypes.MATERIAL_MAJOR_CATEGORY_CREATED:
      return { ...state, materialMajorCategories: [...state.materialMajorCategories, action.payload] }
    case actionTypes.MATERIAL_MAJOR_CATEGORIES_LOADED:
      return { ...state, materialMajorCategories: action.payload }
    case actionTypes.MATERIAL_MAJOR_CATEGORY_LOADED:
      return { ...state, materialMajorCategory: action.payload }
    case actionTypes.MATERIAL_MAJOR_CATEGORY_UPDATED:
      return {
        ...state,
        materialMajorCategory: action.payload,
        materialMajorCategories: state.materialMajorCategories.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.MATERIAL_MAJOR_CATEGORY_DELETED:
      return { ...state, materialMajorCategories: state.materialMajorCategories.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createMaterialMajorCategory: (materialMajorCategory, setSubmitting) => ({ type: actionTypes.CREATE_MATERIAL_MAJOR_CATEGORY, payload: { materialMajorCategory, setSubmitting } }),
  fetchMaterialMajorCategories: () => ({ type: actionTypes.FETCH_MATERIAL_MAJOR_CATEGORIES }),
  fetchMaterialMajorCategory: (id) => ({ type: actionTypes.FETCH_MATERIAL_MAJOR_CATEGORY, payload: id }),
  updateMaterialMajorCategory: (materialMajorCategory, setSubmitting) => ({ type: actionTypes.UPADE_MATERIAL_MAJOR_CATEGORY, payload: { materialMajorCategory, setSubmitting } }),
  deleteMaterialMajorCategory: id => ({ type: actionTypes.DELETE_MATERIAL_MAJOR_CATEGORY, payload: id }),
  materialMajorCategoryCreated: (materialMajorCategory) => ({ type: actionTypes.MATERIAL_MAJOR_CATEGORY_CREATED, payload: materialMajorCategory }),
  materialMajorCategoriesLoaded: (materialMajorCategories) => ({ type: actionTypes.MATERIAL_MAJOR_CATEGORIES_LOADED, payload: materialMajorCategories }),
  materialMajorCategoryLoaded: (materialMajorCategory) => ({ type: actionTypes.MATERIAL_MAJOR_CATEGORY_LOADED, payload: materialMajorCategory }),
  materialMajorCategoryUpdated: (materialMajorCategory) => ({ type: actionTypes.MATERIAL_MAJOR_CATEGORY_UPDATED, payload: materialMajorCategory }),
  materialMajorCategoryDeleted: id => ({ type: actionTypes.MATERIAL_MAJOR_CATEGORY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_MATERIAL_MAJOR_CATEGORY, function * createMaterialMajorCategorySaga (action) {
    const { materialMajorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'material_major_categories', materialMajorCategory)
      yield put(actions.materialMajorCategoryCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIAL_MAJOR_CATEGORIES, function * fetchMaterialMajorCategoriesSaga () {
    try {
      const result = yield call(apiClient.get, 'material_major_categories')
      yield put(actions.materialMajorCategoriesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_MATERIAL_MAJOR_CATEGORY, function * fetchMaterialMajorCategorySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `material_major_categories/${id}`)
      yield put(actions.materialMajorCategoryLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_MATERIAL_MAJOR_CATEGORY, function * updateMaterialMajorCategorySaga (action) {
    const { materialMajorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `material_major_categories/${materialMajorCategory.id}`, materialMajorCategory)
      yield put(actions.materialMajorCategoryUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_MATERIAL_MAJOR_CATEGORY, function * deleteMaterialMajorCategorySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `material_major_categories/${id}`)
      yield put(actions.materialMajorCategoryDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
