export const actionTypes = {
  IS_LOADING: 'IS_LOADING'
}

export const reducer = (state = false, action) => {
  switch (action.type) {
    case actionTypes.IS_LOADING: {
      return action.payload
    }

    default:
      return state
  }
}

export const actions = {
  setLoading: isLoading => ({ type: actionTypes.IS_LOADING, payload: isLoading })
}
