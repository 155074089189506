import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_DEPARTMENT_CATEGORY: 'productDepartmentCategory/CREATE_PRODUCT_DEPARTMENT_CATEGORY',
  FETCH_PRODUCT_DEPARTMENT_CATEGORIES: 'productDepartmentCategory/FETCH_PRODUCT_DEPARTMENT_CATEGORIES',
  FETCH_PRODUCT_DEPARTMENT_CATEGORY: 'productDepartmentCategory/FETCH_PRODUCT_DEPARTMENT_CATEGORY',
  UPADE_PRODUCT_DEPARTMENT_CATEGORY: 'productDepartmentCategory/UPADE_PRODUCT_DEPARTMENT_CATEGORY',
  DELETE_PRODUCT_DEPARTMENT_CATEGORY: 'productDepartmentCategory/DELETE_PRODUCT_DEPARTMENT_CATEGORY',

  PRODUCT_DEPARTMENT_CATEGORY_CREATED: 'productDepartmentCategory/PRODUCT_DEPARTMENT_CATEGORY_CREATED',
  PRODUCT_DEPARTMENT_CATEGORIES_LOADED: 'productDepartmentCategory/PRODUCT_DEPARTMENT_CATEGORIES_LOADED',
  PRODUCT_DEPARTMENT_CATEGORY_LOADED: 'productDepartmentCategory/PRODUCT_DEPARTMENT_CATEGORY_LOADED',
  PRODUCT_DEPARTMENT_CATEGORY_UPDATED: 'productDepartmentCategory/PRODUCT_DEPARTMENT_CATEGORY_UPDATED',
  PRODUCT_DEPARTMENT_CATEGORY_DELETED: 'productDepartmentCategory/PRODUCT_DEPARTMENT_CATEGORY_DELETED'
}

const initialState = {
  productDepartmentCategory: null,
  productDepartmentCategories: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_DEPARTMENT_CATEGORY:
      return { ...state, productDepartmentCategory: action.payload }
    case actionTypes.FETCH_PRODUCT_DEPARTMENT_CATEGORIES:
      return { ...state, productDepartmentCategories: null }
    case actionTypes.FETCH_PRODUCT_DEPARTMENT_CATEGORY:
      return { ...state, productDepartmentCategory: null }
    case actionTypes.UPADE_PRODUCT_DEPARTMENT_CATEGORY:
      return { ...state, productDepartmentCategory: action.payload }
    case actionTypes.DELETE_PRODUCT_DEPARTMENT_CATEGORY:
      return state

    case actionTypes.PRODUCT_DEPARTMENT_CATEGORY_CREATED:
      return { ...state, productDepartmentCategories: [...state.productDepartmentCategories, action.payload] }
    case actionTypes.PRODUCT_DEPARTMENT_CATEGORIES_LOADED:
      return { ...state, productDepartmentCategories: action.payload }
    case actionTypes.PRODUCT_DEPARTMENT_CATEGORY_LOADED:
      return { ...state, productDepartmentCategory: action.payload }
    case actionTypes.PRODUCT_DEPARTMENT_CATEGORY_UPDATED:
      return {
        ...state,
        productDepartmentCategory: action.payload,
        productDepartmentCategories: state.productDepartmentCategories.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_DEPARTMENT_CATEGORY_DELETED:
      return { ...state, productDepartmentCategories: state.productDepartmentCategories.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductDepartmentCategory: (productDepartmentCategory, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_DEPARTMENT_CATEGORY, payload: { productDepartmentCategory, setSubmitting } }),
  fetchProductDepartmentCategories: () => ({ type: actionTypes.FETCH_PRODUCT_DEPARTMENT_CATEGORIES }),
  fetchProductDepartmentCategory: (id) => ({ type: actionTypes.FETCH_PRODUCT_DEPARTMENT_CATEGORY, payload: id }),
  updateProductDepartmentCategory: (productDepartmentCategory, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_DEPARTMENT_CATEGORY, payload: { productDepartmentCategory, setSubmitting } }),
  deleteProductDepartmentCategory: id => ({ type: actionTypes.DELETE_PRODUCT_DEPARTMENT_CATEGORY, payload: id }),
  productDepartmentCategoryCreated: (productDepartmentCategory) => ({ type: actionTypes.PRODUCT_DEPARTMENT_CATEGORY_CREATED, payload: productDepartmentCategory }),
  productDepartmentCategoriesLoaded: (productDepartmentCategories) => ({ type: actionTypes.PRODUCT_DEPARTMENT_CATEGORIES_LOADED, payload: productDepartmentCategories }),
  productDepartmentCategoryLoaded: (productDepartmentCategory) => ({ type: actionTypes.PRODUCT_DEPARTMENT_CATEGORY_LOADED, payload: productDepartmentCategory }),
  productDepartmentCategoryUpdated: (productDepartmentCategory) => ({ type: actionTypes.PRODUCT_DEPARTMENT_CATEGORY_UPDATED, payload: productDepartmentCategory }),
  productDepartmentCategoryDeleted: id => ({ type: actionTypes.PRODUCT_DEPARTMENT_CATEGORY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_DEPARTMENT_CATEGORY, function * createProductDepartmentCategorySaga (action) {
    const { productDepartmentCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_department_categories', productDepartmentCategory)
      yield put(actions.productDepartmentCategoryCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_DEPARTMENT_CATEGORIES, function * fetchProductDepartmentCategoriesSaga () {
    try {
      const result = yield call(apiClient.get, 'product_department_categories')
      yield put(actions.productDepartmentCategoriesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_DEPARTMENT_CATEGORY, function * fetchProductDepartmentCategorySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_department_categories/${id}`)
      yield put(actions.productDepartmentCategoryLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_DEPARTMENT_CATEGORY, function * updateProductDepartmentCategorySaga (action) {
    const { productDepartmentCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_department_categories/${productDepartmentCategory.id}`, productDepartmentCategory)
      yield put(actions.productDepartmentCategoryUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_DEPARTMENT_CATEGORY, function * deleteProductDepartmentCategorySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_department_categories/${id}`)
      yield put(actions.productDepartmentCategoryDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
