import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_BRANCH: 'branch/CREATE_BRANCH',
  FETCH_BRANCHES: 'branch/FETCH_BRANCHES',
  FETCH_BRANCH: 'branch/FETCH_BRANCH',
  UPADE_BRANCH: 'branch/UPADE_BRANCH',
  DELETE_BRANCH: 'branch/DELETE_BRANCH',

  BRANCH_CREATED: 'branch/BRANCH_CREATED',
  BRANCHES_LOADED: 'branch/BRANCHES_LOADED',
  BRANCH_LOADED: 'branch/BRANCH_LOADED',
  BRANCH_UPDATED: 'branch/BRANCH_UPDATED',
  BRANCH_DELETED: 'branch/BRANCH_DELETED'
}

const initialState = {
  branch: null,
  branches: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_BRANCH:
      return { ...state, branch: action.payload }
    case actionTypes.FETCH_BRANCHES:
      return { ...state, branches: null }
    case actionTypes.FETCH_BRANCH:
      return { ...state, branch: null }
    case actionTypes.UPADE_BRANCH:
      return { ...state, branch: action.payload }
    case actionTypes.DELETE_BRANCH:
      return state

    case actionTypes.BRANCH_CREATED:
      return { ...state, branches: [...state.branches, action.payload] }
    case actionTypes.BRANCHES_LOADED:
      return { ...state, branches: action.payload }
    case actionTypes.BRANCH_LOADED:
      return { ...state, branch: action.payload }
    case actionTypes.BRANCH_UPDATED:
      return {
        ...state,
        branch: action.payload,
        branches: state.branches.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.BRANCH_DELETED:
      return { ...state, branches: state.branches.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createBranch: (branch, setSubmitting) => ({ type: actionTypes.CREATE_BRANCH, payload: { branch, setSubmitting } }),
  fetchBranches: () => ({ type: actionTypes.FETCH_BRANCHES }),
  fetchBranch: (id) => ({ type: actionTypes.FETCH_BRANCH, payload: id }),
  updateBranch: (branch, setSubmitting) => ({ type: actionTypes.UPADE_BRANCH, payload: { branch, setSubmitting } }),
  deleteBranch: id => ({ type: actionTypes.DELETE_BRANCH, payload: id }),
  branchCreated: (branch) => ({ type: actionTypes.BRANCH_CREATED, payload: branch }),
  branchesLoaded: (branches) => ({ type: actionTypes.BRANCHES_LOADED, payload: branches }),
  branchLoaded: (branch) => ({ type: actionTypes.BRANCH_LOADED, payload: branch }),
  branchUpdated: (branch) => ({ type: actionTypes.BRANCH_UPDATED, payload: branch }),
  branchDeleted: id => ({ type: actionTypes.BRANCH_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_BRANCH, function * createBranchSaga (action) {
    const { branch, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'branches', branch)
      yield put(actions.branchCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_BRANCHES, function * fetchBranchesSaga () {
    try {
      const result = yield call(apiClient.get, 'branches')
      yield put(actions.branchesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_BRANCH, function * fetchBranchSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `branches/${id}`)
      yield put(actions.branchLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_BRANCH, function * updateBranchSaga (action) {
    const { branch, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `branches/${branch.id}`, branch)
      yield put(actions.branchUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_BRANCH, function * deleteBranchSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `branches/${id}`)
      yield put(actions.branchDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
