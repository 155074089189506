import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import * as accountVouchersItem from './accountVouchersItem.duck'

const timeStampToDate = (timeStamp) => {
  let datetime = new Date()
  datetime.setTime(timeStamp * 1000);
  let year = datetime.getFullYear();
  let month = (datetime.getMonth() + 1) < 10 ? '0' + (datetime.getMonth() + 1) : (datetime.getMonth() + 1);
  let date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
  return year + "-" + month + "-" + date
}

// Actions
export const actionTypes = {
  CREATE_ACCOUNT_VOUCHER: 'accountVoucher/CREATE_ACCOUNT_VOUCHER',
  FETCH_ACCOUNT_VOUCHERS: 'accountVoucher/FETCH_ACCOUNT_VOUCHERS',
  FETCH_ACCOUNT_VOUCHER: 'accountVoucher/FETCH_ACCOUNT_VOUCHER',
  UPADE_ACCOUNT_VOUCHER: 'accountVoucher/UPADE_ACCOUNT_VOUCHER',
  DELETE_ACCOUNT_VOUCHER: 'accountVoucher/DELETE_ACCOUNT_VOUCHER',

  SEARCH_ACCOUNT_VOUCHER: 'accountVoucher/SEARCH_ACCOUNT_VOUCHER',
  UPDATE_PRINT_ACCOUNT_VOUCHER: 'accountVoucher/UPDATE_PRINT_ACCOUNT_VOUCHER',

  ACCOUNT_VOUCHER_CREATED: 'accountVoucher/ACCOUNT_VOUCHER_CREATED',
  ACCOUNT_VOUCHERS_LOADED: 'accountVoucher/ACCOUNT_VOUCHERS_LOADED',
  ACCOUNT_VOUCHER_LOADED: 'accountVoucher/ACCOUNT_VOUCHER_LOADED',
  ACCOUNT_VOUCHER_UPDATED: 'accountVoucher/ACCOUNT_VOUCHER_UPDATED',
  ACCOUNT_VOUCHER_DELETED: 'accountVoucher/ACCOUNT_VOUCHER_DELETED',

  FETCH_ACCOUNT_VOUCHERS_ITEMS: 'accountVoucher/FETCH_ACCOUNT_VOUCHERS_ITEMS'

}

const initialState = {
  accountVoucher: null,
  accountVouchers: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ACCOUNT_VOUCHER:
      return { ...state, accountVoucher: action.payload }
    case actionTypes.FETCH_ACCOUNT_VOUCHERS:
      return { ...state, accountVouchers: null }
    case actionTypes.FETCH_ACCOUNT_VOUCHER:
      return { ...state, accountVoucher: null }
    case actionTypes.UPADE_ACCOUNT_VOUCHER:
      return { ...state, accountVoucher: action.payload }
    case actionTypes.DELETE_ACCOUNT_VOUCHER:
      return state

    case actionTypes.SEARCH_ACCOUNT_VOUCHER:
      return {...state, accountVouchers: null}

    case actionTypes.UPDATE_PRINT_ACCOUNT_VOUCHER:
      return {...state, accountVoucher: action.payload }

    case actionTypes.ACCOUNT_VOUCHERS_LOADED:
      return { ...state, accountVouchers: action.payload }
    case actionTypes.ACCOUNT_VOUCHER_LOADED:
      const result = Object.assign({}, {...action.payload, date: timeStampToDate(action.payload.date)})
      return { ...state, accountVoucher: result }
    case actionTypes.ACCOUNT_VOUCHER_UPDATED:
      return {
        ...state,
        accountVoucher: action.payload,
        accountVouchers: state.accountVouchers.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ACCOUNT_VOUCHER_DELETED:
      return { ...state, accountVouchers: state.accountVouchers.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createAccountVoucher: (accountVoucher, setSubmitting) => ({ type: actionTypes.CREATE_ACCOUNT_VOUCHER, payload: { accountVoucher, setSubmitting } }),
  fetchAccountVouchers: () => ({ type: actionTypes.FETCH_ACCOUNT_VOUCHERS }),
  fetchAccountVoucher: (id) => ({ type: actionTypes.FETCH_ACCOUNT_VOUCHER, payload: id }),
  updateAccountVoucher: (accountVoucher, setSubmitting) => ({ type: actionTypes.UPADE_ACCOUNT_VOUCHER, payload: { accountVoucher, setSubmitting } }),
  deleteAccountVoucher: id => ({ type: actionTypes.DELETE_ACCOUNT_VOUCHER, payload: id }),

  searchAccountVouchers: (values, setSubmitting) => ({ type: actionTypes.SEARCH_ACCOUNT_VOUCHER, payload: { values, setSubmitting } }),
  updatePrintAccountVoucher: (accountVoucher) => ({ type: actionTypes.UPDATE_PRINT_ACCOUNT_VOUCHER, payload: accountVoucher }),

  accountVouchersLoaded: (accountVouchers) => ({ type: actionTypes.ACCOUNT_VOUCHERS_LOADED, payload: accountVouchers }),
  accountVoucherLoaded: (accountVoucher) => ({ type: actionTypes.ACCOUNT_VOUCHER_LOADED, payload: accountVoucher }),
  accountVoucherUpdated: (accountVoucher) => ({ type: actionTypes.ACCOUNT_VOUCHER_UPDATED, payload: accountVoucher }),
  accountVoucherDeleted: id => ({ type: actionTypes.ACCOUNT_VOUCHER_DELETED, payload: id }),

  fetchAccountVouchersItems: (idno) => ({ type: actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEMS, payload: idno })


}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ACCOUNT_VOUCHER, function * createAccountVoucherSaga (action) {
    const { accountVoucher, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'account_vouchers', accountVoucher)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_VOUCHERS, function * fetchAccountVouchersSaga () {
    try {
      const result = yield call(apiClient.get, 'account_vouchers')
      yield put(actions.accountVouchersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_VOUCHER, function * fetchAccountVoucherSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `account_vouchers/${id}`)
      yield put(actions.accountVoucherLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ACCOUNT_VOUCHER, function * updateAccountVoucherSaga (action) {
    const { accountVoucher, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `account_vouchers/${accountVoucher.id}`, accountVoucher)
      yield put(actions.accountVoucherUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ACCOUNT_VOUCHER, function * deleteAccountVoucherSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `account_vouchers/${id}`)
      yield put(actions.accountVoucherDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.SEARCH_ACCOUNT_VOUCHER, function * searchAccountVouchersSaga(action) {
    const { values, setSubmitting } = action.payload
    try{
      const result = yield call(apiClient.get, 'account_vouchers', {params: {
        year: !values.year ? null : values.year,
        source_type: !values.source_type ? null : values.source_type,
        idno: !values.idno ? null : values.idno,
        source_code: !values.source_code ? null : values.source_code,
        date: !values.date1 || values.date2 ? null : {start: values.date1, end: values.date2},
        type: !values.type ? null : values.type
      }})
      yield put(actions.accountVouchersLoaded(result.data.data))
    } catch(e) {
      notifyError(e.response ? e.response.statusText: e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.UPDATE_PRINT_ACCOUNT_VOUCHER, function * updatePrintAccountVoucherSaga (action) {
    const accountVoucher = action.payload
    console.log(accountVoucher, accountVoucher.id)
    try {
      const result = yield call(apiClient.put, `account_vouchers/${accountVoucher.id}`, accountVoucher)
      yield put(actions.accountVoucherUpdated(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } 
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_VOUCHERS_ITEMS, function * fetchAccountVouchersItemsSaga(action) {
    try {
        const idno = action.payload
        const result = yield call(apiClient.get, `account_vouchers_items`)
        const items = result.data.data.filter(item => {
          return item.account_voucher_idno === idno
        })
        if (result.data && items.length > 0) {
          yield put(accountVouchersItem.actions.accountVouchersItemLoaded(result.data.data))
        } else {
          yield put(accountVouchersItem.actions.accountVouchersItemLoaded([{ position: 1 }]))
        }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
