import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_BANK_TRAN: 'bankTran/CREATE_BANK_TRAN',
  FETCH_BANK_TRANS: 'bankTran/FETCH_BANK_TRANS',
  FETCH_BANK_TRAN: 'bankTran/FETCH_BANK_TRAN',
  UPADE_BANK_TRAN: 'bankTran/UPADE_BANK_TRAN',
  DELETE_BANK_TRAN: 'bankTran/DELETE_BANK_TRAN',

  BANK_TRAN_CREATED: 'bankTran/BANK_TRAN_CREATED',
  BANK_TRANS_LOADED: 'bankTran/BANK_TRANS_LOADED',
  BANK_TRAN_LOADED: 'bankTran/BANK_TRAN_LOADED',
  BANK_TRAN_UPDATED: 'bankTran/BANK_TRAN_UPDATED',
  BANK_TRAN_DELETED: 'bankTran/BANK_TRAN_DELETED'
}

const initialState = {
  bankTran: null,
  bankTrans: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_BANK_TRAN:
      return { ...state, bankTran: action.payload }
    case actionTypes.FETCH_BANK_TRANS:
      return { ...state, bankTrans: null }
    case actionTypes.FETCH_BANK_TRAN:
      return { ...state, bankTran: null }
    case actionTypes.UPADE_BANK_TRAN:
      return { ...state, bankTran: action.payload }
    case actionTypes.DELETE_BANK_TRAN:
      return state

    case actionTypes.BANK_TRANS_LOADED:
      return { ...state, bankTrans: action.payload }
    case actionTypes.BANK_TRAN_LOADED:
      return { ...state, bankTran: action.payload }
    case actionTypes.BANK_TRAN_UPDATED:
      return {
        ...state,
        bankTran: action.payload,
        bankTrans: state.bankTrans.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.BANK_TRAN_DELETED:
      return { ...state, bankTrans: state.bankTrans.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createBankTran: (bankTran, setSubmitting) => ({ type: actionTypes.CREATE_BANK_TRAN, payload: { bankTran, setSubmitting } }),
  fetchBankTrans: () => ({ type: actionTypes.FETCH_BANK_TRANS }),
  fetchBankTran: (id) => ({ type: actionTypes.FETCH_BANK_TRAN, payload: id }),
  updateBankTran: (bankTran, setSubmitting) => ({ type: actionTypes.UPADE_BANK_TRAN, payload: { bankTran, setSubmitting } }),
  deleteBankTran: id => ({ type: actionTypes.DELETE_BANK_TRAN, payload: id }),
  bankTransLoaded: (bankTrans) => ({ type: actionTypes.BANK_TRANS_LOADED, payload: bankTrans }),
  bankTranLoaded: (bankTran) => ({ type: actionTypes.BANK_TRAN_LOADED, payload: bankTran }),
  bankTranUpdated: (bankTran) => ({ type: actionTypes.BANK_TRAN_UPDATED, payload: bankTran }),
  bankTranDeleted: id => ({ type: actionTypes.BANK_TRAN_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_BANK_TRAN, function * createBankTranSaga (action) {
    const { bankTran, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'bank_trans', bankTran)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_BANK_TRANS, function * fetchBankTransSaga () {
    try {
      const result = yield call(apiClient.get, 'bank_trans')
      yield put(actions.bankTransLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_BANK_TRAN, function * fetchBankTranSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `bank_trans/${id}`)
      yield put(actions.bankTranLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_BANK_TRAN, function * updateBankTranSaga (action) {
    const { bankTran, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `bank_trans/${bankTran.id}`, bankTran)
      yield put(actions.bankTranUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_BANK_TRAN, function * deleteBankTranSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `bank_trans/${id}`)
      yield put(actions.bankTranDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
