/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useLastLocation } from 'react-router-last-location'
import ErrorsPage from '../pages/errors/ErrorsPage'
import LogoutPage from '../pages/auth/Logout'
import { LayoutContextProvider } from '../../_metronic'
import Layout from '../../_metronic/layout/Layout'
import * as routerHelpers from '../router/RouterHelpers'
import AuthPage from '../pages/auth/AuthPage'
import Toast from '../../shared/toast/Toast'
import ErrorMessageDialog from '../../shared/alert-dialog/ErrorMessageDialog'
import Modal from '@material-ui/core/Modal'
import Box from '@material-ui/core/Box';
import SpinBar from 'shared/components/loading/SpinBar/SpinBar'

const style = {
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const MainLayout = withRouter(({ history }) => {
  const lastLocation = useLastLocation()
  routerHelpers.saveLastLocation(lastLocation)
  const isLoading = useSelector(state => state.isLoading)
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  )

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <>
            <Layout/>
            <Toast />
            <ErrorMessageDialog />
          </>
        )}
      </Switch>
      <Modal
        open={isLoading}
      >
        <Box sx={style}>
          <SpinBar />
        </Box>
      </Modal>
    </LayoutContextProvider>
  )
})
