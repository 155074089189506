import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_MEDIUM_CATEGORY: 'productMediumCategory/CREATE_PRODUCT_MEDIUM_CATEGORY',
  FETCH_PRODUCT_MEDIUM_CATEGORIES: 'productMediumCategory/FETCH_PRODUCT_MEDIUM_CATEGORIES',
  FETCH_PRODUCT_MEDIUM_CATEGORY: 'productMediumCategory/FETCH_PRODUCT_MEDIUM_CATEGORY',
  UPADE_PRODUCT_MEDIUM_CATEGORY: 'productMediumCategory/UPADE_PRODUCT_MEDIUM_CATEGORY',
  DELETE_PRODUCT_MEDIUM_CATEGORY: 'productMediumCategory/DELETE_PRODUCT_MEDIUM_CATEGORY',

  PRODUCT_MEDIUM_CATEGORY_CREATED: 'productMediumCategory/PRODUCT_MEDIUM_CATEGORY_CREATED',
  PRODUCT_MEDIUM_CATEGORIES_LOADED: 'productMediumCategory/PRODUCT_MEDIUM_CATEGORIES_LOADED',
  PRODUCT_MEDIUM_CATEGORY_LOADED: 'productMediumCategory/PRODUCT_MEDIUM_CATEGORY_LOADED',
  PRODUCT_MEDIUM_CATEGORY_UPDATED: 'productMediumCategory/PRODUCT_MEDIUM_CATEGORY_UPDATED',
  PRODUCT_MEDIUM_CATEGORY_DELETED: 'productMediumCategory/PRODUCT_MEDIUM_CATEGORY_DELETED'
}

const initialState = {
  productMediumCategory: null,
  productMediumCategories: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_MEDIUM_CATEGORY:
      return { ...state, productMediumCategory: action.payload }
    case actionTypes.FETCH_PRODUCT_MEDIUM_CATEGORIES:
      return { ...state, productMediumCategories: null }
    case actionTypes.FETCH_PRODUCT_MEDIUM_CATEGORY:
      return { ...state, productMediumCategory: null }
    case actionTypes.UPADE_PRODUCT_MEDIUM_CATEGORY:
      return { ...state, productMediumCategory: action.payload }
    case actionTypes.DELETE_PRODUCT_MEDIUM_CATEGORY:
      return state

    case actionTypes.PRODUCT_MEDIUM_CATEGORY_CREATED:
      return { ...state, productMediumCategories: [...state.productMediumCategories, action.payload] }
    case actionTypes.PRODUCT_MEDIUM_CATEGORIES_LOADED:
      return { ...state, productMediumCategories: action.payload }
    case actionTypes.PRODUCT_MEDIUM_CATEGORY_LOADED:
      return { ...state, productMediumCategory: action.payload }
    case actionTypes.PRODUCT_MEDIUM_CATEGORY_UPDATED:
      return {
        ...state,
        productMediumCategory: action.payload,
        productMediumCategories: state.productMediumCategories.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_MEDIUM_CATEGORY_DELETED:
      return { ...state, productMediumCategories: state.productMediumCategories.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductMediumCategory: (productMediumCategory, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_MEDIUM_CATEGORY, payload: { productMediumCategory, setSubmitting } }),
  fetchProductMediumCategories: () => ({ type: actionTypes.FETCH_PRODUCT_MEDIUM_CATEGORIES }),
  fetchProductMediumCategory: (id) => ({ type: actionTypes.FETCH_PRODUCT_MEDIUM_CATEGORY, payload: id }),
  updateProductMediumCategory: (productMediumCategory, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_MEDIUM_CATEGORY, payload: { productMediumCategory, setSubmitting } }),
  deleteProductMediumCategory: id => ({ type: actionTypes.DELETE_PRODUCT_MEDIUM_CATEGORY, payload: id }),
  productMediumCategoryCreated: (productMediumCategory) => ({ type: actionTypes.PRODUCT_MEDIUM_CATEGORY_CREATED, payload: productMediumCategory }),
  productMediumCategoriesLoaded: (productMediumCategories) => ({ type: actionTypes.PRODUCT_MEDIUM_CATEGORIES_LOADED, payload: productMediumCategories }),
  productMediumCategoryLoaded: (productMediumCategory) => ({ type: actionTypes.PRODUCT_MEDIUM_CATEGORY_LOADED, payload: productMediumCategory }),
  productMediumCategoryUpdated: (productMediumCategory) => ({ type: actionTypes.PRODUCT_MEDIUM_CATEGORY_UPDATED, payload: productMediumCategory }),
  productMediumCategoryDeleted: id => ({ type: actionTypes.PRODUCT_MEDIUM_CATEGORY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_MEDIUM_CATEGORY, function * createProductMediumCategorySaga (action) {
    const { productMediumCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_medium_categories', productMediumCategory)
      yield put(actions.productMediumCategoryCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_MEDIUM_CATEGORIES, function * fetchProductMediumCategoriesSaga () {
    try {
      const result = yield call(apiClient.get, 'product_medium_categories')
      yield put(actions.productMediumCategoriesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_MEDIUM_CATEGORY, function * fetchProductMediumCategorySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_medium_categories/${id}`)
      yield put(actions.productMediumCategoryLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_MEDIUM_CATEGORY, function * updateProductMediumCategorySaga (action) {
    const { productMediumCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_medium_categories/${productMediumCategory.id}`, productMediumCategory)
      yield put(actions.productMediumCategoryUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_MEDIUM_CATEGORY, function * deleteProductMediumCategorySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_medium_categories/${id}`)
      yield put(actions.productMediumCategoryDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
