import { put, call, fork, takeLatest, takeEvery, select } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { defaultValue } from 'admin/pages/home/common/tfai_projects/tfaiProjectDefaultValue'
import * as entireConfigDuck from './entireConfig.duck'
import * as loadingDuck from './loading.duck'
//utils
import { resortPosition } from 'shared/utils/arrayMethod'

// Actions
export const actionTypes = {
  CREATE_TFAI_PROJECT: 'tfaiProject/CREATE_TFAI_PROJECT',
  FETCH_TFAI_PROJECTS: 'tfaiProject/FETCH_TFAI_PROJECTS',
  FETCH_TFAI_PROJECT: 'tfaiProject/FETCH_TFAI_PROJECT',
  UPADE_TFAI_PROJECT: 'tfaiProject/UPADE_TFAI_PROJECT',
  DELETE_TFAI_PROJECT: 'tfaiProject/DELETE_TFAI_PROJECT',
  DELETE_TFAI_PROJECT_BY_POSITION: 'tfaiProject/DELETE_TFAI_PROJECT_BY_POSITION',

  TFAI_PROJECT_CREATED: 'tfaiProject/TFAI_PROJECT_CREATED',
  TFAI_PROJECTS_LOADED: 'tfaiProject/TFAI_PROJECTS_LOADED',
  TFAI_PROJECT_LOADED: 'tfaiProject/TFAI_PROJECT_LOADED',
  TFAI_PROJECT_UPDATED: 'tfaiProject/TFAI_PROJECT_UPDATED',
  TFAI_PROJECT_DELETED: 'tfaiProject/TFAI_PROJECT_DELETED',

  INSERT_NEW_ROW: 'tfaiProject/INSERT_NEW_ROW',
  INSERT_TFAI_DEPARTMENT: 'tfaiProject/INSERT_TFAI_DEPARTMENT',
  INSERT_TFAI_ACCOUNT_TITLE: 'tfaiProject/INSERT_TFAI_ACCOUNT_TITLE',

  UPDATE_GRID_INDEX: 'tfaiProject/UPDATE_GRID_INDEX',
}

const initialState = {
  tfaiProject: null,
  tfaiProjects: null,
  agGridIndex: 0,
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_TFAI_PROJECT:
      return { ...state, tfaiProject: null }
    case actionTypes.FETCH_TFAI_PROJECTS:
      return { ...state, tfaiProjects: null }
    case actionTypes.FETCH_TFAI_PROJECT:
      return { ...state, tfaiProject: null }
    case actionTypes.UPADE_TFAI_PROJECT:
      return { ...state, tfaiProject: null }
    case actionTypes.DELETE_TFAI_PROJECT:
      return state

    case actionTypes.TFAI_PROJECT_CREATED:
      return !state.tfaiProjects ? { ...state, tfaiProjects: [action.payload] } : { ...state, tfaiProjects: [...state.tfaiProjects, action.payload] }
    case actionTypes.TFAI_PROJECTS_LOADED:
      return { ...state, tfaiProjects: resortPosition(action.payload) }
    case actionTypes.TFAI_PROJECT_LOADED:
      return { ...state, tfaiProject: action.payload }
    case actionTypes.TFAI_PROJECT_UPDATED:
      return {
        ...state,
        tfaiProject: action.payload,
        tfaiProjects: state.tfaiProjects.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.TFAI_PROJECT_DELETED:
      const { position: deleteIdPosition, id: deleteId } = action.payload
      return {
        ...state,
        tfaiProjects: resortPosition(state.tfaiProjects.filter(item => item.id !== deleteId)),
        agGridIndex: deleteIdPosition
      }
    case actionTypes.DELETE_TFAI_PROJECT_BY_POSITION:
      const { position: deletePosition } = action.payload
      return {
        ...state,
        tfaiProjects: resortPosition(state.tfaiProjects.filter(item => item.position !== deletePosition)),
        agGridIndex: deletePosition
      }

    case actionTypes.INSERT_NEW_ROW:
      return {
          ...state,
          tfaiProjects: state?.tfaiProjects ? resortPosition([...state.tfaiProjects, { ...defaultValue }]) : [{ ...defaultValue }],
          agGridIndex: state?.tfaiProjects?.length ? state?.tfaiProjects?.length : 0
      }
    case actionTypes.INSERT_TFAI_DEPARTMENT:
      const { position: tfaiDepartmentPosition, row: tfaiDepartmentRow } = action.payload
      return {
        ...state,
        tfaiProjects: state.tfaiProjects.map((item) =>
          item.position === tfaiDepartmentPosition
            ? { ...item, tfai_department_id: tfaiDepartmentRow.data.id, tfai_department: tfaiDepartmentRow.data }
            : item,
        ),
        agGridIndex: tfaiDepartmentPosition
      }
    case actionTypes.INSERT_TFAI_ACCOUNT_TITLE:
      const { position: tfaiAccountTitlePosition, row: tfaiAccountTitleRow } = action.payload
      return {
        ...state,
        tfaiProjects: state.tfaiProjects.map((item) =>
          item.position === tfaiAccountTitlePosition
            ? { ...item, tfai_account_title_id: tfaiAccountTitleRow.data.id, tfai_account_title: tfaiAccountTitleRow.data }
            : item,
        ),
        agGridIndex: tfaiDepartmentPosition
      }

    case actionTypes.UPDATE_GRID_INDEX:
      const { index: gridIndex } = action.payload
      return { ...state, agGridIndex: gridIndex}

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createTfaiProject: (tfaiProject, { callback, isLast }) => ({ type: actionTypes.CREATE_TFAI_PROJECT, payload: { tfaiProject, callback, isLast } }),
  fetchTfaiProjects: () => ({ type: actionTypes.FETCH_TFAI_PROJECTS }),
  fetchTfaiProject: (id) => ({ type: actionTypes.FETCH_TFAI_PROJECT, payload: id }),
  updateTfaiProject: (tfaiProject, { callback, isLast }) => ({ type: actionTypes.UPADE_TFAI_PROJECT, payload: { tfaiProject, callback, isLast } }),
  deleteTfaiProject: (id, position) => ({ type: actionTypes.DELETE_TFAI_PROJECT, payload: { id, position } }),
  deleteTfaiProjectByPosition: (position) => ({ type: actionTypes.DELETE_TFAI_PROJECT_BY_POSITION, payload: { position } }),
  tfaiProjectCreated: (tfaiProject) => ({ type: actionTypes.TFAI_PROJECT_CREATED, payload: tfaiProject }),
  tfaiProjectsLoaded: (tfaiProjects) => ({ type: actionTypes.TFAI_PROJECTS_LOADED, payload: tfaiProjects }),
  tfaiProjectLoaded: (tfaiProject) => ({ type: actionTypes.TFAI_PROJECT_LOADED, payload: tfaiProject }),
  tfaiProjectUpdated: (tfaiProject) => ({ type: actionTypes.TFAI_PROJECT_UPDATED, payload: tfaiProject }),
  tfaiProjectDeleted: (id, position) => ({ type: actionTypes.TFAI_PROJECT_DELETED, payload: { id, position } }),

  insertNewRow: () => ({ type: actionTypes.INSERT_NEW_ROW }),
  insertTfaiDepartment: (position, row) => ({ type: actionTypes.INSERT_TFAI_DEPARTMENT, payload: { position, row }}),
  insertTfaiAccountTitle: (position, row) => ({ type: actionTypes.INSERT_TFAI_ACCOUNT_TITLE, payload: { position, row }}),

  updateGridIndex: (index) => ({ type: actionTypes.UPDATE_GRID_INDEX, payload: { index }}),
}

// Saga
export function * saga () {
  yield takeEvery(actionTypes.CREATE_TFAI_PROJECT, function * createTfaiProjectSaga (action) {
    const { tfaiProject, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.post, 'tfai_projects', tfaiProject)
      if(isLast) {
        yield put(actions.fetchTfaiProjects())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if(callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_PROJECTS, function * fetchTfaiProjectsSaga () {
    try {
      yield put(loadingDuck.actions.setLoading(true))
      const result = yield call(apiClient.get, 'tfai_projects')
      yield put(actions.tfaiProjectsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      yield put(loadingDuck.actions.setLoading(false))
    }
  })

  yield takeLatest(actionTypes.FETCH_TFAI_PROJECT, function * fetchTfaiProjectSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `tfai_projects/${id}`)
      yield put(actions.tfaiProjectLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } 
  })

  yield takeEvery(actionTypes.UPADE_TFAI_PROJECT, function * updateTfaiProjectSaga (action) {
    const { tfaiProject, callback, isLast } = action.payload
    try {
      const result = yield call(apiClient.put, `tfai_projects/${tfaiProject.id}`, tfaiProject)
      if(isLast) {
        yield put(actions.fetchTfaiProjects())
        notifySuccess('儲存成功')
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if(callback && isLast) {
        callback()
      }
    }
  })

  yield takeLatest(actionTypes.DELETE_TFAI_PROJECT, function * deleteTfaiProjectSaga (action) {
    try {
      const { id, position } = action.payload
      yield call(apiClient.delete, `tfai_projects/${id}`)
      yield put(actions.tfaiProjectDeleted(id, position))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
