import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import * as productOptionItemDuck from 'admin/store/ducks/productOptionItem.duck'
import { v4 as uuidv4 } from 'uuid'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_OPTION: 'productOption/CREATE_PRODUCT_OPTION',
  FETCH_PRODUCT_OPTIONS: 'productOption/FETCH_PRODUCT_OPTIONS',
  FETCH_PRODUCT_OPTION: 'productOption/FETCH_PRODUCT_OPTION',
  FETCH_PRODUCT_OPTION_ITEMS: 'productOption/FETCH_PRODUCT_OPTION_ITEMS',
  UPADE_PRODUCT_OPTION: 'productOption/UPADE_PRODUCT_OPTION',
  DELETE_PRODUCT_OPTION: 'productOption/DELETE_PRODUCT_OPTION',
  SAVE_PRODUCT_OPTION_ITEMS: 'productOption/SAVE_PRODUCT_OPTION_ITEMS',

  PRODUCT_OPTION_CREATED: 'productOption/PRODUCT_OPTION_CREATED',
  PRODUCT_OPTIONS_LOADED: 'productOption/PRODUCT_OPTIONS_LOADED',
  PRODUCT_OPTION_LOADED: 'productOption/PRODUCT_OPTION_LOADED',
  PRODUCT_OPTION_UPDATED: 'productOption/PRODUCT_OPTION_UPDATED',
  PRODUCT_OPTION_DELETED: 'productOption/PRODUCT_OPTION_DELETED'
}

const initialState = {
  productOption: null,
  productOptions: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_OPTION:
      return { ...state, productOption: action.payload }
    case actionTypes.FETCH_PRODUCT_OPTIONS:
      return { ...state, productOptions: null }
    case actionTypes.FETCH_PRODUCT_OPTION:
      return { ...state, productOption: null }
    case actionTypes.UPADE_PRODUCT_OPTION:
      return { ...state, productOption: action.payload }
    case actionTypes.DELETE_PRODUCT_OPTION:
      return state

    // case actionTypes.PRODUCT_OPTION_CREATED:
    //   return { ...state, productOptions: [...state.productOptions, action.payload] }
    case actionTypes.PRODUCT_OPTIONS_LOADED:
      return { ...state, productOptions: action.payload }
    case actionTypes.PRODUCT_OPTION_LOADED:
      return { ...state, productOption: action.payload }
    case actionTypes.PRODUCT_OPTION_UPDATED:
      return {
        ...state,
        productOption: action.payload,
        productOptions: state.productOptions == null ? [action.payload] : state.productOptions.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_OPTION_DELETED:
      return { ...state, productOptions: state.productOptions.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductOption: (productOption, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_OPTION, payload: { productOption, setSubmitting } }),
  fetchProductOptions: () => ({ type: actionTypes.FETCH_PRODUCT_OPTIONS }),
  fetchProductOptionItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_OPTION_ITEMS, payload: id }),
  fetchProductOption: (id) => ({ type: actionTypes.FETCH_PRODUCT_OPTION, payload: id }),
  updateProductOption: (productOption, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_OPTION, payload: { productOption, setSubmitting } }),
  deleteProductOption: id => ({ type: actionTypes.DELETE_PRODUCT_OPTION, payload: id }),
  productOptionCreated: (productOption) => ({ type: actionTypes.PRODUCT_OPTION_CREATED, payload: productOption }),
  productOptionsLoaded: (productOptions) => ({ type: actionTypes.PRODUCT_OPTIONS_LOADED, payload: productOptions }),
  productOptionLoaded: (productOption) => ({ type: actionTypes.PRODUCT_OPTION_LOADED, payload: productOption }),
  productOptionUpdated: (productOption) => ({ type: actionTypes.PRODUCT_OPTION_UPDATED, payload: productOption }),
  productOptionDeleted: id => ({ type: actionTypes.PRODUCT_OPTION_DELETED, payload: id }),
  saveProductOptionItems: (productOption) => ({ type: actionTypes.SAVE_PRODUCT_OPTION_ITEMS, payload: { productOption } })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_OPTION, function * createProductOptionSaga (action) {
    const { productOption, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_options', productOption)
      yield put(actions.productOptionCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.SAVE_PRODUCT_OPTION_ITEMS, function * saveProductOptionItemsSaga (action) {
    const { productOption } = action.payload
    try {
      yield call(apiClient.put, `product_options/${productOption.id}`, productOption)
      yield put(actions.fetchProductOptionItems(productOption.id))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_OPTIONS, function * fetchProductOptionsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_options')
      yield put(actions.productOptionsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_OPTION, function * fetchProductOptionSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_options/${id}`)
      yield put(actions.productOptionLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_OPTION, function * updateProductOptionSaga (action) {
    const { productOption, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_options/${productOption.id}`, productOption)
      yield put(actions.productOptionUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_OPTION, function * deleteProductOptionSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_options/${id}`)
      yield put(actions.productOptionDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_OPTION_ITEMS, function * fetchProductOptionItemsSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_options/${id}/product_option_items`)

      if (result.data && result.data.data.length > 0) {
        yield put(productOptionItemDuck.actions.productOptionItemsLoaded(result.data.data))
      } else {
        yield put(productOptionItemDuck.actions.productOptionItemsLoaded([{ position: 1, product_option_id: id, id: uuidv4() }]))
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
