import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_PACKAGE: 'productPackage/CREATE_PRODUCT_PACKAGE',
  FETCH_PRODUCT_PACKAGES: 'productPackage/FETCH_PRODUCT_PACKAGES',
  FETCH_PRODUCT_PACKAGE: 'productPackage/FETCH_PRODUCT_PACKAGE',
  UPADE_PRODUCT_PACKAGE: 'productPackage/UPADE_PRODUCT_PACKAGE',
  DELETE_PRODUCT_PACKAGE: 'productPackage/DELETE_PRODUCT_PACKAGE',

  PRODUCT_PACKAGE_CREATED: 'productPackage/PRODUCT_PACKAGE_CREATED',
  PRODUCT_PACKAGES_LOADED: 'productPackage/PRODUCT_PACKAGES_LOADED',
  PRODUCT_PACKAGE_LOADED: 'productPackage/PRODUCT_PACKAGE_LOADED',
  PRODUCT_PACKAGE_UPDATED: 'productPackage/PRODUCT_PACKAGE_UPDATED',
  PRODUCT_PACKAGE_DELETED: 'productPackage/PRODUCT_PACKAGE_DELETED',

  INSERT_EMPTY_ROW: 'productPackage/INSERT_EMPTY_ROW',
  DELETE_ROW: 'productPackage/DELETE_ROW',
  INSERT_ROWS: 'productPackage/INSERT_ROWS'
}

const initialState = {
  productPackage: null,
  productPackages: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_PACKAGE:
      return { ...state, productPackage: action.payload }
    case actionTypes.FETCH_PRODUCT_PACKAGES:
      return { ...state, productPackages: null }
    // case actionTypes.FETCH_PRODUCT_PACKAGE:
    //   return { ...state, productPackage: null }
    case actionTypes.UPADE_PRODUCT_PACKAGE:
      return { ...state, productPackage: action.payload }
    case actionTypes.DELETE_PRODUCT_PACKAGE:
      return state

    case actionTypes.PRODUCT_PACKAGE_CREATED:
      return { ...state, productPackages: [...state.productPackages, action.payload] }
    case actionTypes.PRODUCT_PACKAGES_LOADED:
      return { ...state, productPackages: action.payload }
    case actionTypes.PRODUCT_PACKAGE_LOADED:
      return { ...state, productPackage: action.payload }
    case actionTypes.PRODUCT_PACKAGE_UPDATED:
      return {
        ...state,
        productPackage: action.payload
      }
    case actionTypes.PRODUCT_PACKAGE_DELETED:
      return { ...state, productPackages: state.productPackages.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductPackage: (productPackage, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_PACKAGE, payload: { productPackage, setSubmitting } }),
  fetchProductPackages: () => ({ type: actionTypes.FETCH_PRODUCT_PACKAGES }),
  fetchProductPackage: (id) => ({ type: actionTypes.FETCH_PRODUCT_PACKAGE, payload: id }),
  updateProductPackage: (productPackage, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_PACKAGE, payload: { productPackage, setSubmitting } }),
  deleteProductPackage: id => ({ type: actionTypes.DELETE_PRODUCT_PACKAGE, payload: id }),
  productPackageCreated: (productPackage) => ({ type: actionTypes.PRODUCT_PACKAGE_CREATED, payload: productPackage }),
  productPackagesLoaded: (productPackages) => ({ type: actionTypes.PRODUCT_PACKAGES_LOADED, payload: productPackages }),
  productPackageLoaded: (productPackage) => ({ type: actionTypes.PRODUCT_PACKAGE_LOADED, payload: productPackage }),
  productPackageUpdated: (productPackage) => ({ type: actionTypes.PRODUCT_PACKAGE_UPDATED, payload: productPackage }),
  productPackageDeleted: id => ({ type: actionTypes.PRODUCT_PACKAGE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_PACKAGE, function * createProductPackageSaga (action) {
    const { productPackage, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_packages', productPackage)
      yield put(actions.productPackageCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_PACKAGES, function * fetchProductPackagesSaga () {
    try {
      const result = yield call(apiClient.get, 'product_packages')
      yield put(actions.productPackagesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_PACKAGE, function * fetchProductPackageSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `products/${id}`)
      yield put(actions.productPackageLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_PACKAGE, function * updateProductPackageSaga (action) {
    const { productPackage, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `products/${productPackage.id}/package_items`, productPackage)
      yield put(actions.productPackageUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_PACKAGE, function * deleteProductPackageSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_packages/${id}`)
      yield put(actions.productPackageDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
