import moment from 'moment'

export const dateToTimeStamp = (date) => {
  const timeStamp = Number(moment(date).format('X'))
  return timeStamp >= 0 ? timeStamp : 0
}

export const timeStampToDate = {
  string: (timeStamp) => {
    return timeStamp > 0 ? moment.unix(timeStamp).format('YYYYMMDD') : ''
  },
  hyphen: (timeStamp) => {
    return timeStamp > 0 ? moment.unix(timeStamp).format('YYYY-MM-DD') : ''
  },
  slash: (timeStamp) => {
    return timeStamp > 0 ? moment.unix(timeStamp).format('YYYY/MM/DD') : ''
  },
}

export const getToday = {
  string: () => moment().format('YYYYMMDD'),
  hyphen: () => moment().format('YYYY-MM-DD'),
  slash: () => moment().format('YYYY/MM/DD'),
  year: () => moment().format('YYYY'),
  timeStamp: () => Number(moment().format('X')),
  mString: () => moment().format('YYYYMM'),
  mHyphen: () => moment().format('YYYY-MM'),
  mSlash: () => moment().format('YYYY/MM'),
}

export const dateFormat = {
  string: (date) => date ? moment(date).format('YYYYMMDD') : '',
  hyphen: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
  slash: (date) => date ? moment(date).format('YYYY/MM/DD') : '',
}

export const dateToMinute = (date) => {
  return moment(date).format('YYYY/MM/DD HH:mm')
}

export const getMonthBefore = {
  hyphen: (amount) =>
    moment()
      .subtract(amount, 'M')
      .format('YYYY-MM-DD'),
}

export const getDayAfter = {
  hyphen: (amount, date = undefined) =>
    moment(date || undefined)
      .add(amount, 'd')
      .format('YYYY-MM-DD'),
}