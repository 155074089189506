import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_CUSTOMER_RECEIVE: 'customerReceive/CREATE_CUSTOMER_RECEIVE',
  FETCH_CUSTOMER_RECEIVES: 'customerReceive/FETCH_CUSTOMER_RECEIVES',
  FETCH_CUSTOMER_RECEIVE: 'customerReceive/FETCH_CUSTOMER_RECEIVE',
  UPADE_CUSTOMER_RECEIVE: 'customerReceive/UPADE_CUSTOMER_RECEIVE',
  DELETE_CUSTOMER_RECEIVE: 'customerReceive/DELETE_CUSTOMER_RECEIVE',

  SEARCH_CUSTOMER_RECEIVES: 'customerReceive/SEARCH_CUSTOMER_RECEIVES',

  CUSTOMER_RECEIVE_CREATED: 'customerReceive/CUSTOMER_RECEIVE_CREATED',
  CUSTOMER_RECEIVES_LOADED: 'customerReceive/CUSTOMER_RECEIVES_LOADED',
  CUSTOMER_RECEIVE_LOADED: 'customerReceive/CUSTOMER_RECEIVE_LOADED',
  CUSTOMER_RECEIVE_UPDATED: 'customerReceive/CUSTOMER_RECEIVE_UPDATED',
  CUSTOMER_RECEIVE_DELETED: 'customerReceive/CUSTOMER_RECEIVE_DELETED'
}

const initialState = {
  customerReceive: null,
  customerReceives: []
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_CUSTOMER_RECEIVE:
      return { ...state, customerReceive: action.payload }
    case actionTypes.FETCH_CUSTOMER_RECEIVES:
      return { ...state, customerReceives: [] }
    case actionTypes.FETCH_CUSTOMER_RECEIVE:
      return { ...state, customerReceive: null }
    case actionTypes.UPADE_CUSTOMER_RECEIVE:
      return { ...state, customerReceive: action.payload }
    case actionTypes.DELETE_CUSTOMER_RECEIVE:
      return state

    case actionTypes.SEARCH_CUSTOMER_RECEIVES:
      return {...state, customerReceives: null}

    case actionTypes.CUSTOMER_RECEIVE_CREATED:
      return { ...state, customerReceives: [...state.customerReceives, action.payload] }
    case actionTypes.CUSTOMER_RECEIVES_LOADED:
      return { ...state, customerReceives: action.payload }
    case actionTypes.CUSTOMER_RECEIVE_LOADED:
      return { ...state, customerReceive: action.payload }
    case actionTypes.CUSTOMER_RECEIVE_UPDATED:
      return {
        ...state,
        customerReceive: action.payload,
        customerReceives: state.customerReceives.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.CUSTOMER_RECEIVE_DELETED:
      return { ...state, customerReceives: state.customerReceives.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createCustomerReceive: (customerReceive, setSubmitting) => ({ type: actionTypes.CREATE_CUSTOMER_RECEIVE, payload: { customerReceive, setSubmitting } }),
  fetchCustomerReceives: () => ({ type: actionTypes.FETCH_CUSTOMER_RECEIVES }),
  fetchCustomerReceive: (id) => ({ type: actionTypes.FETCH_CUSTOMER_RECEIVE, payload: id }),
  updateCustomerReceive: (customerReceive, setSubmitting) => ({ type: actionTypes.UPADE_CUSTOMER_RECEIVE, payload: { customerReceive, setSubmitting } }),
  deleteCustomerReceive: id => ({ type: actionTypes.DELETE_CUSTOMER_RECEIVE, payload: id }),

  searchCustomerReceives: (values, setSubmitting) => ({ type: actionTypes.SEARCH_CUSTOMER_RECEIVES, payload: { values, setSubmitting } }),

  customerReceiveCreated: (customerReceive) => ({ type: actionTypes.CUSTOMER_RECEIVE_CREATED, payload: customerReceive }),
  customerReceivesLoaded: (customerReceives) => ({ type: actionTypes.CUSTOMER_RECEIVES_LOADED, payload: customerReceives }),
  customerReceiveLoaded: (customerReceive) => ({ type: actionTypes.CUSTOMER_RECEIVE_LOADED, payload: customerReceive }),
  customerReceiveUpdated: (customerReceive) => ({ type: actionTypes.CUSTOMER_RECEIVE_UPDATED, payload: customerReceive }),
  customerReceiveDeleted: id => ({ type: actionTypes.CUSTOMER_RECEIVE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_CUSTOMER_RECEIVE, function * createCustomerReceiveSaga (action) {
    const { customerReceive, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'customer_receives', customerReceive)
      yield put(actions.customerReceiveCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_CUSTOMER_RECEIVES, function * fetchCustomerReceivesSaga () {
    try {
      const result = yield call(apiClient.get, 'customer_receives')
      yield put(actions.customerReceivesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_CUSTOMER_RECEIVE, function * fetchCustomerReceiveSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `customer_receives/${id}`)
      yield put(actions.customerReceiveLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_CUSTOMER_RECEIVE, function * updateCustomerReceiveSaga (action) {
    const { customerReceive, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `customer_receives/${customerReceive.id}`, customerReceive)
      yield put(actions.customerReceiveUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_CUSTOMER_RECEIVE, function * deleteCustomerReceiveSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `customer_receives/${id}`)
      yield put(actions.customerReceiveDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.SEARCH_CUSTOMER_RECEIVES, function * searchCustomerReceivesSaga(action) {
    const { values, setSubmitting } = action.payload
    try{
      const result = yield call(apiClient.get, 'customer_receives', {params: {
        customer_code: !values.customer_code ? null : values.customer_code,
        customer_vat_no: !values.customer_vat_no ? null : values.customer_vat_no,
        customer_name: !values.customer_name ? null : values.customer_name
      }})
      yield put(actions.customerReceivesLoaded(result.data.data))
    } catch(e) {
      notifyError(e.response ? e.response.statusText: e.message)
    } finally {
      setSubmitting(false)
    }
  })
}
