import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_ERP_PRODUCT: 'erpProduct/CREATE_ERP_PRODUCT',
  FETCH_ERP_PRODUCTS: 'erpProduct/FETCH_ERP_PRODUCTS',
  FETCH_ERP_PRODUCT: 'erpProduct/FETCH_ERP_PRODUCT',
  UPADE_ERP_PRODUCT: 'erpProduct/UPADE_ERP_PRODUCT',
  DELETE_ERP_PRODUCT: 'erpProduct/DELETE_ERP_PRODUCT',

  ERP_PRODUCT_CREATED: 'erpProduct/ERP_PRODUCT_CREATED',
  ERP_PRODUCTS_LOADED: 'erpProduct/ERP_PRODUCTS_LOADED',
  ERP_PRODUCT_LOADED: 'erpProduct/ERP_PRODUCT_LOADED',
  ERP_PRODUCT_UPDATED: 'erpProduct/ERP_PRODUCT_UPDATED',
  ERP_PRODUCT_DELETED: 'erpProduct/ERP_PRODUCT_DELETED'
}

const initialState = {
  erpProduct: null,
  erpProducts: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ERP_PRODUCT:
      return { ...state, erpProduct: action.payload }
    case actionTypes.FETCH_ERP_PRODUCTS:
      return { ...state, erpProducts: null }
    case actionTypes.FETCH_ERP_PRODUCT:
      return { ...state, erpProduct: null }
    case actionTypes.UPADE_ERP_PRODUCT:
      return { ...state, erpProduct: action.payload }
    case actionTypes.DELETE_ERP_PRODUCT:
      return state

    case actionTypes.ERP_PRODUCT_CREATED:
      return { ...state, erpProducts: [...state.erpProducts, action.payload] }
    case actionTypes.ERP_PRODUCTS_LOADED:
      return { ...state, erpProducts: action.payload }
    case actionTypes.ERP_PRODUCT_LOADED:
      return { ...state, erpProduct: action.payload }
    case actionTypes.ERP_PRODUCT_UPDATED:
      return {
        ...state,
        erpProduct: action.payload,
        erpProducts: state.erpProducts.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ERP_PRODUCT_DELETED:
      return { ...state, erpProducts: state.erpProducts.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createErpProduct: (erpProduct, setSubmitting) => ({ type: actionTypes.CREATE_ERP_PRODUCT, payload: { erpProduct, setSubmitting } }),
  fetchErpProducts: () => ({ type: actionTypes.FETCH_ERP_PRODUCTS }),
  fetchErpProduct: (id) => ({ type: actionTypes.FETCH_ERP_PRODUCT, payload: id }),
  updateErpProduct: (erpProduct, setSubmitting) => ({ type: actionTypes.UPADE_ERP_PRODUCT, payload: { erpProduct, setSubmitting } }),
  deleteErpProduct: id => ({ type: actionTypes.DELETE_ERP_PRODUCT, payload: id }),
  erpProductCreated: (erpProduct) => ({ type: actionTypes.ERP_PRODUCT_CREATED, payload: erpProduct }),
  erpProductsLoaded: (erpProducts) => ({ type: actionTypes.ERP_PRODUCTS_LOADED, payload: erpProducts }),
  erpProductLoaded: (erpProduct) => ({ type: actionTypes.ERP_PRODUCT_LOADED, payload: erpProduct }),
  erpProductUpdated: (erpProduct) => ({ type: actionTypes.ERP_PRODUCT_UPDATED, payload: erpProduct }),
  erpProductDeleted: id => ({ type: actionTypes.ERP_PRODUCT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ERP_PRODUCT, function * createErpProductSaga (action) {
    const { erpProduct, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'erp_products', erpProduct)
      yield put(actions.erpProductCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ERP_PRODUCTS, function * fetchErpProductsSaga () {
    try {
      const result = yield call(apiClient.get, 'erp_products')
      yield put(actions.erpProductsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ERP_PRODUCT, function * fetchErpProductSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `erp_products/${id}`)
      yield put(actions.erpProductLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ERP_PRODUCT, function * updateErpProductSaga (action) {
    const { erpProduct, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `erp_products/${erpProduct.id}`, erpProduct)
      yield put(actions.erpProductUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ERP_PRODUCT, function * deleteErpProductSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `erp_products/${id}`)
      yield put(actions.erpProductDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
