import React from 'react'
import PropTypes from 'prop-types'
import './Toast.scss'
import { toastType } from '../../admin/constants'
import { _t } from '../sharedMethod'

const ToastCard = (props) => {
  const { type, text } = props
  const iconColor = type === toastType.ERROR
    ? 'toast-card__icon--error' : 'toast-card__icon--success'

  return (
    <div className='toast-card'>
      <div className={`toast-card__icon ${iconColor}`}>
        <i className={`fas ${type === toastType.ERROR ? 'fa-times-circle' : 'fa-check-circle'} `}/>
      </div>
      <div>
        <h5>
          {_t(`${type === toastType.ERROR
                            ? 'toast.error.message' : 'toast.success.message'}`)}
        </h5>
        <span>{text}</span>
      </div>
    </div>
  )
}

ToastCard.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  type: PropTypes.string,
  closeToast: PropTypes.func
}

export default ToastCard
