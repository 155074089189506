import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

const timeStampToDate = (timeStamp) => {
  let datetime = new Date()
  datetime.setTime(timeStamp * 1000);
  let year = datetime.getFullYear();
  let month = (datetime.getMonth() + 1) < 10 ? '0' + (datetime.getMonth() + 1) : (datetime.getMonth() + 1);
  let date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
  return year + "-" + month + "-" + date
}

// Actions
export const actionTypes = {
  CREATE_PROJECT: 'project/CREATE_PROJECT',
  FETCH_PROJECTS: 'project/FETCH_PROJECTS',
  FETCH_PROJECT: 'project/FETCH_PROJECT',
  UPADE_PROJECT: 'project/UPADE_PROJECT',
  DELETE_PROJECT: 'project/DELETE_PROJECT',

  PROJECT_CREATED: 'project/PROJECT_CREATED',
  PROJECTS_LOADED: 'project/PROJECTS_LOADED',
  PROJECT_LOADED: 'project/PROJECT_LOADED',
  PROJECT_UPDATED: 'project/PROJECT_UPDATED',
  PROJECT_DELETED: 'project/PROJECT_DELETED'
}

const initialState = {
  project: null,
  projects: []
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PROJECT:
      return { ...state, project: action.payload }
    case actionTypes.FETCH_PROJECTS:
      return { ...state, projects: [] }
    case actionTypes.FETCH_PROJECT:
      return { ...state, project: null }
    case actionTypes.UPADE_PROJECT:
      return { ...state, project: action.payload }
    case actionTypes.DELETE_PROJECT:
      return state

    case actionTypes.PROJECT_CREATED:
      return {...state, projects: [...state.projects, action.payload]}
    case actionTypes.PROJECTS_LOADED:
      return { ...state, projects: action.payload }
    case actionTypes.PROJECT_LOADED:
      const result = Object.assign({}, {...action.payload, void_date: timeStampToDate(action.payload.void_date)})
      return { ...state, project: result }
    case actionTypes.PROJECT_UPDATED:
      return {
        ...state,
        project: action.payload,
        projects: state.projects.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PROJECT_DELETED:
      return { ...state, projects: state.projects.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProject: (project, setSubmitting) => ({ type: actionTypes.CREATE_PROJECT, payload: { project, setSubmitting } }),
  fetchProjects: () => ({ type: actionTypes.FETCH_PROJECTS }),
  fetchProject: (id) => ({ type: actionTypes.FETCH_PROJECT, payload: id }),
  updateProject: (project, setSubmitting) => ({ type: actionTypes.UPADE_PROJECT, payload: { project, setSubmitting } }),
  deleteProject: id => ({ type: actionTypes.DELETE_PROJECT, payload: id }),
  projectCreated: (project) => ({ type: actionTypes.PROJECT_CREATED, payload: project }),
  projectsLoaded: (projects) => ({ type: actionTypes.PROJECTS_LOADED, payload: projects }),
  projectLoaded: (project) => ({ type: actionTypes.PROJECT_LOADED, payload: project }),
  projectUpdated: (project) => ({ type: actionTypes.PROJECT_UPDATED, payload: project }),
  projectDeleted: id => ({ type: actionTypes.PROJECT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PROJECT, function * createProjectSaga (action) {
    const { project, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'projects', project)
      yield put(actions.projectCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PROJECTS, function * fetchProjectsSaga () {
    try {
      const result = yield call(apiClient.get, 'projects')
      yield put(actions.projectsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PROJECT, function * fetchProjectSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `projects/${id}`)
      yield put(actions.projectLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PROJECT, function * updateProjectSaga (action) {
    const { project, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `projects/${project.id}`, project)
      yield put(actions.projectUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PROJECT, function * deleteProjectSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `projects/${id}`)
      yield put(actions.projectDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
