import { environment } from 'environment'
import { Routes } from 'constants/common'
import store from '../store/store'

export default {
  setupInterceptors: (axios, isFormData = false) => {
    const errorMessage = {
      TOKEN_NOT_PROVIDED: 'Token not provided',
      TOKEN_EXPIRED: 'Token has expired',
      TOKEN_INVALID: 'Could not decode token: Error while decoding to JSON: Syntax error',
      TOKEN_INVALID_SIGNATURE: 'Token Signature could not be verified.',
      MEMBER_OR_USER_DEACTIVATE: 'Your account is not active.'
    }

    axios.interceptors.request.use(config => {
      const {
        auth: { authToken }
      } = store.getState()

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      } else {
        if (!window.location.href.includes('login') && !window.location.href.includes('reset-password')) {
          window.location.href = environment.REACT_URL + '/#' + Routes.ADMIN_LOGIN
        }
      }
      if (isFormData) {
        config.headers['Content-Type'] = 'multipart/form-data'
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
    )
    axios.interceptors.response.use(
      response => successHandler(response),
      error => errorHandler(error)
    )
    const errorHandler = (error) => {
      if (error && (error.response.data.message === errorMessage.TOKEN_NOT_PROVIDED ||
                error.response.data.message === errorMessage.TOKEN_INVALID ||
                error.response.data.message === errorMessage.TOKEN_INVALID_SIGNATURE ||
                error.response.data.message === errorMessage.TOKEN_EXPIRED ||
                error.response.data.message === errorMessage.MEMBER_OR_USER_DEACTIVATE)
      ) {
        window.location.href = environment.REACT_URL + '/#' + Routes.ADMIN_LOGOUT
      }
      return Promise.reject({ ...error })
    }
    const successHandler = (response) => {
      return response
    }
  }
}
