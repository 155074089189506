import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { searchDefValue } from 'admin/pages/home/common/products/productDefaultValue'
import * as componentDuck from 'admin/store/ducks/productComponentItem.duck'
import * as bundleDuck from 'admin/store/ducks/productBundleItem.duck'
import * as packageDuck from 'admin/store/ducks/productPackage.duck'
import * as packageItemDuck from 'admin/store/ducks/productPackageItem.duck'
import * as comboDuck from 'admin/store/ducks/productCombo.duck'
import * as comboItemDuck from 'admin/store/ducks/productComboItem.duck'
import * as suiteDuck from 'admin/store/ducks/productSuite.duck'
import * as suiteFolderDuck from 'admin/store/ducks/productSuiteFolder.duck'
import * as subscriptionQuantityDuck from 'admin/store/ducks/subscriptionQuantity.duck'
import * as subscriptionPeriodDuck from 'admin/store/ducks/subscriptionPeriod.duck'
import * as subscriptionQuantityItemDuck from 'admin/store/ducks/subscriptionQuantityItem.duck'
import * as subscriptionPeriodItemDuck from 'admin/store/ducks/subscriptionPeriodItem.duck'
import { v4 as uuidv4 } from 'uuid'

// Actions
export const actionTypes = {
  CREATE_PRODUCT: 'product/CREATE_PRODUCT',
  FETCH_PRODUCTS: 'product/FETCH_PRODUCTS',
  FETCH_PRODUCT: 'product/FETCH_PRODUCT',
  FETCH_PRODUCT_COMPONENTS: 'product/FETCH_PRODUCT_COMPONENTS',
  FETCH_PRODUCT_BUNDLES: 'product/FETCH_PRODUCT_BUNDLES',
  FETCH_PRODUCT_PACKAGES: 'product/FETCH_PRODUCT_PACKAGES',
  FETCH_PRODUCT_PACKAGE_ITEMS: 'product/FETCH_PRODUCT_PACKAGE_ITEMS',
  FETCH_PRODUCT_COMBOS: 'product/FETCH_PRODUCT_COMBOS',
  FETCH_PRODUCT_COMBO_ITEMS: 'product/FETCH_PRODUCT_COMBO_ITEMS',

  FETCH_PRODUCT_SUITES: 'product/FETCH_PRODUCT_SUITES',
  FETCH_PRODUCT_SUBSCRIPTION_QUANTITIES: 'product/FETCH_PRODUCT_SUBSCRIPTION_QUANTITIES',
  FETCH_PRODUCT_SUBSCRIPTION_QUANTITY_ITEMS: 'product/FETCH_PRODUCT_SUBSCRIPTION_QUANTITY_ITEMS',
  FETCH_PRODUCT_SUBSCRIPTION_PERIODS: 'product/FETCH_PRODUCT_SUBSCRIPTION_PERIODS',
  FETCH_PRODUCT_SUBSCRIPTION_PERIOD_ITEMS: 'product/FETCH_PRODUCT_SUBSCRIPTION_PERIOD_ITEMS',

  FETCH_PRODUCT_SUITE_FOLDERS: 'product/FETCH_PRODUCT_SUITE_FOLDERS',

  UPADE_PRODUCT: 'product/UPADE_PRODUCT',
  DELETE_PRODUCT: 'product/DELETE_PRODUCT',

  PRODUCT_CREATED: 'product/PRODUCT_CREATED',
  PRODUCTS_LOADED: 'product/PRODUCTS_LOADED',
  PRODUCT_LOADED: 'product/PRODUCT_LOADED',
  PRODUCT_UPDATED: 'product/PRODUCT_UPDATED',
  PRODUCT_DELETED: 'product/PRODUCT_DELETED',

  SERACH_PRODUCTS: 'product/SERACH_PRODUCTS',
  SEARCH_PRODUCTS_LOADED: 'product/SEARCH_PRODUCTS_LOADED'
}

const initialState = {
  product: null,
  products: null,
  searchValues: null,
  searchProducts: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT:
      return { ...state, product: action.payload }
    case actionTypes.FETCH_PRODUCTS:
      return { ...state, products: null }
    case actionTypes.FETCH_PRODUCT:
      return { ...state, product: null }
    case actionTypes.UPADE_PRODUCT:
      return { ...state, product: action.payload }
    case actionTypes.DELETE_PRODUCT:
      return state
    case actionTypes.PRODUCTS_LOADED:
      return { ...state, products: action.payload }
    case actionTypes.PRODUCT_LOADED:
      return { ...state, product: action.payload }
    case actionTypes.PRODUCT_UPDATED:
      return {
        ...state,
        product: action.payload
        //    products: state.products.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_DELETED:
      return { ...state, products: state.products.filter(item => item.id !== action.payload) }

    case actionTypes.SERACH_PRODUCTS:
      return { ...state, searchValues: action.payload.values, searchProducts: [] }
    case actionTypes.SEARCH_PRODUCTS_LOADED:
      return { ...state, searchProducts: action.payload }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProduct: (product, setSubmitting, history) => ({ type: actionTypes.CREATE_PRODUCT, payload: { product, setSubmitting, history } }),
  fetchProducts: () => ({ type: actionTypes.FETCH_PRODUCTS }),
  fetchProduct: (id) => ({ type: actionTypes.FETCH_PRODUCT, payload: id }),
  fetchProductComponentItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_COMPONENTS, payload: id }),
  fetchProductBundleItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_BUNDLES, payload: id }),
  fetchProductPackages: () => ({ type: actionTypes.FETCH_PRODUCT_PACKAGES }),
  fetchProductPackageItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_PACKAGE_ITEMS, payload: id }),
  fetchProductCombos: () => ({ type: actionTypes.FETCH_PRODUCT_COMBOS }),
  fetchProductComboItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_COMBO_ITEMS, payload: id }),

  fetchProductSubscriptionQuantities: () => ({ type: actionTypes.FETCH_PRODUCT_SUBSCRIPTION_QUANTITIES }),
  fetchProductSubscriptionQuantityItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_SUBSCRIPTION_QUANTITY_ITEMS, payload: id }),

  fetchProductSubscriptionPeriods: () => ({ type: actionTypes.FETCH_PRODUCT_SUBSCRIPTION_PERIODS }),
  fetchProductSubscriptionPeriodItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_SUBSCRIPTION_PERIOD_ITEMS, payload: id }),

  fetchProductSuites: () => ({ type: actionTypes.FETCH_PRODUCT_SUITES }),
  fetchProductSuiteFolders: (id) => ({ type: actionTypes.FETCH_PRODUCT_SUITE_FOLDERS, payload: id }),
  updateProduct: (product, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT, payload: { product, setSubmitting } }),
  deleteProduct: id => ({ type: actionTypes.DELETE_PRODUCT, payload: id }),
  productCreated: (product) => ({ type: actionTypes.PRODUCT_CREATED, payload: product }),
  productsLoaded: (products) => ({ type: actionTypes.PRODUCTS_LOADED, payload: products }),
  productLoaded: (product) => ({ type: actionTypes.PRODUCT_LOADED, payload: product }),
  productUpdated: (product) => ({ type: actionTypes.PRODUCT_UPDATED, payload: product }),
  productDeleted: id => ({ type: actionTypes.PRODUCT_DELETED, payload: id }),

  searchProducts: (values, setSubmitting) => ({ type: actionTypes.SERACH_PRODUCTS, payload: { values, setSubmitting } }),
  searchProductsLoaded: (searchProducts) => ({ type: actionTypes.SEARCH_PRODUCTS_LOADED, payload: searchProducts })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT, function * createProductSaga (action) {
    const { product, setSubmitting, history } = action.payload
    try {
      yield call(apiClient.post, 'products', product)
      // yield put(actions.productCreated(result.data.data))
      history.push('/common/products')
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMPONENTS, function * fetchProductComponentsSaga (action) {
    try {
      if (action.payload > 0) {
        const result = yield call(apiClient.get, `products/${action.payload}/components`)
        if (result.data && result.data.data.length > 0) {
          yield put(componentDuck.actions.productComponentItemsLoaded(result.data.data))
        } else {
          yield put(componentDuck.actions.productComponentItemsLoaded([{}]))
        }
      } else {
        yield put(componentDuck.actions.productComponentItemsLoaded([{}]))
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_BUNDLES, function * fetchProductBundlesSaga (action) {
    try {
      if (action.payload > 0) {
        const result = yield call(apiClient.get, `products/${action.payload}/bundles`)
        if (result.data && result.data.data.length > 0) {
          yield put(bundleDuck.actions.productBundleItemsLoaded(result.data.data))
        } else {
          yield put(bundleDuck.actions.productBundleItemsLoaded([{}]))
        }
      } else {
        yield put(bundleDuck.actions.productBundleItemsLoaded([{}]))
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_PACKAGES, function * fetchProductBundlesSaga (action) {
    try {
      const result = yield call(apiClient.get, 'products/packages')
      yield put(packageDuck.actions.productPackagesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_PACKAGE_ITEMS, function * fetchProductBundlesSaga (action) {
    try {
      if (action.payload > 0) {
        const result = yield call(apiClient.get, `products/${action.payload}/package_items`)
        if (result.data && result.data.data.length > 0) {
          yield put(packageItemDuck.actions.productPackageItemsLoaded(result.data.data))
        } else {
          yield put(packageItemDuck.actions.productPackageItemsLoaded([{}]))
        }
      } else {
        yield put(packageItemDuck.actions.productPackageItemsLoaded([{}]))
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMBOS, function * fetchProductComboSaga (action) {
    try {
      const result = yield call(apiClient.get, 'products/combos')
      yield put(comboDuck.actions.productCombosLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMBO_ITEMS, function * fetchProductComboItemSaga (action) {
    try {
      if (action.payload > 0) {
        const result = yield call(apiClient.get, `products/${action.payload}/combo_items`)
        if (result.data && result.data.data.length > 0) {
          yield put(comboItemDuck.actions.productComboItemsLoaded(result.data.data))
        } else {
          yield put(comboItemDuck.actions.productComboItemsLoaded([{}]))
        }
      } else {
        yield put(comboItemDuck.actions.productComboItemsLoaded([{}]))
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUITES, function * fetchProductSuitesSaga (action) {
    try {
      const result = yield call(apiClient.get, 'products/suites')
      yield put(suiteDuck.actions.productSuitesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUITE_FOLDERS, function * fetchProductSuiteFoldersSaga (action) {
    try {
      const result = yield call(apiClient.get, `products/${action.payload}/suite_folders`)
      if (result.data && result.data.data.length > 0) {
        yield put(suiteFolderDuck.actions.productSuiteFoldersLoaded(result.data.data))
      } else {
        yield put(suiteFolderDuck.actions.productSuiteFoldersLoaded([{ position: 1, id: uuidv4() }]))
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUBSCRIPTION_QUANTITIES, function * fetchProductSubscriptionQuantitiesSaga (action) {
    try {
      const result = yield call(apiClient.get, 'products/subscription_quantities')
      yield put(subscriptionQuantityDuck.actions.subscriptionQuantitiesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUBSCRIPTION_QUANTITY_ITEMS, function * fetchProductSubscriptionQuantityItemsSaga (action) {
    try {
      const result = yield call(apiClient.get, `products/${action.payload}/subscription_quantity_items`)
      yield put(subscriptionQuantityItemDuck.actions.subscriptionQuantityItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUBSCRIPTION_PERIODS, function * fetchProductSubscriptionPeriodsSaga (action) {
    try {
      const result = yield call(apiClient.get, 'products/subscription_periods')
      yield put(subscriptionPeriodDuck.actions.subscriptionPeriodsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUBSCRIPTION_PERIOD_ITEMS, function * fetchProductSubscriptionPeriodItmesSaga (action) {
    try {
      const result = yield call(apiClient.get, `products/${action.payload}/subscription_period_items`)
      yield put(subscriptionPeriodItemDuck.actions.subscriptionPeriodItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCTS, function * fetchProductsSaga () {
    try {
      const result = yield call(apiClient.get, 'products')
      yield put(actions.productsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT, function * fetchProductSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `products/${id}`)
      yield put(actions.productLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT, function * updateProductSaga (action) {
    const { product, setSubmitting } = action.payload
    console.log(product)
    try {
      const result = yield call(apiClient.put, `products/${product.id}`, product)
      yield put(actions.productUpdated(result.data.data))
      yield put(actions.fetchProductComponentItems(product.id))
      yield put(actions.fetchProductBundleItems(product.id))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT, function * deleteProductSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `products/${id}`)
      yield put(actions.productDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.SERACH_PRODUCTS, function * searchProductsSaga (action) {
    const { values, setSubmitting } = action.payload
    try {
      if (!Object.values(values).join('')) {
        yield put(actions.searchProductsLoaded(null))
      } else {
        const result = yield call(apiClient.get, 'products', {
          params: {
            product_major_category_id: !values.product_major_category_id ? null : values.product_major_category_id,
            product_medium_category_id: !values.product_medium_category_id ? null : values.product_medium_category_id,
            product_minor_category_id: !values.product_minor_category_id ? null : values.product_minor_category_id,
            salable: !values.salable ? null : values.salable,
            singleable: !values.singleable ? null : values.singleable,
            bundleable: !values.bundleable ? null : values.bundleable,
            suiteable: !values.suiteable ? null : values.suiteable,
            stayinable: !values.stayinable ? null : values.stayinable,
            delivery_able: !values.delivery_able ? null : values.delivery_able,
            togo_able: !values.togo_able ? null : values.togo_able,
            pointable: !values.pointable ? null : values.pointable,
            subscriptable: !values.subscriptable ? null : values.subscriptable,
            purchasable: !values.purchasable ? null : values.purchasable
          }
        })
        yield put(actions.searchProductsLoaded(result.data.data))
      }
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      if (setSubmitting) {
        setSubmitting(false)
      }
    }
  })
}
