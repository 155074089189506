import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

const timeStampToDate = (timeStamp) => {
  let datetime = new Date()
  datetime.setTime(timeStamp * 1000);
  let year = datetime.getFullYear();
  let month = (datetime.getMonth() + 1) < 10 ? '0' + (datetime.getMonth() + 1) : (datetime.getMonth() + 1);
  let date = datetime.getDate() < 10 ? '0' : datetime.getDate();
  return year + "-" + month + "-" + date
}

// Actions
export const actionTypes = {
  CREATE_CUSTOMER: 'customer/CREATE_CUSTOMER',
  FETCH_CUSTOMERS: 'customer/FETCH_CUSTOMERS',
  FETCH_CUSTOMER: 'customer/FETCH_CUSTOMER',
  UPADE_CUSTOMER: 'customer/UPADE_CUSTOMER',
  DELETE_CUSTOMER: 'customer/DELETE_CUSTOMER',

  SEARCH_CUSTOMERS: 'customer/SEARCH_CUSTOMERS',

  CUSTOMER_CREATED: 'customer/CUSTOMER_CREATED',
  CUSTOMERS_LOADED: 'customer/CUSTOMERS_LOADED',
  CUSTOMER_LOADED: 'customer/CUSTOMER_LOADED',
  CUSTOMER_UPDATED: 'customer/CUSTOMER_UPDATED',
  CUSTOMER_DELETED: 'customer/CUSTOMER_DELETED'
}

const initialState = {
  customer: null,
  customers: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_CUSTOMER:
      return { ...state, customer: action.payload }
    case actionTypes.FETCH_CUSTOMERS:
      return { ...state, customers: null }
    case actionTypes.FETCH_CUSTOMER:
      return { ...state, customer: null }
    case actionTypes.UPADE_CUSTOMER:
      return { ...state, customer: action.payload }
    case actionTypes.DELETE_CUSTOMER:
      return state

    case actionTypes.SEARCH_CUSTOMERS:
      return {...state, customers: null} 

    case actionTypes.CUSTOMER_CREATED:
      return { ...state, customers: [...state.customers, action.payload] }
    case actionTypes.CUSTOMERS_LOADED:
      return { ...state, customers: action.payload }
    case actionTypes.CUSTOMER_LOADED:
      const result = Object.assign({}, {...action.payload, last_sale_date: timeStampToDate(action.payload.last_sale_date), 
                                                           void_date: timeStampToDate(action.payload.void_date)})
      return { ...state, customer: result }
    case actionTypes.CUSTOMER_UPDATED:
      return {
        ...state,
        customer: action.payload,
        customers: state.customers.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.CUSTOMER_DELETED:
      return { ...state, customers: state.customers.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createCustomer: (customer, setSubmitting) => ({ type: actionTypes.CREATE_CUSTOMER, payload: { customer, setSubmitting } }),
  fetchCustomers: () => ({ type: actionTypes.FETCH_CUSTOMERS }),
  fetchCustomer: (id) => ({ type: actionTypes.FETCH_CUSTOMER, payload: id }),
  updateCustomer: (customer, setSubmitting) => ({ type: actionTypes.UPADE_CUSTOMER, payload: { customer, setSubmitting } }),
  deleteCustomer: id => ({ type: actionTypes.DELETE_CUSTOMER, payload: id }),

  searchCustomers: (values, setSubmitting) => ({ type: actionTypes.SEARCH_CUSTOMERS, payload: { values, setSubmitting } }),

  customerCreated: (customer) => ({ type: actionTypes.CUSTOMER_CREATED, payload: customer }),
  customersLoaded: (customers) => ({ type: actionTypes.CUSTOMERS_LOADED, payload: customers }),
  customerLoaded: (customer) => ({ type: actionTypes.CUSTOMER_LOADED, payload: customer }),
  customerUpdated: (customer) => ({ type: actionTypes.CUSTOMER_UPDATED, payload: customer }),
  customerDeleted: id => ({ type: actionTypes.CUSTOMER_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_CUSTOMER, function * createCustomerSaga (action) {
    const { customer, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'customers', customer)
      yield put(actions.customerCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_CUSTOMERS, function * fetchCustomersSaga () {
    try {
      const result = yield call(apiClient.get, 'customers')
      yield put(actions.customersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_CUSTOMER, function * fetchCustomerSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `customers/${id}`)
      yield put(actions.customerLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_CUSTOMER, function * updateCustomerSaga (action) {
    const { customer, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `customers/${customer.id}`, customer)
      yield put(actions.customerUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_CUSTOMER, function * deleteCustomerSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `customers/${id}`)
      yield put(actions.customerDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

   yield takeLatest(actionTypes.SEARCH_CUSTOMERS, function * searchCustomersSaga(action) {
    const { values, setSubmitting } = action.payload
    try{
      const result = yield call(apiClient.get, 'customers', {params: {
        code: !values.code ? null : values.code,
        name: !values.name ? null : values.name,
        tel: !values.tel ? null : values.tel,
        vat_no: !values.vat_no ? null : values.vat_no
      }})
      yield put(actions.customersLoaded(result.data.data))
    } catch(e) {
      notifyError(e.response ? e.response.statusText: e.message)
    } finally {
      setSubmitting(false)
    }
  })
}
