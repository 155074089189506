import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_MAJOR_CATEGORY: 'productMajorCategory/CREATE_PRODUCT_MAJOR_CATEGORY',
  FETCH_PRODUCT_MAJOR_CATEGORIES: 'productMajorCategory/FETCH_PRODUCT_MAJOR_CATEGORIES',
  FETCH_PRODUCT_MAJOR_CATEGORY: 'productMajorCategory/FETCH_PRODUCT_MAJOR_CATEGORY',
  UPADE_PRODUCT_MAJOR_CATEGORY: 'productMajorCategory/UPADE_PRODUCT_MAJOR_CATEGORY',
  DELETE_PRODUCT_MAJOR_CATEGORY: 'productMajorCategory/DELETE_PRODUCT_MAJOR_CATEGORY',

  PRODUCT_MAJOR_CATEGORY_CREATED: 'productMajorCategory/PRODUCT_MAJOR_CATEGORY_CREATED',
  PRODUCT_MAJOR_CATEGORIES_LOADED: 'productMajorCategory/PRODUCT_MAJOR_CATEGORIES_LOADED',
  PRODUCT_MAJOR_CATEGORY_LOADED: 'productMajorCategory/PRODUCT_MAJOR_CATEGORY_LOADED',
  PRODUCT_MAJOR_CATEGORY_UPDATED: 'productMajorCategory/PRODUCT_MAJOR_CATEGORY_UPDATED',
  PRODUCT_MAJOR_CATEGORY_DELETED: 'productMajorCategory/PRODUCT_MAJOR_CATEGORY_DELETED'
}

const initialState = {
  productMajorCategory: null,
  productMajorCategories: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_MAJOR_CATEGORY:
      return { ...state, productMajorCategory: action.payload }
    case actionTypes.FETCH_PRODUCT_MAJOR_CATEGORIES:
      return { ...state, productMajorCategories: null }
    case actionTypes.FETCH_PRODUCT_MAJOR_CATEGORY:
      return { ...state, productMajorCategory: null }
    case actionTypes.UPADE_PRODUCT_MAJOR_CATEGORY:
      return { ...state, productMajorCategory: action.payload }
    case actionTypes.DELETE_PRODUCT_MAJOR_CATEGORY:
      return state

    case actionTypes.PRODUCT_MAJOR_CATEGORY_CREATED:
      return { ...state, productMajorCategories: [...state.productMajorCategories, action.payload] }
    case actionTypes.PRODUCT_MAJOR_CATEGORIES_LOADED:
      return { ...state, productMajorCategories: action.payload }
    case actionTypes.PRODUCT_MAJOR_CATEGORY_LOADED:
      return { ...state, productMajorCategory: action.payload }
    case actionTypes.PRODUCT_MAJOR_CATEGORY_UPDATED:
      return {
        ...state,
        productMajorCategory: action.payload,
        productMajorCategories: state.productMajorCategories.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_MAJOR_CATEGORY_DELETED:
      return { ...state, productMajorCategories: state.productMajorCategories.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductMajorCategory: (productMajorCategory, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_MAJOR_CATEGORY, payload: { productMajorCategory, setSubmitting } }),
  fetchProductMajorCategories: () => ({ type: actionTypes.FETCH_PRODUCT_MAJOR_CATEGORIES }),
  fetchProductMajorCategory: (id) => ({ type: actionTypes.FETCH_PRODUCT_MAJOR_CATEGORY, payload: id }),
  updateProductMajorCategory: (productMajorCategory, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_MAJOR_CATEGORY, payload: { productMajorCategory, setSubmitting } }),
  deleteProductMajorCategory: id => ({ type: actionTypes.DELETE_PRODUCT_MAJOR_CATEGORY, payload: id }),
  productMajorCategoryCreated: (productMajorCategory) => ({ type: actionTypes.PRODUCT_MAJOR_CATEGORY_CREATED, payload: productMajorCategory }),
  productMajorCategoriesLoaded: (productMajorCategories) => ({ type: actionTypes.PRODUCT_MAJOR_CATEGORIES_LOADED, payload: productMajorCategories }),
  productMajorCategoryLoaded: (productMajorCategory) => ({ type: actionTypes.PRODUCT_MAJOR_CATEGORY_LOADED, payload: productMajorCategory }),
  productMajorCategoryUpdated: (productMajorCategory) => ({ type: actionTypes.PRODUCT_MAJOR_CATEGORY_UPDATED, payload: productMajorCategory }),
  productMajorCategoryDeleted: id => ({ type: actionTypes.PRODUCT_MAJOR_CATEGORY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_MAJOR_CATEGORY, function * createProductMajorCategorySaga (action) {
    const { productMajorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_major_categories', productMajorCategory)
      yield put(actions.productMajorCategoryCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_MAJOR_CATEGORIES, function * fetchProductMajorCategoriesSaga () {
    try {
      const result = yield call(apiClient.get, 'product_major_categories')
      yield put(actions.productMajorCategoriesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_MAJOR_CATEGORY, function * fetchProductMajorCategorySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_major_categories/${id}`)
      yield put(actions.productMajorCategoryLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_MAJOR_CATEGORY, function * updateProductMajorCategorySaga (action) {
    const { productMajorCategory, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_major_categories/${productMajorCategory.id}`, productMajorCategory)
      yield put(actions.productMajorCategoryUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_MAJOR_CATEGORY, function * deleteProductMajorCategorySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_major_categories/${id}`)
      yield put(actions.productMajorCategoryDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
