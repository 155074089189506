import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SUBSCRIPTION_PERIOD: 'subscriptionPeriod/CREATE_SUBSCRIPTION_PERIOD',
  FETCH_SUBSCRIPTION_PERIODS: 'subscriptionPeriod/FETCH_SUBSCRIPTION_PERIODS',
  FETCH_SUBSCRIPTION_PERIOD: 'subscriptionPeriod/FETCH_SUBSCRIPTION_PERIOD',
  UPADE_SUBSCRIPTION_PERIOD: 'subscriptionPeriod/UPADE_SUBSCRIPTION_PERIOD',
  DELETE_SUBSCRIPTION_PERIOD: 'subscriptionPeriod/DELETE_SUBSCRIPTION_PERIOD',

  SUBSCRIPTION_PERIOD_CREATED: 'subscriptionPeriod/SUBSCRIPTION_PERIOD_CREATED',
  SUBSCRIPTION_PERIODS_LOADED: 'subscriptionPeriod/SUBSCRIPTION_PERIODS_LOADED',
  SUBSCRIPTION_PERIOD_LOADED: 'subscriptionPeriod/SUBSCRIPTION_PERIOD_LOADED',
  SUBSCRIPTION_PERIOD_UPDATED: 'subscriptionPeriod/SUBSCRIPTION_PERIOD_UPDATED',
  SUBSCRIPTION_PERIOD_DELETED: 'subscriptionPeriod/SUBSCRIPTION_PERIOD_DELETED'
}

const initialState = {
  subscriptionPeriod: null,
  subscriptionPeriods: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SUBSCRIPTION_PERIOD:
      return { ...state, subscriptionPeriod: action.payload }
    case actionTypes.FETCH_SUBSCRIPTION_PERIODS:
      return { ...state, subscriptionPeriods: null }
    case actionTypes.FETCH_SUBSCRIPTION_PERIOD:
      return { ...state, subscriptionPeriod: null }
    case actionTypes.UPADE_SUBSCRIPTION_PERIOD:
      return { ...state, subscriptionPeriod: action.payload }
    case actionTypes.DELETE_SUBSCRIPTION_PERIOD:
      return state

    case actionTypes.SUBSCRIPTION_PERIOD_CREATED:
      return { ...state, subscriptionPeriods: [...state.subscriptionPeriods, action.payload] }
    case actionTypes.SUBSCRIPTION_PERIODS_LOADED:
      return { ...state, subscriptionPeriods: action.payload }
    case actionTypes.SUBSCRIPTION_PERIOD_LOADED:
      return { ...state, subscriptionPeriod: action.payload }
    case actionTypes.SUBSCRIPTION_PERIOD_UPDATED:
      return {
        ...state,
        subscriptionPeriod: action.payload,
        subscriptionPeriods: state.subscriptionPeriods.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SUBSCRIPTION_PERIOD_DELETED:
      return { ...state, subscriptionPeriods: state.subscriptionPeriods.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSubscriptionPeriod: (subscriptionPeriod, setSubmitting) => ({ type: actionTypes.CREATE_SUBSCRIPTION_PERIOD, payload: { subscriptionPeriod, setSubmitting } }),
  fetchSubscriptionPeriods: () => ({ type: actionTypes.FETCH_SUBSCRIPTION_PERIODS }),
  fetchSubscriptionPeriod: (id) => ({ type: actionTypes.FETCH_SUBSCRIPTION_PERIOD, payload: id }),
  updateSubscriptionPeriod: (subscriptionPeriod, setSubmitting) => ({ type: actionTypes.UPADE_SUBSCRIPTION_PERIOD, payload: { subscriptionPeriod, setSubmitting } }),
  deleteSubscriptionPeriod: id => ({ type: actionTypes.DELETE_SUBSCRIPTION_PERIOD, payload: id }),
  subscriptionPeriodCreated: (subscriptionPeriod) => ({ type: actionTypes.SUBSCRIPTION_PERIOD_CREATED, payload: subscriptionPeriod }),
  subscriptionPeriodsLoaded: (subscriptionPeriods) => ({ type: actionTypes.SUBSCRIPTION_PERIODS_LOADED, payload: subscriptionPeriods }),
  subscriptionPeriodLoaded: (subscriptionPeriod) => ({ type: actionTypes.SUBSCRIPTION_PERIOD_LOADED, payload: subscriptionPeriod }),
  subscriptionPeriodUpdated: (subscriptionPeriod) => ({ type: actionTypes.SUBSCRIPTION_PERIOD_UPDATED, payload: subscriptionPeriod }),
  subscriptionPeriodDeleted: id => ({ type: actionTypes.SUBSCRIPTION_PERIOD_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SUBSCRIPTION_PERIOD, function * createSubscriptionPeriodSaga (action) {
    const { subscriptionPeriod, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'subscription_periods', subscriptionPeriod)
      yield put(actions.subscriptionPeriodCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_PERIODS, function * fetchSubscriptionPeriodsSaga () {
    try {
      const result = yield call(apiClient.get, 'subscription_periods')
      yield put(actions.subscriptionPeriodsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_PERIOD, function * fetchSubscriptionPeriodSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `products/${id}`)
      yield put(actions.subscriptionPeriodLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SUBSCRIPTION_PERIOD, function * updateSubscriptionPeriodSaga (action) {
    const { subscriptionPeriod, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `subscription_periods/${subscriptionPeriod.id}`, subscriptionPeriod)
      yield put(actions.subscriptionPeriodUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SUBSCRIPTION_PERIOD, function * deleteSubscriptionPeriodSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `subscription_periods/${id}`)
      yield put(actions.subscriptionPeriodDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
