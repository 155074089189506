import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_UNIT: 'productUnit/CREATE_PRODUCT_UNIT',
  FETCH_PRODUCT_UNITS: 'productUnit/FETCH_PRODUCT_UNITS',
  FETCH_PRODUCT_UNIT: 'productUnit/FETCH_PRODUCT_UNIT',
  UPADE_PRODUCT_UNIT: 'productUnit/UPADE_PRODUCT_UNIT',
  DELETE_PRODUCT_UNIT: 'productUnit/DELETE_PRODUCT_UNIT',

  PRODUCT_UNIT_CREATED: 'productUnit/PRODUCT_UNIT_CREATED',
  PRODUCT_UNITS_LOADED: 'productUnit/PRODUCT_UNITS_LOADED',
  PRODUCT_UNIT_LOADED: 'productUnit/PRODUCT_UNIT_LOADED',
  PRODUCT_UNIT_UPDATED: 'productUnit/PRODUCT_UNIT_UPDATED',
  PRODUCT_UNIT_DELETED: 'productUnit/PRODUCT_UNIT_DELETED'
}

const initialState = {
  productUnit: null,
  productUnits: []
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_UNIT:
      return { ...state, productUnit: action.payload }
    case actionTypes.FETCH_PRODUCT_UNITS:
      return { ...state, productUnits: [] }
    case actionTypes.FETCH_PRODUCT_UNIT:
      return { ...state, productUnit: null }
    case actionTypes.UPADE_PRODUCT_UNIT:
      return { ...state, productUnit: action.payload }
    case actionTypes.DELETE_PRODUCT_UNIT:
      return state

    case actionTypes.PRODUCT_UNIT_CREATED:
      return { ...state, productUnits: [...state.productUnits, action.payload] }
    case actionTypes.PRODUCT_UNITS_LOADED:
      return { ...state, productUnits: action.payload }
    case actionTypes.PRODUCT_UNIT_LOADED:
      return { ...state, productUnit: action.payload }
    case actionTypes.PRODUCT_UNIT_UPDATED:
      return {
        ...state,
        productUnit: action.payload,
        productUnits: state.productUnits.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_UNIT_DELETED:
      return { ...state, productUnits: state.productUnits.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductUnit: (productUnit, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_UNIT, payload: { productUnit, setSubmitting } }),
  fetchProductUnits: () => ({ type: actionTypes.FETCH_PRODUCT_UNITS }),
  fetchProductUnit: (id) => ({ type: actionTypes.FETCH_PRODUCT_UNIT, payload: id }),
  updateProductUnit: (productUnit, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_UNIT, payload: { productUnit, setSubmitting } }),
  deleteProductUnit: id => ({ type: actionTypes.DELETE_PRODUCT_UNIT, payload: id }),
  productUnitCreated: (productUnit) => ({ type: actionTypes.PRODUCT_UNIT_CREATED, payload: productUnit }),
  productUnitsLoaded: (productUnits) => ({ type: actionTypes.PRODUCT_UNITS_LOADED, payload: productUnits }),
  productUnitLoaded: (productUnit) => ({ type: actionTypes.PRODUCT_UNIT_LOADED, payload: productUnit }),
  productUnitUpdated: (productUnit) => ({ type: actionTypes.PRODUCT_UNIT_UPDATED, payload: productUnit }),
  productUnitDeleted: id => ({ type: actionTypes.PRODUCT_UNIT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_UNIT, function * createProductUnitSaga (action) {
    const { productUnit, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_units', productUnit)
       yield put(actions.productUnitCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_UNITS, function * fetchProductUnitsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_units')
      yield put(actions.productUnitsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_UNIT, function * fetchProductUnitSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_units/${id}`)
      yield put(actions.productUnitLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_UNIT, function * updateProductUnitSaga (action) {
    const { productUnit, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_units/${productUnit.id}`, productUnit)
      yield put(actions.productUnitUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_UNIT, function * deleteProductUnitSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_units/${id}`)
      yield put(actions.productUnitDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
