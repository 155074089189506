import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PERMISSION: 'permission/CREATE_PERMISSION',
  FETCH_PERMISSIONS: 'permission/FETCH_PERMISSIONS',
  FETCH_PERMISSION: 'permission/FETCH_PERMISSION',
  UPADE_PERMISSION: 'permission/UPADE_PERMISSION',
  DELETE_PERMISSION: 'permission/DELETE_PERMISSION',

  PERMISSION_CREATED: 'permission/PERMISSION_CREATED',
  PERMISSIONS_LOADED: 'permission/PERMISSIONS_LOADED',
  PERMISSION_LOADED: 'permission/PERMISSION_LOADED',
  PERMISSION_UPDATED: 'permission/PERMISSION_UPDATED',
  PERMISSION_DELETED: 'permission/PERMISSION_DELETED'
}

const initialState = {
  permission: null,
  permissions: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PERMISSION:
      return { ...state, permission: action.payload }
    case actionTypes.FETCH_PERMISSIONS:
      return { ...state, permissions: null }
    case actionTypes.FETCH_PERMISSION:
      return { ...state, permission: null }
    case actionTypes.UPADE_PERMISSION:
      return { ...state, permission: action.payload }
    case actionTypes.DELETE_PERMISSION:
      return state

    case actionTypes.PERMISSION_CREATED:
      return { ...state, permissions: [...state.permissions, action.payload] }
    case actionTypes.PERMISSIONS_LOADED:
      return { ...state, permissions: action.payload }
    case actionTypes.PERMISSION_LOADED:
      return { ...state, permission: action.payload }
    case actionTypes.PERMISSION_UPDATED:
      return {
        ...state,
        permission: action.payload,
        permissions: state.permissions.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PERMISSION_DELETED:
      return { ...state, permissions: state.permissions.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createPermission: (permission, setSubmitting) => ({ type: actionTypes.CREATE_PERMISSION, payload: { permission, setSubmitting } }),
  fetchPermissions: () => ({ type: actionTypes.FETCH_PERMISSIONS }),
  fetchPermission: (id) => ({ type: actionTypes.FETCH_PERMISSION, payload: id }),
  updatePermission: (permission, setSubmitting) => ({ type: actionTypes.UPADE_PERMISSION, payload: { permission, setSubmitting } }),
  deletePermission: id => ({ type: actionTypes.DELETE_PERMISSION, payload: id }),
  permissionCreated: (permission) => ({ type: actionTypes.PERMISSION_CREATED, payload: permission }),
  permissionsLoaded: (permissions) => ({ type: actionTypes.PERMISSIONS_LOADED, payload: permissions }),
  permissionLoaded: (permission) => ({ type: actionTypes.PERMISSION_LOADED, payload: permission }),
  permissionUpdated: (permission) => ({ type: actionTypes.PERMISSION_UPDATED, payload: permission }),
  permissionDeleted: id => ({ type: actionTypes.PERMISSION_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PERMISSION, function * createPermissionSaga (action) {
    const { permission, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'permissions', permission)
      yield put(actions.permissionCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PERMISSIONS, function * fetchPermissionsSaga () {
    try {
      const result = yield call(apiClient.get, 'permissions')
      yield put(actions.permissionsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PERMISSION, function * fetchPermissionSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `permissions/${id}`)
      yield put(actions.permissionLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PERMISSION, function * updatePermissionSaga (action) {
    const { permission, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `permissions/${permission.id}`, permission)
      yield put(actions.permissionUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PERMISSION, function * deletePermissionSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `permissions/${id}`)
      yield put(actions.permissionDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
