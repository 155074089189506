export default {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: [
      {
        title: 'Home',
        root: true,
        icon: 'flaticon2-architecture-and-city',
        page: 'home',
        translate: 'menu.home.title',
        bullet: 'line'
      },
      {
        title: 'Dashboard',
        root: true,
        icon: 'flaticon-line-graph',
        page: 'dashboard',
        translate: 'menu.dashboard.title',
        bullet: 'line'
      },
      // {
      //   title: 'Demo',
      //   root: true,
      //   icon: 'flaticon2-start-up',
      //   translate: 'menu.demo.title',
      //   bullet: 'line',
      //   submenu: [
      //     {
      //       title: 'Demo List',
      //       bullet: 'line',
      //       page: 'demo/list',
      //       translate: 'menu.demo.list.title'
      //     },
      //     {
      //       title: 'Demo Report',
      //       bullet: 'line',
      //       page: 'demo/Report',
      //       translate: 'menu.demo.report.title'
      //     },
      //     {
      //       title: 'Demo Create',
      //       bullet: 'line',
      //       page: 'demo/new',
      //       translate: 'menu.demo.create.title',
      //       hidden: true // crud title and breadcrums
      //     },
      //     {
      //       title: 'Demo Edit',
      //       bullet: 'line',
      //       page: 'demo/edit',
      //       translate: 'menu.demo.edit.title',
      //       hidden: true // crud title and breadcrums
      //     }
      //     // Default menu generated by battlecry
      //   ]
      // },
      { section: 'Main' },
      {
        title: 'Sale Management',
        root: true,
        bullet: 'line',
        icon: 'flaticon2-browser-2',
        translate: 'menu.sale.data.management.title',
        submenu: [
          {
            title: 'PosSale',
            page: 'common/pos_sales',
            translate: 'menu.common.pos_sale.title'
          },
          {
            title: 'PosSale Create',
            page: 'common/pos_sales/:id/new',
            translate: 'menu.common.pos_sale.create.title',
            hidden: true
          },
          {
            title: 'PosSale Edit',
            page: 'common/pos_sales/:id/edit',
            translate: 'menu.common.pos_sale.edit.title',
            hidden: true
          }
        ]
      },
      {
        title: 'Base Data Management',
        root: true,
        bullet: 'line',
        icon: 'flaticon2-browser-2',
        translate: 'menu.base.data.title',
        submenu: [
          {
            title: 'Organization Management',
            bullet: 'line',
            translate: 'menu.base.organization.title',
            submenu: [

              {
                title: 'Company',
                page: 'common/companies',
                translate: 'menu.common.company.title'
              },
              {
                title: 'Department',
                page: 'common/departments',
                translate: 'menu.common.department.title'
              },
              {
                title: 'Department Create',
                page: 'common/departments/:id/new',
                translate: 'menu.common.department.create.title',
                hidden: true
              },
              {
                title: 'Department Edit',
                page: 'common/departments/:id/edit',
                translate: 'menu.common.department.edit.title',
                hidden: true
              },
              {
                title: 'Store',
                page: 'common/stores',
                translate: 'menu.common.store.title'
              },
              {
                title: 'Store Create',
                page: 'common/stores/:id/new',
                translate: 'menu.common.store.create.title',
                hidden: true
              },
              {
                title: 'Store Edit',
                page: 'common/stores/:id/edit',
                translate: 'menu.common.store.edit.title',
                hidden: true
              },
              {
                title: 'PosFloor',
                page: 'common/pos_floors',
                translate: 'menu.common.pos_floor.title'
              },
              {
                title: 'PosFloor Create',
                page: 'common/pos_floors/:id/new',
                translate: 'menu.common.pos_floor.create.title',
                hidden: true
              },
              {
                title: 'PosFloor Edit',
                page: 'common/pos_floors/:id/edit',
                translate: 'menu.common.pos_floor.edit.title',
                hidden: true
              },
              {
                title: 'PosFloor Layout',
                page: 'common/pos_floors/:id/layout',
                translate: 'menu.common.pos_floor.layout.title',
                hidden: true
              },
              {
                title: 'PosTable',
                page: 'common/pos_tables',
                translate: 'menu.common.pos_table.title'
              },
              {
                title: 'PosTable Create',
                page: 'common/pos_tables/:id/new',
                translate: 'menu.common.pos_table.create.title',
                hidden: true
              },
              {
                title: 'PosTable Edit',
                page: 'common/pos_tables/:id/edit',
                translate: 'menu.common.pos_table.edit.title',
                hidden: true
              },
              {
                title: 'Service',
                page: 'common/services',
                translate: 'menu.common.service.title'
              },
              {
                title: 'Service Create',
                page: 'common/services/:id/new',
                translate: 'menu.common.service.create.title',
                hidden: true
              },
              {
                title: 'Service Edit',
                page: 'common/services/:id/edit',
                translate: 'menu.common.service.edit.title',
                hidden: true
              },
              {
                title: 'Branch',
                page: 'common/branches',
                translate: 'menu.common.branch.title'
              },
              {
                title: 'Branch Create',
                page: 'common/branches/:id/new',
                translate: 'menu.common.branch.create.title',
                hidden: true
              },
              {
                title: 'Branch Edit',
                page: 'common/branches/:id/edit',
                translate: 'menu.common.branch.edit.title',
                hidden: true
              }
            ]
          },
          {
            title: 'TfaiProject',
            page: 'common/tfai_projects',
            translate: 'menu.common.tfai_project.title'
          },
          {
            title: 'TfaiProject Create',
            page: 'common/tfai_projects/:id/new',
            translate: 'menu.common.tfai_project.create.title',
            hidden: true
          },
          {
            title: 'TfaiProject Edit',
            page: 'common/tfai_projects/:id/edit',
            translate: 'menu.common.tfai_project.edit.title',
            hidden: true
          },
          {
            title: 'TfaiSaleDepartment',
            page: 'common/tfai_sale_departments',
            translate: 'menu.common.tfai_sale_department.title'
          },
          {
            title: 'TfaiSaleDepartment Create',
            page: 'common/tfai_sale_departments/:id/new',
            translate: 'menu.common.tfai_sale_department.create.title',
            hidden: true
          },
          {
            title: 'TfaiSaleDepartment Edit',
            page: 'common/tfai_sale_departments/:id/edit',
            translate: 'menu.common.tfai_sale_department.edit.title',
            hidden: true
          },
          {
            title: 'TfaiDepartment',
            page: 'common/tfai_departments',
            translate: 'menu.common.tfai_department.title'
          },
          {
            title: 'TfaiDepartment Create',
            page: 'common/tfai_departments/:id/new',
            translate: 'menu.common.tfai_department.create.title',
            hidden: true
          },
          {
            title: 'TfaiDepartment Edit',
            page: 'common/tfai_departments/:id/edit',
            translate: 'menu.common.tfai_department.edit.title',
            hidden: true
          },
          {
            title: 'TfaiAccountTitle',
            page: 'common/tfai_account_titles',
            translate: 'menu.common.tfai_account_title.title'
          },
          {
            title: 'TfaiAccountTitle Create',
            page: 'common/tfai_account_titles/:id/new',
            translate: 'menu.common.tfai_account_title.create.title',
            hidden: true
          },
          {
            title: 'TfaiAccountTitle Edit',
            page: 'common/tfai_account_titles/:id/edit',
            translate: 'menu.common.tfai_account_title.edit.title',
            hidden: true
          },
          {
            title: 'TfaiProduct',
            page: 'common/tfai_products',
            translate: 'menu.common.tfai_product.title'
          },
          {
            title: 'TfaiProduct Create',
            page: 'common/tfai_products/:id/new',
            translate: 'menu.common.tfai_product.create.title',
            hidden: true
          },
          {
            title: 'TfaiProduct Edit',
            page: 'common/tfai_products/:id/edit',
            translate: 'menu.common.tfai_product.edit.title',
            hidden: true
          },
          {
            title: 'User Management',
            bullet: 'line',
            translate: 'menu.base.user.title',
            submenu: [
              {
                title: 'User',
                page: 'common/users',
                translate: 'menu.common.user.title'
              },
              {
                title: 'User Create',
                page: 'common/users/:id/new',
                translate: 'menu.common.user.create.title',
                hidden: true
              },
              {
                title: 'User Edit',
                page: 'common/users/:id/edit',
                translate: 'menu.common.user.edit.title',
                hidden: true
              },
              {
                title: 'PosUser',
                page: 'common/pos_users',
                translate: 'menu.common.pos_user.title'
              },
              {
                title: 'PosUser Create',
                page: 'common/pos_users/:id/new',
                translate: 'menu.common.pos_user.create.title',
                hidden: true
              },
              {
                title: 'PosUser Edit',
                page: 'common/pos_users/:id/edit',
                translate: 'menu.common.pos_user.edit.title',
                hidden: true
              },
              {
                title: 'Employee',
                page: 'common/employees',
                translate: 'menu.common.employee.title'
              },
              {
                title: 'Employee Create',
                page: 'common/employees/:id/new',
                translate: 'menu.common.employee.create.title',
                hidden: true
              },
              {
                title: 'Employee Edit',
                page: 'common/employees/:id/edit',
                translate: 'menu.common.employee.edit.title',
                hidden: true
              }
            ]
          },
          // {
          //   title: 'Pos Management',
          //   bullet: 'line',
          //   translate: 'menu.base.pos.title',
          //   submenu: [
          //     {
          //       title: 'PosInfo',
          //       page: 'common/pos_infos',
          //       translate: 'menu.common.pos_info.title'
          //     },
          //     {
          //       title: 'PosInfo Create',
          //       page: 'common/pos_infos/:id/new',
          //       translate: 'menu.common.pos_info.create.title',
          //       hidden: true
          //     },
          //     {
          //       title: 'PosInfo Edit',
          //       page: 'common/pos_infos/:id/edit',
          //       translate: 'menu.common.pos_info.edit.title',
          //       hidden: true
          //     }
          //   ]
          // },
          {
            title: 'Invoice Management',
            bullet: 'line',
            translate: 'menu.base.invoice.title',
            submenu: [
              {
                title: 'InvoiceSet',
                page: 'common/invoice_sets',
                translate: 'menu.common.invoice_set.title'
              },
              {
                title: 'InvoiceSet Create',
                page: 'common/invoice_sets/:id/new',
                translate: 'menu.common.invoice_set.create.title',
                hidden: true
              },
              {
                title: 'InvoiceSet Edit',
                page: 'common/invoice_sets/:id/edit',
                translate: 'menu.common.invoice_set.edit.title',
                hidden: true
              },
              {
                title: 'InvoiceSource',
                page: 'common/invoice_sources',
                translate: 'menu.common.invoice_source.title'
              },
              {
                title: 'InvoiceSource Create',
                page: 'common/invoice_sources/:id/new',
                translate: 'menu.common.invoice_source.create.title',
                hidden: true
              },
              {
                title: 'InvoiceSource Edit',
                page: 'common/invoice_sources/:id/edit',
                translate: 'menu.common.invoice_source.edit.title',
                hidden: true
              }
            ]
          },
          {
            title: 'StockUnit',
            page: 'common/stock_units',
            translate: 'menu.common.stock_unit.title',
            hidden: true,
            submenu: [
              {
                title: 'TradeUnit',
                page: 'common/stock_units/:parent_id/trade_units',
                translate: 'menu.common.trade_unit.title',
                hidden: true
              },
              {
                title: 'TradeUnit Create',
                page: 'common/stock_units/:parent_id/trade_units/:id/new',
                translate: 'menu.common.trade_unit.create.title',
                hidden: true
              },
              {
                title: 'TradeUnit Edit',
                page: 'common/stock_units/:parent_id/trade_units/:id/edit',
                translate: 'menu.common.trade_unit.edit.title',
                hidden: true
              }
            ]
          },
          {
            title: 'StockUnit',
            page: 'common/stock_units',
            translate: 'menu.common.stock_unit.title'
          },
          {
            title: 'StockUnit Create',
            page: 'common/stock_units/:id/new',
            translate: 'menu.common.stock_unit.create.title',
            hidden: true
          },
          {
            title: 'StockUnit Edit',
            page: 'common/stock_units/:id/edit',
            translate: 'menu.common.stock_unit.edit.title',
            hidden: true
          },
          {
            title: 'Member',
            page: 'common/members',
            translate: 'menu.common.member.title'
          },
          {
            title: 'Member Create',
            page: 'common/members/:id/new',
            translate: 'menu.common.member.create.title',
            hidden: true
          },
          {
            title: 'Member Edit',
            page: 'common/members/:id/edit',
            translate: 'menu.common.member.edit.title',
            hidden: true
          }
        ]
      },
      // {
      //   title: 'Material Management',
      //   bullet: 'line',
      //   translate: 'menu.base.material.title',
      //   root: true,
      //   icon: 'flaticon2-browser-2',
      //   submenu: [
      //     {
      //       title: 'Material Category Management',
      //       bullet: 'line',
      //       translate: 'menu.base.material.category.title',
      //       submenu: [
      //         {
      //           title: 'MaterialMajorCategory',
      //           page: 'common/material_major_categories',
      //           translate: 'menu.common.material_major_category.title'
      //         },
      //         {
      //           title: 'MaterialMajorCategory Create',
      //           page: 'common/material_major_categories/:id/new',
      //           translate: 'menu.common.material_major_category.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'MaterialMajorCategory Edit',
      //           page: 'common/material_major_categories/:id/edit',
      //           translate: 'menu.common.material_major_category.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'MaterialMediumCategory',
      //           page: 'common/material_medium_categories',
      //           translate: 'menu.common.material_medium_category.title'
      //         },
      //         {
      //           title: 'MaterialMediumCategory Create',
      //           page: 'common/material_medium_categories/:id/new',
      //           translate: 'menu.common.material_medium_category.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'MaterialMediumCategory Edit',
      //           page: 'common/material_medium_categories/:id/edit',
      //           translate: 'menu.common.material_medium_category.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'MaterialMinorCategory',
      //           page: 'common/material_minor_categories',
      //           translate: 'menu.common.material_minor_category.title'
      //         },
      //         {
      //           title: 'MaterialMinorCategory Create',
      //           page: 'common/material_minor_categories/:id/new',
      //           translate: 'menu.common.material_minor_category.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'MaterialMinorCategory Edit',
      //           page: 'common/material_minor_categories/:id/edit',
      //           translate: 'menu.common.material_minor_category.edit.title',
      //           hidden: true
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Material Management',
      //       bullet: 'line',
      //       translate: 'menu.base.material.management.title',
      //       submenu: [
      //         {
      //           title: 'Material',
      //           page: 'common/materials',
      //           translate: 'menu.common.material.title'
      //         },
      //         {
      //           title: 'Material Create',
      //           page: 'common/materials/:id/new',
      //           translate: 'menu.common.material.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Material Edit',
      //           page: 'common/materials/:id/edit',
      //           translate: 'menu.common.material.edit.title',
      //           hidden: true
      //         }
      //       ]
      //     }
      //   ]
      // },

      {
        title: 'Product Management',
        root: true,
        bullet: 'line',
        icon: 'flaticon2-browser-2',
        translate: 'menu.common.products.management.title',
        submenu: [

          {
            title: 'POS Category Management',
            bullet: 'line',
            translate: 'menu.common.pos.category.management.title',
            submenu: [
              {
                title: 'ProductDepartmentCategory',
                page: 'common/product_department_categories',
                translate: 'menu.common.product_department_category.title'
              },
              {
                title: 'ProductDepartmentCategory Create',
                page: 'common/product_department_categories/:id/new',
                translate: 'menu.common.product_department_category.create.title',
                hidden: true
              },
              {
                title: 'ProductDepartmentCategory Edit',
                page: 'common/product_department_categories/:id/edit',
                translate: 'menu.common.product_department_category.edit.title',
                hidden: true
              },
              {
                title: 'ProductMajorCategory',
                page: 'common/product_major_categories',
                translate: 'menu.common.product_major_category.title'
              },
              {
                title: 'ProductMajorCategory Create',
                page: 'common/product_major_categories/:id/new',
                translate: 'menu.common.product_major_category.create.title',
                hidden: true
              },
              {
                title: 'ProductMajorCategory Edit',
                page: 'common/product_major_categories/:id/edit',
                translate: 'menu.common.product_major_category.edit.title',
                hidden: true
              },
              {
                title: 'ProductMediumCategory',
                page: 'common/product_medium_categories',
                translate: 'menu.common.product_medium_category.title'
              },
              {
                title: 'ProductMediumCategory Create',
                page: 'common/product_medium_categories/:id/new',
                translate: 'menu.common.product_medium_category.create.title',
                hidden: true
              },
              {
                title: 'ProductMediumCategory Edit',
                page: 'common/product_medium_categories/:id/edit',
                translate: 'menu.common.product_medium_category.edit.title',
                hidden: true
              },
              {
                title: 'ProductMinorCategory',
                page: 'common/product_minor_categories',
                translate: 'menu.common.product_minor_category.title'
              },
              {
                title: 'ProductMinorCategory Create',
                page: 'common/product_minor_categories/:id/new',
                translate: 'menu.common.product_minor_category.create.title',
                hidden: true
              },
              {
                title: 'ProductMinorCategory Edit',
                page: 'common/product_minor_categories/:id/edit',
                translate: 'menu.common.product_minor_category.edit.title',
                hidden: true
              }

            ]
          },
          {
            title: 'Product Management',
            bullet: 'line',
            translate: 'menu.common.products.management.title',
            submenu: [
              {
                title: 'Product',
                page: 'common/products',
                translate: 'menu.common.product.title'
              },
              {
                title: 'Product Create',
                page: 'common/products/:id/new',
                translate: 'menu.common.product.create.title',
                hidden: true
              },
              {
                title: 'Product Edit',
                page: 'common/products/:id/edit',
                translate: 'menu.common.product.edit.title',
                hidden: true
              },
              {
                title: 'ProductBundleItem',
                page: 'common/product_bundle_items',
                translate: 'menu.common.product_bundle_item.title',
                hidden: true
              },
              {
                title: 'ProductBundleItem Create',
                page: 'common/product_bundle_items/:id/new',
                translate: 'menu.common.product_bundle_item.create.title',
                hidden: true
              },
              {
                title: 'ProductBundleItem Edit',
                page: 'common/product_bundle_items/:id/edit',
                translate: 'menu.common.product_bundle_item.edit.title',
                hidden: true
              },
              {
                title: 'ProductComponentItem',
                page: 'common/product_component_items',
                translate: 'menu.common.product_component_item.title',
                hidden: true
              },
              {
                title: 'ProductComponentItem Create',
                page: 'common/product_component_items/:id/new',
                translate: 'menu.common.product_component_item.create.title',
                hidden: true
              },
              {
                title: 'ProductComponentItem Edit',
                page: 'common/product_component_items/:id/edit',
                translate: 'menu.common.product_component_item.edit.title',
                hidden: true
              },
              {
                title: 'ProductOrder',
                page: 'common/product_orders',
                translate: 'menu.common.product_order.title'
              },
              {
                title: 'ProductSuite',
                page: 'common/product_suites',
                translate: 'menu.common.product_suite.title'
              },
              {
                title: 'ProductSuite Create',
                page: 'common/product_suites/:id/new',
                translate: 'menu.common.product_suite.create.title',
                hidden: true
              },
              {
                title: 'ProductSuite Edit',
                page: 'common/product_suites/:id/edit',
                translate: 'menu.common.product_suite.edit.title',
                hidden: true
              },
              {
                title: 'ProductSuiteItem',
                page: 'common/product_suite_items',
                translate: 'menu.common.product_suite_item.title',
                hidden: true
              },
              {
                title: 'ProductSuiteItem Create',
                page: 'common/product_suite_items/:id/new',
                translate: 'menu.common.product_suite_item.create.title',
                hidden: true
              },
              {
                title: 'ProductSuiteItem Edit',
                page: 'common/product_suite_items/:id/edit',
                translate: 'menu.common.product_suite_item.edit.title',
                hidden: true
              },
              {
                title: 'ProductSuiteFolder',
                page: 'common/product_suite_folders',
                translate: 'menu.common.product_suite_folder.title',
                hidden: true
              },
              {
                title: 'ProductSuiteFolder Create',
                page: 'common/product_suite_folders/:id/new',
                translate: 'menu.common.product_suite_folder.create.title',
                hidden: true
              },
              {
                title: 'ProductSuiteFolder Edit',
                page: 'common/product_suite_folders/:id/edit',
                translate: 'menu.common.product_suite_folder.edit.title',
                hidden: true
              },
              {
                title: 'SubscriptionPeriod',
                page: 'common/subscription_periods',
                translate: 'menu.common.subscription_period.title'
              },
              {
                title: 'SubscriptionPeriod Create',
                page: 'common/subscription_periods/:id/new',
                translate: 'menu.common.subscription_period.create.title',
                hidden: true
              },
              {
                title: 'SubscriptionPeriod Edit',
                page: 'common/subscription_periods/:id/edit',
                translate: 'menu.common.subscription_period.edit.title',
                hidden: true
              },
              {
                title: 'SubscriptionQuantity',
                page: 'common/subscription_quantities',
                translate: 'menu.common.subscription_quantity.title'
              },
              {
                title: 'SubscriptionQuantity Create',
                page: 'common/subscription_quantities/:id/new',
                translate: 'menu.common.subscription_quantity.create.title',
                hidden: true
              },
              {
                title: 'SubscriptionQuantity Edit',
                page: 'common/subscription_quantities/:id/edit',
                translate: 'menu.common.subscription_quantity.edit.title',
                hidden: true
              },
              {
                title: 'SubscriptionPeriodItem',
                page: 'common/subscription_period_items',
                translate: 'menu.common.subscription_period_item.title',
                hidden: true
              },
              {
                title: 'SubscriptionPeriodItem Create',
                page: 'common/subscription_period_items/:id/new',
                translate: 'menu.common.subscription_period_item.create.title',
                hidden: true
              },
              {
                title: 'SubscriptionPeriodItem Edit',
                page: 'common/subscription_period_items/:id/edit',
                translate: 'menu.common.subscription_period_item.edit.title',
                hidden: true
              },
              {
                title: 'SubscriptionQuantityItem',
                page: 'common/subscription_quantity_items',
                translate: 'menu.common.subscription_quantity_item.title',
                hidden: true
              },
              {
                title: 'SubscriptionQuantityItem Create',
                page: 'common/subscription_quantity_items/:id/new',
                translate: 'menu.common.subscription_quantity_item.create.title',
                hidden: true
              },
              {
                title: 'SubscriptionQuantityItem Edit',
                page: 'common/subscription_quantity_items/:id/edit',
                translate: 'menu.common.subscription_quantity_item.edit.title',
                hidden: true
              },
              {
                title: 'ProductPackageItem',
                page: 'common/product_package_items',
                translate: 'menu.common.product_package_item.title',
                hidden: true
              },
              {
                title: 'ProductPackageItem Create',
                page: 'common/product_package_items/:id/new',
                translate: 'menu.common.product_package_item.create.title',
                hidden: true
              },
              {
                title: 'ProductPackageItem Edit',
                page: 'common/product_package_items/:id/edit',
                translate: 'menu.common.product_package_item.edit.title',
                hidden: true
              },
              {
                title: 'ProductPackage',
                page: 'common/product_packages',
                translate: 'menu.common.product_package.title'
              },
              {
                title: 'ProductPackage Create',
                page: 'common/product_packages/:id/new',
                translate: 'menu.common.product_package.create.title',
                hidden: true
              },
              {
                title: 'ProductPackage Edit',
                page: 'common/product_packages/:id/edit',
                translate: 'menu.common.product_package.edit.title',
                hidden: true
              },
              // {
              //   title: 'ProductCombo',
              //   page: 'common/product_combos',
              //   translate: 'menu.common.product_combo.title'
              // },
              // {
              //   title: 'ProductCombo Create',
              //   page: 'common/product_combos/:id/new',
              //   translate: 'menu.common.product_combo.create.title',
              //   hidden: true
              // },
              // {
              //   title: 'ProductCombo Edit',
              //   page: 'common/product_combos/:id/edit',
              //   translate: 'menu.common.product_combo.edit.title',
              //   hidden: true
              // },
              {
                title: 'ProductComboItem',
                page: 'common/product_combo_items',
                translate: 'menu.common.product_combo_item.title',
                hidden: true
              },
              {
                title: 'ProductComboItem Create',
                page: 'common/product_combo_items/:id/new',
                translate: 'menu.common.product_combo_item.create.title',
                hidden: true
              },
              {
                title: 'ProductComboItem Edit',
                page: 'common/product_combo_items/:id/edit',
                translate: 'menu.common.product_combo_item.edit.title',
                hidden: true
              }
            ]
          },
          {
            title: 'ProductTag',
            page: 'common/product_tags',
            translate: 'menu.common.product_tag.title'
          },
          {
            title: 'ProductTag Create',
            page: 'common/product_tags/:id/new',
            translate: 'menu.common.product_tag.create.title',
            hidden: true
          },
          {
            title: 'ProductTag Edit',
            page: 'common/product_tags/:id/edit',
            translate: 'menu.common.product_tag.edit.title',
            hidden: true
          },
          {
            title: 'ProductOption',
            page: 'common/product_options',
            translate: 'menu.common.product_option.title'
          },
          {
            title: 'ProductOption Create',
            page: 'common/product_options/:id/new',
            translate: 'menu.common.product_option.create.title',
            hidden: true
          },
          {
            title: 'ProductOption Edit',
            page: 'common/product_options/:id/edit',
            translate: 'menu.common.product_option.edit.title',
            hidden: true
          },
          {
            title: 'ProductOption',
            translate: 'menu.common.product_option.title',
            page: 'common/product_options',
            hidden: true,
            submenu: [
              {
                title: 'ProductOptionItem',
                page: 'common/product_options/:parent_id/product_option_items',
                translate: 'menu.common.product_option_item.title'
              },
              {
                title: 'ProductOptionItem Create',
                page: 'common/product_options/:parent_id/product_option_items/:id/new',
                translate: 'menu.common.product_option_item.create.title',
                hidden: true
              },
              {
                title: 'ProductOptionItem Edit',
                page: 'common/product_options/:parent_id/product_option_items/:id/edit',
                translate: 'menu.common.product_option_item.edit.title',
                hidden: true
              }

            ]
          },
          {
            title: 'Product Management',
            bullet: 'line',
            translate: 'menu.common.promote.management.title',
            submenu: [
              {
                title: 'Promote',
                page: 'common/promotes',
                translate: 'menu.common.promote.title'
              },
              {
                title: 'Promote Create',
                page: 'common/promotes/:id/new',
                translate: 'menu.common.promote.create.title',
                hidden: true
              },
              {
                title: 'Promote Edit',
                page: 'common/promotes/:id/edit',
                translate: 'menu.common.promote.edit.title',
                hidden: true
              }
            ]
          }
        ]
      },
      {
        root: true,
        title: 'Common',
        icon: 'flaticon2-gear',
        translate: 'menu.common.title',
        bullet: 'line', // dot | lin
        submenu: [
          {
            title: 'Config',
            page: 'common/configs',
            translate: 'menu.common.config.title'
          },
          {
            title: 'Warehouse',
            page: 'common/warehouses',
            translate: 'menu.common.warehouse.title'
          },
          {
            title: 'Warehouse Create',
            page: 'common/warehouses/:id/new',
            translate: 'menu.common.warehouse.create.title',
            hidden: true
          },
          {
            title: 'Warehouse Edit',
            page: 'common/warehouses/:id/edit',
            translate: 'menu.common.warehouse.edit.title',
            hidden: true
          }
        ]
      },
      // {
      //   root: true,
      //   title: 'Development',
      //   icon: 'flaticon2-gear',
      //   translate: 'menu.development.title',
      //   bullet: 'line', // dot | lin
      //   submenu: [
      //     {
      //       title: 'Base Data',
      //       bullet: 'line',
      //       translate: 'menu.dev.base.title',
      //       submenu: [
      //         {
      //           title: 'Supplier',
      //           page: 'erp/suppliers',
      //           translate: 'menu.erp.supplier.title'
      //         },
      //         {
      //           title: 'Supplier Create',
      //           page: 'erp/suppliers/:id/new',
      //           translate: 'menu.erp.supplier.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Supplier Edit',
      //           page: 'erp/suppliers/:id/edit',
      //           translate: 'menu.erp.supplier.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'SupplierContact',
      //           page: 'erp/supplier_contacts',
      //           translate: 'menu.erp.supplier_contact.title'
      //         },
      //         {
      //           title: 'SupplierContact Create',
      //           page: 'erp/supplier_contacts/:id/new',
      //           translate: 'menu.erp.supplier_contact.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'SupplierContact Edit',
      //           page: 'erp/supplier_contacts/:id/edit',
      //           translate: 'menu.erp.supplier_contact.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Customer',
      //           page: 'erp/customers',
      //           translate: 'menu.erp.customer.title'
      //         },
      //         {
      //           title: 'Customer Create',
      //           page: 'erp/customers/:id/new',
      //           translate: 'menu.erp.customer.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Customer Edit',
      //           page: 'erp/customers/:id/edit',
      //           translate: 'menu.erp.customer.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'CustomerContact',
      //           page: 'erp/customer_contacts',
      //           translate: 'menu.erp.customer_contact.title'
      //         },
      //         {
      //           title: 'CustomerContact Create',
      //           page: 'erp/customer_contacts/:id/new',
      //           translate: 'menu.erp.customer_contact.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'CustomerContact Edit',
      //           page: 'erp/customer_contacts/:id/edit',
      //           translate: 'menu.erp.customer_contact.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'CustomerReceive',
      //           page: 'erp/customer_receives',
      //           translate: 'menu.erp.customer_receive.title'
      //         },
      //         {
      //           title: 'CustomerReceive Create',
      //           page: 'erp/customer_receives/:id/new',
      //           translate: 'menu.erp.customer_receive.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'CustomerReceive Edit',
      //           page: 'erp/customer_receives/:id/edit',
      //           translate: 'menu.erp.customer_receive.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'ErpDepartment',
      //           page: 'erp/erp_departments',
      //           translate: 'menu.erp.erp_department.title'
      //         },
      //         {
      //           title: 'ErpDepartment Create',
      //           page: 'erp/erp_departments/:id/new',
      //           translate: 'menu.erp.erp_department.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'ErpDepartment Edit',
      //           page: 'erp/erp_departments/:id/edit',
      //           translate: 'menu.erp.erp_department.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Project',
      //           page: 'erp/projects',
      //           translate: 'menu.erp.project.title'
      //         },
      //         {
      //           title: 'Project Create',
      //           page: 'erp/projects/:id/new',
      //           translate: 'menu.erp.project.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Project Edit',
      //           page: 'erp/projects/:id/edit',
      //           translate: 'menu.erp.project.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'ProductUnit',
      //           page: 'erp/product_units',
      //           translate: 'menu.erp.product_unit.title'
      //         },
      //         {
      //           title: 'ProductUnit Create',
      //           page: 'erp/product_units/:id/new',
      //           translate: 'menu.erp.product_unit.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'ProductUnit Edit',
      //           page: 'erp/product_units/:id/edit',
      //           translate: 'menu.erp.product_unit.edit.title',
      //           hidden: true
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Inventory',
      //       bullet: 'line',
      //       translate: 'menu.dev.inventory.title',
      //       hidden: true,
      //       submenu: [
      //         {
      //           title: 'ErpProduct',
      //           page: 'erp/erp_products',
      //           translate: 'menu.erp.erp_product.title'
      //         },
      //         {
      //           title: 'ErpProduct Create',
      //           page: 'erp/erp_products/:id/new',
      //           translate: 'menu.erp.erp_product.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'ErpProduct Edit',
      //           page: 'erp/erp_products/:id/edit',
      //           translate: 'menu.erp.erp_product.edit.title',
      //           hidden: true
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Invoicing',
      //       bullet: 'line',
      //       translate: 'menu.dev.invoicing.title',
      //       submenu: [
      //         {
      //           title: 'Ordering',
      //           page: 'erp/orderings',
      //           translate: 'menu.erp.ordering.title'
      //         },
      //         {
      //           title: 'Ordering Create',
      //           page: 'erp/orderings/:id/new',
      //           translate: 'menu.erp.ordering.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Ordering Edit',
      //           page: 'erp/orderings/:id/edit',
      //           translate: 'menu.erp.ordering.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'OrderingItem',
      //           page: 'erp/ordering_items',
      //           translate: 'menu.erp.ordering_item.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'OrderingItem Create',
      //           page: 'erp/ordering_items/:id/new',
      //           translate: 'menu.erp.ordering_item.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'OrderingItem Edit',
      //           page: 'erp/ordering_items/:id/edit',
      //           translate: 'menu.erp.ordering_item.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Sale',
      //           page: 'erp/sales',
      //           translate: 'menu.erp.sale.title'
      //         },
      //         {
      //           title: 'Sale Create',
      //           page: 'erp/sales/:id/new',
      //           translate: 'menu.erp.sale.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Sale Edit',
      //           page: 'erp/sales/:id/edit',
      //           translate: 'menu.erp.sale.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'SaleItem',
      //           page: 'erp/sale_items',
      //           translate: 'menu.erp.sale_item.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'SaleItem Create',
      //           page: 'erp/sale_items/:id/new',
      //           translate: 'menu.erp.sale_item.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'SaleItem Edit',
      //           page: 'erp/sale_items/:id/edit',
      //           translate: 'menu.erp.sale_item.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Purchase',
      //           page: 'erp/purchases',
      //           translate: 'menu.erp.purchase.title'
      //         },
      //         {
      //           title: 'Purchase Create',
      //           page: 'erp/purchases/:id/new',
      //           translate: 'menu.erp.purchase.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Purchase Edit',
      //           page: 'erp/purchases/:id/edit',
      //           translate: 'menu.erp.purchase.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'PurchaseItem',
      //           page: 'erp/purchase_items',
      //           translate: 'menu.erp.purchase_item.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'PurchaseItem Create',
      //           page: 'erp/purchase_items/:id/new',
      //           translate: 'menu.erp.purchase_item.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'PurchaseItem Edit',
      //           page: 'erp/purchase_items/:id/edit',
      //           translate: 'menu.erp.purchase_item.edit.title',
      //           hidden: true
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Account',
      //       bullet: 'line',
      //       translate: 'menu.dev.account.title',
      //       submenu: [
      //         {
      //           title: 'AccountTitle',
      //           page: 'erp/account_titles',
      //           translate: 'menu.erp.account_title.title'
      //         },
      //         {
      //           title: 'AccountTitle Create',
      //           page: 'erp/account_titles/:id/new',
      //           translate: 'menu.erp.account_title.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountTitle Edit',
      //           page: 'erp/account_titles/:id/edit',
      //           translate: 'menu.erp.account_title.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountVoucher',
      //           page: 'erp/account_vouchers',
      //           translate: 'menu.erp.account_voucher.title'
      //         },
      //         {
      //           title: 'AccountVoucher Create',
      //           page: 'erp/account_vouchers/:id/new',
      //           translate: 'menu.erp.account_voucher.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountVoucher Edit',
      //           page: 'erp/account_vouchers/:id/edit',
      //           translate: 'menu.erp.account_voucher.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountVouchersItem',
      //           page: 'erp/account_vouchers_items',
      //           translate: 'menu.erp.account_vouchers_item.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountVouchersItem Create',
      //           page: 'erp/account_vouchers_items/:id/new',
      //           translate: 'menu.erp.account_vouchers_item.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountVouchersItem Edit',
      //           page: 'erp/account_vouchers_items/:id/edit',
      //           translate: 'menu.erp.account_vouchers_item.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Bank',
      //           page: 'erp/banks',
      //           translate: 'menu.erp.bank.title'
      //         },
      //         {
      //           title: 'Bank Create',
      //           page: 'erp/banks/:id/new',
      //           translate: 'menu.erp.bank.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Bank Edit',
      //           page: 'erp/banks/:id/edit',
      //           translate: 'menu.erp.bank.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'BankTran',
      //           page: 'erp/bank_trans',
      //           translate: 'menu.erp.bank_tran.title'
      //         },
      //         {
      //           title: 'BankTran Create',
      //           page: 'erp/bank_trans/:id/new',
      //           translate: 'menu.erp.bank_tran.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'BankTran Edit',
      //           page: 'erp/bank_trans/:id/edit',
      //           translate: 'menu.erp.bank_tran.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Check',
      //           page: 'erp/checks',
      //           translate: 'menu.erp.check.title'
      //         },
      //         {
      //           title: 'Check Create',
      //           page: 'erp/checks/:id/new',
      //           translate: 'menu.erp.check.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Check Edit',
      //           page: 'erp/checks/:id/edit',
      //           translate: 'menu.erp.check.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'CheckPayment',
      //           page: 'erp/check_payments',
      //           translate: 'menu.erp.check_payment.title'
      //         },
      //         {
      //           title: 'CheckPayment Create',
      //           page: 'erp/check_payments/:id/new',
      //           translate: 'menu.erp.check_payment.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'CheckPayment Edit',
      //           page: 'erp/check_payments/:id/edit',
      //           translate: 'menu.erp.check_payment.edit.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountGeneral',
      //           page: 'erp/account_generals',
      //           translate: 'menu.erp.account_general.title'
      //         },
      //         {
      //           title: 'AccountGeneral Create',
      //           page: 'erp/account_generals/:id/new',
      //           translate: 'menu.erp.account_general.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'AccountGeneral Edit',
      //           page: 'erp/account_generals/:id/edit',
      //           translate: 'menu.erp.account_general.edit.title',
      //           hidden: true
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Invoice',
      //       bullet: 'line',
      //       translate: 'menu.dev.invoice.title',
      //       submenu: [
      //         {
      //           title: 'Invoice',
      //           page: 'erp/invoices',
      //           translate: 'menu.erp.invoice.title'
      //         },
      //         {
      //           title: 'Invoice Create',
      //           page: 'erp/invoices/:id/new',
      //           translate: 'menu.erp.invoice.create.title',
      //           hidden: true
      //         },
      //         {
      //           title: 'Invoice Edit',
      //           page: 'erp/invoices/:id/edit',
      //           translate: 'menu.erp.invoice.edit.title',
      //           hidden: true
      //         }
      //       ]
      //     }
      //   ]
      // },
      {
        title: 'Report Manager',
        root: true,
        bullet: 'line',
        icon: 'flaticon2-browser-2',
        translate: 'menu.report.title',
        submenu: [
          {
            title: 'Report',
            page: 'erp/reports',
            translate: 'menu.erp.report.title'
          },
          {
            title: 'Report Sale',
            page: 'erp/reports/:code/:id/sale_reports',
            translate: 'menu.erp.report.sale_report.title',
            hidden: true
          },
          {
            title: 'Report Invoice',
            page: 'erp/reports/:code/:id/invoice_reports',
            translate: 'menu.erp.report.invoice_report.title',
            hidden: true
          }
        ]
      },
      // {
      //   section: 'Product',
      //   translate: 'menu.section.product.title'
      // },
      // { section: 'Reference' },
      // {
      //   title: 'Error Pages',
      //   root: true,
      //   bullet: 'line',
      //   icon: 'flaticon-danger',
      //   submenu: [
      //     {
      //       title: 'Error Page - 1',
      //       page: 'error/error-v1'
      //     },
      //     {
      //       title: 'Error Page - 2',
      //       page: 'error/error-v2'
      //     },
      //     {
      //       title: 'Error Page - 3',
      //       page: 'error/error-v3'
      //     },
      //     {
      //       title: 'Error Page - 4',
      //       page: 'error/error-v4'
      //     },
      //     {
      //       title: 'Error Page - 5',
      //       page: 'error/error-v5'
      //     },
      //     {
      //       title: 'Error Page - 6',
      //       page: 'error/error-v6'
      //     }
      //   ]
      // }
    ]
  }
}
