import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import * as suitItemDuck from 'admin/store/ducks/productSuiteItem.duck'
import * as productDuck from 'admin/store/ducks/product.duck'
import { insertEmptyRow, insertRows } from 'shared/itemHelper'
import { v4 as uuidv4 } from 'uuid'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_SUITE_FOLDER: 'productSuiteFolder/CREATE_PRODUCT_SUITE_FOLDER',
  FETCH_PRODUCT_SUITE_FOLDERS: 'productSuiteFolder/FETCH_PRODUCT_SUITE_FOLDERS',
  FETCH_PRODUCT_SUITE_FOLDER: 'productSuiteFolder/FETCH_PRODUCT_SUITE_FOLDER',
  FETCH_PRODUCT_SUITE_ITEMS: 'productSuiteFolder/FETCH_PRODUCT_SUITE_ITEMS',

  UPADE_PRODUCT_SUITE_FOLDER: 'productSuiteFolder/UPADE_PRODUCT_SUITE_FOLDER',
  DELETE_PRODUCT_SUITE_FOLDER: 'productSuiteFolder/DELETE_PRODUCT_SUITE_FOLDER',

  PRODUCT_SUITE_FOLDER_CREATED: 'productSuiteFolder/PRODUCT_SUITE_FOLDER_CREATED',
  PRODUCT_SUITE_FOLDERS_LOADED: 'productSuiteFolder/PRODUCT_SUITE_FOLDERS_LOADED',
  PRODUCT_SUITE_FOLDER_LOADED: 'productSuiteFolder/PRODUCT_SUITE_FOLDER_LOADED',
  PRODUCT_SUITE_FOLDER_UPDATED: 'productSuiteFolder/PRODUCT_SUITE_FOLDER_UPDATED',
  PRODUCT_SUITE_FOLDER_DELETED: 'productSuiteFolder/PRODUCT_SUITE_FOLDER_DELETED',

  SET_SELECTED: 'productSuiteFolder/SET_SELECTED',
  INSERT_EMPTY_ROW: 'productSuiteFolder/INSERT_EMPTY_ROW',
  DELETE_ROW: 'productSuiteFolder/DELETE_ROW',
  INSERT_ROWS: 'productSuiteFolder/INSERT_ROWS',

  UPDATE_SELECTED_SUITE_FOLDER_ITEMS: 'productSuiteFolder/UPDATE_SELECTED_SUITE_FOLDER_ITEMS',
  UPDATE_PRODUCT_SUITE_FOLDERS: 'productSuiteFolder/UPDATE_PRODUCT_SUITE_FOLDERS'
}

const initialState = {
  productSuiteFolder: null,
  productSuiteFolders: null,
  suiteFolderSelected: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_SUITE_FOLDER:
      return { ...state, productSuiteFolder: action.payload }
    case actionTypes.FETCH_PRODUCT_SUITE_FOLDERS:
      return { ...state, productSuiteFolders: null }
    case actionTypes.FETCH_PRODUCT_SUITE_FOLDER:
      return { ...state, productSuiteFolder: null }
    case actionTypes.UPADE_PRODUCT_SUITE_FOLDER:
      return { ...state, productSuiteFolder: action.payload }
    case actionTypes.PRODUCT_SUITE_FOLDER_CREATED:
      return { ...state, productSuiteFolders: [...state.productSuiteFolders, action.payload] }
    case actionTypes.PRODUCT_SUITE_FOLDERS_LOADED:
      return { ...state, productSuiteFolders: action.payload }
    case actionTypes.PRODUCT_SUITE_FOLDER_LOADED:
      return { ...state, productSuiteFolder: action.payload }
    case actionTypes.PRODUCT_SUITE_FOLDER_UPDATED:
      return {
        ...state,
        productSuiteFolder: action.payload,
        productSuiteFolders: state.productSuiteFolders.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_SUITE_FOLDER_DELETED:
      return { ...state, productSuiteFolders: state.productSuiteFolders.filter(item => item.id !== action.payload) }

    case actionTypes.INSERT_EMPTY_ROW:
      return {
        ...state,
        productSuiteFolders: insertEmptyRow(state.productSuiteFolders, action.payload, uuidv4())
      }
    case actionTypes.DELETE_ROW: {
      const filterd = state.productSuiteFolders.filter(item => item.position !== action.payload)
      if (filterd.length === 0) {
        return { ...state, productSuiteFolders: [{ position: 1, id: uuidv4() }] }
      } else {
        return { ...state, productSuiteFolders: filterd.map((item, index) => ({ ...item, position: index + 1 })) }
      }
    }
    case actionTypes.INSERT_ROWS: {
      const nodes = action.payload.rows
      return {
        ...state,
        productSuiteFolders: insertRows(
          state.productSuiteFolders,
          nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              product_id: node.data.id,
              productSuiteItems: [],
              id: uuidv4()
            }
          })),
          action.payload.position)
      }
    }
    case actionTypes.SET_SELECTED: {
      return { ...state, suiteFolderSelected: action.payload }
    }
    case actionTypes.UPDATE_SELECTED_SUITE_FOLDER_ITEMS: {
      const items = []
      for (const folder of state.productSuiteFolders) {
        if (state.suiteFolderSelected && state.suiteFolderSelected.id === folder.id) {
          items.push({ ...folder, productSuiteItems: action.payload.map(item => ({ ...item, product_suite_folder_id: folder.id })) })
        } else {
          items.push(folder)
        }
      }
      return { ...state, productSuiteFolders: items }
    }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductSuiteFolder: (productSuiteFolder, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_SUITE_FOLDER, payload: { productSuiteFolder, setSubmitting } }),
  fetchProductSuiteFolders: () => ({ type: actionTypes.FETCH_PRODUCT_SUITE_FOLDERS }),
  fetchProductSuiteFolder: (id) => ({ type: actionTypes.FETCH_PRODUCT_SUITE_FOLDER, payload: id }),
  fetchProductSuiteItems: (id) => ({ type: actionTypes.FETCH_PRODUCT_SUITE_ITEMS, payload: id }),
  updateProductSuiteFolder: (productSuiteFolder, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_SUITE_FOLDER, payload: { productSuiteFolder, setSubmitting } }),
  deleteProductSuiteFolder: id => ({ type: actionTypes.DELETE_PRODUCT_SUITE_FOLDER, payload: id }),
  productSuiteFolderCreated: (productSuiteFolder) => ({ type: actionTypes.PRODUCT_SUITE_FOLDER_CREATED, payload: productSuiteFolder }),
  productSuiteFoldersLoaded: (productSuiteFolders) => ({ type: actionTypes.PRODUCT_SUITE_FOLDERS_LOADED, payload: productSuiteFolders }),
  productSuiteFolderLoaded: (productSuiteFolder) => ({ type: actionTypes.PRODUCT_SUITE_FOLDER_LOADED, payload: productSuiteFolder }),
  productSuiteFolderUpdated: (productSuiteFolder) => ({ type: actionTypes.PRODUCT_SUITE_FOLDER_UPDATED, payload: productSuiteFolder }),
  productSuiteFolderDeleted: id => ({ type: actionTypes.PRODUCT_SUITE_FOLDER_DELETED, payload: id }),
  inertEmptyRow: position => ({ type: actionTypes.INSERT_EMPTY_ROW, payload: position }),
  deleteRow: position => ({ type: actionTypes.DELETE_ROW, payload: position }),
  inertRows: (position, rows) => ({ type: actionTypes.INSERT_ROWS, payload: { position, rows } }),
  setSelected: (suiteFolder) => ({ type: actionTypes.SET_SELECTED, payload: suiteFolder }),
  updateSelectedSuiteFolderItems: (items) => ({ type: actionTypes.UPDATE_SELECTED_SUITE_FOLDER_ITEMS, payload: items }),
  updateProductSuiteFolders: (id, productSuiteFolders, setSubmitting) => ({ type: actionTypes.UPDATE_PRODUCT_SUITE_FOLDERS, payload: { id, productSuiteFolders, setSubmitting } })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.UPDATE_PRODUCT_SUITE_FOLDERS, function * updateProductSuiteFoldersSaga (action) {
    const { id, productSuiteFolders, setSubmitting } = action.payload
    try {
      yield call(apiClient.put, `products/${id}/suite_folders`, productSuiteFolders)
      yield put(productDuck.actions.fetchProductSuiteFolders(id))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.CREATE_PRODUCT_SUITE_FOLDER, function * createProductSuiteFolderSaga (action) {
    const { productSuiteFolder, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_suite_folders', productSuiteFolder)
      yield put(actions.productSuiteFolderCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUITE_FOLDERS, function * fetchProductSuiteFoldersSaga () {
    try {
      const result = yield call(apiClient.get, 'product_suite_folders')
      yield put(actions.productSuiteFoldersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUITE_FOLDER, function * fetchProductSuiteFolderSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_suite_folders/${id}`)
      yield put(actions.productSuiteFolderLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_SUITE_ITEMS, function * fetchProductSuiteItemsSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_suite_folders/${id}/suite_items`)
      yield put(suitItemDuck.actions.productSuiteItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_SUITE_FOLDER, function * updateProductSuiteFolderSaga (action) {
    const { productSuiteFolder, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_suite_folders/${productSuiteFolder.id}`, productSuiteFolder)
      yield put(actions.productSuiteFolderUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_SUITE_FOLDER, function * deleteProductSuiteFolderSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_suite_folders/${id}`)
      yield put(actions.productSuiteFolderDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.DELETE_ROW, function * deleteProductSuiteFolderSaga (action) {
    try {
      yield put(suitItemDuck.actions.productSuiteItemsLoaded([]))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
