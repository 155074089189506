import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SUPPLIER_CONTACT: 'supplierContact/CREATE_SUPPLIER_CONTACT',
  FETCH_SUPPLIER_CONTACTS: 'supplierContact/FETCH_SUPPLIER_CONTACTS',
  FETCH_SUPPLIER_CONTACT: 'supplierContact/FETCH_SUPPLIER_CONTACT',
  UPADE_SUPPLIER_CONTACT: 'supplierContact/UPADE_SUPPLIER_CONTACT',
  DELETE_SUPPLIER_CONTACT: 'supplierContact/DELETE_SUPPLIER_CONTACT',

  SEARCH_SUPPLIER_CONTACTS: 'supplierContact/SEARCH_SUPPLIER_CONTACTS',

  SUPPLIER_CONTACT_CREATED: 'supplierContact/SUPPLIER_CONTACT_CREATED',
  SUPPLIER_CONTACTS_LOADED: 'supplierContact/SUPPLIER_CONTACTS_LOADED',
  SUPPLIER_CONTACT_LOADED: 'supplierContact/SUPPLIER_CONTACT_LOADED',
  SUPPLIER_CONTACT_UPDATED: 'supplierContact/SUPPLIER_CONTACT_UPDATED',
  SUPPLIER_CONTACT_DELETED: 'supplierContact/SUPPLIER_CONTACT_DELETED',

  INSERT_EMPTY_ROW: 'supplierContact/INSERT_EMPTY_ROW',
  SAVE_ROW_DATA: 'supplierContact/SAVE_ROW_DATA'
}

const initialState = {
  supplierContact: null,
  supplierContacts: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SUPPLIER_CONTACT:
      return { ...state, supplierContact: action.payload }
    case actionTypes.FETCH_SUPPLIER_CONTACTS:
      return { ...state, supplierContacts: null }
    case actionTypes.FETCH_SUPPLIER_CONTACT:
      return { ...state, supplierContact: null }
    case actionTypes.UPADE_SUPPLIER_CONTACT:
      return { ...state, supplierContact: action.payload }
    case actionTypes.DELETE_SUPPLIER_CONTACT:
      return state

    case actionTypes.SEARCH_SUPPLIER_CONTACTS:
      return {...state, supplierContacts: null}

    case actionTypes.SUPPLIER_CONTACT_CREATED:
      return !state.supplierContacts ? { ...state, supplierContacts: [action.payload]} : { ...state, supplierContacts: [...state.supplierContacts, action.payload] }
    case actionTypes.SUPPLIER_CONTACTS_LOADED:
      return { ...state, supplierContacts: action.payload }
    case actionTypes.SUPPLIER_CONTACT_LOADED:
      return { ...state, supplierContact: action.payload }
    case actionTypes.SUPPLIER_CONTACT_UPDATED:
      return {
        ...state,
        supplierContact: action.payload,
        supplierContacts: state.supplierContacts.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SUPPLIER_CONTACT_DELETED:
      return { ...state, supplierContacts: state.supplierContacts.filter(item => item.id !== action.payload) }

    case actionTypes.INSERT_EMPTY_ROW:
      return !state.supplierContacts ? {...state, supplierContacts: [{}]} : {...state, supplierContacts: [...state.supplierContacts, {}]}

    case actionTypes.SAVE_ROW_DATA:
      return !state.supplierContacts ? {...state, supplierContacts: action.payload} : {...state, supplierContacts: [...state.supplierContacts, action.payload]}

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSupplierContact: (supplierContact, setSubmitting) => ({ type: actionTypes.CREATE_SUPPLIER_CONTACT, payload: { supplierContact, setSubmitting } }),
  fetchSupplierContacts: () => ({ type: actionTypes.FETCH_SUPPLIER_CONTACTS }),
  fetchSupplierContact: (id) => ({ type: actionTypes.FETCH_SUPPLIER_CONTACT, payload: id }),
  updateSupplierContact: (supplierContact, setSubmitting) => ({ type: actionTypes.UPADE_SUPPLIER_CONTACT, payload: { supplierContact, setSubmitting } }),
  deleteSupplierContact: id => ({ type: actionTypes.DELETE_SUPPLIER_CONTACT, payload: id }),
  
  searchSupplierConatcts: (values, setSubmitting) => ({ type: actionTypes.SEARCH_SUPPLIER_CONTACTS, payload: { values, setSubmitting } }),
  
  supplierContactCreated: (supplierContact) => ({ type: actionTypes.SUPPLIER_CONTACT_CREATED, payload: supplierContact }),
  supplierContactsLoaded: (supplierContacts) => ({ type: actionTypes.SUPPLIER_CONTACTS_LOADED, payload: supplierContacts }),
  supplierContactLoaded: (supplierContact) => ({ type: actionTypes.SUPPLIER_CONTACT_LOADED, payload: supplierContact }),
  supplierContactUpdated: (supplierContact) => ({ type: actionTypes.SUPPLIER_CONTACT_UPDATED, payload: supplierContact }),
  supplierContactDeleted: id => ({ type: actionTypes.SUPPLIER_CONTACT_DELETED, payload: id }),

  insertEmptyRow: () => ({ type: actionTypes.INSERT_EMPTY_ROW }),
  saveRowData: (values) => ({ type: actionTypes.SAVE_ROW_DATA, payload: values})
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SUPPLIER_CONTACT, function * createSupplierContactSaga (action) {
    const { supplierContact, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'supplier_contacts', supplierContact)
      yield put(actions.supplierContactCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUPPLIER_CONTACTS, function * fetchSupplierContactsSaga () {
    try {
      const result = yield call(apiClient.get, 'supplier_contacts')
      yield put(actions.supplierContactsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUPPLIER_CONTACT, function * fetchSupplierContactSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `supplier_contacts/${id}`)
      yield put(actions.supplierContactLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SUPPLIER_CONTACT, function * updateSupplierContactSaga (action) {
    const { supplierContact, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `supplier_contacts/${supplierContact.id}`, supplierContact)
      yield put(actions.supplierContactUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SUPPLIER_CONTACT, function * deleteSupplierContactSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `supplier_contacts/${id}`)
      yield put(actions.supplierContactDeleted(id))
      notifySuccess('刪除成功')           
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.SEARCH_SUPPLIER_CONTACTS, function * searchSupplierContactsSaga(action) {
    const { values, setSubmitting } = action.payload
    try{
      const result = yield call(apiClient.get, 'supplier_contacts', {params: {
        supplier_code: !values.supplier_code ? null : values.supplier_code
      }})
      yield put(actions.supplierContactsLoaded(result.data.data))
    } catch(e) {
      notifyError(e.response ? e.response.statusText: e.message)
    } finally {
      setSubmitting(false)
    }
  })
}
