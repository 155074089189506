import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SALE: 'sale/CREATE_SALE',
  FETCH_SALES: 'sale/FETCH_SALES',
  FETCH_SALE: 'sale/FETCH_SALE',
  UPADE_SALE: 'sale/UPADE_SALE',
  DELETE_SALE: 'sale/DELETE_SALE',

  SALE_CREATED: 'sale/SALE_CREATED',
  SALES_LOADED: 'sale/SALES_LOADED',
  SALE_LOADED: 'sale/SALE_LOADED',
  SALE_UPDATED: 'sale/SALE_UPDATED',
  SALE_DELETED: 'sale/SALE_DELETED'
}

const initialState = {
  sale: null,
  sales: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SALE:
      return { ...state, sale: action.payload }
    case actionTypes.FETCH_SALES:
      return { ...state, sales: null }
    case actionTypes.FETCH_SALE:
      return { ...state, sale: null }
    case actionTypes.UPADE_SALE:
      return { ...state, sale: action.payload }
    case actionTypes.DELETE_SALE:
      return state

    case actionTypes.SALES_LOADED:
      return { ...state, sales: action.payload }
    case actionTypes.SALE_LOADED:
      return { ...state, sale: action.payload }
    case actionTypes.SALE_UPDATED:
      return {
        ...state,
        sale: action.payload,
        sales: state.sales.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SALE_DELETED:
      return { ...state, sales: state.sales.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSale: (sale, setSubmitting) => ({ type: actionTypes.CREATE_SALE, payload: { sale, setSubmitting } }),
  fetchSales: () => ({ type: actionTypes.FETCH_SALES }),
  fetchSale: (id) => ({ type: actionTypes.FETCH_SALE, payload: id }),
  updateSale: (sale, setSubmitting) => ({ type: actionTypes.UPADE_SALE, payload: { sale, setSubmitting } }),
  deleteSale: id => ({ type: actionTypes.DELETE_SALE, payload: id }),
  salesLoaded: (sales) => ({ type: actionTypes.SALES_LOADED, payload: sales }),
  saleLoaded: (sale) => ({ type: actionTypes.SALE_LOADED, payload: sale }),
  saleUpdated: (sale) => ({ type: actionTypes.SALE_UPDATED, payload: sale }),
  saleDeleted: id => ({ type: actionTypes.SALE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SALE, function * createSaleSaga (action) {
    const { sale, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'sales', sale)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SALES, function * fetchSalesSaga () {
    try {
      const result = yield call(apiClient.get, 'sales')
      yield put(actions.salesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SALE, function * fetchSaleSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `sales/${id}`)
      yield put(actions.saleLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SALE, function * updateSaleSaga (action) {
    const { sale, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `sales/${sale.id}`, sale)
      yield put(actions.saleUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SALE, function * deleteSaleSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `sales/${id}`)
      yield put(actions.saleDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
