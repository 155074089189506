import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const Toast = () => {
  return (
    <ToastContainer/>
  )
}

export default Toast
