import { put, call, fork, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_MEMBER: 'member/CREATE_MEMBER',
  FETCH_MEMBERS: 'member/FETCH_MEMBERS',
  FETCH_MEMBER: 'member/FETCH_MEMBER',
  UPADE_MEMBER: 'member/UPADE_MEMBER',
  DELETE_MEMBER: 'member/DELETE_MEMBER',

  MEMBER_CREATED: 'member/MEMBER_CREATED',
  MEMBERS_LOADED: 'member/MEMBERS_LOADED',
  MEMBER_LOADED: 'member/MEMBER_LOADED',
  MEMBER_UPDATED: 'member/MEMBER_UPDATED',
  MEMBER_DELETED: 'member/MEMBER_DELETED'
}

const initialState = {
  member: null,
  members: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_MEMBER:
      return { ...state, member: action.payload }
    case actionTypes.FETCH_MEMBERS:
      return { ...state, members: null }
    case actionTypes.FETCH_MEMBER:
      return { ...state, member: null }
    case actionTypes.UPADE_MEMBER:
      return { ...state, member: action.payload }
    case actionTypes.DELETE_MEMBER:
      return state

    case actionTypes.MEMBER_CREATED:
      return { ...state, members: [...state.members, action.payload] }
    case actionTypes.MEMBERS_LOADED:
      return { ...state, members: action.payload }
    case actionTypes.MEMBER_LOADED:
      return { ...state, member: action.payload }
    case actionTypes.MEMBER_UPDATED:
      return {
        ...state,
        member: action.payload,
        members: state.members.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.MEMBER_DELETED:
      return { ...state, members: state.members.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createMember: (member, setSubmitting) => ({ type: actionTypes.CREATE_MEMBER, payload: { member, setSubmitting } }),
  fetchMembers: () => ({ type: actionTypes.FETCH_MEMBERS }),
  fetchMember: (id) => ({ type: actionTypes.FETCH_MEMBER, payload: id }),
  updateMember: (member, setSubmitting) => ({ type: actionTypes.UPADE_MEMBER, payload: { member, setSubmitting } }),
  deleteMember: id => ({ type: actionTypes.DELETE_MEMBER, payload: id }),
  memberCreated: (member) => ({ type: actionTypes.MEMBER_CREATED, payload: member }),
  membersLoaded: (members) => ({ type: actionTypes.MEMBERS_LOADED, payload: members }),
  memberLoaded: (member) => ({ type: actionTypes.MEMBER_LOADED, payload: member }),
  memberUpdated: (member) => ({ type: actionTypes.MEMBER_UPDATED, payload: member }),
  memberDeleted: id => ({ type: actionTypes.MEMBER_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_MEMBER, function * createMemberSaga (action) {
    const { member, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'members', member)
      yield put(actions.memberCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_MEMBERS, function * fetchMembersSaga () {
    try {
      const result = yield call(apiClient.get, 'members')
      yield put(actions.membersLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_MEMBER, function * fetchMemberSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `members/${id}`)
      const intlData = result.data.data
      const transData = {
        ...intlData,
        birthday: intlData.birthday ? moment(intlData.birthday).format('YYYY-MM-DD') : null,
      }
      yield put(actions.memberLoaded(transData))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_MEMBER, function * updateMemberSaga (action) {
    const { member, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `members/${member.id}`, member)
      yield put(actions.memberUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_MEMBER, function * deleteMemberSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `members/${id}`)
      yield put(actions.memberDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
