import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_CUSTOMER_CONTACT: 'customerContact/CREATE_CUSTOMER_CONTACT',
  FETCH_CUSTOMER_CONTACTS: 'customerContact/FETCH_CUSTOMER_CONTACTS',
  FETCH_CUSTOMER_CONTACT: 'customerContact/FETCH_CUSTOMER_CONTACT',
  UPADE_CUSTOMER_CONTACT: 'customerContact/UPADE_CUSTOMER_CONTACT',
  DELETE_CUSTOMER_CONTACT: 'customerContact/DELETE_CUSTOMER_CONTACT',

  SEARCH_CUSTOMER_CONTACTS: 'customerContact/SEARCH_CUSTOMERR_CONTACTS',

  CUSTOMER_CONTACT_CREATED: 'customerContact/CUSTOMER_CONTACT_CREATED',
  CUSTOMER_CONTACTS_LOADED: 'customerContact/CUSTOMER_CONTACTS_LOADED',
  CUSTOMER_CONTACT_LOADED: 'customerContact/CUSTOMER_CONTACT_LOADED',
  CUSTOMER_CONTACT_UPDATED: 'customerContact/CUSTOMER_CONTACT_UPDATED',
  CUSTOMER_CONTACT_DELETED: 'customerContact/CUSTOMER_CONTACT_DELETED'
}

const initialState = {
  customerContact: null,
  customerContacts: []
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_CUSTOMER_CONTACT:
      return { ...state, customerContact: action.payload }
    case actionTypes.FETCH_CUSTOMER_CONTACTS:
      return { ...state, customerContacts: [] }
    case actionTypes.FETCH_CUSTOMER_CONTACT:
      return { ...state, customerContact: null }
    case actionTypes.UPADE_CUSTOMER_CONTACT:
      return { ...state, customerContact: action.payload }
    case actionTypes.DELETE_CUSTOMER_CONTACT:
      return state

    case actionTypes.SEARCH_CUSTOMER_CONTACTS:
      return {...state, customerContacts: null}

    case actionTypes.CUSTOMER_CONTACT_CREATED:
      return { ...state, customerContacts: [...state.customerContacts, action.payload] }
    case actionTypes.CUSTOMER_CONTACTS_LOADED:
      return { ...state, customerContacts: action.payload }
    case actionTypes.CUSTOMER_CONTACT_LOADED:
      return { ...state, customerContact: action.payload }
    case actionTypes.CUSTOMER_CONTACT_UPDATED:
      return {
        ...state,
        customerContact: action.payload,
        customerContacts: state.customerContacts.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.CUSTOMER_CONTACT_DELETED:
      return { ...state, customerContacts: state.customerContacts.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createCustomerContact: (customerContact, setSubmitting) => ({ type: actionTypes.CREATE_CUSTOMER_CONTACT, payload: { customerContact, setSubmitting } }),
  fetchCustomerContacts: () => ({ type: actionTypes.FETCH_CUSTOMER_CONTACTS }),
  fetchCustomerContact: (id) => ({ type: actionTypes.FETCH_CUSTOMER_CONTACT, payload: id }),
  updateCustomerContact: (customerContact, setSubmitting) => ({ type: actionTypes.UPADE_CUSTOMER_CONTACT, payload: { customerContact, setSubmitting } }),
  deleteCustomerContact: id => ({ type: actionTypes.DELETE_CUSTOMER_CONTACT, payload: id }),

  searchCustomerContacts: (values, setSubmitting) => ({ type: actionTypes.SEARCH_CUSTOMER_CONTACTS, payload: { values, setSubmitting } }),

  customerContactCreated: (customerContact) => ({ type: actionTypes.CUSTOMER_CONTACT_CREATED, payload: customerContact }),
  customerContactsLoaded: (customerContacts) => ({ type: actionTypes.CUSTOMER_CONTACTS_LOADED, payload: customerContacts }),
  customerContactLoaded: (customerContact) => ({ type: actionTypes.CUSTOMER_CONTACT_LOADED, payload: customerContact }),
  customerContactUpdated: (customerContact) => ({ type: actionTypes.CUSTOMER_CONTACT_UPDATED, payload: customerContact }),
  customerContactDeleted: id => ({ type: actionTypes.CUSTOMER_CONTACT_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_CUSTOMER_CONTACT, function * createCustomerContactSaga (action) {
    const { customerContact, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'customer_contacts', customerContact)
      yield put(actions.customerContactCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_CUSTOMER_CONTACTS, function * fetchCustomerContactsSaga () {
    try {
      const result = yield call(apiClient.get, 'customer_contacts')
      yield put(actions.customerContactsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_CUSTOMER_CONTACT, function * fetchCustomerContactSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `customer_contacts/${id}`)
      yield put(actions.customerContactLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_CUSTOMER_CONTACT, function * updateCustomerContactSaga (action) {
    const { customerContact, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `customer_contacts/${customerContact.id}`, customerContact)
      yield put(actions.customerContactUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_CUSTOMER_CONTACT, function * deleteCustomerContactSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `customer_contacts/${id}`)
      yield put(actions.customerContactDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.SEARCH_CUSTOMER_CONTACTS, function * searchCustomerContactsSaga(action) {
    const { values, setSubmitting } = action.payload
    try{
      const result = yield call(apiClient.get, 'customer_contacts', {params: {
        customer_code: !values.customer_code ? null : values.customer_code,
        customer_vat_no: !values.customer_vat_no ? null : values.customer_vat_no,
        customer_name: !values.customer_name ? null : values.customer_name
      }})
      yield put(actions.customerContactsLoaded(result.data.data))
    } catch(e) {
      notifyError(e.response ? e.response.statusText: e.message)
    } finally {
      setSubmitting(false)
    }
  })
}
