import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_INVOICE_SOURCE: 'invoiceSource/CREATE_INVOICE_SOURCE',
  FETCH_INVOICE_SOURCES: 'invoiceSource/FETCH_INVOICE_SOURCES',
  FETCH_INVOICE_SOURCE: 'invoiceSource/FETCH_INVOICE_SOURCE',
  UPADE_INVOICE_SOURCE: 'invoiceSource/UPADE_INVOICE_SOURCE',
  DELETE_INVOICE_SOURCE: 'invoiceSource/DELETE_INVOICE_SOURCE',

  INVOICE_SOURCE_CREATED: 'invoiceSource/INVOICE_SOURCE_CREATED',
  INVOICE_SOURCES_LOADED: 'invoiceSource/INVOICE_SOURCES_LOADED',
  INVOICE_SOURCE_LOADED: 'invoiceSource/INVOICE_SOURCE_LOADED',
  INVOICE_SOURCE_UPDATED: 'invoiceSource/INVOICE_SOURCE_UPDATED',
  INVOICE_SOURCE_DELETED: 'invoiceSource/INVOICE_SOURCE_DELETED'
}

const initialState = {
  invoiceSource: null,
  invoiceSources: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_INVOICE_SOURCE:
      return { ...state, invoiceSource: action.payload }
    case actionTypes.FETCH_INVOICE_SOURCES:
      return { ...state, invoiceSources: null }
    case actionTypes.FETCH_INVOICE_SOURCE:
      return { ...state, invoiceSource: null }
    case actionTypes.UPADE_INVOICE_SOURCE:
      return { ...state, invoiceSource: action.payload }
    case actionTypes.DELETE_INVOICE_SOURCE:
      return state

    case actionTypes.INVOICE_SOURCE_CREATED:
      return { ...state, invoiceSources: [...state.invoiceSources, action.payload] }
    case actionTypes.INVOICE_SOURCES_LOADED:
      return { ...state, invoiceSources: action.payload }
    case actionTypes.INVOICE_SOURCE_LOADED:
      return { ...state, invoiceSource: action.payload }
    case actionTypes.INVOICE_SOURCE_UPDATED:
      return {
        ...state,
        invoiceSource: action.payload,
        invoiceSources: state.invoiceSources.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.INVOICE_SOURCE_DELETED:
      return { ...state, invoiceSources: state.invoiceSources.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createInvoiceSource: (invoiceSource, setSubmitting) => ({ type: actionTypes.CREATE_INVOICE_SOURCE, payload: { invoiceSource, setSubmitting } }),
  fetchInvoiceSources: () => ({ type: actionTypes.FETCH_INVOICE_SOURCES }),
  fetchInvoiceSource: (id) => ({ type: actionTypes.FETCH_INVOICE_SOURCE, payload: id }),
  updateInvoiceSource: (invoiceSource, setSubmitting) => ({ type: actionTypes.UPADE_INVOICE_SOURCE, payload: { invoiceSource, setSubmitting } }),
  deleteInvoiceSource: id => ({ type: actionTypes.DELETE_INVOICE_SOURCE, payload: id }),
  invoiceSourceCreated: (invoiceSource) => ({ type: actionTypes.INVOICE_SOURCE_CREATED, payload: invoiceSource }),
  invoiceSourcesLoaded: (invoiceSources) => ({ type: actionTypes.INVOICE_SOURCES_LOADED, payload: invoiceSources }),
  invoiceSourceLoaded: (invoiceSource) => ({ type: actionTypes.INVOICE_SOURCE_LOADED, payload: invoiceSource }),
  invoiceSourceUpdated: (invoiceSource) => ({ type: actionTypes.INVOICE_SOURCE_UPDATED, payload: invoiceSource }),
  invoiceSourceDeleted: id => ({ type: actionTypes.INVOICE_SOURCE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_INVOICE_SOURCE, function * createInvoiceSourceSaga (action) {
    const { invoiceSource, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'invoice_sources', invoiceSource)
      yield put(actions.invoiceSourceCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_INVOICE_SOURCES, function * fetchInvoiceSourcesSaga () {
    try {
      const result = yield call(apiClient.get, 'invoice_sources')
      yield put(actions.invoiceSourcesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_INVOICE_SOURCE, function * fetchInvoiceSourceSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `invoice_sources/${id}`)
      yield put(actions.invoiceSourceLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_INVOICE_SOURCE, function * updateInvoiceSourceSaga (action) {
    const { invoiceSource, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `invoice_sources/${invoiceSource.id}`, invoiceSource)
      yield put(actions.invoiceSourceUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_INVOICE_SOURCE, function * deleteInvoiceSourceSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `invoice_sources/${id}`)
      yield put(actions.invoiceSourceDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
