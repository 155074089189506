import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SUBSCRIPTION_PERIOD_ITEM: 'subscriptionPeriodItem/CREATE_SUBSCRIPTION_PERIOD_ITEM',
  FETCH_SUBSCRIPTION_PERIOD_ITEMS: 'subscriptionPeriodItem/FETCH_SUBSCRIPTION_PERIOD_ITEMS',
  FETCH_SUBSCRIPTION_PERIOD_ITEM: 'subscriptionPeriodItem/FETCH_SUBSCRIPTION_PERIOD_ITEM',
  UPADE_SUBSCRIPTION_PERIOD_ITEM: 'subscriptionPeriodItem/UPADE_SUBSCRIPTION_PERIOD_ITEM',
  DELETE_SUBSCRIPTION_PERIOD_ITEM: 'subscriptionPeriodItem/DELETE_SUBSCRIPTION_PERIOD_ITEM',

  SUBSCRIPTION_PERIOD_ITEM_CREATED: 'subscriptionPeriodItem/SUBSCRIPTION_PERIOD_ITEM_CREATED',
  SUBSCRIPTION_PERIOD_ITEMS_LOADED: 'subscriptionPeriodItem/SUBSCRIPTION_PERIOD_ITEMS_LOADED',
  SUBSCRIPTION_PERIOD_ITEM_LOADED: 'subscriptionPeriodItem/SUBSCRIPTION_PERIOD_ITEM_LOADED',
  SUBSCRIPTION_PERIOD_ITEM_UPDATED: 'subscriptionPeriodItem/SUBSCRIPTION_PERIOD_ITEM_UPDATED',
  SUBSCRIPTION_PERIOD_ITEM_DELETED: 'subscriptionPeriodItem/SUBSCRIPTION_PERIOD_ITEM_DELETED'
}

const initialState = {
  subscriptionPeriodItem: null,
  subscriptionPeriodItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SUBSCRIPTION_PERIOD_ITEM:
      return { ...state, subscriptionPeriodItem: action.payload }
    case actionTypes.FETCH_SUBSCRIPTION_PERIOD_ITEMS:
      return { ...state, subscriptionPeriodItems: null }
    case actionTypes.FETCH_SUBSCRIPTION_PERIOD_ITEM:
      return { ...state, subscriptionPeriodItem: null }
    case actionTypes.UPADE_SUBSCRIPTION_PERIOD_ITEM:
      return { ...state, subscriptionPeriodItem: action.payload }
    case actionTypes.DELETE_SUBSCRIPTION_PERIOD_ITEM:
      return state

    case actionTypes.SUBSCRIPTION_PERIOD_ITEM_CREATED:
      return { ...state, subscriptionPeriodItems: [...state.subscriptionPeriodItems, action.payload] }
    case actionTypes.SUBSCRIPTION_PERIOD_ITEMS_LOADED:
      return { ...state, subscriptionPeriodItems: action.payload }
    case actionTypes.SUBSCRIPTION_PERIOD_ITEM_LOADED:
      return { ...state, subscriptionPeriodItem: action.payload }
    case actionTypes.SUBSCRIPTION_PERIOD_ITEM_UPDATED:
      return {
        ...state,
        subscriptionPeriodItem: action.payload,
        subscriptionPeriodItems: state.subscriptionPeriodItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SUBSCRIPTION_PERIOD_ITEM_DELETED:
      return { ...state, subscriptionPeriodItems: state.subscriptionPeriodItems.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSubscriptionPeriodItem: (subscriptionPeriodItem, setSubmitting) => ({ type: actionTypes.CREATE_SUBSCRIPTION_PERIOD_ITEM, payload: { subscriptionPeriodItem, setSubmitting } }),
  fetchSubscriptionPeriodItems: () => ({ type: actionTypes.FETCH_SUBSCRIPTION_PERIOD_ITEMS }),
  fetchSubscriptionPeriodItem: (id) => ({ type: actionTypes.FETCH_SUBSCRIPTION_PERIOD_ITEM, payload: id }),
  updateSubscriptionPeriodItem: (subscriptionPeriodItem, setSubmitting) => ({ type: actionTypes.UPADE_SUBSCRIPTION_PERIOD_ITEM, payload: { subscriptionPeriodItem, setSubmitting } }),
  deleteSubscriptionPeriodItem: id => ({ type: actionTypes.DELETE_SUBSCRIPTION_PERIOD_ITEM, payload: id }),
  subscriptionPeriodItemCreated: (subscriptionPeriodItem) => ({ type: actionTypes.SUBSCRIPTION_PERIOD_ITEM_CREATED, payload: subscriptionPeriodItem }),
  subscriptionPeriodItemsLoaded: (subscriptionPeriodItems) => ({ type: actionTypes.SUBSCRIPTION_PERIOD_ITEMS_LOADED, payload: subscriptionPeriodItems }),
  subscriptionPeriodItemLoaded: (subscriptionPeriodItem) => ({ type: actionTypes.SUBSCRIPTION_PERIOD_ITEM_LOADED, payload: subscriptionPeriodItem }),
  subscriptionPeriodItemUpdated: (subscriptionPeriodItem) => ({ type: actionTypes.SUBSCRIPTION_PERIOD_ITEM_UPDATED, payload: subscriptionPeriodItem }),
  subscriptionPeriodItemDeleted: id => ({ type: actionTypes.SUBSCRIPTION_PERIOD_ITEM_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SUBSCRIPTION_PERIOD_ITEM, function * createSubscriptionPeriodItemSaga (action) {
    const { subscriptionPeriodItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'subscription_period_items', subscriptionPeriodItem)
      yield put(actions.subscriptionPeriodItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_PERIOD_ITEMS, function * fetchSubscriptionPeriodItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'subscription_period_items')
      yield put(actions.subscriptionPeriodItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_PERIOD_ITEM, function * fetchSubscriptionPeriodItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `subscription_period_items/${id}`)
      yield put(actions.subscriptionPeriodItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SUBSCRIPTION_PERIOD_ITEM, function * updateSubscriptionPeriodItemSaga (action) {
    const { subscriptionPeriodItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `subscription_period_items/${subscriptionPeriodItem.id}`, subscriptionPeriodItem)
      yield put(actions.subscriptionPeriodItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SUBSCRIPTION_PERIOD_ITEM, function * deleteSubscriptionPeriodItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `subscription_period_items/${id}`)
      yield put(actions.subscriptionPeriodItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
