import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_ORDERING_ITEM: 'orderingItem/CREATE_ORDERING_ITEM',
  FETCH_ORDERING_ITEMS: 'orderingItem/FETCH_ORDERING_ITEMS',
  FETCH_ORDERING_ITEM: 'orderingItem/FETCH_ORDERING_ITEM',
  UPADE_ORDERING_ITEM: 'orderingItem/UPADE_ORDERING_ITEM',
  DELETE_ORDERING_ITEM: 'orderingItem/DELETE_ORDERING_ITEM',

  ORDERING_ITEM_CREATED: 'orderingItem/ORDERING_ITEM_CREATED',
  ORDERING_ITEMS_LOADED: 'orderingItem/ORDERING_ITEMS_LOADED',
  ORDERING_ITEM_LOADED: 'orderingItem/ORDERING_ITEM_LOADED',
  ORDERING_ITEM_UPDATED: 'orderingItem/ORDERING_ITEM_UPDATED',
  ORDERING_ITEM_DELETED: 'orderingItem/ORDERING_ITEM_DELETED'
}

const initialState = {
  orderingItem: null,
  orderingItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ORDERING_ITEM:
      return { ...state, orderingItem: action.payload }
    case actionTypes.FETCH_ORDERING_ITEMS:
      return { ...state, orderingItems: null }
    case actionTypes.FETCH_ORDERING_ITEM:
      return { ...state, orderingItem: null }
    case actionTypes.UPADE_ORDERING_ITEM:
      return { ...state, orderingItem: action.payload }
    case actionTypes.DELETE_ORDERING_ITEM:
      return state

    case actionTypes.ORDERING_ITEM_CREATED:
      return !state.orderingItems ? { ...state, orderingItems: [action.payload] } : { ...state, orderingItems: [...state.orderingItems, action.payload] }
    case actionTypes.ORDERING_ITEMS_LOADED:
      return { ...state, orderingItems: action.payload }
    case actionTypes.ORDERING_ITEM_LOADED:
      return { ...state, orderingItem: action.payload }
    case actionTypes.ORDERING_ITEM_UPDATED:
      return {
        ...state,
        orderingItem: action.payload,
        orderingItems: state.orderingItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ORDERING_ITEM_DELETED:
      return { ...state, orderingItems: state.orderingItems.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createOrderingItem: (orderingItem, setSubmitting) => ({ type: actionTypes.CREATE_ORDERING_ITEM, payload: { orderingItem, setSubmitting } }),
  fetchOrderingItems: () => ({ type: actionTypes.FETCH_ORDERING_ITEMS }),
  fetchOrderingItem: (id) => ({ type: actionTypes.FETCH_ORDERING_ITEM, payload: id }),
  updateOrderingItem: (orderingItem, setSubmitting) => ({ type: actionTypes.UPADE_ORDERING_ITEM, payload: { orderingItem, setSubmitting } }),
  deleteOrderingItem: id => ({ type: actionTypes.DELETE_ORDERING_ITEM, payload: id }),
  orderingItemCreated: (orderingItem) => ({ type: actionTypes.ORDERING_ITEM_CREATED, payload: orderingItem }),
  orderingItemsLoaded: (orderingItems) => ({ type: actionTypes.ORDERING_ITEMS_LOADED, payload: orderingItems }),
  orderingItemLoaded: (orderingItem) => ({ type: actionTypes.ORDERING_ITEM_LOADED, payload: orderingItem }),
  orderingItemUpdated: (orderingItem) => ({ type: actionTypes.ORDERING_ITEM_UPDATED, payload: orderingItem }),
  orderingItemDeleted: id => ({ type: actionTypes.ORDERING_ITEM_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ORDERING_ITEM, function * createOrderingItemSaga (action) {
    const { orderingItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'ordering_items', orderingItem)
      yield put(actions.orderingItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ORDERING_ITEMS, function * fetchOrderingItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'ordering_items')
      yield put(actions.orderingItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ORDERING_ITEM, function * fetchOrderingItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `ordering_items/${id}`)
      yield put(actions.orderingItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ORDERING_ITEM, function * updateOrderingItemSaga (action) {
    const { orderingItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `ordering_items/${orderingItem.id}`, orderingItem)
      yield put(actions.orderingItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ORDERING_ITEM, function * deleteOrderingItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `ordering_items/${id}`)
      yield put(actions.orderingItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
