import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { insertEmptyRow, insertRows } from 'shared/itemHelper'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_PACKAGE_ITEM: 'productPackageItem/CREATE_PRODUCT_PACKAGE_ITEM',
  FETCH_PRODUCT_PACKAGE_ITEMS: 'productPackageItem/FETCH_PRODUCT_PACKAGE_ITEMS',
  FETCH_PRODUCT_PACKAGE_ITEM: 'productPackageItem/FETCH_PRODUCT_PACKAGE_ITEM',
  UPADE_PRODUCT_PACKAGE_ITEM: 'productPackageItem/UPADE_PRODUCT_PACKAGE_ITEM',
  DELETE_PRODUCT_PACKAGE_ITEM: 'productPackageItem/DELETE_PRODUCT_PACKAGE_ITEM',

  PRODUCT_PACKAGE_ITEM_CREATED: 'productPackageItem/PRODUCT_PACKAGE_ITEM_CREATED',
  PRODUCT_PACKAGE_ITEMS_LOADED: 'productPackageItem/PRODUCT_PACKAGE_ITEMS_LOADED',
  PRODUCT_PACKAGE_ITEM_LOADED: 'productPackageItem/PRODUCT_PACKAGE_ITEM_LOADED',
  PRODUCT_PACKAGE_ITEM_UPDATED: 'productPackageItem/PRODUCT_PACKAGE_ITEM_UPDATED',
  PRODUCT_PACKAGE_ITEM_DELETED: 'productPackageItem/PRODUCT_PACKAGE_ITEM_DELETED',

  INSERT_EMPTY_ROW: 'productPackageItem/INSERT_EMPTY_ROW',
  DELETE_ROW: 'productPackageItem/DELETE_ROW',
  INSERT_ROWS: 'productPackageItem/INSERT_ROWS'
}

const initialState = {
  productPackageItem: null,
  productPackageItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_PACKAGE_ITEM:
      return { ...state, productPackageItem: action.payload }
    case actionTypes.FETCH_PRODUCT_PACKAGE_ITEMS:
      return { ...state, productPackageItems: null }
    case actionTypes.FETCH_PRODUCT_PACKAGE_ITEM:
      return { ...state, productPackageItem: null }
    case actionTypes.UPADE_PRODUCT_PACKAGE_ITEM:
      return { ...state, productPackageItem: action.payload }
    case actionTypes.DELETE_PRODUCT_PACKAGE_ITEM:
      return state

    case actionTypes.PRODUCT_PACKAGE_ITEM_CREATED:
      return { ...state, productPackageItems: [...state.productPackageItems, action.payload] }
    case actionTypes.PRODUCT_PACKAGE_ITEMS_LOADED:
      return { ...state, productPackageItems: action.payload }
    case actionTypes.PRODUCT_PACKAGE_ITEM_LOADED:
      return { ...state, productPackageItem: action.payload }
    case actionTypes.PRODUCT_PACKAGE_ITEM_UPDATED:
      return {
        ...state,
        productPackageItem: action.payload
      }
    case actionTypes.PRODUCT_PACKAGE_ITEM_DELETED:
      return { ...state, productPackageItems: state.productPackageItems.filter(item => item.id !== action.payload) }

    case actionTypes.INSERT_EMPTY_ROW: {
      return {
        ...state,
        productPackageItems: insertEmptyRow(state.productPackageItems, action.payload)
      }
    }
    case actionTypes.DELETE_ROW: {
      const filterd = state.productPackageItems.filter(item => item.position !== action.payload)
      if (filterd.length === 0) {
        return { ...state, productPackageItems: [{ position: 1 }] }
      } else {
        return { ...state, productPackageItems: filterd.map((item, index) => ({ ...item, position: index + 1 })) }
      }
    }

    case actionTypes.INSERT_ROWS: {
      const nodes = action.payload.rows
      return {
        ...state,
        productPackageItems: insertRows(
          state.productPackageItems,
          nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              qty: 0,
              product_id: node.data.id,
              id: null
            }
          })),
          action.payload.position)
      }
    }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductPackageItem: (productPackageItem, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_PACKAGE_ITEM, payload: { productPackageItem, setSubmitting } }),
  fetchProductPackageItems: () => ({ type: actionTypes.FETCH_PRODUCT_PACKAGE_ITEMS }),
  fetchProductPackageItem: (id) => ({ type: actionTypes.FETCH_PRODUCT_PACKAGE_ITEM, payload: id }),
  updateProductPackageItem: (productPackageItem, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_PACKAGE_ITEM, payload: { productPackageItem, setSubmitting } }),
  deleteProductPackageItem: id => ({ type: actionTypes.DELETE_PRODUCT_PACKAGE_ITEM, payload: id }),
  productPackageItemCreated: (productPackageItem) => ({ type: actionTypes.PRODUCT_PACKAGE_ITEM_CREATED, payload: productPackageItem }),
  productPackageItemsLoaded: (productPackageItems) => ({ type: actionTypes.PRODUCT_PACKAGE_ITEMS_LOADED, payload: productPackageItems }),
  productPackageItemLoaded: (productPackageItem) => ({ type: actionTypes.PRODUCT_PACKAGE_ITEM_LOADED, payload: productPackageItem }),
  productPackageItemUpdated: (productPackageItem) => ({ type: actionTypes.PRODUCT_PACKAGE_ITEM_UPDATED, payload: productPackageItem }),
  productPackageItemDeleted: id => ({ type: actionTypes.PRODUCT_PACKAGE_ITEM_DELETED, payload: id }),
  inertEmptyRow: position => ({ type: actionTypes.INSERT_EMPTY_ROW, payload: position }),
  deleteRow: position => ({ type: actionTypes.DELETE_ROW, payload: position }),
  inertRows: (position, rows) => ({ type: actionTypes.INSERT_ROWS, payload: { position, rows } })

}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_PACKAGE_ITEM, function * createProductPackageItemSaga (action) {
    const { productPackageItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_package_items', productPackageItem)
      yield put(actions.productPackageItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_PACKAGE_ITEMS, function * fetchProductPackageItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_package_items')
      yield put(actions.productPackageItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_PACKAGE_ITEM, function * fetchProductPackageItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_package_items/${id}`)
      yield put(actions.productPackageItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_PACKAGE_ITEM, function * updateProductPackageItemSaga (action) {
    const { productPackageItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_package_items/${productPackageItem.id}`, productPackageItem)
      yield put(actions.productPackageItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_PACKAGE_ITEM, function * deleteProductPackageItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_package_items/${id}`)
      yield put(actions.productPackageItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
