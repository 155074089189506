import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { insertEmptyRow, insertRows } from 'shared/itemHelper'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_COMBO_ITEM: 'productComboItem/CREATE_PRODUCT_COMBO_ITEM',
  FETCH_PRODUCT_COMBO_ITEMS: 'productComboItem/FETCH_PRODUCT_COMBO_ITEMS',
  FETCH_PRODUCT_COMBO_ITEM: 'productComboItem/FETCH_PRODUCT_COMBO_ITEM',
  UPADE_PRODUCT_COMBO_ITEM: 'productComboItem/UPADE_PRODUCT_COMBO_ITEM',
  DELETE_PRODUCT_COMBO_ITEM: 'productComboItem/DELETE_PRODUCT_COMBO_ITEM',

  PRODUCT_COMBO_ITEM_CREATED: 'productComboItem/PRODUCT_COMBO_ITEM_CREATED',
  PRODUCT_COMBO_ITEMS_LOADED: 'productComboItem/PRODUCT_COMBO_ITEMS_LOADED',
  PRODUCT_COMBO_ITEM_LOADED: 'productComboItem/PRODUCT_COMBO_ITEM_LOADED',
  PRODUCT_COMBO_ITEM_UPDATED: 'productComboItem/PRODUCT_COMBO_ITEM_UPDATED',
  PRODUCT_COMBO_ITEM_DELETED: 'productComboItem/PRODUCT_COMBO_ITEM_DELETED',

  INSERT_EMPTY_ROW: 'productComboItem/INSERT_EMPTY_ROW',
  DELETE_ROW: 'productComboItem/DELETE_ROW',
  INSERT_ROWS: 'productComboItem/INSERT_ROWS'
}

const initialState = {
  productComboItem: null,
  productComboItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_COMBO_ITEM:
      return { ...state, productComboItem: action.payload }
    case actionTypes.FETCH_PRODUCT_COMBO_ITEMS:
      return { ...state, productComboItems: null }
    case actionTypes.FETCH_PRODUCT_COMBO_ITEM:
      return { ...state, productComboItem: null }
    case actionTypes.UPADE_PRODUCT_COMBO_ITEM:
      return { ...state, productComboItem: action.payload }
    case actionTypes.DELETE_PRODUCT_COMBO_ITEM:
      return state

    case actionTypes.PRODUCT_COMBO_ITEM_CREATED:
      return !state.productComboItems ? { ...state, productComboItems: [action.payload] } : { ...state, productComboItems: [...state.productComboItems, action.payload] }
    case actionTypes.PRODUCT_COMBO_ITEMS_LOADED:
      return { ...state, productComboItems: action.payload }
    case actionTypes.PRODUCT_COMBO_ITEM_LOADED:
      return { ...state, productComboItem: action.payload }
    case actionTypes.PRODUCT_COMBO_ITEM_UPDATED:
      return {
        ...state,
        productComboItem: action.payload,
        productComboItems: state.productComboItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_COMBO_ITEM_DELETED:
      return { ...state, productComboItems: state.productComboItems.filter(item => item.id !== action.payload) }

    case actionTypes.INSERT_EMPTY_ROW:
      return {
        ...state,
        productComboItems: insertEmptyRow(state.productComboItems, action.payload)
      }
    case actionTypes.DELETE_ROW: {
      const filterd = state.productComboItems.filter(item => item.position !== action.payload)
      if (filterd.length === 0) {
        return { ...state, productComboItems: [{ position: 1 }] }
      } else {
        return { ...state, productComboItems: filterd.map((item, index) => ({ ...item, position: index + 1 })) }
      }
    }
    case actionTypes.INSERT_ROWS: {
      const nodes = action.payload.rows
      return {
        ...state,
        productComboItems: insertRows(
          state.productComboItems,
          nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              qty: 0,
              product_id: node.data.id,
              id: null
            }
          })),
          action.payload.position)
      }
    }
    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductComboItem: (productComboItem, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_COMBO_ITEM, payload: { productComboItem, setSubmitting } }),
  fetchProductComboItems: () => ({ type: actionTypes.FETCH_PRODUCT_COMBO_ITEMS }),
  fetchProductComboItem: (id) => ({ type: actionTypes.FETCH_PRODUCT_COMBO_ITEM, payload: id }),
  updateProductComboItem: (productComboItem, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_COMBO_ITEM, payload: { productComboItem, setSubmitting } }),
  deleteProductComboItem: id => ({ type: actionTypes.DELETE_PRODUCT_COMBO_ITEM, payload: id }),
  productComboItemCreated: (productComboItem) => ({ type: actionTypes.PRODUCT_COMBO_ITEM_CREATED, payload: productComboItem }),
  productComboItemsLoaded: (productComboItems) => ({ type: actionTypes.PRODUCT_COMBO_ITEMS_LOADED, payload: productComboItems }),
  productComboItemLoaded: (productComboItem) => ({ type: actionTypes.PRODUCT_COMBO_ITEM_LOADED, payload: productComboItem }),
  productComboItemUpdated: (productComboItem) => ({ type: actionTypes.PRODUCT_COMBO_ITEM_UPDATED, payload: productComboItem }),
  productComboItemDeleted: id => ({ type: actionTypes.PRODUCT_COMBO_ITEM_DELETED, payload: id }),
  inertEmptyRow: position => ({ type: actionTypes.INSERT_EMPTY_ROW, payload: position }),
  deleteRow: position => ({ type: actionTypes.DELETE_ROW, payload: position }),
  inertRows: (position, rows) => ({ type: actionTypes.INSERT_ROWS, payload: { position, rows } })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_COMBO_ITEM, function * createProductComboItemSaga (action) {
    const { productComboItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_combo_items', productComboItem)
      yield put(actions.productComboItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMBO_ITEMS, function * fetchProductComboItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_combo_items')
      yield put(actions.productComboItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMBO_ITEM, function * fetchProductComboItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_combo_items/${id}`)
      yield put(actions.productComboItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_COMBO_ITEM, function * updateProductComboItemSaga (action) {
    const { productComboItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_combo_items/${productComboItem.id}`, productComboItem)
      yield put(actions.productComboItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_COMBO_ITEM, function * deleteProductComboItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_combo_items/${id}`)
      yield put(actions.productComboItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
