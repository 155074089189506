import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_BANK: 'bank/CREATE_BANK',
  FETCH_BANKS: 'bank/FETCH_BANKS',
  FETCH_BANK: 'bank/FETCH_BANK',
  UPADE_BANK: 'bank/UPADE_BANK',
  DELETE_BANK: 'bank/DELETE_BANK',

  BANK_CREATED: 'bank/BANK_CREATED',
  BANKS_LOADED: 'bank/BANKS_LOADED',
  BANK_LOADED: 'bank/BANK_LOADED',
  BANK_UPDATED: 'bank/BANK_UPDATED',
  BANK_DELETED: 'bank/BANK_DELETED'
}

const initialState = {
  bank: null,
  banks: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_BANK:
      return { ...state, bank: action.payload }
    case actionTypes.FETCH_BANKS:
      return { ...state, banks: null }
    case actionTypes.FETCH_BANK:
      return { ...state, bank: null }
    case actionTypes.UPADE_BANK:
      return { ...state, bank: action.payload }
    case actionTypes.DELETE_BANK:
      return state

    case actionTypes.BANKS_LOADED:
      return { ...state, banks: action.payload }
    case actionTypes.BANK_LOADED:
      return { ...state, bank: action.payload }
    case actionTypes.BANK_UPDATED:
      return {
        ...state,
        bank: action.payload,
        banks: state.banks.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.BANK_DELETED:
      return { ...state, banks: state.banks.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createBank: (bank, setSubmitting) => ({ type: actionTypes.CREATE_BANK, payload: { bank, setSubmitting } }),
  fetchBanks: () => ({ type: actionTypes.FETCH_BANKS }),
  fetchBank: (id) => ({ type: actionTypes.FETCH_BANK, payload: id }),
  updateBank: (bank, setSubmitting) => ({ type: actionTypes.UPADE_BANK, payload: { bank, setSubmitting } }),
  deleteBank: id => ({ type: actionTypes.DELETE_BANK, payload: id }),
  banksLoaded: (banks) => ({ type: actionTypes.BANKS_LOADED, payload: banks }),
  bankLoaded: (bank) => ({ type: actionTypes.BANK_LOADED, payload: bank }),
  bankUpdated: (bank) => ({ type: actionTypes.BANK_UPDATED, payload: bank }),
  bankDeleted: id => ({ type: actionTypes.BANK_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_BANK, function * createBankSaga (action) {
    const { bank, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'banks', bank)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_BANKS, function * fetchBanksSaga () {
    try {
      const result = yield call(apiClient.get, 'banks')
      yield put(actions.banksLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_BANK, function * fetchBankSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `banks/${id}`)
      yield put(actions.bankLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_BANK, function * updateBankSaga (action) {
    const { bank, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `banks/${bank.id}`, bank)
      yield put(actions.bankUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_BANK, function * deleteBankSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `banks/${id}`)
      yield put(actions.bankDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
