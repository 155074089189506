import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_ORDERING: 'ordering/CREATE_ORDERING',
  FETCH_ORDERINGS: 'ordering/FETCH_ORDERINGS',
  FETCH_ORDERING: 'ordering/FETCH_ORDERING',
  UPADE_ORDERING: 'ordering/UPADE_ORDERING',
  DELETE_ORDERING: 'ordering/DELETE_ORDERING',

  ORDERING_CREATED: 'ordering/ORDERING_CREATED',
  ORDERINGS_LOADED: 'ordering/ORDERINGS_LOADED',
  ORDERING_LOADED: 'ordering/ORDERING_LOADED',
  ORDERING_UPDATED: 'ordering/ORDERING_UPDATED',
  ORDERING_DELETED: 'ordering/ORDERING_DELETED'
}

const initialState = {
  ordering: null,
  orderings: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ORDERING:
      return { ...state, ordering: action.payload }
    case actionTypes.FETCH_ORDERINGS:
      return { ...state, orderings: null }
    case actionTypes.FETCH_ORDERING:
      return { ...state, ordering: null }
    case actionTypes.UPADE_ORDERING:
      return { ...state, ordering: action.payload }
    case actionTypes.DELETE_ORDERING:
      return state

    case actionTypes.ORDERINGS_LOADED:
      return { ...state, orderings: action.payload }
    case actionTypes.ORDERING_LOADED:
      return { ...state, ordering: action.payload }
    case actionTypes.ORDERING_UPDATED:
      return {
        ...state,
        ordering: action.payload,
        orderings: state.orderings.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ORDERING_DELETED:
      return { ...state, orderings: state.orderings.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createOrdering: (ordering, setSubmitting) => ({ type: actionTypes.CREATE_ORDERING, payload: { ordering, setSubmitting } }),
  fetchOrderings: () => ({ type: actionTypes.FETCH_ORDERINGS }),
  fetchOrdering: (id) => ({ type: actionTypes.FETCH_ORDERING, payload: id }),
  updateOrdering: (ordering, setSubmitting) => ({ type: actionTypes.UPADE_ORDERING, payload: { ordering, setSubmitting } }),
  deleteOrdering: id => ({ type: actionTypes.DELETE_ORDERING, payload: id }),
  orderingsLoaded: (orderings) => ({ type: actionTypes.ORDERINGS_LOADED, payload: orderings }),
  orderingLoaded: (ordering) => ({ type: actionTypes.ORDERING_LOADED, payload: ordering }),
  orderingUpdated: (ordering) => ({ type: actionTypes.ORDERING_UPDATED, payload: ordering }),
  orderingDeleted: id => ({ type: actionTypes.ORDERING_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ORDERING, function * createOrderingSaga (action) {
    const { ordering, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'orderings', ordering)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ORDERINGS, function * fetchOrderingsSaga () {
    try {
      const result = yield call(apiClient.get, 'orderings')
      yield put(actions.orderingsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ORDERING, function * fetchOrderingSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `orderings/${id}`)
      yield put(actions.orderingLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ORDERING, function * updateOrderingSaga (action) {
    const { ordering, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `orderings/${ordering.id}`, ordering)
      yield put(actions.orderingUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ORDERING, function * deleteOrderingSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `orderings/${id}`)
      yield put(actions.orderingDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
