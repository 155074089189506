export const constants = {
  IS_LOADING: 'IS_LOADING',
  SEARCH_ACTION: 'SEARCH_ACTION',
  SORT_ACTION: 'SORT_ACTION',
  FILTER_ACTION: 'FILTER_ACTION',
  TOGGLE_ACTION: 'TOGGLE_ACTION',
  ADD_TOAST: 'ADD_TOAST',
  DISPLAY_TOAST: 'DISPLAY_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
  ERROR_ACTION: 'ERROR_ACTION',
  CHANGE_PASSWORD_MODEL_ACTION: 'CHANGE_PASSWORD_MODEL_ACTION'
}

export const Routes = {
  APP_HOME: '/',
  ADMIN_LOGIN: '/login',
  ADMIN_LOGOUT: '/logout',
  ADMIN_HOME: '/dashboard'
}

export const LocalStorageKey = {
  USER: 'user',
  MEMBER: 'member'
}

export const TokenKey = {
  ADMIN: 'authtoken',
  MEMBER: 'memberToken'
}

export const NEW_MODLE_ID = -1
