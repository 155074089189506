import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_WAREHOUSE: 'warehouse/CREATE_WAREHOUSE',
  FETCH_WAREHOUSES: 'warehouse/FETCH_WAREHOUSES',
  FETCH_WAREHOUSE: 'warehouse/FETCH_WAREHOUSE',
  UPADE_WAREHOUSE: 'warehouse/UPADE_WAREHOUSE',
  DELETE_WAREHOUSE: 'warehouse/DELETE_WAREHOUSE',

  WAREHOUSE_CREATED: 'warehouse/WAREHOUSE_CREATED',
  WAREHOUSES_LOADED: 'warehouse/WAREHOUSES_LOADED',
  WAREHOUSE_LOADED: 'warehouse/WAREHOUSE_LOADED',
  WAREHOUSE_UPDATED: 'warehouse/WAREHOUSE_UPDATED',
  WAREHOUSE_DELETED: 'warehouse/WAREHOUSE_DELETED'
}

const initialState = {
  warehouse: null,
  warehouses: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_WAREHOUSE:
      return { ...state, warehouse: action.payload }
    case actionTypes.FETCH_WAREHOUSES:
      return { ...state, warehouses: null }
    case actionTypes.FETCH_WAREHOUSE:
      return { ...state, warehouse: null }
    case actionTypes.UPADE_WAREHOUSE:
      return { ...state, warehouse: action.payload }
    case actionTypes.DELETE_WAREHOUSE:
      return state

    case actionTypes.WAREHOUSE_CREATED:
      return { ...state, warehouses: [...state.warehouses, action.payload] }
    case actionTypes.WAREHOUSES_LOADED:
      return { ...state, warehouses: action.payload }
    case actionTypes.WAREHOUSE_LOADED:
      return { ...state, warehouse: action.payload }
    case actionTypes.WAREHOUSE_UPDATED:
      return {
        ...state,
        warehouse: action.payload,
        warehouses: state.warehouses.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.WAREHOUSE_DELETED:
      return { ...state, warehouses: state.warehouses.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createWarehouse: (warehouse, setSubmitting) => ({ type: actionTypes.CREATE_WAREHOUSE, payload: { warehouse, setSubmitting } }),
  fetchWarehouses: () => ({ type: actionTypes.FETCH_WAREHOUSES }),
  fetchWarehouse: (id) => ({ type: actionTypes.FETCH_WAREHOUSE, payload: id }),
  updateWarehouse: (warehouse, setSubmitting) => ({ type: actionTypes.UPADE_WAREHOUSE, payload: { warehouse, setSubmitting } }),
  deleteWarehouse: id => ({ type: actionTypes.DELETE_WAREHOUSE, payload: id }),
  warehouseCreated: (warehouse) => ({ type: actionTypes.WAREHOUSE_CREATED, payload: warehouse }),
  warehousesLoaded: (warehouses) => ({ type: actionTypes.WAREHOUSES_LOADED, payload: warehouses }),
  warehouseLoaded: (warehouse) => ({ type: actionTypes.WAREHOUSE_LOADED, payload: warehouse }),
  warehouseUpdated: (warehouse) => ({ type: actionTypes.WAREHOUSE_UPDATED, payload: warehouse }),
  warehouseDeleted: id => ({ type: actionTypes.WAREHOUSE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_WAREHOUSE, function * createWarehouseSaga (action) {
    const { warehouse, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'warehouses', warehouse)
      yield put(actions.warehouseCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_WAREHOUSES, function * fetchWarehousesSaga () {
    try {
      const result = yield call(apiClient.get, 'warehouses')
      yield put(actions.warehousesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_WAREHOUSE, function * fetchWarehouseSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `warehouses/${id}`)
      yield put(actions.warehouseLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_WAREHOUSE, function * updateWarehouseSaga (action) {
    const { warehouse, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `warehouses/${warehouse.id}`, warehouse)
      yield put(actions.warehouseUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_WAREHOUSE, function * deleteWarehouseSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `warehouses/${id}`)
      yield put(actions.warehouseDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
