import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_EMPLOYEE: 'employee/CREATE_EMPLOYEE',
  FETCH_EMPLOYEES: 'employee/FETCH_EMPLOYEES',
  FETCH_EMPLOYEE: 'employee/FETCH_EMPLOYEE',
  UPADE_EMPLOYEE: 'employee/UPADE_EMPLOYEE',
  DELETE_EMPLOYEE: 'employee/DELETE_EMPLOYEE',

  EMPLOYEE_CREATED: 'employee/EMPLOYEE_CREATED',
  EMPLOYEES_LOADED: 'employee/EMPLOYEES_LOADED',
  EMPLOYEE_LOADED: 'employee/EMPLOYEE_LOADED',
  EMPLOYEE_UPDATED: 'employee/EMPLOYEE_UPDATED',
  EMPLOYEE_DELETED: 'employee/EMPLOYEE_DELETED'
}

const initialState = {
  employee: null,
  employees: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_EMPLOYEE:
      return { ...state, employee: action.payload }
    case actionTypes.FETCH_EMPLOYEES:
      return { ...state, employees: null }
    case actionTypes.FETCH_EMPLOYEE:
      return { ...state, employee: null }
    case actionTypes.UPADE_EMPLOYEE:
      return { ...state, employee: action.payload }
    case actionTypes.DELETE_EMPLOYEE:
      return state

    case actionTypes.EMPLOYEE_CREATED:
      return { ...state, employees: [...state.employees, action.payload] }
    case actionTypes.EMPLOYEES_LOADED:
      return { ...state, employees: action.payload }
    case actionTypes.EMPLOYEE_LOADED:
      return { ...state, employee: action.payload }
    case actionTypes.EMPLOYEE_UPDATED:
      return {
        ...state,
        employee: action.payload,
        employees: state.employees.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.EMPLOYEE_DELETED:
      return { ...state, employees: state.employees.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createEmployee: (employee, setSubmitting) => ({ type: actionTypes.CREATE_EMPLOYEE, payload: { employee, setSubmitting } }),
  fetchEmployees: () => ({ type: actionTypes.FETCH_EMPLOYEES }),
  fetchEmployee: (id) => ({ type: actionTypes.FETCH_EMPLOYEE, payload: id }),
  updateEmployee: (employee, setSubmitting) => ({ type: actionTypes.UPADE_EMPLOYEE, payload: { employee, setSubmitting } }),
  deleteEmployee: id => ({ type: actionTypes.DELETE_EMPLOYEE, payload: id }),
  employeeCreated: (employee) => ({ type: actionTypes.EMPLOYEE_CREATED, payload: employee }),
  employeesLoaded: (employees) => ({ type: actionTypes.EMPLOYEES_LOADED, payload: employees }),
  employeeLoaded: (employee) => ({ type: actionTypes.EMPLOYEE_LOADED, payload: employee }),
  employeeUpdated: (employee) => ({ type: actionTypes.EMPLOYEE_UPDATED, payload: employee }),
  employeeDeleted: id => ({ type: actionTypes.EMPLOYEE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_EMPLOYEE, function * createEmployeeSaga (action) {
    const { employee, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'employees', employee)
      yield put(actions.employeeCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_EMPLOYEES, function * fetchEmployeesSaga () {
    try {
      const result = yield call(apiClient.get, 'employees')
      yield put(actions.employeesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_EMPLOYEE, function * fetchEmployeeSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `employees/${id}`)
      yield put(actions.employeeLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_EMPLOYEE, function * updateEmployeeSaga (action) {
    const { employee, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `employees/${employee.id}`, employee)
      yield put(actions.employeeUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_EMPLOYEE, function * deleteEmployeeSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `employees/${id}`)
      yield put(actions.employeeDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
