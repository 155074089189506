import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_SUBSCRIPTION_QUANTITY: 'subscriptionQuantity/CREATE_SUBSCRIPTION_QUANTITY',
  FETCH_SUBSCRIPTION_QUANTITIES: 'subscriptionQuantity/FETCH_SUBSCRIPTION_QUANTITIES',
  FETCH_SUBSCRIPTION_QUANTITY: 'subscriptionQuantity/FETCH_SUBSCRIPTION_QUANTITY',
  UPADE_SUBSCRIPTION_QUANTITY: 'subscriptionQuantity/UPADE_SUBSCRIPTION_QUANTITY',
  DELETE_SUBSCRIPTION_QUANTITY: 'subscriptionQuantity/DELETE_SUBSCRIPTION_QUANTITY',

  SUBSCRIPTION_QUANTITY_CREATED: 'subscriptionQuantity/SUBSCRIPTION_QUANTITY_CREATED',
  SUBSCRIPTION_QUANTITIES_LOADED: 'subscriptionQuantity/SUBSCRIPTION_QUANTITIES_LOADED',
  SUBSCRIPTION_QUANTITY_LOADED: 'subscriptionQuantity/SUBSCRIPTION_QUANTITY_LOADED',
  SUBSCRIPTION_QUANTITY_UPDATED: 'subscriptionQuantity/SUBSCRIPTION_QUANTITY_UPDATED',
  SUBSCRIPTION_QUANTITY_DELETED: 'subscriptionQuantity/SUBSCRIPTION_QUANTITY_DELETED'
}

const initialState = {
  subscriptionQuantity: null,
  subscriptionQuantities: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SUBSCRIPTION_QUANTITY:
      return { ...state, subscriptionQuantity: action.payload }
    case actionTypes.FETCH_SUBSCRIPTION_QUANTITIES:
      return { ...state, subscriptionQuantities: null }
    case actionTypes.FETCH_SUBSCRIPTION_QUANTITY:
      return { ...state, subscriptionQuantity: null }
    case actionTypes.UPADE_SUBSCRIPTION_QUANTITY:
      return { ...state, subscriptionQuantity: action.payload }
    case actionTypes.DELETE_SUBSCRIPTION_QUANTITY:
      return state

    case actionTypes.SUBSCRIPTION_QUANTITY_CREATED:
      return { ...state, subscriptionQuantities: [...state.subscriptionQuantities, action.payload] }
    case actionTypes.SUBSCRIPTION_QUANTITIES_LOADED:
      return { ...state, subscriptionQuantities: action.payload }
    case actionTypes.SUBSCRIPTION_QUANTITY_LOADED:
      return { ...state, subscriptionQuantity: action.payload }
    case actionTypes.SUBSCRIPTION_QUANTITY_UPDATED:
      return {
        ...state,
        subscriptionQuantity: action.payload,
        subscriptionQuantities: state.subscriptionQuantities.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.SUBSCRIPTION_QUANTITY_DELETED:
      return { ...state, subscriptionQuantities: state.subscriptionQuantities.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createSubscriptionQuantity: (subscriptionQuantity, setSubmitting) => ({ type: actionTypes.CREATE_SUBSCRIPTION_QUANTITY, payload: { subscriptionQuantity, setSubmitting } }),
  fetchSubscriptionQuantities: () => ({ type: actionTypes.FETCH_SUBSCRIPTION_QUANTITIES }),
  fetchSubscriptionQuantity: (id) => ({ type: actionTypes.FETCH_SUBSCRIPTION_QUANTITY, payload: id }),
  updateSubscriptionQuantity: (subscriptionQuantity, setSubmitting) => ({ type: actionTypes.UPADE_SUBSCRIPTION_QUANTITY, payload: { subscriptionQuantity, setSubmitting } }),
  deleteSubscriptionQuantity: id => ({ type: actionTypes.DELETE_SUBSCRIPTION_QUANTITY, payload: id }),
  subscriptionQuantityCreated: (subscriptionQuantity) => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_CREATED, payload: subscriptionQuantity }),
  subscriptionQuantitiesLoaded: (subscriptionQuantities) => ({ type: actionTypes.SUBSCRIPTION_QUANTITIES_LOADED, payload: subscriptionQuantities }),
  subscriptionQuantityLoaded: (subscriptionQuantity) => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_LOADED, payload: subscriptionQuantity }),
  subscriptionQuantityUpdated: (subscriptionQuantity) => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_UPDATED, payload: subscriptionQuantity }),
  subscriptionQuantityDeleted: id => ({ type: actionTypes.SUBSCRIPTION_QUANTITY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_SUBSCRIPTION_QUANTITY, function * createSubscriptionQuantitySaga (action) {
    const { subscriptionQuantity, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'subscription_quantities', subscriptionQuantity)
      yield put(actions.subscriptionQuantityCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_QUANTITIES, function * fetchSubscriptionQuantitiesSaga () {
    try {
      const result = yield call(apiClient.get, 'subscription_quantities')
      yield put(actions.subscriptionQuantitiesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_SUBSCRIPTION_QUANTITY, function * fetchSubscriptionQuantitySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `products/${id}`)
      yield put(actions.subscriptionQuantityLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_SUBSCRIPTION_QUANTITY, function * updateSubscriptionQuantitySaga (action) {
    const { subscriptionQuantity, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `subscription_quantities/${subscriptionQuantity.id}`, subscriptionQuantity)
      yield put(actions.subscriptionQuantityUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_SUBSCRIPTION_QUANTITY, function * deleteSubscriptionQuantitySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `subscription_quantities/${id}`)
      yield put(actions.subscriptionQuantityDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
