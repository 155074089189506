import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_PRODUCT_COMBO: 'productCombo/CREATE_PRODUCT_COMBO',
  FETCH_PRODUCT_COMBOS: 'productCombo/FETCH_PRODUCT_COMBOS',
  FETCH_PRODUCT_COMBO: 'productCombo/FETCH_PRODUCT_COMBO',
  UPADE_PRODUCT_COMBO: 'productCombo/UPADE_PRODUCT_COMBO',
  DELETE_PRODUCT_COMBO: 'productCombo/DELETE_PRODUCT_COMBO',

  PRODUCT_COMBO_CREATED: 'productCombo/PRODUCT_COMBO_CREATED',
  PRODUCT_COMBOS_LOADED: 'productCombo/PRODUCT_COMBOS_LOADED',
  PRODUCT_COMBO_LOADED: 'productCombo/PRODUCT_COMBO_LOADED',
  PRODUCT_COMBO_UPDATED: 'productCombo/PRODUCT_COMBO_UPDATED',
  PRODUCT_COMBO_DELETED: 'productCombo/PRODUCT_COMBO_DELETED'
}

const initialState = {
  productCombo: null,
  productCombos: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_COMBO:
      return { ...state, productCombo: action.payload }
    case actionTypes.FETCH_PRODUCT_COMBOS:
      return { ...state, productCombos: null }
    case actionTypes.FETCH_PRODUCT_COMBO:
      return { ...state, productCombo: null }
    case actionTypes.UPADE_PRODUCT_COMBO:
      return { ...state, productCombo: action.payload }
    case actionTypes.DELETE_PRODUCT_COMBO:
      return state

    case actionTypes.PRODUCT_COMBO_CREATED:
      return !state.productCombos ? { ...state, productCombos: [action.payload] } : { ...state, productCombos: [...state.productCombos, action.payload] }
    case actionTypes.PRODUCT_COMBOS_LOADED:
      return { ...state, productCombos: action.payload }
    case actionTypes.PRODUCT_COMBO_LOADED:
      return { ...state, productCombo: action.payload }
    case actionTypes.PRODUCT_COMBO_UPDATED:
      return {
        ...state,
        productCombo: action.payload
      }
    case actionTypes.PRODUCT_COMBO_DELETED:
      return { ...state, productCombos: state.productCombos.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductCombo: (productCombo, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_COMBO, payload: { productCombo, setSubmitting } }),
  fetchProductCombos: () => ({ type: actionTypes.FETCH_PRODUCT_COMBOS }),
  fetchProductCombo: (id) => ({ type: actionTypes.FETCH_PRODUCT_COMBO, payload: id }),
  updateProductCombo: (productCombo, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_COMBO, payload: { productCombo, setSubmitting } }),
  deleteProductCombo: id => ({ type: actionTypes.DELETE_PRODUCT_COMBO, payload: id }),
  productComboCreated: (productCombo) => ({ type: actionTypes.PRODUCT_COMBO_CREATED, payload: productCombo }),
  productCombosLoaded: (productCombos) => ({ type: actionTypes.PRODUCT_COMBOS_LOADED, payload: productCombos }),
  productComboLoaded: (productCombo) => ({ type: actionTypes.PRODUCT_COMBO_LOADED, payload: productCombo }),
  productComboUpdated: (productCombo) => ({ type: actionTypes.PRODUCT_COMBO_UPDATED, payload: productCombo }),
  productComboDeleted: id => ({ type: actionTypes.PRODUCT_COMBO_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_COMBO, function * createProductComboSaga (action) {
    const { productCombo, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_combos', productCombo)
      yield put(actions.productComboCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMBOS, function * fetchProductCombosSaga () {
    try {
      const result = yield call(apiClient.get, 'product_combos')
      yield put(actions.productCombosLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_COMBO, function * fetchProductComboSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `products/${id}`)
      yield put(actions.productComboLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_COMBO, function * updateProductComboSaga (action) {
    const { productCombo, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `products/${productCombo.id}/combo_items`, productCombo)
      yield put(actions.productComboUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_COMBO, function * deleteProductComboSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_combos/${id}`)
      yield put(actions.productComboDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
