import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'
import { insertEmptyRow, insertRows } from 'shared/itemHelper'
// Actions
export const actionTypes = {
  CREATE_PRODUCT_BUNDLE_ITEM: 'productBundleItem/CREATE_PRODUCT_BUNDLE_ITEM',
  FETCH_PRODUCT_BUNDLE_ITEMS: 'productBundleItem/FETCH_PRODUCT_BUNDLE_ITEMS',
  FETCH_PRODUCT_BUNDLE_ITEM: 'productBundleItem/FETCH_PRODUCT_BUNDLE_ITEM',
  UPADE_PRODUCT_BUNDLE_ITEM: 'productBundleItem/UPADE_PRODUCT_BUNDLE_ITEM',
  DELETE_PRODUCT_BUNDLE_ITEM: 'productBundleItem/DELETE_PRODUCT_BUNDLE_ITEM',
  PRODUCT_BUNDLE_ITEM_CREATED: 'productBundleItem/PRODUCT_BUNDLE_ITEM_CREATED',
  PRODUCT_BUNDLE_ITEMS_LOADED: 'productBundleItem/PRODUCT_BUNDLE_ITEMS_LOADED',
  PRODUCT_BUNDLE_ITEM_LOADED: 'productBundleItem/PRODUCT_BUNDLE_ITEM_LOADED',
  PRODUCT_BUNDLE_ITEM_UPDATED: 'productBundleItem/PRODUCT_BUNDLE_ITEM_UPDATED',
  PRODUCT_BUNDLE_ITEM_DELETED: 'productBundleItem/PRODUCT_BUNDLE_ITEM_DELETED',
  INSERT_EMPTY_ROW: 'productBundleItem/INSERT_EMPTY_ROW',
  DELETE_ROW: 'productBundleItem/DELETE_ROW',
  INSERT_ROWS: 'productBundleItem/INSERT_ROWS'
}

const initialState = {
  productBundleItem: null,
  productBundleItems: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_BUNDLE_ITEM:
      return { ...state, productBundleItem: action.payload }
    case actionTypes.FETCH_PRODUCT_BUNDLE_ITEMS:
      return { ...state, productBundleItems: null }
    case actionTypes.FETCH_PRODUCT_BUNDLE_ITEM:
      return { ...state, productBundleItem: null }
    case actionTypes.UPADE_PRODUCT_BUNDLE_ITEM:
      return { ...state, productBundleItem: action.payload }
    case actionTypes.DELETE_PRODUCT_BUNDLE_ITEM:
      return state

    case actionTypes.PRODUCT_BUNDLE_ITEM_CREATED:
      return { ...state, productBundleItems: [...state.productBundleItems, action.payload] }
    case actionTypes.PRODUCT_BUNDLE_ITEMS_LOADED:
      return { ...state, productBundleItems: action.payload }
    case actionTypes.PRODUCT_BUNDLE_ITEM_LOADED:
      return { ...state, productBundleItem: action.payload }
    case actionTypes.PRODUCT_BUNDLE_ITEM_UPDATED:
      return {
        ...state,
        productBundleItem: action.payload,
        productBundleItems: state.productBundleItems.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.PRODUCT_BUNDLE_ITEM_DELETED:
      return { ...state, productBundleItems: state.productBundleItems.filter(item => item.id !== action.payload) }

    case actionTypes.INSERT_EMPTY_ROW:
      return {
        ...state,
        productBundleItems: insertEmptyRow(state.productBundleItems, action.payload)
      }
    case actionTypes.DELETE_ROW: {
      const filterd = state.productBundleItems.filter(item => item.position !== action.payload)
      if (filterd.length === 0) {
        return { ...state, productBundleItems: [{ position: 1 }] }
      } else {
        return { ...state, productBundleItems: filterd.map((item, index) => ({ ...item, position: index + 1 })) }
      }
    }
    case actionTypes.INSERT_ROWS: {
      const nodes = action.payload.rows
      return {
        ...state,
        productBundleItems: insertRows(
          state.productBundleItems,
          nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              qty_limit: 0,
              default_bundled: false,
              product_id: node.data.id,
              bundle_price: 0,
              id: null
            }
          })),
          action.payload.position)
      }
    }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createProductBundleItem: (productBundleItem, setSubmitting) => ({ type: actionTypes.CREATE_PRODUCT_BUNDLE_ITEM, payload: { productBundleItem, setSubmitting } }),
  fetchProductBundleItems: () => ({ type: actionTypes.FETCH_PRODUCT_BUNDLE_ITEMS }),
  fetchProductBundleItem: (id) => ({ type: actionTypes.FETCH_PRODUCT_BUNDLE_ITEM, payload: id }),
  updateProductBundleItem: (productBundleItem, setSubmitting) => ({ type: actionTypes.UPADE_PRODUCT_BUNDLE_ITEM, payload: { productBundleItem, setSubmitting } }),
  deleteProductBundleItem: id => ({ type: actionTypes.DELETE_PRODUCT_BUNDLE_ITEM, payload: id }),
  productBundleItemCreated: (productBundleItem) => ({ type: actionTypes.PRODUCT_BUNDLE_ITEM_CREATED, payload: productBundleItem }),
  productBundleItemsLoaded: (productBundleItems) => ({ type: actionTypes.PRODUCT_BUNDLE_ITEMS_LOADED, payload: productBundleItems }),
  productBundleItemLoaded: (productBundleItem) => ({ type: actionTypes.PRODUCT_BUNDLE_ITEM_LOADED, payload: productBundleItem }),
  productBundleItemUpdated: (productBundleItem) => ({ type: actionTypes.PRODUCT_BUNDLE_ITEM_UPDATED, payload: productBundleItem }),
  productBundleItemDeleted: id => ({ type: actionTypes.PRODUCT_BUNDLE_ITEM_DELETED, payload: id }),
  inertEmptyRow: position => ({ type: actionTypes.INSERT_EMPTY_ROW, payload: position }),
  deleteRow: position => ({ type: actionTypes.DELETE_ROW, payload: position }),
  inertRows: (position, rows) => ({ type: actionTypes.INSERT_ROWS, payload: { position, rows } })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_PRODUCT_BUNDLE_ITEM, function * createProductBundleItemSaga (action) {
    const { productBundleItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'product_bundle_items', productBundleItem)
      yield put(actions.productBundleItemCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_BUNDLE_ITEMS, function * fetchProductBundleItemsSaga () {
    try {
      const result = yield call(apiClient.get, 'product_bundle_items')
      yield put(actions.productBundleItemsLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_PRODUCT_BUNDLE_ITEM, function * fetchProductBundleItemSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `product_bundle_items/${id}`)
      yield put(actions.productBundleItemLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_PRODUCT_BUNDLE_ITEM, function * updateProductBundleItemSaga (action) {
    const { productBundleItem, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `product_bundle_items/${productBundleItem.id}`, productBundleItem)
      yield put(actions.productBundleItemUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_PRODUCT_BUNDLE_ITEM, function * deleteProductBundleItemSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `product_bundle_items/${id}`)
      yield put(actions.productBundleItemDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
