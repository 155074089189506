import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_ACCOUNT_TITLE: 'accountTitle/CREATE_ACCOUNT_TITLE',
  FETCH_ACCOUNT_TITLES: 'accountTitle/FETCH_ACCOUNT_TITLES',
  FETCH_ACCOUNT_TITLE: 'accountTitle/FETCH_ACCOUNT_TITLE',
  UPADE_ACCOUNT_TITLE: 'accountTitle/UPADE_ACCOUNT_TITLE',
  DELETE_ACCOUNT_TITLE: 'accountTitle/DELETE_ACCOUNT_TITLE',

  ACCOUNT_TITLE_CREATED: 'accountTitle/ACCOUNT_TITLE_CREATED',
  ACCOUNT_TITLES_LOADED: 'accountTitle/ACCOUNT_TITLES_LOADED',
  ACCOUNT_TITLE_LOADED: 'accountTitle/ACCOUNT_TITLE_LOADED',
  ACCOUNT_TITLE_UPDATED: 'accountTitle/ACCOUNT_TITLE_UPDATED',
  ACCOUNT_TITLE_DELETED: 'accountTitle/ACCOUNT_TITLE_DELETED'
}

const initialState = {
  accountTitle: null,
  accountTitles: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ACCOUNT_TITLE:
      return { ...state, accountTitle: action.payload }
    case actionTypes.FETCH_ACCOUNT_TITLES:
      return { ...state, accountTitles: null }
    case actionTypes.FETCH_ACCOUNT_TITLE:
      return { ...state, accountTitle: null }
    case actionTypes.UPADE_ACCOUNT_TITLE:
      return { ...state, accountTitle: action.payload }
    case actionTypes.DELETE_ACCOUNT_TITLE:
      return state

    case actionTypes.ACCOUNT_TITLES_LOADED:
      return { ...state, accountTitles: action.payload }
    case actionTypes.ACCOUNT_TITLE_LOADED:
      return { ...state, accountTitle: action.payload }
    case actionTypes.ACCOUNT_TITLE_UPDATED:
      return {
        ...state,
        accountTitle: action.payload,
        accountTitles: state.accountTitles.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.ACCOUNT_TITLE_DELETED:
      return { ...state, accountTitles: state.accountTitles.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createAccountTitle: (accountTitle, setSubmitting) => ({ type: actionTypes.CREATE_ACCOUNT_TITLE, payload: { accountTitle, setSubmitting } }),
  fetchAccountTitles: () => ({ type: actionTypes.FETCH_ACCOUNT_TITLES }),
  fetchAccountTitle: (id) => ({ type: actionTypes.FETCH_ACCOUNT_TITLE, payload: id }),
  updateAccountTitle: (accountTitle, setSubmitting) => ({ type: actionTypes.UPADE_ACCOUNT_TITLE, payload: { accountTitle, setSubmitting } }),
  deleteAccountTitle: id => ({ type: actionTypes.DELETE_ACCOUNT_TITLE, payload: id }),
  accountTitlesLoaded: (accountTitles) => ({ type: actionTypes.ACCOUNT_TITLES_LOADED, payload: accountTitles }),
  accountTitleLoaded: (accountTitle) => ({ type: actionTypes.ACCOUNT_TITLE_LOADED, payload: accountTitle }),
  accountTitleUpdated: (accountTitle) => ({ type: actionTypes.ACCOUNT_TITLE_UPDATED, payload: accountTitle }),
  accountTitleDeleted: id => ({ type: actionTypes.ACCOUNT_TITLE_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_ACCOUNT_TITLE, function * createAccountTitleSaga (action) {
    const { accountTitle, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'account_titles', accountTitle)
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_TITLES, function * fetchAccountTitlesSaga () {
    try {
      const result = yield call(apiClient.get, 'account_titles')
      yield put(actions.accountTitlesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_ACCOUNT_TITLE, function * fetchAccountTitleSaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `account_titles/${id}`)
      yield put(actions.accountTitleLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_ACCOUNT_TITLE, function * updateAccountTitleSaga (action) {
    const { accountTitle, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `account_titles/${accountTitle.id}`, accountTitle)
      yield put(actions.accountTitleUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_ACCOUNT_TITLE, function * deleteAccountTitleSaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `account_titles/${id}`)
      yield put(actions.accountTitleDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
