import { put, call, fork, takeLatest } from 'redux-saga/effects'
import apiClient from 'admin/api/apiClient'
import { notifyError, notifySuccess } from 'shared/sharedMethod'

// Actions
export const actionTypes = {
  CREATE_COMPANY: 'company/CREATE_COMPANY',
  FETCH_COMPANIES: 'company/FETCH_COMPANIES',
  FETCH_COMPANY: 'company/FETCH_COMPANY',
  UPADE_COMPANY: 'company/UPADE_COMPANY',
  DELETE_COMPANY: 'company/DELETE_COMPANY',

  COMPANY_CREATED: 'company/COMPANY_CREATED',
  COMPANIES_LOADED: 'company/COMPANIES_LOADED',
  COMPANY_LOADED: 'company/COMPANY_LOADED',
  COMPANY_UPDATED: 'company/COMPANY_UPDATED',
  COMPANY_DELETED: 'company/COMPANY_DELETED'
}

const initialState = {
  company: null,
  companies: null
}

// Reducer
export function reducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_COMPANY:
      return { ...state, company: action.payload }
    case actionTypes.FETCH_COMPANIES:
      return { ...state, companies: null }
    case actionTypes.FETCH_COMPANY:
      return { ...state, company: null }
    case actionTypes.UPADE_COMPANY:
      return { ...state, company: action.payload }
    case actionTypes.DELETE_COMPANY:
      return state

    case actionTypes.COMPANY_CREATED:
      return { ...state, companies: [...state.companies, action.payload] }
    case actionTypes.COMPANIES_LOADED:
      return { ...state, companies: action.payload }
    case actionTypes.COMPANY_LOADED:
      return { ...state, company: action.payload }
    case actionTypes.COMPANY_UPDATED:
      return {
        ...state,
        company: action.payload,
        companies: state.companies.map(item => item.id === +action.payload.id ? action.payload : item)
      }
    case actionTypes.COMPANY_DELETED:
      return { ...state, companies: state.companies.filter(item => item.id !== action.payload) }

    default:
      return state
  }
}

// Action Creators
export const actions = {
  createCompany: (company, setSubmitting) => ({ type: actionTypes.CREATE_COMPANY, payload: { company, setSubmitting } }),
  fetchCompanies: () => ({ type: actionTypes.FETCH_COMPANIES }),
  fetchCompany: (id) => ({ type: actionTypes.FETCH_COMPANY, payload: id }),
  updateCompany: (company, setSubmitting) => ({ type: actionTypes.UPADE_COMPANY, payload: { company, setSubmitting } }),
  deleteCompany: id => ({ type: actionTypes.DELETE_COMPANY, payload: id }),
  companyCreated: (company) => ({ type: actionTypes.COMPANY_CREATED, payload: company }),
  companiesLoaded: (companies) => ({ type: actionTypes.COMPANIES_LOADED, payload: companies }),
  companyLoaded: (company) => ({ type: actionTypes.COMPANY_LOADED, payload: company }),
  companyUpdated: (company) => ({ type: actionTypes.COMPANY_UPDATED, payload: company }),
  companyDeleted: id => ({ type: actionTypes.COMPANY_DELETED, payload: id })
}

// Saga
export function * saga () {
  yield takeLatest(actionTypes.CREATE_COMPANY, function * createCompanySaga (action) {
    const { company, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.post, 'companies', company)
      yield put(actions.companyCreated(result.data.data))
      notifySuccess('新增成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.FETCH_COMPANIES, function * fetchCompaniesSaga () {
    try {
      const result = yield call(apiClient.get, 'companies')
      yield put(actions.companiesLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.FETCH_COMPANY, function * fetchCompanySaga (action) {
    try {
      const id = action.payload
      const result = yield call(apiClient.get, `companies/${id}`)
      yield put(actions.companyLoaded(result.data.data))
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })

  yield takeLatest(actionTypes.UPADE_COMPANY, function * updateCompanySaga (action) {
    const { company, setSubmitting } = action.payload
    try {
      const result = yield call(apiClient.put, `companies/${company.id}`, company)
      yield put(actions.companyUpdated(result.data.data))
      notifySuccess('更新成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    } finally {
      setSubmitting(false)
    }
  })

  yield takeLatest(actionTypes.DELETE_COMPANY, function * deleteCompanySaga (action) {
    try {
      const id = action.payload
      yield fork(apiClient.delete, `companies/${id}`)
      yield put(actions.companyDeleted(id))
      notifySuccess('刪除成功')
    } catch (e) {
      notifyError(e.response ? e.response.statusText : e.message)
    }
  })
}
